import React, { useEffect, useState } from "react";
import styles from "../../../../Components/NewDashboard/AddProducts/ScheduleMeetingForm.module.css";
import { Autocomplete, Avatar, Box, TextField, Chip,Typography } from "@mui/material";
import InputField from "Components/Common/InputField/InputField";
import { GetDirectors, GetManagers } from "Api/Backend/backend";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Add } from "@mui/icons-material";

const AddTeamForm = ({ setTeamName, userid, selectedDirector,setSelectedDirector,selectedManager,setSelectedManager }) => {
  const [managerlist, setManagerList] = useState([]);
  const [directorList, setDirectorList] = useState([]);

  
  const getManager = async (id) => {
    const response = await GetManagers(id)
      .then((response) => {
        setManagerList(response);
      })
      .catch((e) => console.log(e));
  };

  const getDirector = async (id) => {
    const response = await GetDirectors(id)
      .then((response) => {
        setDirectorList(response);
      })
      .catch((e) => console.log(e));
  };
 
  useEffect(() => {
    if (userid) {
      getManager(userid);
      getDirector(userid);
    }
  }, []);

  const handleManager = (event, value) => {
    setSelectedManager(value);
  };
  const handleDirector = (event, value) => {
    setSelectedDirector(value);
  };
 
  return (
    <>
      <Box className={styles.scheduleMeetingFormContainer}>
        <Box className={styles.formRow}>
          <InputField
            placeholder="Team Name"
            bgColor="null"
            editable={true}
            inputHeight="50"
            inputWidth="550"
            setter={setTeamName}
          />
        </Box>
    
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>Manager</div>
        </Box>
        <Box sx={{backgroundColor:"rgba(64, 64, 64) !important", padding:"10px",borderRadius:"10px"}} className={styles.formRow}>
         <>
            <AccountCircleIcon fontSize="small" sx={{ color: "#77767C" }} />

            {Array.isArray(managerlist) && managerlist.length > 0 ? (
              <Autocomplete
                popupIcon={<Add sx={{ color: "rgba(255, 255, 255, 0.50)" }} />}
                clearIcon={
                  <KeyboardBackspaceIcon
                    sx={{ color: "rgba(255, 255, 255, 0.50)" }}
                  />
                }
                
                sx={{
                  width: "100%",
                  
                }}
                options={managerlist}
                getOptionLabel={(option) => option.email}
                filterSelectedOptions
                defaultValue={"Add Manager"}
                onChange={handleManager}
                value={selectedManager}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Assign Manager"
                    sx={{
                      "& input": {
                        color: "rgba(255, 255, 255, 0.50) !important",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottom: "none !important", // Customize the hover border color here
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "none !important", // Customize the border color here
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none !important", // Customize the focus border color here
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box sx={{
                    background: "rgba(64, 64, 64, 1)", color: "rgba(255, 255, 255, 0.50)",
                    "&: hover": { backgroundColor: "rgba(64, 64, 64, 0.8) !important", color: "rgba(255, 255, 255, 0.50) " }
                  }} {...props}>
                    <Avatar
                      sx={{
                        background:
                          "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                        border: option.user_image ? "none" : "0.5px dashed #807BC6",
                        color: "#3b358a !important",
                        fontWeight: "700 !important",
                        fontSize: "14px !important",
                        marginRight: "5px"
                      }}
                      src={option.user_image}
                      alt="icon"
                    />
                    
                    {option.first_name+ " "+ option.last_name + " ("+option.email+")"}
                  </Box>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{
                        "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                          {
                            color: "rgba(255, 255, 255, 0.50) !important",
                          },
                        color: "rgba(255, 255, 255, 0.50) !important",
                      }}
                      key={index}
                      label={option.email}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderNoOptions={() => (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      mt: 2,
                      fontStyle: "italic",
                      color: "rgba(255, 255, 255, 0.50)",
                    }}
                  >
                    No Managers
                  </Typography>
                )}
              />
            ) : (
              <Typography
                variant="body1"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "rgba(255, 255, 255, 0.50) !important",
                }}
              >
                {Array.isArray(managerlist) && managerlist.length >0
                  ? "Loading..."
                  : "No Manager"}
              </Typography>
            )}
          </>
        </Box>
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>Director</div>
        </Box>
        <Box sx={{backgroundColor:"rgba(64, 64, 64) !important", padding:"10px",borderRadius:"10px"}} className={styles.formRow}>
         <>
            <AccountCircleIcon fontSize="small" sx={{ color: "#77767C" }} />

            {Array.isArray(directorList) && directorList.length > 0 ? (
              <Autocomplete
                popupIcon={<Add sx={{ color: "rgba(255, 255, 255, 0.50)" }} />}
                clearIcon={
                  <KeyboardBackspaceIcon
                    sx={{ color: "rgba(255, 255, 255, 0.50)" }}
                  />
                }
                
                sx={{ width: "100%" }}
                options={directorList}
                getOptionLabel={(option) => option.email}
                filterSelectedOptions
                defaultValue={"Add Director"}
                onChange={handleDirector}
                value={selectedDirector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Assign Director"
                    sx={{
                      "& input": {
                        color: "rgba(255, 255, 255, 0.50) !important",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottom: "none !important", // Customize the hover border color here
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "none !important", // Customize the border color here
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none !important", // Customize the focus border color here
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box sx={{
                    background: "rgba(64, 64, 64, 1)", color: "rgba(255, 255, 255, 0.50)",
                    "&: hover": { backgroundColor: "rgba(64, 64, 64, 0.8) !important", color: "rgba(255, 255, 255, 0.50) " }
                  }} {...props}>
                    <Avatar
                      sx={{
                        background:
                          "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                        border: option.user_image ? "none" : "0.5px dashed #807BC6",
                        color: "#3b358a !important",
                        fontWeight: "700 !important",
                        fontSize: "14px !important",
                        marginRight:"5px"
                      }}
                      src={option.user_image}
                      alt="icon"
                    />
                    {option.first_name + " " + option.last_name + " (" + option.email + ")"}
                  </Box>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{
                        "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                          {
                            color: "rgba(255, 255, 255, 0.50) !important",
                          },
                        color: "rgba(255, 255, 255, 0.50) !important",
                      }}
                      key={index}
                      label={option.email}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderNoOptions={() => (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      mt: 2,
                      fontStyle: "italic",
                      color: "rgba(255, 255, 255, 0.50)",
                    }}
                  >
                    No Directors
                  </Typography>
                )}
              />
            ) : (
              <Typography
                variant="body1"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "rgba(255, 255, 255, 0.50) !important",
                }}
              >
                {Array.isArray(directorList) && directorList.length >0
                  ? "Loading..."
                  : "No Director"}
              </Typography>
            )}
          </>
        </Box>

    
        <Box
          sx={{
            marginTop: "20px",
            borderTop:
              "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
          }}
          className={styles.formRow}
        ></Box>

       
      </Box>
    </>
  );
};
AddTeamForm.defaultProps = {
  setManager: () => {},
  setTeamName: () => {},
};
export default AddTeamForm;
