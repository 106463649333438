import React from "react";
import cardImage from "../../Assets/cardImage.png";
import videoInfoIcon from "../../Assets/videoinfoIcon.svg";
import clockIcon from "../../Assets/clockIcon.svg";
import playIcon from "../../Assets/playIcon.svg";
import { Typography } from "@mui/material";
import styles from "./featuredcard.module.css";

const FeaturedCard = ({ bImage, title, description, runtime }) => {
  return (
    <div className={styles.mainContainer}>
      <div
        style={{
          borderRadius: "8px",
          backgroundImage: `url(${bImage})`,
          width: "100%",
          height: "150px",
        }}
      ></div>
      <div style={{ width: "100%", marginLeft: "15px" }}>
        <div className={styles.videoInfoContainer}>
          <Typography className={styles.moduleTitle}>
            Module Title Here
          </Typography>

          <div className={styles.flexRow}>
            <img style={{ margin: "0" }} src={clockIcon} />
            <Typography className={styles.videoInfo}>{runtime}</Typography>
          </div>
        </div>
        <div style={{ width: "90%" }}>
          <Typography className={styles.videoTitle}>{title}</Typography>
          <Typography className={styles.videoDescription}>
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
};

FeaturedCard.defaultProps = {
  bImage: cardImage,
  title: " Nature’s Reset",
  description:
    "This is the Client's guide to the Webflow Editor. Share these lessons to get your clients up and running.",
  runtime: "45 min",
};

export default FeaturedCard;
