import React, { useState } from "react";
import { Button } from "@material-ui/core";
import styles from "./SwitchContainer.module.css";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";

const SwitchContainer = ({ setActiveButton, activeButton, setValue }) => {
  // const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonNumber) => {
    setValue(0);

    setActiveButton(buttonNumber);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button
        size="small"
        startIcon={<DataUsageIcon fontSize="small" />}
        className={
          activeButton === 1 ? styles.buttonActive : styles.buttonInActive
        }
        onClick={() => handleButtonClick(1)}
      >
        Meeting Room
      </Button>
      <Button
        startIcon={<FolderSpecialIcon />}
        className={
          activeButton === 2 ? styles.buttonActive : styles.buttonInActive
        }
        onClick={() => handleButtonClick(2)}
      >
        Advance Stats
      </Button>
    </div>
  );
};

export default SwitchContainer;
