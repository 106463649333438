import React, { useEffect, useState } from "react";
import styles from "./Library.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch } from "react-redux";
import HeartIcon from "../../../Assets/heartIcon.svg";
import LibraryIconGraySmall from "../../../Assets/libraryIconGraySmall.svg";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import CustomTabs from "Components/Common/Tabs/CustomTabs";
import MenuList from "./MenuList";
import DocumentTab from "Components/SalesRep/DocumentTab/DocumentTab";
import axios from "axios";
import { Modal } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteFilesModal from "Pages/SalesRep/Library/DeleteFilesModal";
import { baseUrl } from "../../../Api/Backend/backend";
import { toast } from "react-toastify";
import { setCurrentRoute } from "Store/features/currentRoute";

const Library = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pdfDocs, setPdfDocs] = useState([]);
  const [videoDocs, setVideoDocs] = useState([]);
  const [imageDocs, setImageDocs] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [favouriteDocs, setFavouriteDocs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteButtonActive, setDeleteButtonActive] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [tabOptions, setTabOptions] = useState([
    { title: "Documents", value: "0", selected: "document" },
  ]);

  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleDeleteAllClick = (index) => {
    setDeleteModalOpen(true);
  };
  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleUpload = async () => {
    try {
      setUploading(true);
      const user = JSON.parse(sessionStorage.getItem("user"));

      const formData = new FormData();
      formData.append("user_id", user.user_id);
      formData.append("file", selectedFile);

      const response = await axios.post(
        `${baseUrl}add_meeting_document/`,
        formData
      );

      // Handle the response as needed

      // Reset the selected file and close the modal
      setSelectedFile(null);
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      getAllDocs(user.user_id);
    }
  }, [modalOpen, deleteModalOpen, selectedIndex]);

  const handleFileUpload = (files) => {
    // Perform any necessary actions with the uploaded files
    setUploadedFiles(files);
    setModalOpen(false); // Close the modal after uploading
  };

  const getAllDocs = async (id) => {
    const resp = await axios
      .post(`${baseUrl}get_documents/`, {
        user_id: id,
      })
      .then((response) => {
        setImageDocs(response.data.image_documents);
        setPdfDocs(response.data.pdf_documents);
        setVideoDocs(response.data.video_documents);
        setAllDocs(response.data.all_documents);
        setFavouriteDocs(response.data.favourite_documents);

        const updatedTabOptions = [
          {
            title: "Documents",
            value: String(response.data.length),
            selected: "document",
          },
        ];

        setTabOptions(updatedTabOptions);
      })
      .catch((e) => console.log(e));
  };

  const handleDelete = async () => {
    const payload = {
      document_id: selectedIds,
      // Add other required fields here if needed
    };

    try {
      const resp = await axios.delete(`${baseUrl}delete_document/`, {
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      toast.success("Documents Deleted Successfully !");
      setDeleteModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const dummyOptions = [
    { id: "0", value: "Sort by" },
    { id: "1", value: "Today" },
    { id: "2", value: "Yesterday" },
    { id: "3", value: "This Week" },
  ];

  const productTemplateOptions = [
    { icon: HeartIcon, text: "My favourites" },
    { icon: LibraryIconGraySmall, text: "Medicine" },
    { icon: LibraryIconGraySmall, text: "Treatment Drugs" },
    { icon: LibraryIconGraySmall, text: "Others" },
  ];

  const dummyProducts = [
    {
      title: "Paracetamol",
      description:
        "Treat pain and reduce a high temperature. It's typically used to relieve mild or moderate pain",
    },
    {
      title: "Januvia",
      description:
        "Treat pain and reduce a high temperature. It's typically used to relieve mild or moderate pain",
    },
    {
      title: "Entresto",
      description:
        "Treat pain and reduce a high temperature. It's typically used to relieve mild or moderate pain",
    },
    {
      title: "Methadone",
      description:
        "Treat pain and reduce a high temperature. It's typically used to relieve mild or moderate pain",
    },
  ];

  const dummyDocuments = [
    { title: "Paracetamol" },
    { title: "Paracetamol" },
    { title: "Paracetamol" },
    { title: "Paracetamol" },
    { title: "Paracetamol" },
    { title: "Paracetamol" },
    { title: "Paracetamol" },
    { title: "Paracetamol" },
  ];

  const libraryOptions = [
    { icon: HeartIcon, text: "My favourites" },
    { icon: LibraryIconGraySmall, text: "PDF documents" },
    { icon: LibraryIconGraySmall, text: "Video files" },
    { icon: LibraryIconGraySmall, text: "Image files" },
    { icon: LibraryIconGraySmall, text: "All documents" },
  ];

  const [selected, setSelected] = React.useState(tabOptions[0].selected);
  const [selectedListItemProduct, setSelectedListItemProduct] = React.useState(
    productTemplateOptions[0].text
  );
  const [selectedListItemLibrary, setSelectedListItemLibrary] = React.useState(
    libraryOptions[0].text
  );
  const [cardHeaderProduct, setCardHeaderProduct] = React.useState(
    productTemplateOptions[0].text
  );
  const [cardHeaderLibrary, setCardHeaderLibrary] = React.useState(
    libraryOptions[0].text
  );
  function getFileAcceptTypes(selectedIndex) {
    switch (selectedIndex) {
      case 0:
        return "application/pdf,image/jpeg,image/png,image/gif,video/mp4,video/avi,video/quicktime";
      case 1:
        return "application/pdf";
      case 3:
        return "image/jpeg,image/png,image/gif";
      case 2:
        return "video/mp4,video/avi,video/quicktime";
      case 4:
        return "";
      default:
        return "application/pdf";
    }
  }
  function getDocTypes(selectedIndex) {
    switch (selectedIndex) {
      case 0:
        return favouriteDocs;
      case 1:
        return pdfDocs;
      case 3:
        return imageDocs;
      case 2:
        return videoDocs;
      case 4:
        return allDocs;
      default:
        return allDocs;
    }
  }
  useEffect(() => {
    dispatch(setCurrentRoute(4));
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        fluid
        maxWidth={true}
        sx={{ padding: "0vh 0vh 3vh 0vh !important", width: "90%" }}
      >
        <Container maxWidth={true} className={styles.container}>
          <Container maxWidth={true} sx={{ padding: "0px !important" }}>
            <Box className={styles.titleContainer}>
              <Typography
                className={styles.title}
                fontSize={24}
                fontWeight={600}
              >
                Library
              </Typography>
            </Box>
            <Box>
              <CustomTabs
                tabOptions={tabOptions}
                setSelected={setSelected}
                selected={selected}
              />
            </Box>
            <Container maxWidth={true} className={styles.secondaryContainer}>
              <Container maxWidth={true} className={styles.libraryContainer}>
                {selected == "product_templates" ? (
                  <>
                    <Container maxWidth={true} className={styles.libraryFlex}>
                      <Box className={styles.menuSection}>
                        <MenuList
                          list={productTemplateOptions}
                          selected={selectedListItemProduct}
                          setSelected={setCardHeaderProduct}
                        />
                      </Box>
                      <Box className={styles.cardSection}>
                        <Box className={styles.titleContainer}>
                          <Typography
                            className={styles.title}
                            fontSize={20}
                            fontWeight={700}
                            sx={{
                              fontFamily: "Red Hat Display !important",
                              color: "#201F28",
                            }}
                          >
                            {cardHeaderProduct}
                          </Typography>
                        </Box>
                        <Box className={styles.cardListContainer}>
                          <DocumentTab
                            type="page"
                            variant="product"
                            data={dummyProducts}
                          />
                        </Box>
                      </Box>
                    </Container>
                  </>
                ) : (
                  <>
                    <Container maxWidth={true} className={styles.libraryFlex}>
                      <Box className={styles.menuSection}>
                        <Box className={styles.searchFieldContainer}></Box>
                        <MenuList
                          setSelectedIndex={setSelectedIndex}
                          list={libraryOptions}
                          selected={selectedListItemLibrary}
                          setSelected={setCardHeaderLibrary}
                        />
                      </Box>
                      <Box className={styles.cardSection}>
                        <Box className={styles.titleContainer}>
                          <Typography
                            className={styles.title}
                            fontSize={20}
                            fontWeight={700}
                            sx={{
                              fontFamily: "Red Hat Display !important",
                              color: "#201F28",
                            }}
                          >
                            {cardHeaderLibrary}
                          </Typography>
                          {selectedIndex === 0 ? (
                            <Box display="flex" alignItems="center">
                              <Button
                                className={
                                  deleteButtonActive ? styles.DeleteButton : ""
                                }
                                style={{ marginLeft: "10px" }}
                                onClick={() => setDeleteModalOpen(true)}
                                disabled={!deleteButtonActive}
                              >
                                Delete All
                              </Button>
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Button
                                className={
                                  deleteButtonActive ? styles.DeleteButton : ""
                                }
                                style={{ marginLeft: "10px" }}
                                onClick={() => setDeleteModalOpen(true)}
                                disabled={!deleteButtonActive}
                              >
                                Delete All
                              </Button>
                              <Button
                                className={
                                  deleteButtonActive ? "" : styles.AddDocButton
                                }
                                style={{ marginLeft: "10px" }}
                                disabled={deleteButtonActive}
                                onClick={() => setModalOpen(true)}
                              >
                                Upload
                              </Button>
                            </Box>
                          )}
                          <Modal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            aria-labelledby="upload-modal-title"
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                width: 600,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="h6" id="upload-modal-title">
                                Upload Document
                              </Typography>
                              <Box
                                sx={{
                                  height: 200,
                                  width: "100%",
                                  border: "1px dashed #ccc",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 2,
                                  marginBottom: 2,
                                  padding: 2,
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                                onDragOver={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                onDrop={handleFileDrop}
                              >
                                <label htmlFor="file-upload-input">
                                  <input
                                    id="file-upload-input"
                                    type="file"
                                    accept={getFileAcceptTypes(selectedIndex)} // Specify the accepted file types if necessary
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                  <AddCircleOutlineIcon
                                    sx={{
                                      ml: 10,
                                      fontSize: 60,
                                      position: "absolute",
                                      color: "rgba(0, 0, 0, 0.3)",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      textAlign: "center",
                                      paddingTop: "50px",
                                    }}
                                  >
                                    Drag a file here or click to browse
                                  </Typography>
                                </label>
                              </Box>
                              {selectedFile && (
                                <Typography variant="h6" sx={{ marginTop: 2 }}>
                                  Selected file: {selectedFile.name}
                                </Typography>
                              )}

                              <Button
                                onClick={handleUpload}
                                disabled={!selectedFile || uploading}
                                className={
                                  !selectedFile || uploading
                                    ? ""
                                    : styles.AddDocButton
                                }
                                sx={{ cursor: "pointer" }}
                              >
                                Upload
                              </Button>
                              <Button
                                onClick={() => {
                                  setModalOpen(false);
                                  setSelectedFile(null);
                                }}
                                sx={{
                                  backgroundColor: "#ffffff",
                                  color: "#201F28",
                                  borderRadius: "4px",
                                  border: "1px solid #E9E9E9",
                                  height: "40px",
                                  padding: "9px 16px 9px 16px",
                                  fontSize: "14px",
                                  textTransform: "none",
                                  width: "100px",
                                }}
                              >
                                Close
                              </Button>
                            </Box>
                          </Modal>
                        </Box>

                        <Box className={styles.cardListContainer}>
                          <DocumentTab
                            type="page"
                            getAllDocs={getAllDocs}
                            selectedIndex={selectedIndex}
                            variant="document"
                            data={getDocTypes(selectedIndex)}
                            setSelectedIds={setSelectedIds}
                            selectedIds={selectedIds}
                            setDeleteButtonActive={setDeleteButtonActive}
                          />
                        </Box>
                      </Box>
                    </Container>
                  </>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      {deleteModalOpen && (
        <>
          <DeleteFilesModal
            isOpen={deleteModalOpen}
            onClose={handleCancelDelete}
            onDelete={handleDelete}
            title={"Are you sure you want to delete all selected documents?"}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default Library;
