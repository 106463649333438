import React from "react";
import styles from "./GridView.module.css";
import DonutChartSmall from "../DonutChart/DonutChartSmall";
import { Box, Button, IconButton, Typography } from "@mui/material";
import MeetingStatsCard from "../MeetingStatsCard/MeetingStatsCard";
import LinearGraph from "Pages/Meeting/Screens/LinearGraph";
import attentiveIcon from "../../Assets/AttentiveIcon.svg";
import valueIcon from "../../Assets/valueIcon.svg";
import moodIcon from "../../Assets/moodIcon.svg";
import { DataUsage, FolderSpecial, Timeline } from "@mui/icons-material";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import TimelineIcon from "@mui/icons-material/Timeline";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import FullWidthTabs from "../MeetingTabs/MeetingTabs";
import SuggestionBar from "../SuggestionBar/SuggestionBar";
const GridView = ({
  prevSpeechEmotionValue,
  speechEmotionValue,
  attentionGraphScore,
  note,
  documents,
  userName,
  moodGraphScore,
  inMeetingNotes,
  valueGraphScore,
  alignmentValue,
  prevValue,
  prevMood,
  prevAttention,
  resonanceScore,
  value,
  gazeValue,
  speechEmotionOutcome,
  gazeOutcome,
  alignmentOutcome,
  setValue,
  meetingProgress,
  advanceAnalytics,
  moodScore,
  valueScore,
  attentionScore,
  setAdvanceAnalytics,
}) => {
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  const getMeetingProgress = (perc) => {
    const score = perc;
    if (score >= 0 && score < 30) {
      return "negative";
    } else if (score >= 30 && score < 50) {
      return "average";
    } else if (score >= 50) {
      return "positive";
    }
  };
  return (
    <Box className={styles.statsFlex}>
      <Box
        sx={{
          width: "100%",
          // marginBottom: "50px",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Button
            startIcon={<DataUsage />}
            disableRipple={true}
            className={styles.buttonInActive}
            // onClick={() => handleButtonClick(1)}
          >
            Real Time Analytics
          </Button>
        </Box>
        <>
          <Box
            sx={{
              width: "100%",

              marginBottom: "50px",
            }}
          >
            <MeetingStatsCard
              advanceAnalytics={false}
              resonanceScore={resonanceScore}
              barColor={barBg(resonanceScore)}
              userName={userName}
              affectScore={getMeetingProgress(resonanceScore)}
            />
            <div
              className={styles.suggestionBarContainer}
              // style={{
              //   display: "flex",
              //   // padding: " 2px 20px",
              //   maxWidth: "100%",
              //   width: "100%",
              //   overflowX: "auto",
              //   alignItems: "center",
              //   gap: "10px ",
              //   // alignSelf: "stretch",
              // }}
            >
              <SuggestionBar title={"MOeen"} />
              <SuggestionBar />
              <SuggestionBar title={"MOeen"} />
              <SuggestionBar title={"MOeen"} />
              <SuggestionBar title={"MOeen"} />
            </div>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                height: "22vh",
                alignItems: "center",
                // background: "pink",
              }}
            >
              {/* <Box
                          sx={{
                            width: "33%",
                          }}
                        >
                          <DonutChartSmall
                            value={resonanceScore.toString()}
                            x={40}
                            cutout={"85%"}
                            fontSize={"28px"}
                            y={-2.7}
                            barColor={barBg(resonanceScore)}
                          />
                          <Typography className={styles.advanceTitles}>
                            Affect
                            <br /> Resonance
                          </Typography>
                        </Box> */}
              {/* <Box sx={{ width: "100%" }}> */}
              <div
                style={{
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <img alt="icon" src={attentiveIcon} />
                <div style={{ margin: "auto 5px", width: "50%" }}>
                  <Typography className={styles.scoreValue}>Curious</Typography>
                  <Typography className={styles.scoreText}>
                    Attention
                  </Typography>
                </div>
                <LinearGraph
                  inMeeting={true}
                  graphScore={attentionGraphScore}
                  type={"att"}
                />
                <Typography className={styles.graphXaxis}>60 sec</Typography>
              </div>
              <div
                style={{
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <img alt="icon" src={moodIcon} />
                <div style={{ margin: "auto 5px", width: "50%" }}>
                  <Typography className={styles.scoreValue}>
                    Distracted
                  </Typography>
                  <Typography className={styles.scoreText}>Mood</Typography>
                </div>
                <LinearGraph
                  inMeeting={true}
                  graphScore={moodGraphScore}
                  type={"mood"}
                />
                <Typography className={styles.graphXaxis}>60 sec</Typography>
              </div>
              <div
                style={{
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <img alt="icon" src={valueIcon} />
                <div style={{ margin: "auto 5px", width: "50%" }}>
                  <Typography className={styles.scoreValue}>
                    Interested
                  </Typography>
                  <Typography className={styles.scoreText}>Value</Typography>
                </div>
                <LinearGraph
                  inMeeting={true}
                  graphScore={valueGraphScore}
                  type={"value"}
                />
                <Typography className={styles.graphXaxis}>60 sec</Typography>
              </div>
              {/* </Box> */}
            </Box>
            {/* <Box
                        sx={{
                          display: "flex !important",
                          alignItems: "center",
                          justifyContent: "space-between !important",
                          width: "100% !important",
                        }}
                      >
                        <Box sx={{ width: "30%" }}>
                          <DonutChartSmall
                            value={attentionScore.toString()}
                            x={0}
                            y={-2.5}
                            barColor={barBg(attentionScore)}
                          />
                          <Typography className={styles.donutChartHeading}>
                            Attentional
                            <br />
                            Ecnomics
                          </Typography>
                        </Box>
                        <Box sx={{ width: "30%" }}>
                          <DonutChartSmall
                            value={moodScore.toString()}
                            x={0}
                            y={-2.5}
                            barColor={barBg(moodScore)}
                          />
                          <Typography className={styles.donutChartHeading}>
                            Mood
                            <br /> Indication
                          </Typography>
                        </Box>
                        <Box sx={{ width: "30%" }}>
                          <DonutChartSmall
                            value={valueScore.toString()}
                            x={0}
                            y={-2.5}
                            barColor={barBg(valueScore)}
                          />
                          <Typography className={styles.donutChartHeading}>
                            Value
                            <br /> Internalization
                          </Typography>
                        </Box>
                      </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Typography className={styles.advanceScoreValue}>
                              {attentionScore}%
                            </Typography> */}
                  <Typography className={styles.motionHeadings}>
                    {speechEmotionOutcome}
                  </Typography>
                  {attentionScore > prevAttention ? (
                    <ArrowUpwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "#28CA90",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#28CA903D",
                      }}
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "var(--dark-red-0, #E51A3F)",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#E51A3F3D",
                      }}
                    />
                  )}
                </Box>
                <Typography className={styles.advanceScoreValueTitle}>
                  Talk:Listen Ratio
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Typography className={styles.advanceScoreValue}>
                              {valueScore}%
                            </Typography> */}
                  <Typography
                    className={
                      gazeValue === "Attentive" || gazeValue === "Looking Away"
                        ? styles.motionHeadings
                        : styles.emptyMotionHeadings
                    }
                  >
                    {gazeOutcome}
                  </Typography>

                  {gazeValue === "Attentive" ? (
                    <ArrowUpwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "#28CA90",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#28CA903D",
                      }}
                    />
                  ) : gazeValue === "Looking Away" ? (
                    <ArrowDownwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "var(--dark-red-0, #E51A3F)",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#E51A3F3D",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <Typography className={styles.advanceScoreValueTitle}>
                  Voice Pace & Pitch
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Typography className={styles.advanceScoreValue}>
                              {moodScore}%
                            </Typography> */}
                  <Typography className={styles.motionHeadings}>
                    {alignmentOutcome}
                  </Typography>

                  {alignmentValue === "Forward" ? (
                    <ArrowUpwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "#28CA90",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#28CA903D",
                      }}
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "var(--dark-red-0, #E51A3F)",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#E51A3F3D",
                      }}
                    />
                  )}
                </Box>
                <Typography className={styles.advanceScoreValueTitle}>
                  Body ALignment
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={styles.motionHeadings}>
                    {speechEmotionOutcome}
                  </Typography>

                  {speechEmotionValue > prevSpeechEmotionValue ? (
                    <ArrowUpwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "#28CA90",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#28CA903D",
                      }}
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "var(--dark-red-0, #E51A3F)",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#E51A3F3D",
                      }}
                    />
                  )}
                </Box>
                <Typography className={styles.motionTitle}>
                  Voice Indicator
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className={
                      gazeValue === "Attentive" || gazeValue === "Looking Away"
                        ? styles.motionHeadings
                        : styles.emptyMotionHeadings
                    }
                  >
                    {gazeOutcome}
                  </Typography>
                  {gazeValue === "Attentive" ? (
                    <ArrowUpwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "#28CA90",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#28CA903D",
                      }}
                    />
                  ) : gazeValue === "Looking Away" ? (
                    <ArrowDownwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "var(--dark-red-0, #E51A3F)",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#E51A3F3D",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <Typography className={styles.motionTitle}>Gaze</Typography>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography className={styles.motionHeadings}>
                    {alignmentOutcome}
                  </Typography>

                  {alignmentValue === "Forward" ? (
                    <ArrowUpwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "#28CA90",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#28CA903D",
                      }}
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      className={styles.arrowSize}
                      style={{
                        color: "var(--dark-red-0, #E51A3F)",
                        borderRadius: "20px",
                        marginLeft: "5px",
                        padding: "1px",
                        background: "#E51A3F3D",
                      }}
                    />
                  )}
                </Box>
                <Typography className={styles.motionTitle}>
                  Head Alignment
                </Typography>
              </Box>
            </Box>
            {/* <Button
              sx={{ color: "white" }}
              className={styles.advanceStatsButton}
              startIcon={<TimelineIcon />}
              onClick={() => setActiveButton(1)}
            >
              View Meeting Files
            </Button> */}
          </Box>
        </>
      </Box>

      <Box sx={{ textAlign: "left" }}>
        <Button
          startIcon={<FolderSpecial />}
          disableRipple={true}
          className={styles.buttonInActive}
          // onClick={() => handleButtonClick(1)}
        >
          Meeting Folder
        </Button>
      </Box>
      <FullWidthTabs
        notes={note}
        inMeetingNotes={inMeetingNotes}
        gridView={true}
        documents={documents}
        products={[]}
        value={value}
        setValue={setValue}
      />
    </Box>
  );
};

export default GridView;
