import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,

} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,

);
const BarChartGraph = ({ isSingle, score, graphLabels, engagements }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        
      },
      title: {
        display: true,
      },
    
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgba(255, 255, 255, 0.08)"
        },
      },
      y: {
        grid: {
          display: true,
          color: "rgba(255, 255, 255, 0.08)"
        },
      },
    },
    animation: false,
  };

  const labels = [
    "Parac..",
    "Torg..",
    "Oxy..",
    "Zinc..",
    "Garfu..",
    "Maste..",
    "Parac..",
    "Torg..",
    "Oxy..",
    "Zinc..",
    "Garf..",
    "Maste..",
  ];

  const data = isSingle
    ? {
        labels,
        datasets: [
          {
            label: "Dataset 1",
            data: labels.map(() => Math.random(0, 100)),
            backgroundColor: "#9747FF",
            barThickness: 6, // Adjust the bar width
          },
        ],
      }
    : {
        labels: graphLabels,
        datasets: [
          {
            label: "Score",
            data: score,
            backgroundColor: "#9747FF",
            barThickness: 30, // Adjust the bar width
          },
          {
            label: "Engagements",
            data: engagements,
            backgroundColor: "#1890FF",
            barThickness: 30, // Adjust the bar width
          },
        ],
      };

  return (
    <div style={{ width: "75%" }}>
      <Bar options={options} data={data} />
    </div>
  );
};
BarChartGraph.defaultProps = {
  isSingle: false,
  graphLabels: [],
};
export default BarChartGraph;
