import React from "react";
import styles from "./Welcome.module.css";
import { Typography, Container } from "@mui/material";
import Logo from "../Assets/logoTextual.svg"

const Welcome = () => {
  return (
    <>
      <Container className={styles.welcomeContainer}>
        <img className={styles.logo} src={Logo} alt="Logo" />
        <Typography className={styles.welcome}>
          Welcome to your AffectCX AI assistant !
        </Typography>
        <Typography className={styles.starting}>Starting...</Typography>
      </Container>
    </>
  );
};

export default Welcome;
