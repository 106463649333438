import React from "react";
import valueIcon from "../Assets/valueIcon.svg";
import curiousIcon from "../Assets/curiousIcon.svg";
import styles from "./meetingsbox.module.css";
const MeetingsDataBox = ({ isValue, value, text }) => {
  return (
    <div className={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={isValue ? valueIcon : curiousIcon} alt="icon" />
        <div className={styles.title}>{text}</div>
      </div>
      <div className={styles.valueText}>{value}</div>
    </div>
  );
};

export default MeetingsDataBox;
