import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import styles from "./NotesTab.module.css";

const NotesTab = ({ note }) => {
  return (
    <>
      {note.length > 0 ? (
        <>
          <Box className={styles.listContainer}>
            <Stack spacing={2} direction="row">
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ color: "white" }}
                dangerouslySetInnerHTML={{ __html: note }}
              ></Typography>
            </Stack>
          </Box>
        </>
      ) : (
        <>No notes available for this meeting !</>
      )}
    </>
  );
};

export default NotesTab;
