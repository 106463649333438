import React from 'react';

const ColoredCircle = ({ color, size }) => {
  const circleStyle = {
    backgroundColor: color,
    borderRadius: '50%',
    width: size,
    height: size,
  };

  return <div style={circleStyle}></div>;
};

export default ColoredCircle;
ColoredCircle.defaultProps ={
    size: "9px",
    color:"#3EB265"
}