import React, { useEffect, useState } from "react";
import UserInfo from "Components/NewDashboard/UserInfo/UserInfo";
import LeaderBoard from "Components/NewDashboard/LeaderBoard/LeaderBoard";
import QuickLinks from "Components/NewDashboard/QuickLinks/QuickLinks";
import searchIcon from "../../../../Components/NewDashboard/Assets/searchIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import SplitView from "Components/NewDashboard/SplitViewSlider/SplitView";
import MeetingCard from "Components/NewDashboard/MeetingCard/MeetingCard";
import ScheduleMeetingModal from "Components/SalesRep/ScheduleMeetingForm/ScheduleMeetingModal";
import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  allMeetings,
  scheduleCount,
} from "Store/features/scheduleMeetingSlice";
import { completedMeetings } from "Store/features/scheduleMeetingSlice";
import { setUser } from "Store/features/setUserSlice";
import moment from "moment";
import { baseUrl, recordingStats } from "Api/Backend/backend";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useSelector } from "react-redux";
import styles from "./home.module.css";
import ProductSplitView from "Components/NewDashboard/ProductSplit/ProductSplitView";

const NewHome = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [title, setTitle] = useState("");

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };
  const [meetingData, setMeetingData] = useState(0);
  const [isPastMeeting, setIsPastMeeting] = useState(false);
  const [isFocus, setIsFocus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [pastMeetings, setPastMeetings] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [currentWeek, setCurrentWeek] = useState({ start: null, end: null });
  const [scheduledMeetings, setAllScheduledMeetings] = useState([]);
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [scheduled, setScheduled] = useState(false);

  const [user, setLocalUser] = useState("");

  const today = new Date().toISOString().split("T")[0];

  const handleMeetingModal = async () => {
    setMeetingModalOpen(true);
  };
  function getCurrentTime() {
    const date = new Date();
    const currentTime = date.toLocaleString();
    return currentTime;
  }
  function getTimeOnly(dateString) {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();

    return time;
  }
  function getDateOn() {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    return formattedDate;
  }
  function getTimeOnlyCurrent(dateString) {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();
    return time;
  }
  function getDateOnly() {
    const today = new Date();
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = today.toLocaleString("en-US", options);
    return formattedDate;
  }
  const getScheduledMeetings = async () => {
    const time = getCurrentTime();
    const todayTime = getTimeOnlyCurrent(time);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const resp = await axios
      .post(`${baseUrl}get_meeting_by_user_id/`, {
        user_id: user.user_id,
        current_time: current_time,
        timezone: timeZone,
      })
      .then((response) => {
        setAllScheduledMeetings(response.data);
      });
  };
  const getAllMeetings = async (id) => {
    const time = getCurrentTime();
    const todayTime = getTimeOnly(time);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const resp = await axios
      .post(`${baseUrl}get_meeting_by_user_id/`, {
        user_id: id,
        current_time: current_time,
        timezone: timeZone,
      })
      .then((response) => {
        const data = response.data.filter(
          (meeting) => meeting.actual_end_time == null
        );
        const completed = response.data.filter(
          (meeting) => meeting.actual_end_time != null
        );
        setMeetings(data);
        dispatch(allMeetings(data));
        dispatch(completedMeetings(completed.length));
        dispatch(scheduleCount());
      })
      .catch((e) => console.log(e));
  };
  const getPastMeetings = async (id) => {
    const time = getCurrentTime();
    const todayTime = getTimeOnly(time);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const resp = await axios
      .post(`${baseUrl}get_past_meetings_by_user_id/`, {
        user_id: id,
        timezone: timeZone,
      })
      .then((response) => {
        setPastMeetings(response.data);
      })
      .catch((e) => console.log(e));
  };
  const handleClick = (data, pastMeeting) => {
    setIsPastMeeting(pastMeeting);
    setMeetingData(data);
    setIsOpen(true);
  };
  const setUserFunction = (user) => {
    setLocalUser(user);
    dispatch(setUser(user));
  };
  const [meetingsByWeek, setMeetingsByWeek] = useState(null);

  useEffect(() => {
    const today = new Date();

    // Start of the next week (Sunday)
    const startOfNextWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 7
    );

    // End of the next week (Saturday)
    const endOfNextWeek = new Date(
      startOfNextWeek.getFullYear(),
      startOfNextWeek.getMonth(),
      startOfNextWeek.getDate() + 6
    );

    const meetingsInNextWeek = meetings.filter((meeting) => {
      const meetingDate = new Date(meeting.start_time);
      return meetingDate >= startOfNextWeek && meetingDate <= endOfNextWeek;
    });

    const meetingsSeparatedByDay = meetingsInNextWeek.reduce((acc, meeting) => {
      const meetingDate = new Date(meeting.start_time);
      const dayKey = meetingDate.toISOString().split("T")[0];

      if (!acc[dayKey]) {
        acc[dayKey] = [];
      }

      acc[dayKey].push(meeting);

      return acc;
    }, {});

    setMeetingsByWeek(meetingsSeparatedByDay);
  }, [meetings]);

  const [meetingsByDay, setMeetingsByDay] = useState(null);
  const [pastMeetingsByDay, setPastMeetingsByDay] = useState(null);

  useEffect(() => {
    const today = new Date();
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    ); // Start of the week (Sunday)
    const endOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + (6 - today.getDay())
    ); // End of the week (Saturday)

    setCurrentWeek({ start: startOfWeek, end: endOfWeek });

    const meetingsInCurrentWeek = meetings.filter((meeting) => {
      const meetingDate = new Date(meeting.start_time);
      return meetingDate >= startOfWeek && meetingDate <= endOfWeek;
    });

    const pastMeeting = pastMeetings.filter((meeting) => {
      const meetingDate = new Date(meeting.start_time);
      return meetingDate < startOfWeek; // Include past meetings
    });

    const meetingsSeparatedByDay = meetingsInCurrentWeek.reduce(
      (acc, meeting) => {
        const meetingDate = new Date(meeting.start_time);
        const dayKey = meetingDate.toISOString().split("T")[0];

        if (!acc[dayKey]) {
          acc[dayKey] = [];
        }

        acc[dayKey].push(meeting);

        return acc;
      },
      {}
    );

    const pastMeetingsSeparatedByDay = pastMeeting.reduce((acc, meeting) => {
      const meetingDate = new Date(meeting.start_time);
      const dayKey = meetingDate.toISOString().split("T")[0];

      if (!acc[dayKey]) {
        acc[dayKey] = [];
      }

      acc[dayKey].push(meeting);

      return acc;
    }, {});
    setMeetingsByDay(meetingsSeparatedByDay);
    setPastMeetingsByDay(pastMeetingsSeparatedByDay);
  }, [meetings, pastMeetings]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      dispatch(setCurrentRoute(0));
      getAllMeetings(user.user_id);
      setUserFunction(user);
      getStatData(user.user_id);
      getPastMeetings(user.user_id);
    }
  }, []);
  const [statData, setStatData] = useState({
    most_contacted_doctor: "",
    most_contacted_doctor_image: null,
    meeting_count: 0,
    position: "",
    total_users: "",
  });
  const getStatData = async (id) => {
    const response = await recordingStats(id)
      .then((response) =>
        setStatData((prevData) => {
          const data = {
            most_contacted_doctor: response.most_contacted_doctor,
            meeting_count: response.meeting_count,
            most_contacted_doctor_image: response.most_contacted_doctor_image,
            position: response.position,
            total_users: response.total_users,
          };
          return {
            ...prevData,
            ...data,
          };
        })
      )
      .catch((e) => console.log(e));
  };
  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filterMeetings = (meetings) => {
    return meetings.filter((meeting) => {
      const doctorEmailMatch = meeting.doctor_email
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      const titleMatch = meeting.title
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      const medicineMatch = meeting.med_name
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      return doctorEmailMatch || titleMatch || medicineMatch;
    });
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <UserInfo
            userName={`${user.first_name} ${user.last_name}`}
            userTitle={user.team_name}
          />
          <LeaderBoard
            position={statData.position}
            totalReps={statData.total_users}
            icon={statData.most_contacted_doctor_image}
            mostContactedDoctor={statData.most_contacted_doctor}
            totalEngagements={statData.meeting_count}
          />
          {/* <QuickLinks /> */}
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.meetingSearchContainer}>
            <div className={styles.searchTitle}>My Meetings</div>
            <div className={styles.flexRow}>
              <div className={styles.searchBarContainer}>
                <img src={searchIcon} alt="icon" />
                <input
                  className={styles.searchInput}
                  onChange={handleChange}
                  placeholder="Search by doctor, medicine and more"
                />
              </div>
              <button
                className={styles.scheduleButton}
                onClick={() => setMeetingModalOpen(true)}
              >
                {" "}
                <AddIcon
                  className={styles.plusIcon}
                  sx={{ height: "20px", width: "20px" }}
                />
                Schedule Meeting
              </button>
            </div>
          </div>
          <div className={styles.tabContainer}>
            <div
              onClick={() => {
                setIsOpen(false);
                setIsFocus(0);

                setValue(0);
              }}
              className={styles.tab}
              style={{
                borderBottom:
                  value === 0 && "2px solid var(--dark-inverted-bg-100, #fff)",
              }}
            >
              <CalendarTodayRoundedIcon
                sx={{
                  color:
                    value === 0
                      ? "white"
                      : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
                }}
              />
              <div className={styles.tabText}>This Week</div>
              {/* {value === 0 && (
                <div className={styles.countContainer}>{meetings.length}</div>
              )} */}
            </div>
            <div
              onClick={() => {
                setIsOpen(false);
                setValue(1);
              }}
              className={styles.tab}
              style={{
                borderBottom:
                  value === 1 && "2px solid var(--dark-inverted-bg-100, #fff)",
              }}
            >
              <InsertDriveFileOutlinedIcon
                sx={{
                  color:
                    value === 1
                      ? "white"
                      : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
                }}
              />
              <div className={styles.tabText}>Next Week</div>
              {/* {value === 1 && <div className={styles.countContainer}>8</div>} */}
            </div>
            <div
              onClick={() => {
                setIsOpen(false);
                setValue(2);
              }}
              className={styles.tab}
              style={{
                borderBottom:
                  value === 2 && "2px solid var(--dark-inverted-bg-100, #fff)",
              }}
            >
              <DownloadIcon
                sx={{
                  color:
                    value === 2
                      ? "white"
                      : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
                }}
              />
              <div className={styles.tabText}>Past</div>
              {/* {value === 2 && <div className={styles.countContainer}>8</div>} */}
            </div>
          </div>
          {value === 0 && (
            <>
              {meetingsByDay &&
                Object.keys(meetingsByDay)
                  .sort()
                  .map((day, index) => (
                    <MeetingCard
                      cardIndex={index}
                      setIsFocus={setIsFocus}
                      isFocus={isFocus}
                      date={day}
                      meetings={filterMeetings(meetingsByDay[day])}
                      onClick={handleClick}
                    />
                  ))}
            </>
          )}
          {value === 1 && (
            <>
              {meetingsByWeek &&
                Object.keys(meetingsByWeek)
                  .sort()
                  .map((day, index) => (
                    <MeetingCard
                      cardIndex={index}
                      setIsFocus={setIsFocus}
                      isFocus={isFocus}
                      date={day}
                      meetings={filterMeetings(meetingsByWeek[day])}
                      onClick={handleClick}
                    />
                  ))}
            </>
          )}
          {value === 2 && (
            <>
              {pastMeetingsByDay &&
                Object.keys(pastMeetingsByDay).map((day, index) => (
                  <MeetingCard
                    cardIndex={index}
                    setIsFocus={setIsFocus}
                    isFocus={isFocus}
                    isPastMeeting={true}
                    date={day}
                    meetings={filterMeetings(pastMeetingsByDay[day])}
                    onClick={handleClick}
                  />
                ))}
            </>
          )}
        </div>
      </div>
      {meetingData != 0 && (
        <SplitView
          setIsFocus={setIsFocus}
          setIsOpen={setIsOpen}
          setTitle={setTitle}
          setSourceImage={setSourceImage}
          isViewerOpen={isViewerOpen}
          setIsViewerOpen={setIsViewerOpen}
          isPastMeeting={isPastMeeting}
          meetingData={meetingData}
          isOpen={isOpen}
        />
      )}
      <ScheduleMeetingModal
        meetings={meetings}
        getAllMeetings={getAllMeetings}
        setAllScheduledMeetings={setAllScheduledMeetings}
        scheduledMeetings={scheduledMeetings}
        setScheduled={setScheduled}
        meetingModalOpen={meetingModalOpen}
        setMeetingModalOpen={setMeetingModalOpen}
        id={user.user_id}
      />
      {isViewerOpen && (
        <div style={{ width: "40%", marginLeft: "30px" }}>
          <ProductSplitView
            title={title}
            isOpen={isViewerOpen}
            setIsOpen={setIsViewerOpen}
            fileExtension={getFileExtension(sourceImage)}
            src_image={sourceImage}
          />
        </div>
      )}
    </>
  );
};

export default NewHome;
