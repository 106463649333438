import React from "react";
import styles from "./cards.module.css";
import StarsIcon from "../../Assets/starIcon.svg";
import CrossIcon from "../../Assets/crossIcon.svg";
import iconGroup from "../../Assets/iconGroup.svg";
import averageIcon from "../../Assets/averageIcon.svg";
import { Typography, Box } from "@mui/material";
import DonutChartSmall from "../DonutChart/DonutChartSmall";
import Gauge from "../DonutChart/Gauge";

const MeetingStatsCard = ({
  affectScore,
  advanceAnalytics,
  prompt,
  userName,
  doctorName,
  resonanceScore,
  barColor,
  gridView,
}) => {
  return (
    <Box
      className={
        affectScore === "positive"
          ? styles.container
          : affectScore === "negative"
          ? styles.containerNegative
          : styles.containerAverage
      }
    >
      {advanceAnalytics && (
        <img
          src={CrossIcon}
          alt="icon"
          style={{ position: "absolute", right: "15px", top: "14px" }}
        />
      )}
      <Box className={styles.flexContainer}>
        <Box className={styles.innerContainer}>
          {advanceAnalytics || gridView ? (
            <img
              style={{ marginLeft: "15px" }}
              src={
                affectScore === "positive"
                  ? StarsIcon
                  : affectScore === "negative"
                  ? iconGroup
                  : averageIcon
              }
              alt="icon"
            />
          ) : (
            <div style={{ width: "35%" }}>
              <DonutChartSmall
                value={resonanceScore.toString()}
                x={40}
                inMeeting={true}
                cutout={"90%"}
                fontSize={"24px"}
                y={-3.3}
                barColor={barColor}
              />
              {/* <Gauge
                value={resonanceScore.toString()}
                x={40}
                inMeeting={true}
                cutout={"90%"}
                fontSize={"24px"}
                y={-1}
                barColor={barColor}
              /> */}

              <Typography className={styles.title}>AFFECT RESONANCE</Typography>
            </div>
          )}
          <Box sx={{ marginLeft: "15px", width: "75%" }}>
            {affectScore === "positive" ? (
              <>
                <Typography className={styles.heading}>{prompt} </Typography>
                <Typography className={styles.description}>
                  Keep up the way you are conducting the conversation with{" "}
                  {doctorName}
                </Typography>
              </>
            ) : affectScore === "negative" ? (
              <>
                <Typography className={styles.heading}>{prompt}</Typography>
                <Typography className={styles.description}>
                  {userName}, meeting is going south. Let's try something to
                  engage {doctorName}
                </Typography>
              </>
            ) : (
              <>
                <Typography className={styles.heading}>{prompt}</Typography>
                <Typography className={styles.description}>
                  AffectCX has analysed few patterns and suggests using these
                  phrases to resonate more with the {doctorName}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
MeetingStatsCard.defaultProps = {
  affectScore: "positive",
  advanceAnalytics: true,
  userName: "",
  prompt: "",
  gridView: false,
  doctorName: "",
};
export default MeetingStatsCard;
