import React, { useState } from "react";
import styles from "./card.module.css";
import { Typography, Box } from "@mui/material";
import pdfIcon from "../../Assets/pdfIcon.svg";
import excelIcon from "../../Assets/excelIcon.svg";
import viewIcon from "../../Assets/viewIcon.svg";
import shareIcon from "../../Assets/shareIcon.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import ImageComponent from "../ImageComponent/ImageComponent";
import CustomMenu from "../CustomMenu/CustomMenu";
// import PdfLogo from "../../Assets/pdfLogo.svg";
import ShareDocument from "../../Assets/shareDocument.svg";
// import SamplePdf from "../../Assets/samplepdf.pdf";
import SuccessIcon from "../../Assets/success.svg";
import CustomFileViewer from "Components/Common/DocumentCard/CustomFileViewer";

const DocumentCard = ({ timeStamp, title, type, link }) => {
  const [isFileViewer, setIsFileViewer] = useState(false);
  const handleClick = () => {
    setIsFileViewer(true);
  };
  const handleCloseViewer = () => {
    setIsFileViewer(false);
  };
  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };
  const renderFileViewer = () => {
    const fileExtension = getFileExtension(link);

    return (
      <CustomFileViewer
        fileType={fileExtension}
        filePath={link}
        onClose={handleCloseViewer}
      />
    );
  };
  const shortenName = (documentName) => {
    if (documentName.length > 20) {
      return documentName.slice(0, 20);
    } else {
      return documentName;
    }
  };
  return (
    <Box className={styles.mainContainer}>
      <Box onClick={() => handleClick()} className={styles.headingContainer}>
        <Box className={styles.headingFlex}>
          <img alt="icon" src={type === ".pdf" ? pdfIcon : excelIcon} />
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>
              {shortenName(title)}
            </Typography>
            <Typography className={styles.type}>{type}</Typography>
          </Box>
        </Box>
        <Box className={styles.iconContainer}>
          <img alt="icon" style={styles.iconStyle} src={viewIcon} />
          <img alt="icon" style={styles.iconStyle} src={shareIcon} />
          <CustomMenu style={styles.iconStyle} icon={detailsIcon} />
        </Box>
      </Box>
      <Box className={styles.container}>
        <Typography className={styles.timeStamp}>{timeStamp}</Typography>
      </Box>
      {isFileViewer && (
        <CustomFileViewer
          isNewDocument={setIsFileViewer}
          fileType={getFileExtension(link)}
          filePath={link}
          onClose={handleCloseViewer}
        />
      )}
    </Box>
  );
};
DocumentCard.defaultProps = {
  timeStamp: "Yesterday",
  title: "Paracetamol Plus",
  type: ".pdf",
};

export default DocumentCard;
