import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import styles from "./AllInteractions.module.css";
import SingleStat from "Components/SalesRep/SingleStat/SingleStat";
import CalenderIconSmall from "../../../../Assets/calenderIconSmall.svg";
import ClientIcon from "../../../../Assets/clientIcon.svg";
import SuccessScoreIcon from "../../../../Assets/successScoreIcon.svg";
import FeedbackIcon from "../../../../Assets/feedbackIcon.svg";
import DataTable from "Components/Common/DataTable/DataTable";
import { useSelector } from "react-redux";
import columnsDef from "./ColumnDef";
// import MOCK_DATA from "./mock_data/MOCK_DATA.json";
import { useNavigate } from "react-router-dom";
import { getAllInteractions, recordingStats } from "Api/Backend/backend";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useDispatch } from "react-redux";
import EngagementInfoBox from "Components/NewDashboard/EngagementInfoBox/EngagementInfoBox";

const AllInteractions = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.setUser.user);
  const userId = user.user_id;
  const [tableData, setTableData] = useState([]);
  const [statData, setStatData] = useState({
    meeting_count: 0,
    average_success_score: 0,
    doctor_name: undefined,
    feedback_count: 0,
  });
  const [count, setCount] = useState(0);
  const onRowClick = (data) => {
    const rowData = {
      name: data.doctor_name,
      doctorId: data.doctor_id,
    };
    navigate("/single-interaction", { state: rowData });
  };

  const getTableData = async (id) => {
    const response = await getAllInteractions(id)
      .then((response) => {
        setTableData(response);
      })
      .catch((e) => console.log(e));
  };

  const getStatData = async (id) => {
    const response = await recordingStats(id)
      .then((response) => setStatData(response))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      getTableData(user.user_id);
      getStatData(user.user_id);
    }
  }, [count]);
  useEffect(() => {
    dispatch(setCurrentRoute(3));
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fluid maxWidth={true}>
        <Container maxWidth={true} className={styles.container}>
          <Container maxWidth={true} sx={{ padding: "0px !important" }}>
            <Container
              maxWidth={true}
              sx={{ margin: "30px 0px 0px 0px" }}
              className={styles.singleStatContainer}
            >
              <EngagementInfoBox
                title={"Total Interactions"}
                value={tableData.length}
              />
              <EngagementInfoBox
                title={"Avg. Affect Score"}
                type={3}
                value={`${Math.round(
                  Number(statData.average_success_score.toFixed(2))
                )} `}
              />
              <EngagementInfoBox
                title={"Feedback"}
                value={Math.round(
                  statData.feedback_count / statData.meeting_count
                )}
                type={2}
              />
              <EngagementInfoBox
                fontSize={"16px"}
                title={"Most Contacted Doctor"}
                value={
                  statData.most_contacted_doctor
                    ? `${statData.most_contacted_doctor}`
                    : "N/A"
                }
              />
            </Container>

            <Container maxWidth={true} className={styles.dataTableContainer}>
              <DataTable
                isAllInteraction={true}
                variant={"filter"}
                defination={columnsDef}
                cellData={tableData}
                onInteraction={true}
                onRowClick={onRowClick}
                id={userId}
                setCount={setCount}
              />
            </Container>
          </Container>
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default AllInteractions;
