import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  meeting: {},
  numberOfCompletedMeetings: 0,
  numberOfInCompleteMeetings: 0,
  scheduleCount: 0
};



export const scheduleMeetingSlice = createSlice({
  name: "scheduleMeeting",
  initialState,
  reducers: {
    scheduleMeeting: (state, action) => {
      state.meeting = action.payload;
    },
    endMeeting: (state) => {
      state.meeting = {};
    },
    allMeetings: (state, action) => {
      state.meeting = action.payload;
    },
    completedMeetings: (state, action) => {
      state.numberOfCompletedMeetings = action.payload
    },
    scheduleCount: (state) => {
      state.scheduleCount = state.numberOfCompletedMeetings + state.numberOfInCompleteMeetings
    }
  },
});

export const { scheduleMeeting, endMeeting, allMeetings, completedMeetings, scheduleCount } = scheduleMeetingSlice.actions;

export default scheduleMeetingSlice.reducer;
