import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import TranscriptedText from "./TranscriptedText";
import menuIcon from "../NewMeetingDetails/Assets/menuIcon.svg";
import { getTranscript, getZoomToken } from "Api/Backend/backend";
import styles from "./Transcription.module.css";

const Transcription = ({ mid }) => {
  const [transcript, setTranscript] = useState([]);
  const data = [
    { id: 1, label: "Notes" },
    { id: 2, label: "Snippets" },
    { id: 3, label: "Pricing" },
  ];

  const [selectedChip, setSelectedChip] = useState([]);

  const getTranscription = (mid, token) => {
    const response = getTranscript(mid, token)
      .then((resp) => {
        setTranscript(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getZoomToken()
      .then((res) => {
        // setToken(res)
        getTranscription(mid, res);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <>
      <Box className={styles.greeting}>
        <img src={menuIcon} />
        <div className={styles.greetingText}>Transcript</div>
      </Box>

      <Box className={styles.transcriptedTextContainer}>
        {transcript.length > 0 ? (
          <>
            <TranscriptedText type={"sales"} transcript={transcript} />
          </>
        ) : (
          <>Transcripts Processing</>
        )}
      </Box>
    </>
  );
};

export default Transcription;
