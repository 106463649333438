import React from "react";
import styles from "./Contact.module.css";
import arrow from "./Assets/arrow.svg";
import { Typography } from "@mui/material";
const ContactCard = ({ contactTitle, numberOfContacts }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className={styles.cardContainer}>
          <div
            style={{ width: "100%", display: "flex", alignItems: "flex-start" }}
          >
            <div style={{ marginLeft: "15px" }}>
              <Typography className={styles.title}>{contactTitle}</Typography>
              <Typography
                className={styles.subTitle}
              >{`${numberOfContacts} Contacts`}</Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
