import React, { useEffect, useState } from "react";
import medicineIcon from "Components/NewDashboard/Assets/syringIcon.svg";

import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import SendIcon from "@mui/icons-material/Send";
import rtIcon from "../../Assets/rtIcon.svg";
import chatIcon from "../../Assets/chatIcon.svg";

import dividerIcon from "../../Assets/dividerIcon.svg";

import axios from "axios";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import DonutChart from "Components/NewDashboard/DonutChartNew/DonutChart";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import { singleFeedBackData } from "Api/Backend/backend";
import SuggestionBar from "Pages/Meeting/Components/SuggestionBar/SuggestionBar";
import styles from "./splitview.module.css";
import { Avatar } from "@mui/material";

const SalesSplitView = ({ isOpen, meetingData, setIsOpen, setIsFocus }) => {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState([]);
  const [overallScore, setOverallScore] = useState({});
  const [feedBackData, setFeedBackData] = useState([]);

  const getFeedBackData = async () => {
    const data = await singleFeedBackData(meetingData.mid)
      .then((res) => {
        setFeedBackData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getFeedBackData();
  }, []);
  const sendMidTrasncription = async () => {
    const endBot = axios
      .get("https://zoombot.affectcx.io/api/stop-all-containers")
      .then((res) => {});
    const resp = await axios
      .post(`https://microservicesaudio.affectcx.io/whisper/post-mid`, {
        mid: meetingData.mid,
      })
      .then((response) => {
        navigate("/meeting", {
          state: {
            link: `${meetingData.meeting_id}`,
            note: `${meetingData.note}`,
            doc_list: meetingData.doc_list,
            zoomToken: `${meetingData.zak}`,
            title: `${meetingData.title}`,
            mid: `${meetingData.mid}`,
          },
        });
      })
      .catch((e) => console.log(e));
  };

  const getDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formatted = dateObject.toLocaleDateString("en-UK", options);
    return formatted;
  };
  const barBg = (perc) => {
    if (perc > 0 && perc < 50) {
      return "#E51A3F";
    } else if (perc >= 50 && perc < 70) {
      return "#F3C243";
    } else if (perc >= 70) {
      return "#28CA90 ";
    }
  };
  const getMeetingProgress = (perc) => {
    const score = perc;
    if (score >= 0 && score < 50) {
      return "negative";
    } else if (score >= 50 && score < 70) {
      return "average";
    } else if (score >= 70) {
      return "positive";
    }
  };
  const calculateTimeRemaining = (meetingStartTime) => {
    const now = new Date();
    const meetingStart = new Date(meetingStartTime);
    const timeDiff = meetingStart - now;

    if (timeDiff > 0) {
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      return `Starting in ${hours}h ${minutes}m`;
    } else {
      return "Meeting has started";
    }
  };
  const onRowClick = () => {
    sessionStorage.setItem("meetingTitle", meetingData.title);
    navigate(`/Engagements/${meetingData.mid}`, { state: meetingData });
  };

  return (
    <div className={isOpen ? styles.drawerOpen : styles.drawer}>
      <div style={{ textAlign: "right", width: "100%" }}>
        <CloseIcon
          onClick={() => {
            setIsOpen(false);
            setIsFocus(0);
          }}
          sx={{
            color: "var(--dark-inverted-bg-100, #fff) ",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={styles.meetingStartTime}>
        {calculateTimeRemaining(meetingData.meetingDuration)}
      </div>
      <div className={styles.meetingTitle}>{meetingData.title}</div>
      <div className={styles.meetingDetails}>
        <div>
          <div className={styles.meetingDuration}>
            {meetingData.start_time.split("!", 1)}
          </div>
          <div className={styles.meetingDate}>
            {meetingData.start_time.slice(13)}
          </div>
        </div>
        <div className={styles.overviewContainer}>
          <div className={styles.sectionHeader}>
            <TimelineIcon sx={{ color: "rgba(255, 255, 255, 0.24)" }} />
            <div className={styles.sectionHeaderText}>Meeting Stats</div>
          </div>
          <div style={{ display: "flex", gap: "1vw", marginTop: "1vh" }}>
            <InfoCard
              icon={meetingData.doctor_profile_picture}
              title={meetingData.doctor_name}
            />
            <InfoCard
              subTitle={"product"}
              title={meetingData.med_name}
              icon={medicineIcon}
            />
          </div>
          <div className={styles.chartContainer}>
            <div style={{ width: "50%", textAlign: "center" }}>
              <DonutChart
                meetingProgress={getMeetingProgress(meetingData.success_score)}
                value={`${meetingData.success_score}`}
                x={-8.5}
                y={-12.5}
                barColor={barBg(meetingData.success_score)}
              />
              <img alt="icon" src={dividerIcon} />
            </div>
            <div
              style={{
                gap: "1vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InfoCard title={"Vishal Mehtani"} subTitle={"Jr. Sales Rep"} />
              <div style={{ display: "flex", padding: "8px", gap: "8px" }}>
                <Avatar>RT</Avatar>
                <div className={styles.teamTitle}>Rome Team</div>
              </div>
              <InfoCard
                icon={rtIcon}
                subTitle={"3rd Interaction."}
                title={"24 Min,  "}
              />
            </div>
          </div>
        </div>
        <div className={styles.meetingInfoInnerContainer}>
          <div className={styles.meetingInfoHeading}>
            Client was satisfied but not interested
          </div>
          <div className={styles.meetingInfoSuggestion}>
            For next time lets try to be more affirmative and try to clear more
            doubts for the client
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <div className={styles.sectionHeader}>
          <img src={chatIcon} alt="icon" />
          <div className={styles.sectionHeaderText}>Feedback</div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          <div className={styles.buttonContainer}>
            <WestIcon sx={{ color: "white" }} />
          </div>
          <div className={styles.buttonContainer}>
            <EastIcon sx={{ color: "white" }} />
          </div>
        </div>
      </div>
      <div className={styles.suggestionBarContainer}>
        {feedBackData.length > 0 ? (
          feedBackData.map((data) => <SuggestionBar title={data.text} />)
        ) : (
          <></>
        )}
      </div>

      <div className={styles.bottomContainer}>
        {/* <div className={styles.buttonContainer}>
          <WestIcon sx={{ color: "white" }} />
        </div>
        <div className={styles.buttonContainer}>
          <EastIcon sx={{ color: "white" }} />
        </div> */}
        <div></div>
        <div></div>

        <button onClick={sendMidTrasncription} className={styles.joinMeeting}>
          <SendIcon />
          View Engagement
        </button>
      </div>
    </div>
  );
};
SalesSplitView.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  meetingTime: "",
  meetingDuration: "",
  setIsFocus: () => {},
};
export default SalesSplitView;
