import "firebase/compat/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "affectcxauth.firebaseapp.com",
  projectId: "affectcxauth",
  storageBucket: "affectcxauth.appspot.com",
  messagingSenderId: "154215785586",
  appId: "1:154215785586:web:736a8528477c8820438eeb",
  measurementId: "G-E17ND86PDR",
};

export { firebaseConfig };
