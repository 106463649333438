import React from "react";
import quickLinkIcon from "../../Assets/quickLinkIcon.svg";
import resumeLearning from "../../Assets/resumeLearning.svg";
import profileIcon from "../../Assets/profileIcon.svg";
import featuredIcon from "../../Assets/featuredIcon.svg";
import cardImage2 from "../../Assets/cardImage2.svg";
import homeIcon from "../../Assets/homeIcon.svg";
import { Button, Divider, Typography } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import SalesBadge from "../../Components/SalesBadge/SalesBadge";
import ProgressBar from "../../Components/progressBar/ProgressBar";
import QuickLinkCard from "../../Components/QuickLinkCard/QuickLinkCard";
import ResumeVideoCard from "../../Components/ResumeVideoCard/ResumeVideoCard";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import FeaturedCard from "../../Components/FeaturedCard/FeaturedCard";
import WestIcon from "@mui/icons-material/West";
import styles from "./behaviouraltraining.module.css";
import { useNavigate } from "react-router";

const BehaviouralTraining = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.flexBetween}>
            <div style={{ alignItems: "center", display: "flex" }}>
              <WestIcon
                onClick={() => navigate(-1)}
                sx={{
                  color: "white",
                  background: "#ffffff0a",
                  padding: "4px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              />
              <Typography className={styles.dashboardTitle}>
                My Dashboard
              </Typography>
            </div>

            <SearchBar />
          </div>
          <div className={styles.dashboardContainer}>
            <div className={styles.userInfo}>
              <div className={styles.flexRow}>
                <img src={profileIcon} />
                <div className={styles.flexColumnCenter}>
                  <Typography className={styles.userName}>
                    Kevin Mullen
                  </Typography>
                  <Typography className={styles.userTitle}>
                    Junior Sales Rep
                  </Typography>
                </div>
              </div>
              <div className={styles.badgeContainer}>
                <SalesBadge />
                <SalesBadge isScore={false} />
              </div>
              <div className={styles.levelContainer}>
                <Typography className={styles.levelTitle}>
                  Close to Level Up
                </Typography>
                <Typography className={styles.levelSuggestion}>
                  Only three more modules left to graduate
                </Typography>
              </div>
              <div className={styles.progressContainer}>
                <ProgressBar />
                <ProgressBar title={"12 Days"} type={1} />
                <ProgressBar type={2} title={"8/13 Courses"} />
              </div>
            </div>
            <Divider
              orientation={"vertical"}
              sx={{
                width: "2px",
                background:
                  "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
              }}
              flexItem
            />

            <div className={styles.userPage}>
              <div className={styles.flexRow}>
                <img style={{ margin: "0" }} src={quickLinkIcon} />
                <Typography className={styles.pageTitle}>
                  Quick Links
                </Typography>
              </div>
              <div
                style={{ flexWrap: "wrap", width: "100%" }}
                className={styles.flexRow}
              >
                <QuickLinkCard />
                <QuickLinkCard title={"MockAiCalls"} type={1} />
                <QuickLinkCard title={"Practice with Friend"} type={2} />
              </div>
              <div style={{ marginTop: "20px" }} className={styles.flexRow}>
                <img style={{ margin: "0" }} src={resumeLearning} />
                <Typography className={styles.pageTitle}>
                  Continue Learning
                </Typography>
              </div>
              <div
                style={{ flexWrap: "wrap", width: "100%" }}
                className={styles.flexRow}
              >
                <ResumeVideoCard />
                <ResumeVideoCard bImage={cardImage2} />
              </div>
              <div style={{ marginTop: "20px" }} className={styles.flexBetween}>
                <div className={styles.flexRow}>
                  <img style={{ margin: "0" }} src={featuredIcon} />
                  <Typography className={styles.pageTitle}>
                    Featured Material & Calls
                  </Typography>
                </div>
                <Button
                  endIcon={<TrendingFlatIcon />}
                  className={styles.allButton}
                  disableRipple
                >
                  View All
                </Button>
              </div>
              <div
                style={{ flexWrap: "wrap", width: "100%" }}
                className={styles.flexRow}
              >
                <FeaturedCard />
                <FeaturedCard bImage={cardImage2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BehaviouralTraining;
