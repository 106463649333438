import { Typography } from "@mui/material";
import styled from "@emotion/styled";

export const StyledMeetingTime = styled(Typography)({
  fontFamily: "Satoshi",
  fontStyle: "normal",

  fontWeight: 500,

  cursor: "pointer",
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0075em",
  color: "rgba(255, 255, 255, 0.60)",
});

export const StyledLast = styled(Typography)({
  fontFamily: "Red Hat Display",
  fontStyle: "normal",
  textAlign: "left",
  fontWeight: "600",
  fontSize: "13px",
  lineHeight: "17px",

  /* Neutrals/Primary_black */

  color: "#201F28",
});

export const Card_Success = styled("div")({
  boxSizing: "border-box",
  // position: absolute,
  width: "90%",
  marginBottom: "10px",
  paddingTop: "5px",
  paddingBottom: "30px",
  // height: "224px",
  borderRadius: "8px",
  borderBottom:
    "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
  background: "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
});
export const Card_Average = styled("div")({
  boxSizing: "border-box",
  // position: absolute,
  width: "95%",
  marginBottom: "10px",
  height: "224px",
  borderRadius: "8px",
  borderBottom:
    "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
  background: "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
});
export const Card_low = styled("div")({
  boxSizing: "border-box",
  // position: absolute,
  width: "95%",
  marginBottom: "10px",
  height: "224px",
  borderRadius: "8px",
  borderBottom:
    "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
  background: "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
});
export const StyledContainer = styled("div")({
  display: "flex",
  width: "80%",
  marginTop: "5px",
  marginBottom: "5px",
  alignItems: "center",
  justifyContent: "flex-start",
});

export const StyledScore = styled(Typography)({
  textAlign: "center",
  fontFamily: "'Red Hat Display'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#201F28",
  marginLeft: "5px",
});
export const StyledTimeContainer = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "left",
});
export const StyledTime = styled(Typography)({
  lineHeight: "160%",
  letterSpacing: "0.01em",
  fontSize: "10px",
  width: "70%",

  textAlign: "left",
  // background:"#464092",
  fontSize: "12px",
  fontWeight: 700,
  color: "rgba(255, 255, 255, 0.50)",
});
export const StyledName = styled(Typography)({
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",
  marginLeft: "10px",
  lineHeight: "18px",
  /* identical to box height */

  letterSpacing: "0.0075em",

  /* Neutrals/Muted */

  color: "#77767C",
});
export const StyledTitle = styled(Typography)({
  // textAlign:"left",

  textAlign: "left",

  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "19px",
  color: "rgba(255, 255, 255, 0.80)",
});
export const ImageStyled = styled("img")({
  width: "35px",
  height: "35px",

  // "left": "16px",
  // "top": "23px",
  // "background": `url(${imageSrc})), #D9D9D9`,

  borderRadius: "20px",
  borderColor: "white",
  // "boxShadow": "0px 0px 4px rgba(0, 0, 0, 0.25)"
});
export const LastMeetingInfo = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: "10px",
  padding: "10px",
  alignItems: "flex-start",
  borderRadius: " 8px",
  background: "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
});
export const LastMeetingInfoHeading = styled("div")({
  color: "rgba(255, 255, 255, 0.60)",

  /* Capitol Heading 25 */
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "14px" /* 140% */,
  letterSpacing: "1px",
  textTransform: "uppercase",
});
