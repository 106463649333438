import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Divider, Typography } from "@mui/material";
import SingleStat from "Components/SalesRep/SingleStat/SingleStat";
import styles from "./ClientAnalytics.module.css";
import { createTheme } from "@mui/material/styles";
import { useState } from "react";
import HalfDonutChart from "Components/SalesRep/HalfDonutChart/HalfDonutChart";
import ValueIcon from "../../../../Assets/valueIcon.svg";
import MoodIcon from "../../../../Assets/moodIcon.svg";
import AttentionIcon from "../../../../Assets/attentionIcon.svg";

const ClientAnalytics = ({ overallScore }) => {
  const [btnGroup, setBtnGroup] = useState("");

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  return (
    <>
      <Container className={styles.container}>
        <Box className={styles.greeting}>
          <Typography
            fontSize={20}
            fontWeight={700}
            fontFamily={"Red Hat Display !important"}
            sx={{ color: "#201F28" }}
          >
            Overall Affect score
          </Typography>
        </Box>
        <Divider />
        <Box className={styles.statsContainer}>
          <Box className={styles.graphSection}>
            <Box className={styles.graphContainer}>
              <HalfDonutChart
                value={overallScore.success_score}
                x={50}
                y={-8}
              />
            </Box>
            <Box className={styles.tip}>
              <Typography
                fontSize={12}
                sx={{ color: "#77767C" }}
                className={styles.tipText}
              >
                Live feedbacks helps you to maintain your Affect score
              </Typography>
            </Box>
          </Box>
          <Box className={styles.singleStatContainer}>
            <SingleStat
              title={"Attention Success"}
              value={`${overallScore.attention_success}%`}
              image={AttentionIcon}
            ></SingleStat>
            <SingleStat
              title={"Top Mood Induced"}
              value={`${overallScore.top_mood}%`}
              image={MoodIcon}
            ></SingleStat>
            <SingleStat
              title={"Value Internalization"}
              value={`${overallScore.value_internalization}%`}
              image={ValueIcon}
            ></SingleStat>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ClientAnalytics;
