import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./SingleStat.module.css";
import InfoIcon from "../../../Assets/infoIcon.svg";

const SingleStat = ({
  title,
  subtitle,
  value,
  image,
  tfont,
  vfont,
  infoIcon,
}) => {
  const titleSize = tfont ? tfont : 20;

  return (
    <>
      <Box className={styles.container}>
        {image ? (
          <>
            <Box className={styles.imageBox}>
              <img alt="image" src={image} />
            </Box>
          </>
        ) : (
          <></>
        )}

        <Box className={styles.textBox}>
          <Typography
            fontSize={titleSize}
            fontWeight={700}
            fontFamily={"Red Hat Display"}
            sx={{ color: "#201F28" }}
          >
            {value}
          </Typography>
          <Box className={styles.subtext}>
            <Typography
              fontSize={13}
              fontWeight={500}
              sx={{ fontFamily: "Satoshi-Variable", color: "#77767C" }}
            >
              {title}
            </Typography>
            <br />
          </Box>
          <Box className={styles.subtext}></Box>
          <Typography
            fontSize={13}
            fontWeight={500}
            sx={{ fontFamily: "Satoshi-Variable", color: "#77767C" }}
          >
            {subtitle}
          </Typography>
          {infoIcon == "no" ? (
            <></>
          ) : (
            <>
              <img alt="icon" src={InfoIcon} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SingleStat;
