import React, { useState } from "react";
import styles from "./deletemodal.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import warningIcon from "../../../Assets/warningIcon.svg";
import { Modal } from "@mui/material";

const DeleteFilesModal = ({ isOpen, onClose, onDelete, title, totalFiles }) => {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <div
        style={{
          position: "absolute",
          background: "rgba(0, 0, 0, 0.80)",
          width: "100%",
          height: "100%",
          boxShadow: 24,
          p: 4,
          gap: "40px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={warningIcon} alt="icon" />
        <div className={styles.sectionHeader}>{title}</div>
        <div className={styles.sectionDescription}>
          Permenantaly remove {totalFiles}{" "}
          {totalFiles.length > 1 ? "files" : "file"}. You won’t be able to
          recover them
        </div>
        <div style={{ display: "flex", gap: "1vw" }}>
          <button className={styles.cancelButton} onClick={onClose}>
            Cancel
          </button>
          <button className={styles.deleteButton} onClick={onDelete}>
            Delete All
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFilesModal;
DeleteFilesModal.defaultProps = {
  isOpen: () => {},
  onClose: () => {},
  onDelete: () => {},
  title: "Pass title in props",
  totalFiles: 0,
};
