import React from "react";
import TrainingPageStructure from "../TrainingPageStructure/TrainingPageStructure";
import ModuleDetails from "Pages/BehaviouralTraining/Components/ModuleDetails/ModuleDetails";
import RetroMaster from "Pages/BehaviouralTraining/Components/RetroMaster/RetroMaster";
import trasncriptIcon from "../../Assets/transcriptIcon.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";

import styles from "./learning.module.css";
import SearchBar from "Pages/BehaviouralTraining/Components/SearchBar/SearchBar";
import TranscriptList from "Pages/BehaviouralTraining/Components/TranscriptList/TranscriptList";
const LearningModules = () => {
  return (
    <TrainingPageStructure>
      <div className={styles.mainContainer}>
        <div className={styles.leftSideBar}>
          <div className={styles.flexColumn}>
            <ModuleDetails />
            <RetroMaster />
            <RetroMaster
              totalModules={[1, 243, 4]}
              title={"Deep Breaths and Articulation"}
            />
          </div>
        </div>
        <div className={styles.mainPage}>
          <div className={styles.mainPageVideo}></div>
          <div className={styles.flexBetween}>
            <div className={styles.flexRow}>
              <ArrowBackIcon fontSize="small" style={{ color: "#FFFFFF" }} />
              <Typography className={styles.directionText}>Previous</Typography>
              <Typography className={styles.moduleTitle}>
                Resetting Quiz
              </Typography>
            </div>
            <div className={styles.flexRow}>
              <Typography className={styles.directionText}>Next</Typography>
              <Typography className={styles.moduleTitle}>
                Master Rhythm Quiz
              </Typography>
              <ArrowForwardIcon fontSize="small" style={{ color: "#FFFFFF" }} />
            </div>
          </div>
          <Typography className={styles.moduleTitleMainPage}>
            Nature’s Reset Vol 1
          </Typography>
          <Typography className={styles.moduleTopicText}>
            How to cycle breathing in a client meeting
          </Typography>
          <Typography className={styles.moduleTitleDescription}>
            Lesson Brief
          </Typography>
          <Typography className={styles.moduleTitleDetails}>
            This is the Client's guide to the Webflow Editor. Share these
            lessons to get your clients up and running.Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Quisque sollicitudin leo in massa
            hendrerit venenatis.
          </Typography>
        </div>
        <div className={styles.rightSideBar}>
          <div style={{ marginBottom: "30px" }} className={styles.flexRow}>
            <img src={trasncriptIcon} />
            <Typography className={styles.transcriptTitle}>
              Transcript
            </Typography>
          </div>
          <SearchBar placeholder={"Search Transcript"} width={"100%"} />
          <TranscriptList />
        </div>
      </div>
    </TrainingPageStructure>
  );
};

export default LearningModules;
