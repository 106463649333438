import React, { useEffect, useState } from "react";
import InputField from "Components/Common/InputField/InputField";
import { Grid, Chip, Button, CircularProgress, Box } from "@mui/material";
import MailIcon from "../Assets/MailIcon.svg";
import AccountIcon from "../Assets/AccountIcon.svg";
import styles from "../../../../Components/NewDashboard/AddProducts/ScheduleMeetingForm.module.css";
import { toast } from "react-toastify";

const AddUserForm = ({ isClose, onSubmit, loader, editUser, user }) => {
  const [role, setRole] = useState(user ? user.role : "");
  const [firstName, setFirstName] = useState(user ? user.first_name : "");
  const [lastName, setLastName] = useState(user ? user.last_name : "");
  const [email, setEmail] = useState(user ? user.email : "");

  const flag = firstName && lastName && email && role ? false : true;

  const handleRole = (val) => {
    setRole(val);
  };
  useEffect(() => {
    if (user) {
      setRole(user.role);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
    }
  }, [user]);

  const validateEmail = (email) => {
   
    const re =
     
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(vc|[a-zA-Z]{2,})))$/;

    return re.test(String(email).toLowerCase());
  };
  const validateName = (Name) => {
    const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    return re.test(Name);
  };

  const handleOnSubmit = () => {
   
      const checkemail= validateEmail(email);
      if(!checkemail){
        toast.error("Enter valid email");
        return
      }
      const checkname=validateName(firstName);
      if(!checkname){
        toast.error("Enter valid firstname");
        return;
      }
      const checkLastName=validateName(lastName);
      if(!checkLastName){
        toast.error("Enter valid lastname");
        return;
      }
    const payload = {
      role: role,
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: "NewUserAffectcx",
    };
    onSubmit(payload);
  };
  const handleOnSubmitEdit = () => {
    const payload = {
      user_id: user.user_id,
      role: role,
      first_name: firstName,
      last_name: lastName,
    };
    onSubmit(payload);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>Email</div>
        </Box>
      </Grid>
      <Grid item md={12}>
        <InputField
          value={email}
          placeholder="Enter Email"
          bgColor="null"
          // isEmail={true}
          startIcon={MailIcon}
          editable={!editUser}
          inputWidth="100%"
          setter={setEmail}
        />
      </Grid>
      <Grid item md={12}>
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>Details</div>
        </Box>
      </Grid>
      <Grid item md={6}>
        <div style={{ marginTop: "8px" }}>
          <InputField
            value={firstName}
            startIcon={AccountIcon}
            placeholder="First Name"
            bgColor="null"
            editable={true}
            inputWidth="100%"
            setter={setFirstName}
          />
        </div>
      </Grid>
      <Grid item md={6}>
        <div style={{ marginTop: "8px" }}>
          <InputField
            value={lastName}
            placeholder="Last Name"
            startIcon={AccountIcon}
            bgColor="null"
            editable={true}
            inputHeight=""
            inputWidth="100%"
            setter={setLastName}
          />
        </div>
      </Grid>
      <Grid item md={12}>
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>User Role</div>
        </Box>
      </Grid>

      <Grid item md={3}>
        <Chip
          label="Manager"
          value={role}
          style={{
            color: role === "manager" ? "white" : "#9747FF",
            border: "2px solid #9747FF",
            background: role === "manager" && "#9747FF",
          }}
          onClick={() => handleRole("manager")}
        />
      </Grid>
      <Grid item md={3}>
        <Chip
          value={role}
          label="Director"
          style={{
            color: role === "director" ? "white" : "#9747FF",
            border: "2px solid #9747FF",
            background: role === "director" && "#9747FF",
          }}
          onClick={() => handleRole("director")}
        />
      </Grid>
      <Grid item md={3}>
        <Chip
          value={role}
          label="Sales Rep"
          style={{
            color: role === "sales_rep" ? "white" : "#9747FF",
            border: "2px solid #9747FF",
            background: role === "sales_rep" && "#9747FF",
          }}
          onClick={() => handleRole("sales_rep")}
        />
      </Grid>

      <Box
        sx={{
          marginTop: "20px",
          borderTop:
            "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
        }}
        className={styles.formRow}
      ></Box>
      <Grid item md={12} style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          style={{
            border: "1px solid white",
            color: "white",
            textTransform: "none",
          }}
          onClick={isClose}
        >
          Cancel
        </Button>
        {!loader ? (
          editUser ? (
            <Button
              style={{
                textTransform: "none",
                marginLeft: "5px",
                background: !loader && !flag && "white",
                color: flag ? "#FFFFFF14" : "black",
              }}
              onClick={handleOnSubmitEdit}
              disabled={loader || flag}
              variant="contained"
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                marginLeft: "5px",
                background: !loader && !flag && "white",
                color: flag ? "#FFFFFF14" : "black",
              }}
              onClick={handleOnSubmit}
              disabled={loader || flag}
            >
              Send Invite User
            </Button>
          )
        ) : (
          <CircularProgress size={32} color="primary" />
        )}
      </Grid>
    </Grid>
  );
};

export default AddUserForm;
