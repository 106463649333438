import React from "react";
import styles from "./transcript.module.css";
const TranscriptList = ({ TranscriptArray }) => {
  return (
    <>
      {TranscriptArray.map((data) => (
        <div className={styles.container}>
          <div style={{ width: "10%" }} className={styles.transcriptText}>
            {data}
          </div>
          <div style={{ width: "90%" }} className={styles.transcriptText}>
            This is the Client's guide to the Webflow Editor. Share these
            lessons to get your clients up and running.Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Quisque sollicitudin leo in massa
            hendrerit venenatis.{" "}
          </div>
        </div>
      ))}
    </>
  );
};
TranscriptList.defaultProps = {
  TranscriptArray: [
    "0:01",
    "0:03",
    "1:21",
    "1:45",
    "0:01",
    "0:03",
    "1:21",
    "0:01",
    "0:03",
    "1:21",
    "1:45",
    "1:45",
  ],
};
export default TranscriptList;
