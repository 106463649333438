import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
  AreaChart,
} from "recharts";

const data = [
  { name: "Jan", uv: 20, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 40, pv: 4567, amt: 2400 },
  { name: "Mar", uv: 60, pv: 1398, amt: 2400 },
  { name: "Apr", uv: 70, pv: 9800, amt: 2400 },
];

const LinearGraph = ({ type, height, graphScore, inMeeting }) => {
  return (
    // <div style={{ width: "100%" }}>
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart data={graphScore}>
        <CartesianGrid stroke="#FFFFFF40" />
        <defs>
          <filter id={`${type}`} x="-20%" y="-20%" width="120%" height="125%">
            <feDropShadow
              dx="4"
              dy="4"
              stdDeviation="4"
              floodColor={
                type === "att"
                  ? "#E51A3F"
                  : type === "mood"
                  ? "#28CA90"
                  : "#F3C243"
              }
            />
          </filter>
        </defs>
        {/* <XAxis dataKey="time" width={0} display="none" /> */}
        {/* Set the X-axis dataKey to "time" */}
        <YAxis domain={[0, 100]} width={0} display="none" />{" "}
        {/* Set Y-axis domain to [0, 100] */}
        <Area
          strokeWidth={3}
          type="monotone"
          dataKey={
            inMeeting
              ? "attenScore"
              : type === "att"
              ? "ae_score"
              : type === "mood"
              ? "mi_score"
              : "vi_score"
          }
          stroke={
            type === "att" ? "#E51A3F" : type === "mood" ? "#28CA90" : "#F3C243"
          }
          fill={
            type === "att"
              ? "#E51A3F00"
              : type === "mood"
              ? "#28CA9000"
              : "#F3C24300"
          }
          filter={`url(#${type})`}
        />
      </AreaChart>
    </ResponsiveContainer>
    // </div>
  );
};
LinearGraph.defaultProps = {
  height: 75, // Adjust the default height as needed
  graphScore: [
    { time: 10, attenScore: 49 },
    { time: 20, attenScore: 39 },
    { time: 30, attenScore: 59 },
  ],
  inMeeting: false,
};

export default LinearGraph;
