import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Engagements from "Pages/SalesRep/Recordings/Recordings";
import Prospects from "Pages/SalesRep/Prospects/Prospects";
import Library from "Pages/SalesRep/Library/Library";
import Integrations from "Pages/SalesRep/Integrations/Integrations";
import Profile from "Pages/SalesRep/Profile/Profile";
import ClientProfile from "Pages/SalesRep/Prospects/ClientProfile/ClientProfile";
import MeetingDetails from "Pages/SalesRep/Recordings/MeetingDetails/MeetingDetails";
import { Container } from "@mui/material";
import useWindowDimensions from "Components/Common/GetWindowDimension/GetWindowDimension";
import SalesRepFlow from "Flows/SalesRepFlow/SalesRepFlow";
import Registration from "Pages/Registration/Registration";
import EmailVerification from "Pages/Registration/EmailVerification";
import AccountIntegrations from "Pages/Registration/AccountIntegrations";
import Login from "Pages/Login/Login";
import ForgotPassword from "Pages/Login/ForgotPassword";
import UserNotFound from "Pages/Registration/UserNotFound";
import Meeting from "Pages/Meeting/Meeting";
import { Provider } from "react-redux";
import store from "Store/store";
import DestroyMeeting from "Pages/Meeting/DestroyMeeting";
import NewAnalytics from "Pages/SalesRep/Analytics/NewAnalytics";
import GlobalToastContainer from "Components/Common/ToastContainer/ToastContainer";
import SingleInteraction from "Components/Common/DetailsCard/SingleDetailsCard/SingleInteraction";
import AllInteractions from "Pages/SalesRep/Prospects/AllInteractions/AllInteractions";
import BehaviouralTraining from "Pages/BehaviouralTraining/Pages/Dashboard/BehaviouralTraining";
import Header from "Pages/BehaviouralTraining/Header/Header";
import LearningModules from "Pages/BehaviouralTraining/Pages/LearningModules/LearningModules";
import QuizModule from "Pages/BehaviouralTraining/Pages/QuizModule/QuizModule";
import NewHome from "Pages/SalesRep/Home/NewHome/NewHome";
import NewEngagements from "Pages/SalesRep/Recordings/NewEngagements/NewEngagements";
import NewLibrary from "Pages/SalesRep/Library/NewLibrary/NewLibrary";
import NewMeetingDetails from "Pages/SalesRep/Recordings/NewMeetingDetails/NewMeetingDetails";
import Statistics from "Pages/SalesRep/Analytics/Statistics/Statistics";
import ManagerFlow from "Flows/ManagerFlow/ManagerFlow";
import ManagersHome from "Pages/ManagersFlow/Pages/ManagersHome/ManagersHome";
import Products from "Pages/ManagersFlow/Pages/Products/Products";
import ManagerStats from "Pages/ManagersFlow/Pages/ManagersStats/ManagerStats";
import CheckLogin from "CheckLogin/CheckLogin";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser } from "Store/features/setUserSlice";
import DirectorHome from "Pages/DirectorFlow/Pages/DirectorHome/DirectorHome";
import DirectorFlow from "Flows/DirectorFlow/DirectorFlow";
import Teams from "Pages/DirectorFlow/Teams/Teams";
//Admin Imports
import AdminFlow from "Flows/AdminFlow/Admin"
import AdminHome from "Pages/AdminFlow/Pages/Home/Home";
import AdminUsers from "Pages/AdminFlow/Pages/Users/Users";
import AdminTeams from "Pages/AdminFlow/Pages/Teams/Teams";
import AdminLibrary from "Pages/AdminFlow/Pages/Library/Library";
import AdminProducts from "Pages/AdminFlow/Pages/Products/Products";
import AdminCompany from "Pages/AdminFlow/Pages/Company/Company";
import ManageTeam from "Pages/AdminFlow/Components/teams/ManageTeam";
import Users from "Pages/AdminFlow/Pages/Users/Users";
import { ManagerUsers } from "Pages/ManagersFlow/Pages/ManagerUsers/Users";


const AppRouter = ({ containerPadding }) => {

  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.setUser.user.role);


  useEffect(() => {
    if (!userRole) {
      const user = JSON.parse(sessionStorage.getItem("user"));
      if (user) {
        dispatch(setUser(user));
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>

        {!userRole  && (<Route path="/" element={<Login />} />)}
        <Route path="/signup" element={<Registration />} />
        <Route path="/verification" element={<EmailVerification />} />
        <Route path="/verification:email" element={<EmailVerification />} />
        <Route path="/account-integrations" element={<AccountIntegrations />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/user-not-found" element={<UserNotFound />} />
      </Routes>
      <Routes>
        <Route path="/meeting" element={<Meeting />} />
      </Routes>
      <Routes>
        <Route element={<Header />}>
          <Route path="/training" element={<BehaviouralTraining />} />
          <Route path="/modules" element={<LearningModules />} />
          <Route path="/quiz" element={<QuizModule />} />
        </Route>
      </Routes>
      <Routes>
        <Route element={<CheckLogin />}>
          {/*Sales Rep Routes*/}
          {userRole === "sales_rep" && (
            <Route
              path="/"
              element={<SalesRepFlow containerPadding={containerPadding} />}
            >
              <Route path="/" element={<NewHome />} />
              <Route path="/analytics" element={<Statistics />} />
              <Route path="/Engagements" element={<NewEngagements />} />
              <Route path="/interaction" element={<Prospects />} />
              <Route
                path="/single-interaction"
                element={<SingleInteraction />}
              />
              <Route path="/all-interactions" element={<AllInteractions />} />

              <Route path="/prospects/:clientId" element={<ClientProfile />} />
              <Route
                path="/Engagements/:recordingId"
                element={<NewMeetingDetails />}
              />
              <Route path="/library" element={<NewLibrary />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/destroy_meeting" element={<DestroyMeeting />} />
            </Route>
          )}
          {/*Manage Routes*/}
          {userRole === "manager" && (
            <Route
              path="/"
              element={<ManagerFlow containerPadding={containerPadding} />}
            >
              <Route path="/" element={<ManagersHome />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/my-products" element={<Products />} />
              <Route path="/analytics" element={<ManagerStats />} />
              <Route path="/users" element={<ManagerUsers fromManager={true} team_id={1} fromTeam={true}/>} />
            </Route>
          )}
          {/*director Routes*/}
          {userRole === "director" && (
            <Route
              path="/"
              element={<DirectorFlow containerPadding={containerPadding} />}
            >
              <Route path="/" element={<DirectorHome />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
          )}
          {/*Admin Routes*/}
          {userRole === "admin" && (
            <Route path="/" element={<AdminFlow containerPadding={containerPadding} />}>
              <Route path="/" element={<AdminHome />} />
              <Route path="/users" element={<AdminUsers fromManager={false} />} />
              <Route path="/teams" element={<AdminTeams />} />
              <Route path="/setting" element={<ManageTeam/>} />
              <Route path="/library" element={<AdminLibrary />} />
              <Route path="/products" element={<AdminProducts />} />
              <Route path="/company" element={<AdminCompany />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
          )}
        </Route>
      </Routes>

      {/* </Container> */}
    </BrowserRouter>
  );
};

export default AppRouter;
