import React from "react";
import styles from "./Attachment.module.css";
import { Typography, Box, Chip } from "@mui/material";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const Attachment = () => {
  return (
    <>
      <Box className={styles.attachedDocument}>
        <Box className={styles.infoContainer}>
          <Typography className={styles.description}>
            Inhibitor of RAS GTPase Family
          </Typography>
        </Box>
        <Box className={styles.imageContainer}>
          <Chip
            className={styles.chipDefault}
            label="Notes"
            icon={<DescriptionOutlinedIcon className={styles.icon} />}
          />
        </Box>
      </Box>
    </>
  );
};

export default Attachment;
