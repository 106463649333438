import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Autocomplete, TextField } from "@mui/material";
import editIcon from "../../NewDashboard/Assets/editIcon.svg";
import greenCheck from "../../NewDashboard/Assets/greenCheck.svg";
import crossIcon from "../../NewDashboard/Assets/crossIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";

import styles from "./product.module.css";
import { updateMedicine } from "Api/Backend/backend";
const ProductTemplate = ({
  productData,
  fromAdmin,
  productName,
  isManager,
  selectedProduct,
  getAllProducts,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [description, setDescription] = useState(
    `You are supposed to cover all of these talking points that has been updated by your organisation while you’re in the meeting.`
  );
  const [agendaType, setAgendaType] = useState("");
  const [instruction, setInstruction] = useState("");
  const handleChange = (e) => {
    if (e.target.name === "type") {
      setAgendaType(e.target.value);
    } else {
      setInstruction(e.target.value);
    }
  };
  let arr;
  if (productData.length > 0) {
    arr = JSON.parse(productData);
  }
  const handleSubmit = () => {
    arr[agendaType] = instruction;
    const data = JSON.stringify(arr);
    console.log(data);
    selectedProduct["med_dosage_administration"] = `${data}`;
    console.log(selectedProduct);
    const response = updateMedicine(selectedProduct).then(() => {
      setIsEditMode(false);
      toast.success("Updated");
      setAgendaType("");
      getAllProducts();
      setInstruction("");
    });
  };

  return (
    <div
      className={
        isManager ? styles.managerProductContainer : styles.productContainer
      }
    >
      {isEditMode ? (
        <>
          <div className={styles.sectionHeader}>Description</div>
          <TextField
            disabled={true}
            sx={{
              "& .css-1y057mc-MuiInputBase-root-MuiOutlinedInput-root": {
                color: "#77767c !important",
                textAlign: "left !important",

                fontWeight: "700 !important",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#77767c",
              },
            }}
            multiline
            className={styles.editModetalkingPointHeading}
            value={description}
          />
          <div className={styles.sectionHeader}>Agenda Items</div>
          {productData.length > 0 &&
            Object.keys(arr).map((data) => (
              <div className={styles.talkingPointContainer}>
                <div style={{ display: "flex", width: "30%", gap: "4px" }}>
                  <CheckIcon
                    sx={{
                      color: "green",
                    }}
                  />
                  <div className={styles.talkingPointTitle}>{data}</div>
                </div>
                <div className={styles.talkingPointDescription}>
                  {arr[data]}
                </div>
                <img src={editIcon} />
                <img src={crossIcon} />
              </div>
            ))}
          {isAddMode && (
            <div style={{ display: "flex", width: "100%", gap: "24px" }}>
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={agendaItems}
                sx={{
                  width: "40%",
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                  padding: "10px 16px",
                }}
                renderInput={(params) => (
                  <TextField
                    focused
                    {...params}
                    variant="standard"
                    placeholder="Type of Agenda"
                    sx={{
                      "& input": {
                        color: "rgba(255, 255, 255, 0.90) !important",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottom: "none !important", // Customize the hover border color here
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "none !important", // Customize the border color here
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none !important", // Customize the focus border color here
                      },

                      "& .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator":
                        {
                          color: "white !important", // Hide the dropdown icon
                        },
                    }}
                  />
                )}
              /> */}
              <input
                id="agenda_input"
                name="type"
                className={styles.editModetalkingPointHeading}
                placeholder="Type of Agenda"
                onChange={handleChange}
              />
              <input
                name="instruction"
                id="text_input"
                className={styles.editModetalkingPointHeading}
                placeholder="Enter instructions here"
                onChange={handleChange}
              />
              <div style={{ display: "flex", gap: "8px" }}>
                <img onClick={handleSubmit} src={greenCheck} />
                <img src={crossIcon} />
              </div>
            </div>
          )}
          <button
            onClick={() => setIsAddMode(!isAddMode)}
            className={styles.editButton}
          >
            {isAddMode ? (
              "Remove Agenda Item"
            ) : (
              <>
                <AddIcon />
                Add Agenda Item
              </>
            )}
          </button>{" "}
        </>
      ) : (
        <>
          <div className={styles.medicineName}>{productName}</div>
          <div className={styles.talkingPointsHeading}>
            You are supposed to cover all of these talking points that has been
            updated by your organisation while you’re in the meeting.
          </div>
          {productData.length > 0 &&
            Object.keys(arr).map((data) => (
              <div className={styles.talkingPointContainer}>
                <div style={{ display: "flex", width: "30%", gap: "4px" }}>
                  <CheckIcon
                    sx={{
                      cursor: "pointer",
                      color: "green",
                    }}
                  />
                  <div className={styles.talkingPointTitle}>{data}</div>
                </div>
                <div className={styles.talkingPointDescription}>
                  {arr[data]}
                </div>
              </div>
            ))}
        </>
      )}
      {isManager &&
        (isEditMode ? (
          <div style={{ display: "flex", gap: "10px" }}>
            {" "}
            <button
              onClick={() => setIsEditMode(false)}
              className={styles.editButton}
            >
              Cancel
            </button>{" "}
            <button
              onClick={() => setIsEditMode(false)}
              className={styles.cancelButton}
            >
              Save Changes
            </button>
          </div>
        ) : (
          (fromAdmin && <button
            onClick={() => setIsEditMode(true)}
            className={styles.editButton}
          >
            Edit Template
          </button>)
        ))}
    </div>
  );
};
ProductTemplate.defaultProps = {
  productName: "AMJEVITA",
  productData: [],
  isManager: false,
};

export default ProductTemplate;
