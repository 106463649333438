import React from "react";
import scoreIcon from "../../Assets/scoreIcon.svg";
import levelIcon from "../../Assets/levelIcon.svg";
import { Typography } from "@mui/material";
import styles from "./salesbadge.module.css";
const SalesBadge = ({ isScore, title, score, badge }) => {
  return (
    <div className={styles.mainContainer}>
      {isScore ? (
        <div className={styles.flexRow}>
          <img style={{ margin: "0" }} src={badge} />
          <div style={{ display: "flex" }}>
            <Typography className={styles.badgeTitle}>Score {score}</Typography>
            <Typography className={styles.badgeScore}>/300</Typography>
          </div>
        </div>
      ) : (
        <div className={styles.flexRow}>
          <img style={{ margin: "0" }} src={badge} />
          <Typography className={styles.badgeTitle}>{title}</Typography>
        </div>
      )}
    </div>
  );
};
SalesBadge.defaultProps = {
  isScore: true,
  title: "Beginner",
  score: "230",
  badge: scoreIcon,
};

export default SalesBadge;
