import React, { useEffect, useState } from "react";
import DataTable from "Components/Common/DataTable/DataTable";
import EngagementInfoBox from "Components/NewDashboard/EngagementInfoBox/EngagementInfoBox";
import styles from "./engagements.module.css";
import columnsDef from "../ColumnDef";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  get_ttlr_by_user,
  recordingStats,
  recordingTable,
} from "Api/Backend/backend";
import { useSelector } from "react-redux";
import { setCurrentRoute } from "Store/features/currentRoute";
import Loading from "Pages/Misc/Loading/Loading";

const NewEngagements = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ttlr, set_ttlr] = useState(0);
  const [statData, setStatData] = useState({
    meeting_count: 0,
    average_success_score: 0,
    doctor_name: undefined,
    feedback_count: 0,
  });
  const currentUser = useSelector((state) => state.setUser.user);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const getTableData = async (id) => {
    const response = await recordingTable(id)
      .then((response) => {
        setTableData(response);
      })
      .catch((e) => console.log(e));
  };
  const onRowClick = (data) => {
    sessionStorage.setItem("meetingTitle", data.title);
    navigate(`/Engagements/${data.mid}`, { state: data });
  };
  const getStatData = async (id) => {
    const response = await recordingStats(id)
      .then((response) => setStatData(response))
      .catch((e) => console.log(e));
  };
  const get_ttlr = async (id) => {
    const response = await get_ttlr_by_user(id)
      .then((response) => set_ttlr(response.data))
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      dispatch(setCurrentRoute(2));
      getTableData(user.user_id);
      getStatData(user.user_id);
      get_ttlr(user.user_id);
    }
  }, [count]);
  const [loading, setLoading] = useState(true);
  return (
    <>
      {loading ? (
        <Loading setLoading={setLoading} />
      ) : (
        <div className={styles.container}>
          <div className={styles.statisticsContainer}>
            <div className={styles.statisticsTitle}>Engagements Statistics</div>
            <div className={styles.infoBoxContianer}>
              <EngagementInfoBox
                title={"Total Engagements"}
                value={statData.meeting_count}
              />
              <EngagementInfoBox
                title={"Talk/Listen"}
                talkValue={
                  ttlr.doc_avg_ttl === null
                    ? `N/A`
                    : `${(
                        ttlr.doc_avg_ttl /
                        (ttlr.doc_avg_ttl + ttlr.user_avg_ttl)
                      ).toFixed(2)}`
                }
                listenValue={
                  ttlr.doc_avg_ttl === null
                    ? `N/A`
                    : `${(
                        ttlr.user_avg_ttl /
                        (ttlr.doc_avg_ttl + ttlr.user_avg_ttl)
                      ).toFixed(2)}`
                }
                type={1}
                fontSize={"24px"}
              />
              <EngagementInfoBox
                title={"Feedback"}
                value={
                  statData.feedback_count === 0
                    ? `N/A`
                    : Math.round(
                        statData.feedback_count / statData.meeting_count
                      )
                }
                type={2}
              />
              {console.log(statData)}
              <EngagementInfoBox
                title={"Average Affect Score"}
                value={Math.round(statData.average_success_score)}
                type={3}
              />
            </div>
          </div>
          <div className={styles.dataTableContainer}>
            <DataTable
              variant={"filter"}
              defination={columnsDef}
              cellData={tableData}
              onRowClick={onRowClick}
              id={currentUser.user_id}
              getTableData={getTableData}
              setCount={setCount}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NewEngagements;
