import React from "react";
import { Container } from "@mui/system";
import styles from "./Loading.module.css";
import logo from "Pages/Login/Assets/Logo.svg";
import LinearDeterminate from "./LinearDeterminate";
import { Typography } from "@mui/material";

const Loading = ({ setLoading }) => {
  return (
    <>
      <Container className={styles.loadingContainer}>
        <img src={logo} alt="text" />
        <LinearDeterminate setLoading={setLoading} />
        <Typography className={styles.redirectText}>Redirecting...</Typography>
      </Container>
    </>
  );
};

export default Loading;
