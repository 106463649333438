import React from "react";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import styles from "./tabs.module.css";

const Tabs = ({ value, setValue, firstTab,fromManager, secondTab, thirdTab }) => {
  return (
    <div className={styles.tabContainer}>
      <div
        onClick={() => {
          setValue(0);
        }}
        className={styles.tab}
        style={{
          borderBottom:
            value === 0 && "2px solid var(--dark-inverted-bg-100, #fff)",
        }}
      >
        <CalendarTodayRoundedIcon
          fontSize="small"
          sx={{
            color:
              value === 0
                ? "white"
                : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
          }}
        />
        <div className={styles.tabText}>{firstTab}</div>
        {/* {value === 0 && <div className={styles.countContainer}>8</div>} */}
      </div>
      <div
        onClick={() => {
          setValue(1);
        }}
        className={styles.tab}
        style={{
          borderBottom:
            value === 1 && "2px solid var(--dark-inverted-bg-100, #fff)",
        }}
      >
        <InsertDriveFileOutlinedIcon
          fontSize="small"
          sx={{
            color:
              value === 1
                ? "white"
                : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
          }}
        />
        <div className={styles.tabText}>{secondTab}</div>
        {/* {value === 1 && <div className={styles.countContainer}>8</div>} */}
      </div>
      {fromManager ? <></>:<div
        onClick={() => {
          setValue(2);
        }}
        className={styles.tab}
        style={{
          borderBottom:
            value === 2 && "2px solid var(--dark-inverted-bg-100, #fff)",
        }}
      >
        <DownloadIcon
          fontSize="small"
          sx={{
            color:
              value === 2
                ? "white"
                : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
          }}
        />
        <div className={styles.tabText}>{thirdTab}</div>
        {/* {value === 2 && <div className={styles.countContainer}>8</div>} */}
      </div>}
    </div>
  );
};
Tabs.defaultProps = {
  value: 0,
  setValue: () => {},
  secondTab: "Documents",
  thirdTab: "Notes",
  firstTab: "Description",
};

export default Tabs;
