import React from "react";
import dummyDrIcon from "../../../Assets/dummyDrIcon.svg";
import { Avatar } from "@mui/material";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";
import HalfDonutChart from "Components/SalesRep/HalfDonutChart/HalfDonutChart";
import styles from "./infocard.module.css";

const InfoCard = ({
  title,
  subTitle,
  icon,
  hasDonutChart,
  value,
  width,
  isUserCard,
}) => {
  return (
    <div
      style={{
        boxShadow: isUserCard ? "none" : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
      className={styles.container}
    >
      {hasDonutChart ? (
        <HalfDonutChartSmall value={value} />
      ) : (
        <Avatar src={icon} alt={title} />
      )}
      <div>
        <div style={{ width: width }} className={styles.cardTitle}>
          {title}
        </div>
        <div className={styles.cardSubtitle}>{subTitle}</div>
      </div>
    </div>
  );
};
InfoCard.defaultProps = {
  title: "Dr. Jessy Barad ",
  subTitle: "oncologist",
  icon: dummyDrIcon,
  hasDonutChart: false,
  value: 43,
  width: "auto",
  isUserCard: false,
};
export default InfoCard;
