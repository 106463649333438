import React from "react";
import productIcon from "../../Assets/productIcon.svg";
import calendarIcon from "../../Assets/calendarIcon.svg";
import coursesIcon from "../../Assets/coursesIcon.svg";
import styles from "./progressbar.module.css";
import { Typography } from "@mui/material";

const ProgressBar = ({ title, type }) => {
  return (
    <div className={styles.flexRow}>
      <img
        style={{ margin: "0" }}
        src={type === 0 ? productIcon : type === 1 ? calendarIcon : coursesIcon}
      />
      <div className={styles.innerContainer}>
        <Typography className={styles.description}>
          {type === 0 ? (
            <> Status</>
          ) : type === 1 ? (
            <>Streak</>
          ) : (
            <>Completion</>
          )}
        </Typography>
        <Typography className={styles.title}>{title}</Typography>
        {type === 0 && (
          <Typography className={styles.courses}>
            3 compulsory courses left
          </Typography>
        )}
      </div>
    </div>
  );
};
ProgressBar.defaultProps = {
  title: "Not Ready",
  type: 0,
};
export default ProgressBar;
