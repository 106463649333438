import React, { useState } from "react";
import styles from "./Meeting.module.css";
import { Container } from "@mui/material";
import Screen from "./Screens/Screen";
import App from "./ZoomMeeting";
import { useLocation } from "react-router-dom";

const Meeting = () => {
  const location = useLocation();
  const meetingLink = location.state.link;
  const meetingTitle = location.state.title;
  const mid = location.state.mid;
  const doc_list = location.state.doc_list;
  const note = location.state.note;
  const doctor_name = location.state.doctor_name;
  const [isBotCohost, setIsBotCohost] = useState(false);
  const zoomToken = location.state.zoomToken;
  let screen_mode = "inmeeting";
  return (
    <>
      <Container className={styles.meetingContainer} maxWidth={false}>
        <Container maxWidth={true} className={styles.videoCallContainer}>
          <App
            setIsBotCohost={setIsBotCohost}
            meetingTitle={meetingTitle}
            zoomMeetingLink={meetingLink}
            doctorName={doctor_name}
            zoomToken={zoomToken}
            mid={mid}
            note={note}
            doc_list={doc_list}
          />
        </Container>
        <Container className={styles.meetingSidebarContainer}>
          <Screen
            isBotCohost={isBotCohost}
            meetingTitle={meetingTitle}
            active={screen_mode}
            doctorName={doctor_name}
            mid={mid}
            note={note}
            doc_list={doc_list}
          />
        </Container>
      </Container>
    </>
  );
};

export default Meeting;
