import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./WeeklyCalender.module.css";

const DayCard = ({
  isAnalytics,
  dayNum,
  dayName,
  index,
  selectedComponent,
  setSelectedComponent,
  dayData,
  setFirstDate,
}) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(false);
    const date = new Date();
    const day = date.getDay();
    if (day === 6 || day === 0) {
      setFirstDate(
        `${dayData[0].year}-${
          dayData[0].monthDate < 10
            ? `0${dayData[0].monthDate}`
            : `${dayData[0].monthDate}`
        }-${
          dayData[0].dayNum < 10
            ? `0${dayData[0].dayNum}`
            : `${dayData[0].dayNum}`
        }`
      );
    } else {
      setFirstDate(
        `${dayData[day - 1].year}-${
          dayData[day - 1].monthDate < 10
            ? `0${dayData[day - 1].monthDate}`
            : `${dayData[day - 1].monthDate}`
        }-${
          dayData[day - 1].dayNum < 10
            ? `0${dayData[day - 1].dayNum}`
            : `${dayData[day - 1].dayNum}`
        }`
      );
    }
    setSelected(new Date().getDate() === dayNum ? true : false);
  }, []);

  useEffect(() => {
    if (selectedComponent !== 0) {
      if (selectedComponent === dayNum) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [selectedComponent]);

  const handleClick = (cardIndex) => {
    setSelectedComponent(dayNum);
    setFirstDate(
      `${dayData[cardIndex].year}-${
        dayData[cardIndex].monthDate < 10
          ? `0${dayData[cardIndex].monthDate}`
          : `${dayData[cardIndex].monthDate}`
      }-${
        dayData[cardIndex].dayNum < 10
          ? `0${dayData[cardIndex].dayNum}`
          : `${dayData[cardIndex].dayNum}`
      }`
    );
  };

  return (
    <Box
      onClick={() => handleClick(index)}
      className={
        selected ? styles.dayCardContainerSelected : styles.dayCardContainer
      }
      sx={{ marginRight: "12px" }}
    >
      <div className={styles.cardContent}>
        <Typography
          className={selected ? styles.cardTextSelected : styles.cardText}
        >
          {dayNum}
        </Typography>
        <Typography
          className={selected ? styles.cardTextSelected : styles.cardText}
        >
          {dayName}
        </Typography>
      </div>
      {isAnalytics && dayName === "Fri" && (
        <div className={styles.verticalLine} />
      )}
    </Box>
  );
};

export default DayCard;
