import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../Components/NewDashboard/AddProducts/ScheduleMeetingModal.module.css";
import closeIcon from "../Assets/Icon.svg";
import {
  createTeam,
} from "Api/Backend/backend";
import { toast } from "react-toastify";
import { ClickAwayListener } from "@mui/base";
import { Modal } from "@mui/material";
import AddTeamForm from "Pages/AdminFlow/Components/teams/AddTeamForm";

const AddTeamModal = ({ meetingModalOpen, setMeetingModalOpen, id }) => {
  const [teamName, setTeamName] = useState("");
  const [manager, setManager] = useState(0);
  const [director, setDirector] = useState(0);
  const [selectedDirector, setSelectedDirector] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
 
  const buttonRef = useRef();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        buttonRef.current.click();
      }
    };
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const handleClose = () => {
    setMeetingModalOpen(false);
  };
  const handleClickAway = (e) => {
    e.stopPropagation();
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    borderRadius: "12px",
    background: "var(--theme-dark-0, #222224)",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "95vh",
    p: 4,
    overflowY: "scroll",
  };
 
  const addTeam = () => {
     console.log(teamName.length)

     if(teamName.trim()==="" && !selectedDirector && !selectedManager){
      toast.error("Fill all fields");
      return;
     
    }
    if(teamName.length >= 30 ){
      toast.error("Team name too long");
      return;
    }
   
    const data = {
      director_id:selectedDirector.user_id,
      manager_id:selectedManager.user_id,
      organization_name:"DiveDeep",
      team_name:teamName,
  };
    const response = createTeam(data)
      .then((res) => {
        toast.success("Team Created");
        setMeetingModalOpen(false)
      })
      .catch((e) => console.log(e));
  };
  return (
    <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
      <Modal
        open={meetingModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div className={styles.headerContainer}>
            <div className={styles.dialogueTitle}>Add Team</div>
            <img
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              src={closeIcon}
              alt="close icon"
            />
          </div>
          <div style={{ padding: "40px" }}>
            <div className={styles.sectionHeader}>Name</div>
            <AddTeamForm
              setTeamName={setTeamName}
              setManager={setManager}
              setDirector={setDirector}
              selectedManager={selectedManager}
              setSelectedManager={setSelectedManager}
              selectedDirector={selectedDirector}
              setSelectedDirector={setSelectedDirector}
              userid={id}
            />
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={handleClose} className={styles.cancelButton}>
              Cancel
            </button>
            <button 
             onClick={addTeam}
             className={styles.createButton}>
              Create Team
            </button>
          </div>
        </div>
      </Modal>
    </ClickAwayListener>
  );
};

AddTeamModal.defaultProps = {
  getAllMeetings: () => {},
  meetings: "",
  scheduledMeetings: [],
  setAllScheduledMeetings: () => {},
};
export default AddTeamModal;
