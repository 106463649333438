import React, { useState } from "react";
import styles from "./selectmonth.module.css";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const MonthSelect = ({ setDates, setSelectedMonth, selectedMonth }) => {
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleClick = (index) => {
    const generatedDates = generateMonthDates(index);
    setDates(generatedDates);
  };

  const generateMonthDates = (index) => {
    // Logic to generate the dates of the selected month
    // Replace this with your own implementation
    const currentDate = new Date();
    const selectedMonth = index;
    const selectedYear = currentDate.getFullYear();

    const firstDay = new Date(selectedYear, selectedMonth, 1);
    const lastDay = new Date(selectedYear, selectedMonth + 1, 0);

    const dates = [];
    for (let date = firstDay.getDate(); date <= lastDay.getDate(); date++) {
      dates.push(date);
    }
    return dates;
  };
  return (
    <FormControl>
      <Select
        className={styles.selectContainer}
        sx={{
          "& .css-1faq7rz-MuiFormLabel-root-MuiInputLabel-root": {
            display: "none !important",
          },

          "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
            color: "rgba(255, 255, 255, 0.8) !important",
          },
          "& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
            color: "rgba(255, 255, 255, 0.8)",
          },
          color: "rgba(255, 255, 255, 0.8)", // Set text color to white
        }}
        labelId="month-select-label"
        id="month-select"
        value={selectedMonth} // Select the current month
        onChange={handleMonthChange}
      >
        {months.map((month, index) => (
          <MenuItem
            onClick={() => handleClick(index)}
            key={index}
            value={index}
          >
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export default MonthSelect;
MonthSelect.defaultProps = {
  setDates: () => {},
  selectedMonth: new Date().getMonth(),
  setSelectedMonth: () => {},
};
