import React, { useEffect, useState } from "react";
import InfoCard from "../InfoCard/InfoCard";
import medicineIcon from "../Assets/syringIcon.svg";
import Tabs from "../Tabs/Tabs";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import SendIcon from "@mui/icons-material/Send";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import NotesTab from "Components/SalesRep/Notes/NotesTab";
import DocumentTab from "Components/SalesRep/DocumentTab/DocumentTab";
import CreateIcon from "@mui/icons-material/Create";
import LoginIcon from "@mui/icons-material/Login";
import TextEditor from "Components/NewDashboard/TextEditor/TextEditor";
import { IconButton } from "@mui/material";
import emailjs from "@emailjs/browser";
import DuoOutlinedIcon from "@mui/icons-material/DuoOutlined";
import styles from "./splitview.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SplitView = ({
  isOpen,
  meetingData,
  setIsOpen,
  setIsFocus,
  isPastMeeting,
  setTitle,
  setSourceImage,
  isViewerOpen,
  setIsViewerOpen,
}) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.setUser.user);
  const userName = `${user.first_name} ${user.last_name}`;

  const [editText, setEditText] = useState(false);
  const text = `

Date & Time: ${meetingData.meetingDate} at ${meetingData.start_time} - ${meetingData.end_time}
<br/>

Meeting ID: ${meetingData.meeting_id}
<br/>

Passcode: fAk9RX
<br/>

Zoom Link:
${meetingData.meeting_link}

`;
  const [primaryText, setPrimaryText] = useState();
  useEffect(() => {
    setEditText(false);
    setPrimaryText(`
Dear ${meetingData.doctor_name},
<br/>
Thank you for agreeing to meet with me. I hope you found our earlier discussion helpful and informative. I have scheduled our meeting today.
<br/>

Talk to you soon 🤗.
<br/>

If you have any queries, please do feel free to contact me.
<br/>

Sincerely,
<br/>

${userName}
<br/>

AMGEN Pharma
<br/>
`);
  }, [meetingData]);
  const renderLink = (props) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      <b>{props.children}</b>
    </a>
  );
  const sendEmailText = primaryText + text;
  const renderList = ({ children }) => <ul>{children}</ul>;
  const renderListItem = ({ children }) => <li>{children}</li>;

  const sendMidTrasncription = async () => {
    const endBot = axios
      .get("https://zoombot.affectcx.io/api/stop-all-containers")
      .then((res) => {});
    const resp = await axios
      .post(`https://microservicesaudio.affectcx.io/whisper/post-mid`, {
        mid: meetingData.mid,
      })
      .then((response) => {
        navigate("/meeting", {
          state: {
            link: `${meetingData.meeting_id}`,
            note: `${meetingData.note}`,
            doc_list: meetingData.doc_list,
            zoomToken: `${meetingData.zak}`,
            title: `${meetingData.title}`,
            mid: `${meetingData.mid}`,
            doctor_name: `${meetingData.doctor_name}`,
          },
        });
      })
      .catch((e) => console.log(e));
  };
  function sendEmailToClient(clientEmail, message) {
    setIsLoading(true);
    const templateParams = {
      from_name: userName,
      to_name: meetingData.doctor_name,
      to_email: meetingData.email,
      message: message,
      meeting_title: meetingData.title,
    };

    emailjs
      .send(
        "service_i3d9tym",
        "template_0xfg8dw",
        templateParams,
        "YVWLSVJUruJde6JzU",
        { headers: { contentType: "application/json" } }
      )
      .then((result) => {
        setIsLoading(false);

        toast.success("Email sent successfully");
      })
      .catch((error) => {
        toast.error("Email sending failed");
        setIsLoading(false);
      });
  }
  const getDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formatted = dateObject.toLocaleDateString("en-UK", options);
    return formatted;
  };
  const calculateTimeRemaining = (meetingStartTime) => {
    const now = new Date();
    const meetingStart = new Date(meetingStartTime);
    const timeDiff = meetingStart - now;

    if (timeDiff > 0) {
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      return `Starting in ${hours}h ${minutes}m`;
    } else {
      return "Meeting has started";
    }
  };
  const onRowClick = () => {
    sessionStorage.setItem("meetingTitle", meetingData.title);
    navigate(`/Engagements/${meetingData.mid}`, { state: meetingData });
  };
  const handleClick = () => {
    setEditText(true);
  };
  const removeHTMLTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    console.log(doc);

    return doc.body.innerText || "";
  };
  const handleSave = (data) => {
    setEditText(false);
    setPrimaryText(data);
  };

  return (
    <div className={isOpen ? styles.drawerOpen : styles.drawer}>
      <div style={{ textAlign: "right", width: "100%" }}>
        <CloseIcon
          onClick={() => {
            setIsOpen(false);
            setIsFocus(0);
          }}
          sx={{
            color: "var(--dark-inverted-bg-100, #fff) ",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={styles.meetingStartTime}>
        {calculateTimeRemaining(meetingData.meetingDuration)}
      </div>
      <div className={styles.meetingTitle}>{meetingData.title}</div>
      <div className={styles.meetingDetails}>
        <div>
          <div className={styles.meetingDuration}>
            {meetingData.start_time} to {meetingData.end_time}
          </div>
          <div className={styles.meetingDate}>
            {getDate(meetingData.meetingDate)}
          </div>
        </div>

        <div style={{ display: "flex", gap: "1vw" }}>
          <InfoCard
            icon={meetingData.doctor_profile_picture}
            title={meetingData.doctor_name}
          />
          <InfoCard
            subTitle={"product"}
            title={meetingData.med_name}
            icon={medicineIcon}
          />
        </div>
      </div>
      <div className={styles.meetingInfoContainer}>
        <Tabs value={value} setValue={setValue} />
        {value === 0 && editText === false && (
          <IconButton
            sx={{
              alignSelf: "flex-end",
              marginTop: "2vh",
              padding: "4px",
              borderRadius: "2px",
              border: "2px solid #f6f6f6",
            }}
            onClick={handleClick}
          >
            <CreateIcon fontSize="small" sx={{ color: "#f6f6f6" }} />
          </IconButton>
        )}
        <div className={styles.description}>
          {value === 0 && (
            <>
              {editText ? (
                <TextEditor
                  setEditText={setEditText}
                  onSave={handleSave}
                  isSideBar={true}
                  initialContent={primaryText}
                />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: sendEmailText }} />
              )}
            </>
          )}
          {value === 1 && (
            <>
              {meetingData.doc_list.length === 0 ? (
                <p>There are no documents available for this meeting.</p>
              ) : (
                <DocumentTab
                  ishome={true}
                  type="page"
                  setTitle={setTitle}
                  setSourceImage={setSourceImage}
                  isViewerOpen={isViewerOpen}
                  setIsViewerOpen={setIsViewerOpen}
                  selectedIds={[]}
                  variant="document"
                  data={meetingData.doc_list}
                />
              )}
            </>
          )}
          {value === 2 && (
            <>
              <NotesTab note={meetingData.note} />
            </>
          )}
        </div>
      </div>
      <div className={styles.bottomContainer}>
        {/* <div className={styles.buttonContainer}>
          <WestIcon sx={{ color: "white" }} />
        </div>
        <div className={styles.buttonContainer}>
          <EastIcon sx={{ color: "white" }} />
        </div> */}
        <div></div>
        {isPastMeeting ? (
          <></>
        ) : (
          <button
            onClick={() => sendEmailToClient(meetingData.email, sendEmailText)}
            className={styles.cancelButton}
            disabled={isLoading}
          >
            <SendIcon fontSize="small" />
            {isLoading ? "Sending" : "Send Invite"}
          </button>
        )}

        <button
          onClick={isPastMeeting ? onRowClick : sendMidTrasncription}
          className={styles.joinMeeting}
        >
          {isPastMeeting ? <DuoOutlinedIcon /> : <LoginIcon />}
          {isPastMeeting ? "Meeting Details" : "Join Meeting"}
        </button>
      </div>
    </div>
  );
};
SplitView.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  title: "",
  ETA: "Starting in 20min",
  meetingTime: "",
  meetingDuration: "",
  setIsFocus: () => {},
  isPastMeeting: false,
  setTitle: () => {},
  setSourceImage: () => {},
  isViewerOpen: () => {},
  setIsViewerOpen: () => {},
};
export default SplitView;
