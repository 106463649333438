import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import axios from "axios";
import { baseUrl } from "../../Api/Backend/backend";

const DestroyMeeting = () => {
  const location = useLocation();
  const mid = location.state;
  const feedbacks = useSelector((state) => state.endMeetingStats.feedbacks);
  const meetingInfo = useSelector((state) => state.scheduleMeeting.meeting);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const user_id = user.user_id;

  const sendFeedbacks = async () => {
    sessionStorage.setItem(
      "feedbacks",
      JSON.stringify({
        mid: mid,
        user_id: user_id,
        feedbacks: feedbacks,
      })
    );
    const response = await axios
      .post(
        `${baseUrl}add_feedback/?mid=${mid}&user_id=${user_id}`,
        feedbacks,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    sendFeedbacks();
  }, []);

  return <></>;
};

export default DestroyMeeting;
