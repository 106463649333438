import React, { useEffect, useState } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import LineChartWithArea from "Components/NewDashboard/SuccessGraph/SuccessGraph";
import ColoredCircle from "Components/Common/ColoredCircle/ColorCircle";
import medicineIcon from "../../../../Assets/medicineIcon.svg";
import DataTable from "Components/Common/DataTable/DataTable";
import searchIcon from "Components/NewDashboard/Assets/searchIcon.svg";
import columnsDef from "./ColumnDef";
import statsStyles from "../ManagersHome/home.module.css";

import upcomingColumnDef from "./upcomingColumnDef";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  baseUrl,
  getInteractionsByManager,
  getProductStats,
  getRepStatsByManager,
  getTeamStatsByManager,
  getTopClients,
  getTopClientsByUser,
  getTopReps,
  get_analytics_feedback,
  recordingStats,
  recordingTable,
} from "Api/Backend/backend";
import { setCurrentRoute } from "Store/features/currentRoute";
import firstaidIcon from "./Assets/firstaidIcon.svg";
import capsuleIcon from "./Assets/capsuleIcon.svg";
import styles from "./stats.module.css";
import SalesRepLeaderBoard from "Components/NewDashboard/SalesrepLeaderBoard/SalesRepLeaderBoard";
import SuggestionBar from "Pages/Meeting/Components/SuggestionBar/SuggestionBar";
import BarChartGraph from "Components/NewDashboard/BarChart/BarChart";
import NewAnalytics from "../../../SalesRep/Analytics/NewAnalytics";
import MonthSelect from "Components/NewDashboard/SelectMonth/SelectMonth";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router";
import { Chip, Grid } from "@mui/material";
import MeetingResult from "Components/NewDashboard/MeetingResult/MeetingResult";

const ManagerStats = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [feedbacks, setFeedBack] = useState([]);
  const [statData, setStatData] = useState({
    meeting_count: 0,
    average_success_score: 0,
    doctor_name: undefined,
    feedback_count: 0,
  });
  const [clients, setClients] = useState([]);
  const [reps, setReps] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const currentUser = useSelector((state) => state.setUser.user);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [productData, setProductData] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [activeTab, setActiveTab] = useState("product");
  const [teamData, setTeamData] = useState({
    avg_success_score: 0,
    number_of_engagements: 0,
  });
  const [count, setCount] = useState(0);
  const getTableData = async (id) => {
    const response4 = getRepStatsByManager(id)
      .then((res) => {
        console.log(res);
        setTableData(res);
      })
      .catch((e) => console.log(e));
    const response3 = getProductStats(id)
      .then((res) => {
        console.log(res);
        setProductData(res);
      })
      .catch((e) => console.log(e));
    const response2 = getInteractionsByManager(id)
      .then((res) => {
        console.log(res);
        setInteractions(res);
      })
      .catch((e) => console.log(e));
    const response = getTeamStatsByManager(id)
      .then((res) => {
        setTeamData(res);
        console.log(res);
      })
      .catch((e) => console.log(e));
  };

  const onRowClick = (data) => {
    sessionStorage.setItem("meetingTitle", data.title);
    navigate(`/Engagements/${data.mid}`, { state: data });
  };

  // const getTopClient = () => {
  //   const clients = getTopClients()
  //     .then((res) => {
  //       setClients(res.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  const getTopClientByUser = (id) => {
    const clients = getTopClientsByUser(id)
      .then((res) => {
        setClients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  const getReps = () => {
    const clients = getTopReps()
      .then((res) => {
        setReps(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getStatData = async (id) => {
    const response = await recordingStats(id)
      .then((response) => setStatData(response))
      .catch((e) => console.log(e));
  };
  function getCurrentTime() {
    const date = new Date();
    const currentTime = date.toLocaleString();

    return currentTime;
  }
  function getTimeOnly(dateString) {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();

    return time;
  }
  function getDateOn() {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    return formattedDate;
  }
  const getAllMeetings = async (id) => {
    const time = getCurrentTime();
    const todayTime = getTimeOnly(time);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const resp = await axios
      .post(`${baseUrl}get_meeting_by_user_id/`, {
        user_id: id,
        current_time: current_time,
        timezone: timeZone,
      })
      .then((response) => {
        const data = response.data.filter(
          (meeting) => meeting.actual_end_time == null
        );
        const completed = response.data.filter(
          (meeting) => meeting.actual_end_time != null
        );
        setUpcomingMeetings(data);
      })
      .catch((e) => console.log(e));
  };
  const getFeedback = (id) => {
    const response = get_analytics_feedback(id).then((res) =>
      setFeedBack(res.data.top_ten_feedbacks)
    );
  };
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      dispatch(setCurrentRoute(1));
      getTableData(user.user_id);
      getAllMeetings(user.user_id);
      getFeedback(user.user_id);
      getStatData(user.user_id);
      // getTopClient();
      getTopClientByUser(user.user_id);
      getReps();
    }
  }, [count]);
  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        {/* <div className={styles.statisticsContainer}>
          <div className={styles.sectionHeaderContainer}>
            <div className={styles.sectionHeader}>Key Statistics</div>
            <div className={styles.dateContainer}>
              <div className={styles.dateText}>Oct 01 - Oct 24</div>
              <div className={styles.dateSubtitle}>Monthly</div>
            </div>
          </div>
          <div className={styles.statisticsInnerContainer}>
            <div className={styles.stats}>
              <h3>Average Affect Score</h3>
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <DonutChartSmall
                  width={"70%"}
                  value={Math.round(teamData.avg_success_score)}
                  x={40}
                  cutout={"90%"}
                  fontSize={"22px"}
                  y={-3.5}
                  barColor={barBg(Math.round(teamData.avg_success_score))}
                />
              </div>
              <div
                style={{
                  color: "#EE6880",
                  fontSize: "12px",
                  opacity: 0.8,
                  fontWeight: 500,
                  width: "60%",
                }}
              >
                8 Reps under 40% average success score
              </div>
            </div>
            <div className={styles.stats}>
              <h3>Top Performing Rep</h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <div className={styles.statsValue}>
                  {tableData.length > 0 && (
                    <>
                      {tableData[0].first_name} {tableData[0].last_name}
                    </>
                  )}
                </div>
                <ArrowUpwardRounded
                  fontSize="small"
                  style={{
                    color: "#28CA90",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#28CA903D",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  color: "rgba(255, 255, 255, 0.80)",
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                {tableData.length > 0 && tableData[0].engagements} Engagments
                <br />
                <span
                  style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}
                >
                  {" "}
                  {tableData.length > 0 &&
                    Math.round(tableData[0].avg_success_score)}
                  % Success Score
                </span>
              </div>
            </div>
            <div className={styles.stats}>
              <h3>Least Performing Rep</h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <div className={styles.statsValue}>
                  {tableData.length > 0 && (
                    <>
                      {tableData[tableData.length - 1].first_name}{" "}
                      {tableData[tableData.length - 1].last_name}
                    </>
                  )}
                </div>
                <ArrowUpwardRounded
                  fontSize="small"
                  style={{
                    color: "#28CA90",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#28CA903D",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  color: "#EE6880",
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                {tableData.length > 0 &&
                  tableData[tableData.length - 1].engagements}{" "}
                Engagments
                <br />
                <span
                  style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}
                >
                  {" "}
                  {tableData.length > 0 &&
                    tableData[tableData.length - 1].avg_success_score}
                  % Success Score
                </span>
              </div>
            </div>

            <div
              style={{
                borderRight: " 1px solid transparent",
              }}
              className={styles.stats}
            >
              <h3>Top Product </h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <div className={styles.statsValue}>
                  {productData.length > 0 && productData[0].med_name}
                </div>
                <ArrowUpwardRounded
                  fontSize="small"
                  style={{
                    color: "#28CA90",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#28CA903D",
                  }}
                />
              </div>
              <InfoCard
                title={
                  productData.length > 0 &&
                  `${Math.round(productData[0].avg_success_score)}%`
                }
                subTitle={"Avg Success Score "}
                icon={capsuleIcon}
              />
            </div>
          </div>
        </div> */}
        <div
          style={{ marginTop: "3vh" }}
          className={styles.sectionHeaderContainer}
        >
          <div className={styles.sectionHeader}>Sales Rep Performance</div>
          {/* <div className={styles.dateContainer}>
            <MonthSelect
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              setDates={setDates}
            />
          </div> */}
        </div>
        <div
          style={{ display: "flex", marginTop: "32px", alignItems: "center" }}
        >
          <div className={styles.statsTopHeading}>SHOW PERFORMANCE AGAINST</div>
          <div>
            <Grid container>
              <Grid item style={{ marginLeft: "15px" }}>
                <Chip
                  label="PRODUCTS"
                  style={{
                    color:
                      activeTab === "product"
                        ? "white"
                        : "rgba(255, 255, 255, 0.50)",
                    background:
                      activeTab === "product"
                        ? "#9747FF"
                        : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                    cursor: "pointer",
                    borderRadius: "20px",
                  }}
                  onClick={() => handleTab("product")}
                />
              </Grid>
              <Grid item style={{ marginLeft: "15px" }}>
                <Chip
                  label="ENGAGEMENTS"
                  style={{
                    color:
                      activeTab === "engagement"
                        ? "white"
                        : "rgba(255, 255, 255, 0.50)",
                    background:
                      activeTab === "engagement"
                        ? "#9747FF"
                        : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                    cursor: "pointer",
                    borderRadius: "20px",
                  }}
                  onClick={() => handleTab("engagement")}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {activeTab === "product" ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1vh",
                width: "100%",
              }}
            >
              <BarChartGraph isSingle={true} />
              <div className={styles.statGraphContainer}>
                <div className={styles.statGraphHeading}>
                  Product vs Success score
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={styles.barGraphHeading}>Paracetamol OD</div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.statGraphSubtitle}>
                  Is the most well received among clients
                </div>
              </div>
            </div>
            <div className={styles.graphFooter}>
              <div style={{ display: "flex", gap: "8px" }}>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#9747FF"} /> Paracetamol oD
                </div>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Torglip Md
                </div>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Oxygen ai
                </div>{" "}
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Zincoflex
                </div>{" "}
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Garfuek
                </div>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Masterso
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: "2%" }}
            >
              {/* <LineChartWithArea /> */}
              {tableData.length > 0 && (
                <BarChartGraph
                  graphLabels={tableData.map((data) => data.first_name)}
                  score={tableData.map((data) => data.avg_success_score)}
                  engagements={tableData.map((data) => data.engagements)}
                  isSingle={false}
                />
              )}
              {/* <NewAnalytics
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            dates={dates}
          /> */}
              <div className={styles.statGraphContainer}>
                <div className={styles.statGraphHeading}>
                  Engagement vs Success score
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={styles.statGraphValue}>23% More</div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.statGraphSubtitle}>
                  Gain in success score over regular calls
                </div>
              </div>
            </div>
            <div className={styles.graphFooter}>
              <div style={{ display: "flex", gap: "8px" }}>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#9747FF"} /> Score
                </div>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Engagement
                </div>
              </div>
            </div>
          </>
        )}
        <div style={{ marginTop: "40px" }}>
          <div className={styles.sectionHeaderContainer}>
            <div className={styles.statsTopHeading}>REPS INTERACTIONS</div>

            {/* <div className={styles.dateContainer}>
            <div className={styles.dateText}>Oct 01 - Oct 24</div>
            <div className={styles.dateSubtitle}>Monthly</div>
          </div> */}
          </div>
          <div className={styles.flexRow}>
            <div
              style={{ marginTop: "3%" }}
              className={styles.searchBarContainer}
            >
              <img src={searchIcon} alt="icon" />
              <input
                className={styles.searchInput}
                onChange={handleChange}
                placeholder="Search Reps"
              />
            </div>
          </div>
          {/* <div className={styles.tabContainer}>
          <div
            onClick={() => {
              setValue(0);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 0 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 0
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Risky Deal
            </div>
            {/* {value === 0 && (
                <div className={styles.countContainer}>{meetings.length}</div>
              )} 
          </div>
          <div
            onClick={() => {
              setValue(1);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 1 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 1
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Medium Deals
            </div>
            {/* {value === 1 && <div className={styles.countContainer}>8</div>} 
          </div>
          <div
            onClick={() => {
              setValue(2);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 2 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 2
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Successful Deals
            </div>
            {/* {value === 0 && (
                <div className={styles.countContainer}>{meetings.length}</div>
              )} 
          </div>
          {/* {value === 1 && <div className={styles.countContainer}>8</div>} 
        </div> */}

          {value === 0 && (
            <div className={styles.dataTableContainer}>
              <DataTable
                isAllInteraction={true}
                isOnStatistics={true}
                variant={"filter"}
                defination={columnsDef}
                cellData={interactions.filter(
                  (data) => data.status === "RISKY"
                )}
                onRowClick={() => {}}
                id={currentUser.user_id}
                getTableData={getTableData}
                setCount={setCount}
              />
            </div>
          )}
        </div>
        {/* {value === 1 && (
          <div className={styles.dataTableContainer}>
            <DataTable
              isAllInteraction={true}
              isOnStatistics={true}
              variant={"filter"}
              defination={columnsDef}
              cellData={interactions.filter((data) => data.status === "MEDIUM")}
              onRowClick={() => {}}
              id={currentUser.user_id}
              getTableData={getTableData}
              setCount={setCount}
            />
          </div>
        )} */}
        {/* {value === 2 && (
          <div className={styles.dataTableContainer}>
            <DataTable
              isAllInteraction={true}
              isOnStatistics={true}
              variant={"filter"}
              defination={columnsDef}
              cellData={interactions.filter(
                (data) => data.status === "SUCCESS"
              )}
              onRowClick={() => {}}
              id={currentUser.user_id}
              getTableData={getTableData}
              setCount={setCount}
            />
          </div>
        )} */}
        {/* <div className={styles.topRightContainer}>
          <div className={styles.leaderBoard}>
            <div className={styles.sectionHeader}>Reps Leaderboard</div>
            <div className={styles.leaderBoardHeader}>
              <div className={styles.headerText}>Name</div>
              <div className={styles.headerText}>Success Score</div>
            </div>
            {tableData.length > 0 &&
              tableData.map((data, index) => (
                <SalesRepLeaderBoard
                  index={index}
                  subTitle={`${data.engagements} Meetings`}
                  name={`${data.first_name} ${data.last_name}`}
                  icon={data.user_image}
                  value={Math.round(data.avg_success_score)}
                />
              ))}
          </div>
          <div className={styles.topClients}>
            <div className={styles.sectionHeader}>Product Leaderboard</div>
            <div className={styles.leaderBoardHeader}>
              <div className={styles.headerText}>Name</div>
              <div className={styles.headerText}>Success Score</div>
            </div>
            {productData.length > 0 &&
              productData.map((data, index) => (
                <SalesRepLeaderBoard
                  index={index}
                  subTitle={``}
                  name={`${data.med_name}`}
                  icon={medicineIcon}
                  value={Math.round(data.avg_success_score)}
                />
              ))}
          </div>
        </div> */}
      </div>
      <div className={styles.rightContainer}>
        <div
          style={{ marginTop: "3vh" }}
          className={styles.sectionHeaderContainer}
        >
          <div className={styles.sectionHeader}>Key Statistics</div>
          <div className={styles.dateContainer}>
            <div className={styles.dateText}>Oct 01 - Oct 24</div>
            <div className={styles.dateSubtitle}>Monthly</div>
          </div>
        </div>
        <div className={statsStyles.statisticsInnerContainer}>
          <div className={statsStyles.stats}>
            <h3>Average Affect Score</h3>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                width: "100%",
              }}
            >
              {teamData && (
                <DonutChartSmall
                  width={"50%"}
                  value={Math.round(teamData.avg_success_score)}
                  x={40}
                  cutout={"90%"}
                  fontSize={"22px"}
                  y={-2.5}
                  barColor={barBg(Math.round(teamData.avg_success_score))}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(238, 104, 128, 1)",
                fontSize: "12px",
                wordBreak: "break-all",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              8 Reps under 40% average success score
            </div>
          </div>
          <div className={statsStyles.stats}>
            <h3>Engagements</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {teamData && (
                <div className={statsStyles.statsValueNumber}>
                  {teamData.number_of_engagements}
                </div>
              )}
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(255, 255, 255, 0.50)",
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              More than 700 calls with over 50% success score
            </div>
          </div>
          <div className={statsStyles.stats}>
            <h3>Top Performing REP</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {tableData.length > 0 && (
                <div className={statsStyles.statsValue}>
                  {tableData[0].first_name} {tableData[0].last_name}
                </div>
              )}
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(255, 255, 255, 0.80)",
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              {tableData.length > 0 &&
                `${tableData[0].engagements} Interactions`}
              <br />
              <span style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}>
                {" "}
                {tableData.length > 0 &&
                  `${Math.round(
                    tableData[0].avg_success_score
                  )}% Success Score`}
              </span>
            </div>
          </div>
          <div className={statsStyles.stats}>
            <h3>Least Performing REP</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {tableData.length && (
                <div className={statsStyles.statsValue}>
                  {tableData[tableData.length - 1].first_name}{" "}
                  {tableData[tableData.length - 1].last_name}
                </div>
              )}
              <ArrowDownwardRounded
                fontSize="small"
                style={{
                  color: "#E51A3F",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#E51A3F3D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "#EE6880",
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              {tableData.length > 0 &&
                `${tableData[tableData.length - 1].engagements} Interactions`}
              <br />
              <span style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}>
                {" "}
                {tableData.length > 0 &&
                  tableData[tableData.length - 1].avg_success_score}
                % Success Score
              </span>
            </div>
          </div>
          <div className={statsStyles.stats}>
            <h3>Best Product</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {productData.length > 0 && (
                <div className={statsStyles.statsValue}>
                  {productData[0].med_name}
                </div>
              )}
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            {productData.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  color: "rgba(255, 255, 255, 0.50)",
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                {" "}
                <span
                  style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}
                >
                  {" "}
                  {`Avg Success ${Math.round(
                    productData[0].avg_success_score
                  )}% Score`}{" "}
                </span>
              </div>
              // title={`${Math.round(productData[0].avg_success_score)}%`}
              // subTitle={"Avg Success Score"}
            )}
          </div>
          <div className={statsStyles.stats}>
            <h3>Worst Product</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {productData.length > 0 && (
                <div className={statsStyles.statsValue}>
                  {productData[1].med_name.toLowerCase()}
                </div>
              )}
              <ArrowDownwardRounded
                fontSize="small"
                style={{
                  color: "#E51A3F",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#E51A3F3D",
                }}
              />
            </div>
            {productData.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  color: "rgba(255, 255, 255, 0.50)",
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                {" "}
                <span
                  style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}
                >
                  {" "}
                  {`Avg Success ${Math.round(
                    productData[1].avg_success_score
                  )}% Score`}{" "}
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ marginTop: "3vh" }}
          className={styles.sectionHeaderContainer}
        >
          <div className={styles.sectionHeader}>To Review</div>
        </div>
        <div className={statsStyles.statisticsInnerContainer}>
          <div className={styles.review}>
            <MeetingResult width={"fit-content"} type={2} title={"High"} />
            <h3>REPS PERFORMANCE</h3>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "#fff",
                fontFamily: "Satoshi Variable",
                fontSize: "13px",
                wordBreak: "break-all",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Nikhil Verma, Cathrina +2
            </div>
            <h3>Drop in performance in last 30 days</h3>
          </div>
          <div className={styles.review}>
            <MeetingResult width={"fit-content"} type={1} title={"Medium"} />
            <h3>ENGAGEMENTS</h3>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "#fff",
                fontFamily: "Satoshi Variable",
                fontSize: "13px",
                wordBreak: "break-all",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              12 this week
            </div>
            <h3>70% drop in engagements</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerStats;
