import React, { useEffect, useState } from "react";
import AccountInfo from "./AccountInfo";
import { Box, Button, Divider, Grid } from "@mui/material";
import ChangePassword from "./ChangePassword";
import { useSelector } from "react-redux";
import { StylesProvider } from "@material-ui/core";
import styles from "./AccountInfo.module.css";
import { DeleteTeam } from "Api/Backend/backend";
import { useNavigate } from "react-router";

import { toast } from "react-toastify";

const TeamSettings = ({teamData}) => {
  const navigate= useNavigate();
  const user = useSelector((state) => state.setUser.user);
  const userImage = user.user_image;
  const [isEditMode, setEditMode] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!data && user) {
      setData({
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        role: user.role,
        organization_type: user.organization_type,
        organization_name: user.organization_name,
        region: user.region,
        id: user.user_id,
        team_name: user.team_name,
      });
    }
  }, [user]);

  const deleteTeam = () => {
    if (teamData) {
      const response = DeleteTeam(teamData.team_id)
        .then((res) => {
          toast.success("Team Deleted");
         navigate(-1)
        })
        .catch((e) => {
          toast.error("Error ");
          console.log(e)
        });

    }


  }
  return (
    <>
      <Grid container spacing={3}>
        {data && (
          <>
            <Grid item xs={12}>
              <AccountInfo
                teamData={teamData}
                userData={data}
                isEditMode={isEditMode}
                setEditMode={setEditMode}
                setData={setData}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider />
          <Box style={{float:"right",marginRight:"5%",cursor:"pointer"}}>
          <Button onClick={deleteTeam} className={styles.deleteButton}>Delete</Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TeamSettings;
