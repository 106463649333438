
import React from 'react'
import Teams from './TeamSetting/Teams'
import { useLocation } from 'react-router';

export default function ManageTeam() {
const location = useLocation();
const teamData = location.state?.teamData;
  return (
    <div>
      <Teams teamData={teamData} />  
    </div>
  )
}
