
import React, { useState } from "react";
import styles from "./selectmonth.module.css";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const YearSelect = ({ selectedYear,setSelectedYear }) => {
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
  
    const years = [2023, 2024];
  
    return (
      <FormControl>
        <Select
          className={styles.selectContainer}
          sx={{
            "& .css-1faq7rz-MuiFormLabel-root-MuiInputLabel-root": {
              display: "none !important",
            },
  
            "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
              color: "rgba(255, 255, 255, 0.8) !important",
            },
            "& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
              color: "rgba(255, 255, 255, 0.8)",
            },
            color: "rgba(255, 255, 255, 0.8)", // Set text color to white
          }}
          labelId="year-select-label"
          id="year-select"
          value={selectedYear} // Select the current year
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  
  export default YearSelect;