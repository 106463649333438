import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuList from "Components/Common/MenuList/MenuList";
import BadgeAvatar from "Components/Common/BadgeAvatar/BadgeAvatar";
import TeamSettings from "./TeamSettings";
import { useSelector } from "react-redux";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useDispatch } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import styles from "./teams.module.css";
import { setUser } from "Store/features/setUserSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl, updateUserPicture } from "Api/Backend/backend";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import UserCard from "Pages/ManagersFlow/Components/UserCard/UserCard";
import Users from "Pages/AdminFlow/Pages/Users/Users";

const Teams = ({ teamData }) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);

  const user = useSelector((state) => state.setUser.user);
  const userName = `Jason H. Miller`;
  const userImage = user.user_image;

  const userId = user.user_id;
  const options = [
    {
      icon: (
        <AccountCircleOutlinedIcon fontSize="small" sx={{ color: "white" }} />
      ),
      text: "Team Settings",
    },
    {
      icon: (
        <NotificationsNoneOutlinedIcon
          fontSize="small"
          sx={{ color: "white" }}
        />
      ),
      text: "Manage Users",
    },
  ];

  const [selectedListItem, setSelectedListItem] = React.useState(
    options[0].text
  );
  const handleClick = (text) => {
    if (text ==="Team Settings") {
      setSelectedListItem(options[0].text);
    } else {
      setSelectedListItem(options[1].text);
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userId);

    const response = updateUserPicture(formData)
      .then(async () => {
        toast.success("Picture Updated");
        const uid = sessionStorage.getItem("firebase");
        const getUser = await axios
          .post(
            `${baseUrl}get_user_by_firebaseid/?firebase_id=${uid}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            if (response.data) {
              dispatch(setUser(response.data));
              sessionStorage.setItem("user", JSON.stringify(response.data));
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };
  useEffect(() => {
    dispatch(setCurrentRoute(6));
  }, []);

  return (
    <>
      {console.log(teamData)}
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <CssBaseline />

      <div className={styles.container1}>
        <Box className={selectedListItem==="Team Settings" ? styles.menuSectionTeam :styles.menuSectionUser}>
          <Box >
            <UserCard title={`Admin`} subTitle={"Super Admin Account"} />
          </Box>
          {options.map((data) => (
            <div
              onClick={(e) => handleClick(data.text)}
              className={
                selectedListItem === data.text
                  ? styles.MenuListContainerActive
                  : styles.MenuListContainer
              }
            >
              {data.icon}
              <div className={styles.MenuItem}>{data.text}</div>
            </div>
          ))}
        </Box>
        <Box
          className={selectedListItem=== "Team Settings"?
                styles.settingSection:styles.settingSectionUser
             
          }
        >
          {selectedListItem == "Team Settings" ? (
            
              <TeamSettings teamData={teamData} />
           
          ) : (
            
            <Users fromTeam={true} leftPanel={true} team_id={teamData.team_id} />
           
            
          )}
        </Box>
      </div>
    </>
  );
};

export default Teams;
