import React from "react";
import styles from "./InfoBlock.module.css";
import { Box, Container, Typography } from "@mui/material";

const InfoBlock = ({
  heading,
  description,
  headingFontSize,
  headingFontWeight,
  icon,
}) => {
  return (
    <>
      <Container className={styles.container}>
        {icon ? (
          <>
            <Box className={styles.iconContainer}>
              <img alt="icon" src={icon} height="24px" width="24px" />
            </Box>
          </>
        ) : (
          <></>
        )}
        <Box className={styles.textContainer}>
          <Typography className={styles.heading}>{heading}</Typography>
          <Typography className={styles.description}>{description}</Typography>
        </Box>
      </Container>
    </>
  );
};

export default InfoBlock;
