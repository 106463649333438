import { createColumnHelper } from "@tanstack/react-table";
import GenericListItem from "Components/Common/GenericListItem/GenericListItem";
import { Box } from "@mui/system";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";
import { Container, Typography } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import medicineIcon from "../../../../Assets/medicineIcon.svg";
import styles from "./Recordings.module.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";
import MeetingResult from "Components/NewDashboard/MeetingResult/MeetingResult";
import MeetingsDataBox from "Components/NewDashboard/MeetingsDataBox/MeetingsDataBox";

const dummyOptions = [
  { id: "0", value: "All Time" },
  { id: "1", value: "Today" },
  { id: "2", value: "Yesterday" },
  { id: "3", value: "This Week" },
];

const mySplit = (text, index) => {
  const arr = text.split("!");
  return arr[index];
};

const columnHelper = createColumnHelper();

const columnsDef = [
  columnHelper.accessor("first_name", {
    header: () => "#",
    cell: (info) => (
      <Typography className={styles.tableRowIndex}>
        {info.row.index + 1}
      </Typography>
    ),
  }),
  columnHelper.accessor("first_name", {
    header: () => "Name",
    cell: (info) => (
      <Typography fontSize={16} fontWeight={500} className={styles.firstTd}>
        {info.renderValue()}
      </Typography>
    ),
  }),
  columnHelper.accessor("first_name", {
    header: () => "Team",
    cell: (info) => (
      <Container
        sx={{
          width: "100%",
          padding: "0px !important",
          color: "#636269 !important",
          gap: "8px !important",
        }}
      >
        <GenericListItem
          avatar={info.row.original.doctor_image}
          secondaryText={"Oncologist"}
          text={info.renderValue()}
          isClient={true}
          variant="table"
          name={info.renderValue()}
        />
      </Container>
    ),
  }),

  columnHelper.accessor("avg_success_score", {
    header: () => "Score",
    cell: (info) => (
      <Box
        sx={{
          height: "25px",
          width: "100px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <HalfDonutChartSmall value={info.renderValue()} />
        <Typography sx={{ color: "rgba(255, 255, 255, 0.8) !important" }}>
          {Math.round(info.renderValue()) + `%`}
        </Typography>
      </Box>
    ),
  }),

  // columnHelper.accessor("doctor_name", {
  //   header: () => "",
  //   cell: (info) => <MeetingResult />,
  // }),
  // columnHelper.accessor("doctor_name", {
  //   header: () => "",
  //   cell: (info) => (
  //     <MeetingsDataBox isValue={false} text={"Curious"} value={"23%"} />
  //   ),
  // }),
  // columnHelper.accessor("doctor_name", {
  //   header: () => "",
  //   cell: (info) => (
  //     <MeetingsDataBox isValue={true} text={"Valuable"} value={"53%"} />
  //   ),
  // }),
];

export default columnsDef;
