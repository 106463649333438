import Users from 'Pages/AdminFlow/Pages/Users/Users';
import React from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentRoute } from "Store/features/currentRoute";



export const ManagerUsers = ({fromManager,team_id,fromTeam}) => {
  const dispatch = useDispatch();
  dispatch(setCurrentRoute(3));
  return (
    <><Users fromManager={fromManager} team_id={team_id} fromTeam={fromTeam} /></>
  )
}
