import React, { useState } from "react";
import pdfIcon from "./Assets/pdfIcon.svg";
import videoIcon from "./Assets/videoIcon.svg";
import imageIcon from "./Assets/imageIcon.svg";
import fileIcon from "./Assets/fileIcon.svg";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import styles from "./card.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postFavouriteDocument } from "Api/Backend/backend";
import CustomFileViewer from "Components/Common/DocumentCard/CustomFileViewer";
import { Checkbox } from "@mui/material";
import ProductSplitView from "../ProductSplit/ProductSplitView";
const NewDocumentCard = ({
  setSelectedIds,
  onInteraction,
  setSourceImage,
  setTitle,
  isViewerOpen,
  setIsViewerOpen,
  isFavourite,
  currentIndex,
  getAllDocs,
  documentLength,
  selectedIndex,
  selectedIds,
  setDeleteButtonActive,
  docid,
  tabbed,
  type,
  variant,
  title,
  desc,
  src_image,
  ext,
  ishome,
}) => {
  // const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [checked, setChecked] = React.useState(false);
  const user = useSelector((state) => state.setUser.user);
  const userId = user.user_id;
  const [favouriteChecked, setFavouriteChecked] = React.useState("");

  React.useEffect(() => {
    setFavouriteChecked(isFavourite);
  }, [isFavourite]);
  React.useEffect(() => {
    if (selectedIds.length === 0) {
      setChecked(false);
    }
  }, [selectedIds]);
  const handleCheckboxChange = (event) => {
    const { id } = event.target;
    const isChecked = event.target.checked;
    setChecked(event.target.checked);
    setDeleteButtonActive(event.target.checked);

    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        // Add the docid to the selectedIds array
        if (!prevSelectedIds.includes(docid)) {
          return [...prevSelectedIds, docid];
        }
      } else {
        // Remove the docid from the selectedIds array
        return prevSelectedIds.filter((selectedId) => selectedId !== docid);
      }
    });

    //setSelectedIds([...selectedIds, docid])
  };
  const indexZero = () => {
    setFavouriteChecked(true);
  };
  const handleFavouriteChange = (event) => {
    const { id } = event.target;
    const isChecked = event.target.checked;
    setFavouriteChecked(event.target.checked);
    postFavouriteDocument(docid)
      .then((res) => {
        if (selectedIndex === 0) {
          getAllDocs(userId).then(() => {
            indexZero();
          });
        }
        if (isChecked) {
          toast.success("Added to favourites");
        } else {
          toast.success("Removed from favourites");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCardMediaClick = () => {
    setTitle(title);
    setSourceImage(src_image);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };
  const renderFileViewer = () => {
    const fileExtension = getFileExtension(src_image);

    return (
      <CustomFileViewer
        fileType={fileExtension}
        filePath={src_image}
        onClose={handleCloseViewer}
      />
    );
  };
  return (
    <>
      <div
        className={styles.container}
        style={{
          border: checked && " 2px solid var(--light-primary-bg, #9747ff)",
        }}
      >
        <img
          src={
            ext == "pdf"
              ? pdfIcon
              : ext === "png" || ext === "jpeg" || ext === "jpg"
              ? imageIcon
              : ext === "mp4"
              ? videoIcon
              : fileIcon
          }
          alt="icon"
        />
        <div onClick={handleCardMediaClick} className={styles.innerContainer}>
          <div className={styles.title}>
            {title.length > 10 ? title.slice(0, 10) + "..." : title}
          </div>
          <div className={styles.type}>{ext}</div>
        </div>
        {!ishome && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "inherit",
              justifyContent: "flex-end",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              style={{
                color: "#ffffff",
                opacity: checked ? 1 : 0.5,
              }}
              sx={{
                padding: "4px !important",
                color: "#ffffff",
                borderRadius: "12px !important",
                background:
                  "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                "&.Mui-checked": {
                  color: "#ffffff",
                  background: "#9747FF",
                },
                "& .css-i4bv87-MuiSvgIcon-root": {
                  width: "12px !important",
                  height: "12px !important",
                },
                "& .css-vubbuv": {
                  width: "12px !important",
                  height: "12px !important",
                },
              }}
            />
            <Checkbox
              icon={<FavoriteBorder sx={{ fontSize: "12px" }} />}
              checkedIcon={<Favorite sx={{ fontSize: "12px" }} />}
              onChange={handleFavouriteChange}
              checked={favouriteChecked}
              sx={{
                padding: "4px",
                color: "#ffffff",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background:
                  "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                "&.Mui-checked": {
                  color: "#ffffff",
                  background: "#EE6880",
                },
              }}
              style={{
                opacity: favouriteChecked ? 1 : 0.5,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
NewDocumentCard.defaultProps = {
  ishome: false,
};
export default NewDocumentCard;
