import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SplitView from "../SplitViewSlider/SplitView";
import styles from "./meetingcard.module.css";
import axios from "axios";
import { useNavigate } from "react-router";

const MeetingCard = ({
  date,
  cardIndex,
  isPastMeeting,
  meetingCount,
  day,
  onClick,
  meetings,
  isFocus,
  setIsFocus,
}) => {
  const navigate = useNavigate();

  const [meetingTime, setMeetingTime] = useState("");
  // const [title, setTitle] = useState("");
  const [ETA, setETA] = useState("");

  function getTimeOnly(dateString) {
    const dateWithoutOffset = dateString.slice(0, -6);
    const date = new Date(dateWithoutOffset);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    if (hours > 9) {
      return `${hours}:${minutes} ${period}`;
    } else {
      return `0${hours}:${minutes} ${period}`;
    }
  }
  const getDate = (date) => {
    const todayDate = date.slice(0, 10);
    return todayDate;
  };
  const getDateInUk = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formatted = dateObject.toLocaleDateString("en-UK", options);
    return formatted;
  };
  const handleClick = (index) => {
    setIsFocus(meetings[index].mid);
    const meetingData = {
      title: meetings[index].title,
      doctor_name: meetings[index].doctor_email,
      med_name: meetings[index].med_name,
      email: meetings[index].email,
      meeting_id: meetings[index].meeting_id,
      doctor_id: meetings[index].doctor_id,
      meeting_link: meetings[index].meeting_link,
      doc_list: meetings[index].doc_list,
      note: meetings[index].note,
      start_time: getTimeOnly(meetings[index].start_time),
      end_time: getTimeOnly(meetings[index].end_time),
      mid: meetings[index].mid,
      zak: meetings[index].zak,
      meetingDate: getDate(meetings[index].start_time),
      meetingDuration: meetings[index].start_time,
      doctor_profile_picture: meetings[index].doctor_profile_picture,
      isPastMeeting: isPastMeeting,
    };
    onClick(meetingData, isPastMeeting);
  };
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const today = new Date();

    // Define month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format the date
    const day = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    // Update the state with the formatted date
    setFormattedDate(formattedDate);
  }, []);
  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Calculate the difference in milliseconds
    const durationInMilliseconds = end - start;

    // Convert the duration to hours and minutes
    const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours} hours ${minutes} minutes`;
  };
  const checkDate = (dateString) => {
    // Convert input date string to a Date object
    const inputDateObj = new Date(dateString);
    inputDateObj.setHours(0, 0, 0, 0); // Set time to midnight for accurate date comparison

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Check if it's today
    if (inputDateObj.getTime() === today.getTime()) {
      return "Today";
    }

    // Check if it's tomorrow
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (inputDateObj.getTime() === tomorrow.getTime()) {
      return "Tomorrow";
    }

    // Check if it's within this week
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(today.getDate() - today.getDay()); // Start of the week (Sunday)
    const thisWeekEnd = new Date(thisWeekStart);
    thisWeekEnd.setDate(thisWeekStart.getDate() + 6); // End of the week (Saturday)

    if (
      inputDateObj.getTime() >= thisWeekStart.getTime() &&
      inputDateObj.getTime() <= thisWeekEnd.getTime()
    ) {
      return "In this week";
    }

    // If it's beyond this week, you can handle it as needed
    return isPastMeeting ? "Past" : "Next week";
  };
  const calculateTimeRemaining = (meetingStartTime) => {
    const now = new Date();
    const meetingStart = new Date(meetingStartTime);
    const timeDiff = meetingStart - now;

    if (timeDiff > 0) {
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      return `Starting in ${hours}h ${minutes}m`;
    } else {
      return "Meeting has started";
    }
  };
  return (
    <>
      {meetings.length > 0 && (
        <div className={styles.container}>
          <div className={styles.timeContainer}>
            <div>
              <div className={styles.dayTitleText}>
                {checkDate(date)},
                <div style={{ opacity: ".5" }}>{getDateInUk(date)}</div>
              </div>
            </div>
            <div className={styles.meetingCountText}>
              {meetings.length} Meetings
            </div>
          </div>
          {meetings.length > 0 &&
            meetings.map((data, index) => (
              <div key={index} className={styles.meetingContainer}>
                <div className={styles.meetingTimeContainer}>
                  <div className={styles.meetingTimeText}>
                    {getTimeOnly(data.start_time)}{" "}
                    <span style={{ opacity: ".5" }}>to</span>{" "}
                    {getTimeOnly(data.end_time)}
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <AccessTimeIcon
                      fontSize="small"
                      sx={{ color: "rgba(255, 255, 255, 0.4)" }}
                    />
                    <div className={styles.meetingDurationText}>
                      {calculateDuration(data.start_time, data.end_time)}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => handleClick(index)}
                  style={{
                    border:
                      isFocus === meetings[index].mid &&
                      "3px solid rgba(151, 71, 255, 0.80)",
                  }}
                  className={styles.meetingInnerContainer}
                >
                  <div className={styles.meetingTimeInfo}>
                    {calculateTimeRemaining(data.start_time)}
                  </div>
                  <div className={styles.meetingTitle}>{data.title}</div>
                  <div className={styles.meetingDescription}>
                    Follow-up meeting for the drug aspirin to talk...
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};
MeetingCard.defaultProps = {
  day: "Today",
  date: "24 October 2023",
  meetingCount: "2",
  isFocus: false,
  isPastMeeting: false,
  onClick: () => {},
  meetings: [],
  setIsFocus: () => {},
};

export default MeetingCard;
