import React from "react";
import styles from "./IntegrationCard.module.css";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";

const IntegrationCard = ({
  height,
  logo,
  title,
  subtitle,
  func,
  connected,
}) => {
  return (
    <>
      <Box className={styles.subContainer}>
        <Box className={styles.card} sx={{ height: { height } }}>
          <Box className={styles.connect}>
            <img alt="icon" src={logo} />
            {connected ? (
              <>
                <Chip
                  className={styles.chip}
                  icon={
                    <CheckIcon
                      sx={{
                        width: "14px",
                        height: "14px",
                        color: "#328e51 !important",
                      }}
                    />
                  }
                  label="Connected"
                />
              </>
            ) : (
              <>
                <Button
                  sx={{
                    width: "79px",
                    height: "32px",
                    borderRadius: "4px",
                    fontFamily: "Satoshi-Variable",
                    color: "#201F28",
                    backgroundColor: "#F6F6F6",
                  }}
                >
                  Connect
                </Button>
              </>
            )}
          </Box>
          <Box className={styles.name}>
            <Typography
              fontSize={16}
              fontWeight={700}
              sx={{
                color: "#201F28 !important",
                fontFamily: "Red Hat Display !important",
              }}
            >
              {title}
            </Typography>

            {subtitle ? (
              <>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  fontFamily={"Satoshi-Variable"}
                  sx={{ color: "#77767C" }}
                >
                  {subtitle}
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Box>
          {func ? (
            <>
              <Box className={styles.chipDefaultContainer}>
                <Chip
                  className={styles.chipDefault}
                  label={func}
                  sx={{ fontSize: "13px !important" }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default IntegrationCard;
