import React, { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Stack, Typography } from "@mui/material";
import styles from "./ClientDataTable.module.css";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import DropDownButton from "Components/Common/DropDownButton/DropDownButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";

const ClientDataTable = ({ defination, cellData, title }) => {
  const dummyOptions = [
    { id: "0", value: "All Time" },
    { id: "1", value: "Today" },
    { id: "2", value: "Yesterday" },
    { id: "3", value: "This Week" },
  ];

  const columns = defination;
  const data = cellData;
  const rerender = React.useReducer(() => ({}), {})[1];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  return (
    <>
      <Container className={styles.tableContainer}>
        <Box className={styles.headerContainer}>
          <Typography fontSize={20} fontWeight={700}>
            {title}
          </Typography>
          <Stack direction="row" gap={3}>
            <DropDownButton
              Icon={KeyboardArrowDownIcon}
              background={"#ffffff"}
              variant={"outlined"}
              text={"Filter"}
              size="medium"
              options={dummyOptions}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4A42AD",
                height: "40px",
                width: "190px",
                padding: "9px 16px 9px 16px",
              }}
              startIcon={
                <AddIcon sx={{ height: "13.33px", width: "13.33px" }} />
              }
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{
                  textTransform: "none",
                  fontFamily: "Satoshi-Variable",
                }}
              >
                Schedule Meeting
              </Typography>
            </Button>
          </Stack>
        </Box>

        {data ? (
          <>
            <Box className={styles.tableSection}>
              <table>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default ClientDataTable;
