import React, { useRef, useState } from "react";

const VideoGraphComponent = ({ graphData, videoRef, mid, videoOnCloud }) => {
  const [graphWidth, setGraphWidth] = useState(0);

  return (
    <>
      {videoOnCloud ? (
        <>
          <div style={{ width: "100%", height: "100%" }}>
            {mid && (
              <video ref={videoRef} width={"100%"} height="100%" controls>
                <source src={videoOnCloud} type="video/mp4" />
              </video>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        >
          Video Processing
        </div>
      )}
    </>
  );
};

export default VideoGraphComponent;
