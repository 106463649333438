import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import styles from "./sigin.module.css";
import { IconButton } from "@mui/material";

const SigninTextField = ({
  label,
  placeholder,
  FieldIcon,
  isPassword,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    setShowPassword(!showPassword);
  };
  const handleFocus = () => {
    setActive(true);
  };
  const handleBlur = () => {
    setActive(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {isPassword ? (
          <LockOutlinedIcon
            style={{
              color: active ? "white" : "#77767c",
            }}
          />
        ) : (
          <MailOutlineIcon
            style={{
              color: active ? "white" : "#77767c",
            }}
          />
        )}
        {isPassword ? (
          <input
            {...props}
            autoComplete={false}
            type={!showPassword ? "password" : "text"}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={styles.inputField}
            placeholder={label}
          />
        ) : (
          <input
            {...props}
            type="text"
            autoComplete={false}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={styles.inputField}
            placeholder={label}
          />
        )}

        {isPassword && (
          <IconButton onClick={handleClick} sx={{ color: "#77767c" }}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}{" "}
          </IconButton>
        )}
      </div>
    </div>
  );
};
SigninTextField.defaultProps = {
  label: "Email",
  isPassword: false,
};

export default SigninTextField;
