import React from "react";
import scoreIcon from "../../../Pages/BehaviouralTraining/Assets/scoreIcon.svg";
import starsIcon from "../Assets/starsIcon.svg";
import styles from "./salesbadge.module.css";
const SalesBadge = ({ isScore, title, score, badge }) => {
  return (
    <div
      style={{ background: !isScore ? " rgba(243, 194, 67, 0.24)" : "" }}
      className={styles.mainContainer}
    >
      {isScore ? (
        <div className={styles.flexRow}>
          <img style={{ margin: "0" }} src={badge} />
          <div className={styles.badgeTitle}>Score {score}</div>
        </div>
      ) : (
        <div className={styles.flexRow}>
          <img style={{ margin: "0" }} src={starsIcon} alt="icon" />
          <div className={styles.badgeTitle}>{title}</div>
        </div>
      )}
    </div>
  );
};
SalesBadge.defaultProps = {
  isScore: true,
  title: "Master",
  score: "230",
  badge: scoreIcon,
};

export default SalesBadge;
