import React, { useEffect, useState } from "react";
import { Container, Divider, Icon, Typography } from "@mui/material";
import callIcon from "./Assets/callIcon.svg";
import avatar from "./Assets/avatar.svg";
import styled from "@emotion/styled";
import ColoredCircle from "../ColoredCircle/ColorCircle";
import { getScoreList } from "Api/Backend/backend";
import styles from "./DetailCard.module.css";
import { useNavigate } from "react-router-dom";
const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 2, 1, 2, 3, 3, 4, 4, 5, 6, 7, 7];

const Card_Success = styled("div")({
  boxSizing: "border-box",
  // position: absolute,

  height: "224px",
  background: "#ECF7F0",
  border: "2px solid #E9E9E9",
  borderRadius: "12px",
  minWidth: "220px",
  overflowY: "scroll",
  paddingBottom: "10px",
});
const Card_Average = styled("div")({
  boxSizing: "border-box",
  // position: absolute,
  width: "200px",
  height: "224px",
  background: "#FFF5ED",
  border: "2px solid #E9E9E9",
  borderRadius: "12px",
  minWidth: "200px",
});
const Card_low = styled("div")({
  boxSizing: "border-box",
  // position: absolute,
  width: "200px",
  height: "224px",
  background: "#FAEDED",
  border: "2px solid #E9E9E9",
  borderRadius: "12px",
  minWidth: "200px",
});
const StyledContainer = styled("div")({
  display: "flex",
  width: "90%",
  marginTop: "10px",
  alignItems: "flex-start",
  justifyContent: "space-evenly",
});

const StyledScore = styled(Typography)({
  textAlign: "center",
  fontFamily: "'Red Hat Display'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#201F28",
  marginLeft: "5px",
});
const StyledTimeContainer = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "left",
});
const StyledTime = styled(Typography)({
  border: "1px solid",
  borderRadius: "21px",
  lineHeight: "160%",
  letterSpacing: "0.01em",
  fontSize: "10px",
  width: "70%",
  marginLeft: "05px",
  textAlign: "center",
  background: "#464092",
  fontSize: "12px",
  fontWeight: 700,
  color: "white",
});
const StyledTitle = styled(Typography)({
  textAlign: "left",
  fontFamily: "'Red Hat Display'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "19px",
  color: "#201F28",
});
const ImageStyled = styled("img")({
  width: "35px",
  height: "35px",

  // "left": "16px",
  // "top": "23px",
  // "background": `url(${imageSrc})), #D9D9D9`,
  border: "5px solid #FFFFFF",
  borderRadius: "20px",
  borderColor: "white",
  // "boxShadow": "0px 0px 4px rgba(0, 0, 0, 0.25)"
});

const DetailsCard = ({
  imageSrc,
  meeting_title,
  title,
  status,
  avgScore,
  meetingId,
  call_time,
  dots,
}) => {
  let navigate = useNavigate();

  const [graphData, setGraphData] = useState([]);
  const getColor = (score) => {
    if (score < 30) {
      return "red";
    } else if (score < 50) {
      return "orange";
    } else {
      return "green";
    }
  };

  const color = getColor(avgScore);

  return status === "success" ? (
    <Card_Success
      onClick={(e) =>
        navigate(`/Engagements/${meetingId}`, { state: meetingId })
      }
      className={styles.card_success}
      style={{
        background:
          avgScore < 30 ? "#FAEDED" : avgScore < 50 ? "#FFF5ED" : "#ECF7F0",
      }}
    >
      <StyledContainer>
        <div style={{ width: "35%", textAlign: "right" }}>
          <ImageStyled src={imageSrc} />
        </div>
        <div style={{ width: "65%", marginTop: "5px", marginLeft: "5px" }}>
          <StyledTitle>{title}</StyledTitle>
          <StyledTimeContainer>
            <ImageStyled
              style={{ height: "15px", width: "15px" }}
              src={callIcon}
            />
            <StyledTime>{call_time}</StyledTime>
          </StyledTimeContainer>
        </div>
      </StyledContainer>
      <div
        style={{
          marginTop: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ColoredCircle color={color} />
        <StyledScore>Avg. Affect Score : {avgScore}</StyledScore>
      </div>
      <div style={{ width: "100%" }}>
        <Divider
          sx={{
            margin: "0 auto",
            width: "90%",
            marginBottom: "10px",
            // height: '3px',
            bgcolor: "#BCBCBF",
          }}
        />
      </div>
      <div
        style={{ display: "flex", marginLeft: "15px", alignItems: "flex-end" }}
      >
        <Typography
          sx={{
            fontFamily: "Red Hat Display",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            marginLeft: "10px",
            lineHeight: "16px",
            color: "#201F28",
          }}
        >
          {meeting_title}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "90%",
          marginLeft: "15px",
          marginTop: "15px",
        }}
      >
        {dots.map((item) => (
          <div style={{ flexBasis: "8%", marginTop: "5px" }}>
            <ColoredCircle size={"12px"} color={getColor(item)} />
          </div>
        ))}
      </div>
    </Card_Success>
  ) : status === "average" ? (
    <Card_Average>
      <StyledContainer>
        <div style={{ width: "35%", textAlign: "right" }}>
          <ImageStyled src={imageSrc} />
        </div>
        <div style={{ width: "65%", marginTop: "5px" }}>
          <StyledTitle>{title}</StyledTitle>
          <StyledTimeContainer>
            <ImageStyled
              style={{ height: "15px", width: "15px" }}
              src={callIcon}
            />
            <StyledTime>01:54:04</StyledTime>
          </StyledTimeContainer>
        </div>
      </StyledContainer>
      <div
        style={{
          marginTop: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ColoredCircle />
        <StyledScore>Avg. Affect Score : {avgScore}</StyledScore>
      </div>
      <div style={{ width: "100%" }}>
        <Divider
          sx={{
            margin: "0 auto",
            width: "90%",
            marginBottom: "10px",
            // height: '3px',
            bgcolor: "#BCBCBF",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "90%",
          marginLeft: "15px",
          marginTop: "15px",
        }}
      >
        {graphData.map((item) => (
          <div style={{ flexBasis: "8%", marginTop: "5px" }}>
            <ColoredCircle size={"14px"} color={getColor(item.score)} />
          </div>
        ))}
      </div>
    </Card_Average>
  ) : (
    <Card_low>
      <StyledContainer>
        <div style={{ width: "35%", textAlign: "right" }}>
          <ImageStyled src={imageSrc} />
        </div>
        <div style={{ width: "65%", marginTop: "5px" }}>
          <StyledTitle>{title}</StyledTitle>
          <StyledTimeContainer>
            <ImageStyled
              style={{ height: "15px", width: "15px" }}
              src={callIcon}
            />
            <StyledTime>01:54:04</StyledTime>
          </StyledTimeContainer>
        </div>
      </StyledContainer>
      <div
        style={{
          marginTop: "15px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ColoredCircle />
        <StyledScore>Avg Affect Score : {avgScore}</StyledScore>
      </div>
      <div style={{ width: "100%" }}>
        <Divider
          sx={{
            margin: "0 auto",
            width: "90%",
            marginBottom: "10px",
            // height: '3px',
            bgcolor: "#BCBCBF",
          }}
        />
      </div>
      <div
        style={{ display: "flex", marginLeft: "15px", alignItems: "flex-end" }}
      >
        <Typography
          sx={{
            fontFamily: "Red Hat Display",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            marginLeft: "10px",
            lineHeight: "16px",
            color: "#201F28",
          }}
        >
          {meeting_title}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "90%",
          marginLeft: "15px",
          marginTop: "15px",
        }}
      >
        {data.map((item) => (
          <div style={{ flexBasis: "8%", marginTop: "5px" }}>
            <ColoredCircle size={"14px"} />
          </div>
        ))}
      </div>
    </Card_low>
  );
};

export default DetailsCard;
DetailsCard.defaultProps = {
  avgScore: "73%",
};
