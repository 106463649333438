import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMeetingsById } from "Api/Backend/backend";

const initialState = [];

export const meetingApiSlice = createSlice({
  name: "meetingApi",
  initialState,
  reducers: {
    getMeeting: (state, action) => {
      state = action.payload
    },
  },
});

export const { getMeeting } = meetingApiSlice.actions;

export default meetingApiSlice.reducer;
