import * as React from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import DocumentWordImage from "../../../Assets/icon_word.png";
import DocumentPdfImage from "../../../Assets/icon_pdf.png";
import TemplateCardIcon from "../../../Assets/templateCardIcon.svg";
import FavIcon from "../../../Assets/favIcon.svg";
import styles from "./DocumentCard.module.css";
import { Container } from "@mui/system";
import CustomFileViewer from "./CustomFileViewer";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { postFavouriteDocument } from "Api/Backend/backend";
import { toast } from "react-toastify";
import { pink, red } from "@mui/material/colors";
import { useSelector } from "react-redux";

const DocumentCard = ({
  setSelectedIds,
  onInteraction,
  isFavourite,
  currentIndex,
  getAllDocs,
  documentLength,
  selectedIndex,
  selectedIds,
  setDeleteButtonActive,
  docid,
  tabbed,
  type,
  variant,
  title,
  desc,
  src_image,
  ext,
  ishome,
}) => {
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const user = useSelector((state) => state.setUser.user);
  const userId = user.user_id;
  const [favouriteChecked, setFavouriteChecked] = React.useState("");

  React.useEffect(() => {
    setFavouriteChecked(isFavourite);
  }, [isFavourite]);
  React.useEffect(() => {
    if (selectedIds.length === 0) {
      setChecked(false);
    }
  }, [selectedIds]);
  const handleCheckboxChange = (event) => {
    const { id } = event.target;
    const isChecked = event.target.checked;
    setChecked(event.target.checked);
    setDeleteButtonActive(event.target.checked);

    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        // Add the docid to the selectedIds array
        if (!prevSelectedIds.includes(docid)) {
          return [...prevSelectedIds, docid];
        }
      } else {
        // Remove the docid from the selectedIds array
        return prevSelectedIds.filter((selectedId) => selectedId !== docid);
      }
    });

    //setSelectedIds([...selectedIds, docid])
  };
  const indexZero = () => {
    setFavouriteChecked(true);
  };
  const handleFavouriteChange = (event) => {
    const { id } = event.target;
    const isChecked = event.target.checked;
    setFavouriteChecked(event.target.checked);
    postFavouriteDocument(docid)
      .then((res) => {
        if (selectedIndex === 0) {
          getAllDocs(userId).then(() => {
            indexZero();
          });
        }
        if (isChecked) {
          toast.success("Added to favourites");
        } else {
          toast.success("Removed from favourites");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCardMediaClick = () => {
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };
  const renderFileViewer = () => {
    const fileExtension = getFileExtension(src_image);

    return (
      <CustomFileViewer
        fileType={fileExtension}
        filePath={src_image}
        onClose={handleCloseViewer}
      />
    );
  };
  return (
    <Container
      className={variant == "product" ? styles.favorites : styles.documentCont}
    >
      <Box className={styles.favIconContainer}>
        <img alt="icon" src={FavIcon} className={styles.favIcon} />
      </Box>
      <Card className={type == "page" ? styles.cardTypePage : styles.card}>
        <CardActionArea>
          {!ishome && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              {!onInteraction && (
                <>
                  <Checkbox
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    onChange={handleFavouriteChange}
                    checked={favouriteChecked}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: red[600],
                      },
                    }}
                    style={{
                      position: "absolute",
                      top: 0.1,
                      left: 0.1,
                      opacity: favouriteChecked ? 1 : 0.2,
                    }}
                  />
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    style={{
                      position: "absolute",
                      top: 0.1,
                      right: 0.1,
                      opacity: checked ? 1 : 0.2,
                    }}
                  />
                </>
              )}
            </div>
          )}

          <CardMedia
            component={variant == "document" ? "img" : "div"}
            height={type == "page" ? "60" : "100"}
            image={
              ext === "docx"
                ? DocumentWordImage
                : ext === "pdf"
                ? DocumentPdfImage
                : src_image
            }
            alt="Document Image"
            sx={{ objectFit: "scale-down", marginTop: "3px !important" }}
            onClick={handleCardMediaClick}
          />
          {variant == "product" ? (
            <>
              <Box className={styles.productCard}>
                <img alt="icon" src={TemplateCardIcon} />
              </Box>
            </>
          ) : (
            <></>
          )}

          <CardContent
            sx={{
              backgroundColor: "#ffffff",
              boxShadow: "-1px -1px 2px 0px rgba(230,230,230,0.42);",
              marginTop: "4px !important",
            }}
          >
            {tabbed ? (
              <>
                <Typography
                  gutterBottom
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Red Hat Display !important"}
                  component="div"
                  textAlign="center"
                  className={styles.cardTitle}
                >
                  {title}
                </Typography>
              </>
            ) : (
              <>
                <Box sx={{ height: "100px" }}>
                  <Typography
                    gutterBottom
                    fontSize={14}
                    fontWeight={600}
                    fontFamily={"Red Hat Display !important"}
                    component="div"
                    textAlign="start"
                    className={styles.cardTitle}
                  >
                    {title}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    className={styles.subtext}
                    textAlign="start"
                  >
                    {desc}
                  </Typography>
                </Box>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      {isViewerOpen && renderFileViewer()}
    </Container>
  );
};
DocumentCard.defaultProps = {
  onInteraction: false,
};
export default DocumentCard;
