import React from "react";
import companyIcon from "../../Assets/companyIcon.svg";
import styles from "./companycard.module.css";

const CompanyCard = ({ teamName, teamMembers }) => {
  return (
    <div className={styles.innerContainer}>
      <div style={{ display: "flex", gap: "10px" }}>
        <img src={companyIcon} />
        <div>
          <div className={styles.title}>{teamName}</div>
          <div className={styles.subtitle}>{teamMembers} Users</div>
        </div>
      </div>
      {/* <div className={styles.manageLink}>Manage</div> */}
    </div>
  );
};
CompanyCard.defaultProps = {
  teamMembers: 0,
  teamName: "Starlight",
};

export default CompanyCard;
