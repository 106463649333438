import React from "react";
import learningIcon from "../../Assets/learningIcon.svg";
import chatIcon from "../../Assets/chatIcon.svg";
import AiCall from "../../Assets/AiCall.svg";
import styles from "./quicklinkcard.module.css";
const QuickLinkCard = ({ type, title }) => {
  return (
    <div className={styles.mainContainer}>
      <img
        style={{ margin: "0" }}
        src={type === 0 ? learningIcon : type === 1 ? AiCall : chatIcon}
      />
      <div className={styles.title}>{title}</div>
    </div>
  );
};

QuickLinkCard.defaultProps = {
  type: 0,
  title: "Learning Modules",
};
export default QuickLinkCard;
