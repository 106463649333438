import React, { useEffect, useState } from "react";
import AccountInfo from "./AccountInfo";
import { Box, Divider, Grid } from "@mui/material";
import ChangePassword from "./ChangePassword";
import { useSelector } from "react-redux";

const TeamSettings = () => {
  const user = useSelector((state) => state.setUser.user);
  const userImage = user.user_image;
  const [isEditMode, setEditMode] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!data && user) {
      setData({
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        role: user.role,
        organization_type: user.organization_type,
        organization_name: user.organization_name,
        region: user.region,
        id: user.user_id,
        team_name: user.team_name,
      });
    }
  }, [user]);
  return (
    <>
      <Grid container spacing={3}>
        {data && (
          <>
            <Grid item xs={12}>
              <AccountInfo
                userData={data}
                isEditMode={isEditMode}
                setEditMode={setEditMode}
                setData={setData}
              />
            </Grid>
          </>
        )}
        {/* <Grid item xs={12}>
          <Divider />
          <Box sx={{ mt: 5 }}></Box>
          <ChangePassword />
        </Grid> */}
      </Grid>
    </>
  );
};

export default TeamSettings;
