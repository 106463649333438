import React, { useEffect, useState } from "react";
import InfoCard from "../InfoCard/InfoCard";
import medicineIcon from "../Assets/syringIcon.svg";
import Tabs from "../Tabs/Tabs";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import SendIcon from "@mui/icons-material/Send";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./splitview.module.css";
import CustomFileViewer from "Components/Common/DocumentCard/CustomFileViewer";

const ProductSplitView = ({
  isOpen,
  fileExtension,
  setIsOpen,
  title,
  src_image,
  doctorName,
  meetingTime,
  meetingDuration,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  return (
    <div className={isOpen ? styles.drawerOpen : styles.drawer}>
      <div
        style={{
          width: "100%",
          display: "flex",
          padding: "4vh 1vw 0px 0vw",
          justifyContent: "space-between",
        }}
      >
        <div className={styles.meetingTitle}>{title}</div>
        <CloseIcon
          onClick={() => setIsOpen(false)}
          sx={{
            color: "var(--dark-inverted-bg-100, #fff) ",
            cursor: "pointer",
          }}
        />
      </div>
      <CustomFileViewer fileType={fileExtension} filePath={src_image} />
    </div>
  );
};
ProductSplitView.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  title: "",
  ETA: "Starting in 20min",
  meetingTime: "",
  meetingDuration: "",
};
export default ProductSplitView;
