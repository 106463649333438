import React from "react";
import styles from "./trainingpage.module.css";
const TrainingPageStructure = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.innerMainContainer}>
          <div className={styles.dashboardContainer}>{children}</div>
        </div>
      </div>
    </div>
  );
};
TrainingPageStructure.defaultProps = {
  children: <></>,
};
export default TrainingPageStructure;
