import React from "react";
import dummyProfilePicture from "../Assets/dummyProfilePicture.svg";
import SalesBadge from "../SalesBadge/SalesBadge";
import styles from "./user.module.css";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
const UserInfo = ({ userTitle, userName }) => {
  const currentUser = useSelector((state) => state.setUser.user);

  return (
    <div className={styles.container}>
      <div className={styles.flexRow}>
        <div style={{ margin: "auto 0" }}>
          <Avatar
            alt="icon"
            sx={{ cursor: "pointer" }}
            src={currentUser.user_image}
          />
        </div>
        <div className={styles.flexColumnCenter}>
          <div className={styles.userName}>{userName}</div>
          <div className={styles.userTitle}>{userTitle}</div>
        </div>
      </div>
      {/* <div className={styles.badgeContainer}>
        <SalesBadge score={"3,490"} />
        <SalesBadge isScore={false} title={"Master"} />
      </div> */}
    </div>
  );
};
UserInfo.defaultProps = {
  userName: "",
  userTitle: "",
};
export default UserInfo;
