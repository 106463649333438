import React from 'react'
import { Container } from "@mui/material";
import AdminSideNav from "Components/Common/Sidenav/AdminSideNav";
import { Outlet } from "react-router";
import styles from "./admin.module.css";

const Admin = ({ containerPadding }) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0px !important",
        paddingLeft: `${containerPadding}vw !important`,
      }}
    >
      <AdminSideNav />
      <div className={styles.container}>
        <Outlet />
      </div>
    </Container>
  )
}

export default Admin