import React, { useEffect, useState } from "react";
import styles from "./Prospects.module.css";
import { Divider, Tabs, Tab, Avatar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DetailsCardInteraction from "../DetailsCardInteraction";
import DuoOutlinedIcon from "@mui/icons-material/DuoOutlined";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import TextEditor from "Components/Common/TextEditor/TextEditor";
import {
  add_notes,
  getDoctorMeetings,
  get_documents_by_mid,
  get_notes,
} from "Api/Backend/backend";
import CalledCard from "../CalledCard";
import { toast } from "react-toastify";
import ScheduleMeetingModal from "Components/SalesRep/ScheduleMeetingForm/ScheduleMeetingModal";
import DocumentTab from "Components/SalesRep/DocumentTab/DocumentTab";
import ProductSplitView from "Components/NewDashboard/ProductSplit/ProductSplitView";
import { useDispatch } from "react-redux";
import { setCurrentRoute } from "Store/features/currentRoute";

const TabStyled = styled(Tabs)({
  fontFamily: "Red Hat Display",
  fontStyle: "normal",
  fontHeight: "600",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#77767C",
});

const SingleInteraction = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  const user = useSelector((state) => state.setUser.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { imageSrc, name, doctorId } = location.state;
  const tabOptions = [
    { title: "My Contacts", value: "6", selected: "contact" },
  ];
  const [scheduled, setScheduled] = useState(false);
  const [value, setValue] = React.useState(0);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledMeetings, setScheduledMeetings] = useState([]);
  const [meetingScheduleOpen, setMeetingScheduleOpen] = useState(false);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [editorData, setEditorData] = useState("");
  const [documentData, setDocumentData] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [title, setTitle] = useState("");

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selected, setSelected] = React.useState(tabOptions[0].selected);
  const handleSave = (content) => {
    const mid = isScheduled
      ? scheduledMeetings[currentIndex].mid
      : completedMeetings[currentIndex].mid;
    add_notes(mid, content)
      .then((res) => {
        toast.success("Note Added");
        getNotes(currentIndex, isScheduled);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancel = () => {
    console.log("Editor cancelled");
  };

  const getDocMeetings = () => {
    getDoctorMeetings(doctorId)
      .then((res) => {
        if (res != "No meetings for this doctor") {
          setScheduledMeetings(res.scheduled_meetings);
          setCompletedMeetings(res.completed_meetings.reverse());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getNotes = (thisMid, bool) => {
    get_notes(
      bool ? scheduledMeetings[thisMid].mid : completedMeetings[thisMid].mid
    )
      .then((res) => {
        setEditorData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getDocuments = (thisMid, bool) => {
    get_documents_by_mid(
      bool ? scheduledMeetings[thisMid].mid : completedMeetings[thisMid].mid
    )
      .then((res) => {
        setDocumentData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (completedMeetings.length > 0) {
      getNotes(0);
      getDocuments(0);
    }
    if (completedMeetings.length === 0 && scheduledMeetings.length > 0) {
      setIsScheduled(true);
      getNotes(0, true);
      getDocuments(0, true);
    }
  }, [completedMeetings]);
  useEffect(() => {
    getDocMeetings();
    dispatch(setCurrentRoute(3));
  }, []);
  const handleClick = (data) => {
    setIsScheduled(false);
    setCurrentIndex(data.index);
    getNotes(data.index, false);
    getDocuments(data.index, false);
  };
  const handleScheduledClick = (data) => {
    setIsScheduled(true);

    setCurrentIndex(data);
    getNotes(data, true);
    getDocuments(data, true);
  };
  const onRowClick = () => {
    sessionStorage.setItem(
      "meetingTitle",
      completedMeetings[currentIndex].title
    );
    navigate(`/Engagements/${completedMeetings[currentIndex].mid}`, {
      state: completedMeetings[currentIndex],
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fluid maxWidth={true}>
        <Container maxWidth={false} className={styles.container}>
          <Container maxWidth={true} className={styles.secondaryContainer}>
            <Container maxWidth={true} className={styles.innerContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px 0 10px",
                }}
              >
                <Box className={styles.backButton}>
                  <Typography
                    className={styles.title}
                    fontSize={24}
                    fontWeight={600}
                  >
                    Dr. {name}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => setMeetingScheduleOpen(true)}
                    className={styles.scheduleMeetingButton}
                  >
                    Schedule Meeting
                  </Button>
                </Box>
              </div>
              <div style={{ width: "100%", marginBottom: "30px" }}>
                <Divider
                  sx={{
                    margin: "0 auto",
                    width: "100%",
                    border: "1px solid rgba(255, 255, 255, 0.80)",
                  }}
                />
              </div>
              <Container
                maxWidth={true}
                className={styles.secondInnerContainer}
              >
                <div
                  style={{
                    width: "30%",
                    maxHeight: "600px",
                    minHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {scheduledMeetings.length > 0 &&
                    scheduledMeetings.map((qualifiedInteraction, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                          marginLeft: "20px",
                        }}
                      >
                        <Divider orientation="vertical" flexItem />
                        <DetailsCardInteraction
                          width={"94%"}
                          index={index}
                          currentIndex={
                            isScheduled && scheduledMeetings[currentIndex].mid
                          }
                          mid={qualifiedInteraction.mid}
                          status={"scheduled"}
                          onInteraction={true}
                          onClick={handleScheduledClick}
                          doctorId={qualifiedInteraction.doctor_id}
                          date={qualifiedInteraction.date}
                          times={qualifiedInteraction.times}
                          name={qualifiedInteraction.doctor_name}
                          clinic={qualifiedInteraction.doctor_clinic}
                          title={qualifiedInteraction.doctor_speciality}
                          imageSrc={qualifiedInteraction.doctor_image}
                        />
                      </div>
                    ))}
                  {completedMeetings.length > 0 &&
                    completedMeetings.map((contact, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            sx={{
                              background:
                                parseInt(contact.success_score) <= 30
                                  ? "red"
                                  : parseInt(contact.success_score) > 30 &&
                                    parseInt(contact.success_score) <= 49
                                  ? "orange"
                                  : "green",
                              cursor: "pointer",
                            }}
                            alt="Edit"
                          >
                            {completedMeetings.length - index}
                          </Avatar>
                          <Divider
                            sx={{
                              height: "75% !important",
                              borderWidth: "1 !important",
                              marginTop: "5px",
                            }}
                            orientation="vertical"
                          />
                        </div>
                        <CalledCard
                          currentIndex={
                            !isScheduled && completedMeetings[currentIndex].mid
                          }
                          index={index}
                          onInteraction={true}
                          status={"interaction"}
                          mid={contact.mid}
                          onClick={handleClick}
                          successScore={contact.dots}
                          meetingLength={contact.call_duration}
                          title={contact.title}
                          avgScore={
                            contact.success_score === "0%"
                              ? contact.success_score.slice(0, 1)
                              : contact.success_score.slice(0, 2)
                          }
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          drSpeciality={contact.doctor_speciality}
                        />
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    width: "60%",
                    background: "#171618",
                    borderRadius: "6px",
                    marginBottom: "30px",
                    height: "100%",
                  }}
                >
                  <div style={{ width: "95%", margin: "20px" }}>
                    {completedMeetings.length > 0 ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className={styles.editorTitle}>
                          {isScheduled
                            ? scheduledMeetings[currentIndex].title
                            : completedMeetings[currentIndex].title}
                        </Typography>
                        {!isScheduled && (
                          <Button
                            startIcon={<DuoOutlinedIcon />}
                            onClick={onRowClick}
                            className={styles.recordingAnalysisButton}
                          >
                            Recording Analysis
                          </Button>
                        )}
                      </div>
                    ) : isScheduled ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className={styles.editorTitle}>
                          {isScheduled
                            ? scheduledMeetings[currentIndex].title
                            : completedMeetings[currentIndex].title}
                        </Typography>
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* <div
                      style={{
                        width: "100%",
                        marginBottom: "05px",
                        marginTop: "10px",
                      }}
                    >
                      <Divider
                        sx={{
                          margin: "0 auto",
                          width: "100%",
                          border: "1px solid #AFADB0",
                        }}
                      />
                    </div> */}

                    <Box sx={{ width: "100%" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="none"
                        textColor="inherit"
                        aria-label="wrapped label tabs example"
                      >
                        <Tab
                          label="Notes"
                          sx={{
                            color: "#AFADB0",
                            fontSize: "10px",
                            fontFamily: "Red Hat Display",
                            fontStyle: "normal",
                            "&:focus": "none",
                            textTransform: "none",
                            minWidth: "0px",
                            paddingTop: "0px",
                          }}
                        />

                        <Tab
                          sx={{
                            color: "#AFADB0",
                            fontSize: "10px",
                            fontFamily: "Red Hat Display",
                            fontStyle: "normal",
                            textTransform: "none",
                            minWidth: "0px",
                            paddingTop: "0px",
                          }}
                          label="Documents"
                        />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                        <TextEditor
                          onSave={handleSave}
                          onCancel={handleCancel}
                          initialContent={editorData}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        {documentData.length > 0 ? (
                          <>
                            <div style={{ flexWrap: "wrap" }}>
                              <DocumentTab
                                ishome={true}
                                type="page"
                                setTitle={setTitle}
                                setSourceImage={setSourceImage}
                                isViewerOpen={isViewerOpen}
                                setIsViewerOpen={setIsViewerOpen}
                                selectedIds={[]}
                                variant="document"
                                data={documentData}
                              />
                            </div>
                          </>
                        ) : (
                          <div style={{ color: "white" }}>No Documents</div>
                        )}
                      </TabPanel>
                    </Box>
                  </div>
                </div>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <ScheduleMeetingModal
        setScheduled={setScheduled}
        meetingModalOpen={meetingScheduleOpen}
        setMeetingModalOpen={setMeetingScheduleOpen}
        id={user.user_id}
      />
      {isViewerOpen && (
        <div style={{ width: "40%", marginLeft: "30px" }}>
          <ProductSplitView
            title={title}
            isOpen={isViewerOpen}
            setIsOpen={setIsViewerOpen}
            fileExtension={getFileExtension(sourceImage)}
            src_image={sourceImage}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleInteraction;
