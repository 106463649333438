import * as React from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
// import UploadIcon from "@mui/icons-material/Upload";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import styles from "./Tabs.module.css";
import emptyProductIcon from "../../Assets/emptyProductIcon.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UploadIcon from "@mui/icons-material/Upload";
import Typography from "@mui/material/Typography";
import ProductIcon from "../../Assets/productIcon.svg";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import addNotesIcon from "../../Assets/addNotes.svg";
import emptyDocuments from "../../Assets/emptyDocuments.svg";
import Box from "@mui/material/Box";
import ImageComponent from "../ImageComponent/ImageComponent";
import NotesCard from "../NotesCard/NotesCard";
import { Button, TextField } from "@mui/material";
import ProductCard from "../ProductCard/ProductCard";
import VerticalDividerWithCheckbox from "../VerticalDivider/VerticalDivider";
import DocumentCard from "../NewDocumentCard/NewDocumentCard";

const tabContainerStyles = {
  display: "flex",
  alignItems: "center",
};

const tabContentStyles = {
  display: "flex",
  alignItems: "center",
};

const iconStyles = {
  marginRight: "4px", // Adjust the spacing between icon and label
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({
  value,
  gridView,
  setValue,
  inMeetingNotes,
  notes,
  products,
  documents,
}) {
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "none", marginTop: "20px" }}>
      <AppBar
        sx={{
          background: gridView
            ? "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))"
            : "none",
          borderBottom:
            "1px solid var(--on-surface-disabled-d, rgba(255, 255, 255, 0.16))",
        }}
        position="static"
      >
        <Tabs
          sx={{
            "& .MuiTabs-indicator": {
              background: "white",
            },
          }}
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab
            disableRipple
            sx={{
              textTransform: "none",
            }}
            // icon={<ImageComponent src={ProductIcon} />}
            iconPosition="start"
            label={
              <div style={tabContentStyles}>
                <ImageComponent src={ProductIcon} style={iconStyles} />
                Products
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            disableRipple
            sx={{ textTransform: "none" }}
            label={
              <div style={tabContentStyles}>
                <InsertDriveFileOutlinedIcon sx={iconStyles} />
                Notes
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            disableRipple
            sx={{ textTransform: "none" }}
            label={
              <div style={tabContentStyles}>
                <DownloadIcon sx={iconStyles} />
                Documents
              </div>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        {products.length > 0 ? (
          products.map((data) => (
            <Box className={styles.productContainer}>
              <VerticalDividerWithCheckbox />
              <ProductCard type={data} />
            </Box>
          ))
        ) : (
          <Box className={styles.tabContent}>
            <Box sx={{ marginTop: "20px" }} className={styles.emptyContainer}>
              <img alt="icon" src={emptyProductIcon} />
              <Typography className={styles.emptyHeading}>
                No Products found
              </Typography>
              <Typography className={styles.emptyDescription}>
                No products are associated with this meeting. Go to your
                scheduled meeting to add new products
              </Typography>
              {/* <Button className={styles.emptyButton} startIcon={<AddIcon />}>
                Add Products
              </Button> */}
            </Box>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box className={styles.tabContent}>
          {notes.length != 0 ? (
            <div style={{ marginBottom: "50px", width: "100%" }}>
              <Typography className={styles.title}>
                Pre-Meeting Notes
              </Typography>
              {/* {notes.map((data) => ( */}
              <NotesCard data={notes} />
              {/* ))} */}
              <Typography
                sx={{ marginTop: "30px", marginBottom: "10px" }}
                className={styles.title}
              >
                In-Meeting Notes
              </Typography>
              {inMeetingNotes.map((data) => (
                <>
                  <NotesCard data={data} inMeeting={true} />
                </>
              ))}
            </div>
          ) : (
            <>
              {inMeetingNotes.length === 0 ? (
                <Box
                  className={
                    gridView ? styles.emptyGridContainer : styles.emptyContainer
                  }
                >
                  <Typography
                    sx={{ marginTop: "05px", marginBottom: "10px" }}
                    className={styles.title}
                  >
                    In-Meeting Notes
                  </Typography>
                  <>
                    <img alt="icon" src={addNotesIcon} />
                    <Typography className={styles.emptyHeading}>
                      This looks lonely!
                    </Typography>
                    <Typography className={styles.emptyDescription}>
                      Add your thoughts, ideas and comments here to review after
                      the meeting!
                    </Typography>
                  </>
                </Box>
              ) : (
                <div style={{ marginBottom: "50px", width: "100%" }}>
                  <Typography
                    sx={{ marginTop: "05px", marginBottom: "10px" }}
                    className={styles.title}
                  >
                    In-Meeting Notes
                  </Typography>
                  {inMeetingNotes.map((data) => (
                    <>
                      <NotesCard data={data} inMeeting={true} />
                    </>
                  ))}
                </div>
              )}
            </>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        {documents.length > 0 ? (
          <>
            <Box className={styles.documentHeading}>
              <Typography className={styles.title}>
                UPLOADED DOCUMENTS ({documents.length})
              </Typography>
              <UploadIcon
                sx={{
                  "&: hover": {
                    cursor: "pointer",
                  },
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              />
            </Box>
            {documents.map((data) => (
              <Box className={styles.documentContainer}>
                <DocumentCard
                  type={data.extension}
                  title={data.document_name}
                  link={data.document_link}
                />
              </Box>
            ))}
          </>
        ) : (
          <Box className={styles.tabContent}>
            <Box sx={{ marginTop: "10px" }} className={styles.emptyContainer}>
              <img alt="icon" src={emptyDocuments} />
              <Typography className={styles.emptyHeading}>
                No documents found
              </Typography>
              <Typography className={styles.emptyDescription}>
                AffectCX intelligence automatically creates and generate
                documents regarding the meeting. <br />
                <br />
                You can also upload your own documents to use in the meeting
              </Typography>
              {/* <Button className={styles.emptyButton} startIcon={<UploadIcon />}>
                Upload Documents
              </Button> */}
            </Box>
          </Box>
        )}
      </TabPanel>
    </Box>
  );
}
FullWidthTabs.defaultProps = {
  gridView: false,
  inMeetingNotes: [],
};
