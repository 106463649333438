import React from 'react'
import NewLibrary from 'Pages/SalesRep/Library/NewLibrary/NewLibrary'

const Library = () => {
  return (
    
       <NewLibrary />
   
  )
}

export default Library