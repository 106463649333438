import React from "react";
import "./App.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import useWindowDimensions from "Components/Common/GetWindowDimension/GetWindowDimension";
import { Provider } from "react-redux";
import store from "Store/store";
import GlobalToastContainer from "Components/Common/ToastContainer/ToastContainer";
import AppRouter from "Router/AppRouter";

const theme = createTheme({
  typography: {
    fontFamily: ["Red Hat Display", "Satoshi-Variable"].join(","),
  }
});

function App() {
  const { height, width } = useWindowDimensions();
  const containerPadding =
    width <= 824 ? 0 : width <= 1065 && width > 824 ? 8 : 6;
  const flexDirection = width <= 824 ? "column" : "row";
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
              justifyContent: "center",
              background:
                "linear-gradient(142deg, #9747FF -28.17%, rgba(100, 0, 229, 0.27) 18.25%, rgba(100, 0, 229, 0.00) 62.78%)",
              padding:
                width <= 824
                  ? `${containerPadding}vw !important`
                  : "0px !important",
              flexDirection: flexDirection,
              alignItems: "center",
            }}
          >
            <GlobalToastContainer />
            <AppRouter containerPadding={containerPadding} />
          </Container>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
