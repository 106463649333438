import React, { useEffect, useRef, useState } from "react";
import dummyImage from "./Assets/dummyVideoImage.png";
import clockIcon from "./Assets/clockIcon.svg";
import feedbackIcon from "./Assets/feedbackIcon.svg";
import curiousIcon from "./Assets/curiousIcon.svg";
import moodIcon from "./Assets/moodIcon.svg";
import valueIcon from "./Assets/valueIcon.svg";
import chatIcon from "./Assets/chatIcon.svg";
import CheckIcon from "@mui/icons-material/Check";
import capsuleIcon from "./Assets/capsuleIcon.svg";
import medicineIcon from "./Assets/medicineIcon.svg";
import ratioGraph from "./Assets/ratioGraph.svg";
import dividerIcon from "./Assets/dividerIcon.svg";
import timelineIcon from "./Assets/timelineIcon.svg";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import TimelineIcon from "@mui/icons-material/Timeline";
import Transcription from "../MeetingDetails/Transcription";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import DonutChart from "Components/NewDashboard/DonutChartNew/DonutChart";
import LinearGraph from "Pages/Meeting/Screens/LinearGraph";
import SuggestionBar from "Pages/Meeting/Components/SuggestionBar/SuggestionBar";
import { useLocation, useNavigate } from "react-router";
import styles from "./NewMeetingDetails.module.css";
import {
  getScoreList,
  getZoomToken,
  overallSuccessCallSummary,
  postZoomVideo,
  singleFeedBackData,
} from "Api/Backend/backend";
import { ArrowUpwardRounded } from "@mui/icons-material";
import SuggestionBarVertical from "Components/NewDashboard/SuggestionBarVertical/SuggestionBarVertical";
import RecordedVideo from "../MeetingDetails/RecordedVideo";
import VideoGraphComponent from "Components/Common/VideoGraph/VideoGraph";
import LinearGraphTimeLine from "Components/Common/LinearGraph/LinearGraph";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useDispatch } from "react-redux";
import ProductTemplate from "Components/NewDashboard/ProductTemplate/ProductTemplate";
import StackGraph from "Components/Common/LinearGraph/StackGraph";

const NewMeetingDetails = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const videoRef = useRef(null);
  const recordingId = location.state.mid;
  const doctorName = location.state.doctor_name;
  const isPastMeeting = location.state.isPastMeeting;
  const doctorImage =
    location.state.doctor_image || location.state.doctor_profile_picture;

  const [overallScore, setOverallScore] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [meetingData, setMeetingData] = useState(true);
  const [token, setToken] = useState("");
  const [feedBackData, setFeedBackData] = useState([]);
  const [videoOnCloud, setVideoOnCloud] = useState("");
  const meetingTitle = sessionStorage.getItem("meetingTitle");
  const getDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formatted = dateObject.toLocaleDateString("en-UK", options);
    return formatted;
  };
  const getTimeOnly = (date) => {
    const todayDate = date.slice(13);
    return todayDate;
  };
  // Overview functions
  const getMeetingProgress = (perc) => {
    const score = perc;
    if (score >= 0 && score < 30) {
      return "negative";
    } else if (score >= 30 && score < 50) {
      return "average";
    } else if (score >= 50) {
      return "positive";
    }
  };
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  const getFeedBackData = async (id) => {
    const data = await singleFeedBackData(id)
      .then((res) => {
        setFeedBackData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getGraphData = (id) => {
    const response = getScoreList(id)
      .then((res) => {
        setGraphData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getOverallScore = async (mid) => {
    const response = await overallSuccessCallSummary(Number(mid))
      .then((response) => {
        setOverallScore(response);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    dispatch(setCurrentRoute(2));
    getOverallScore(recordingId);
    setTimeout(() => {
      getGraphData(recordingId);
    }, 1000);
    getFeedBackData(recordingId);
    if (!token) {
      const data = getZoomToken()
        .then(async (res) => {
          setToken(res);
          const response = await postZoomVideo(recordingId, res)
            .then((resp) => {
              setVideoOnCloud(resp.link);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.meetingId}>MK{recordingId}</div>
          <div className={styles.meetingTitle}>{meetingTitle}</div>
          <div className={styles.meetingTime}>
            {isPastMeeting ? (
              location.state.meetingDate
            ) : (
              <>
                {getDate(location.state.time) === "Invalid Date"
                  ? location.state.date
                  : getDate(location.state.time)}
              </>
            )}

            <span style={{ color: "rgba(255, 255, 255, 0.5)" }}>
              ,{" "}
              {isPastMeeting ? (
                `${location.state.start_time} - ${location.state.end_time}`
              ) : (
                <>
                  {location.state.start_time
                    ? getTimeOnly(location.state.start_time)
                    : location.state.times}
                </>
              )}
            </span>
          </div>
        </div>
        <div className={styles.videoContainer}>
          <VideoGraphComponent
            videoRef={videoRef}
            mid={recordingId}
            videoOnCloud={videoOnCloud}
          />
        </div>
        <Transcription mid={recordingId} />
        <div className={styles.fixedContainer}>
          <InfoCard
            hasDonutChart={true}
            title={`${overallScore.success_score}%`}
            value={overallScore.success_score}
            subTitle={"Affect Score"}
          />
          <InfoCard
            hasDonutChart={true}
            value={overallScore.ttlr_dr ? overallScore.success_score : 0}
            title={
              overallScore.ttlr_dr
                ? `${(
                    overallScore.ttlr_dr /
                    (overallScore.ttlr_dr + overallScore.ttlr_user)
                  ).toFixed(2)}:${(
                    overallScore.ttlr_user /
                    (overallScore.ttlr_dr + overallScore.ttlr_user)
                  ).toFixed(2)}`
                : "N/A"
            }
            subTitle={"Talk/Listen"}
          />
          <InfoCard
            icon={clockIcon}
            title={`${overallScore.meeting_duration} Min`}
            subTitle={"Duration"}
          />
          <InfoCard
            icon={feedbackIcon}
            title={`${overallScore.feedback_count}`}
            subTitle={"Affect Feedback"}
          />
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.tabContainer}>
          <div
            onClick={() => {
              setValue(0);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 0 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 0
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Overview
            </div>
            {/* {value === 0 && (
                <div className={styles.countContainer}>{meetings.length}</div>
              )} */}
          </div>
          <div
            onClick={() => {
              setValue(1);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 1 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 1
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Analysis
            </div>
            {/* {value === 1 && <div className={styles.countContainer}>8</div>} */}
          </div>
          <div
            onClick={() => {
              setValue(2);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 2 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 2
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Meeting Files
            </div>
            {/* {value === 2 && <div className={styles.countContainer}>8</div>} */}
          </div>
        </div>
        {value === 0 && (
          <div className={styles.overviewContainer}>
            <div className={styles.sectionHeader}>
              <TimelineIcon sx={{ color: "rgba(255, 255, 255, 0.24)" }} />
              <div className={styles.sectionHeaderText}>Meeting Stats</div>
            </div>
            <div className={styles.chartContainer}>
              <div style={{ width: "40%", textAlign: "center" }}>
                <DonutChart
                  meetingProgress={getMeetingProgress(
                    overallScore.success_score
                  )}
                  value={`${overallScore.success_score}`}
                  x={-8.5}
                  y={-12.5}
                  barColor={barBg(overallScore.success_score)}
                />
                <img alt="icon" src={dividerIcon} />
                <div className={styles.meetingInfoHeading}>
                  Client was satisfied but not interested
                </div>
                <div className={styles.meetingInfoSuggestion}>
                  For next time lets try to be more affirmative and try to clear
                  more doubts for the client
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  gap: "1vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={curiousIcon} alt="icon" />
                  <div style={{ margin: "auto 5px", width: "40%" }}>
                    <div className={styles.graphTitle}>Curious</div>
                    <div className={styles.graphHeading}>ATTENTION</div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <LinearGraph graphScore={graphData} type={"att"} />
                    <div className={styles.graphXaxis}>
                      {overallScore.meeting_duration} Min
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={moodIcon} alt="icon" />
                  <div style={{ margin: "auto 5px", width: "40%" }}>
                    <div className={styles.graphTitle}>Distracted</div>
                    <div className={styles.graphHeading}>Mood</div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <LinearGraph graphScore={graphData} type={"mood"} />
                    <div className={styles.graphXaxis}>
                      {overallScore.meeting_duration} Min
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={valueIcon} alt="icon" />
                  <div style={{ margin: "auto 5px", width: "40%" }}>
                    <div className={styles.graphTitle}>Interested</div>
                    <div className={styles.graphHeading}>Value</div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <LinearGraph graphScore={graphData} type={"value"} />
                    <div className={styles.graphXaxis}>
                      {overallScore.meeting_duration} Min
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", padding: "3vh 0" }}>
              <InfoCard
                icon={doctorImage}
                subTitle={overallScore.doctor_speciality}
                title={doctorName}
              />
              <InfoCard
                icon={medicineIcon}
                title={"Paracetamol"}
                subTitle={"Medicine"}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className={styles.sectionHeader}>
                <img src={chatIcon} alt="icon" />
                <div className={styles.sectionHeaderText}>Feedback</div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                {/* <div className={styles.buttonContainer}>
                  <WestIcon sx={{ color: "white" }} />
                </div>
                <div className={styles.buttonContainer}>
                  <EastIcon sx={{ color: "white" }} />
                </div> */}
              </div>
            </div>
            <div className={styles.suggestionBarContainer}>
              {feedBackData.length > 0 ? (
                feedBackData.map((data) => <SuggestionBar title={data.text} />)
              ) : (
                <div
                  style={{
                    height: "10vh",
                    width: "100%",
                    color: "rgba(255,255,255,0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  No Feedbacks
                </div>
              )}
            </div>
            <div className={styles.sectionHeader}>
              <img src={capsuleIcon} alt="icon" />
              <div className={styles.sectionHeaderText}>Product template</div>
            </div>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionHeaderText}>No Product Data available</div>
            </div>
          </div>
        )}
        {value === 1 && (
          <>
            <div className={styles.sectionHeader}>
              <img src={timelineIcon} alt="icon" />
              <div className={styles.sectionHeaderText}>Timeline</div>
            </div>
            <div style={{ width: "95%" }}>
              <StackGraph videoRef={videoRef} graphData={graphData} />
            </div>
            <div className={styles.sectionHeader}>
              <TimelineIcon sx={{ color: "rgba(255, 255, 255, 0.24)" }} />{" "}
              <div className={styles.sectionHeaderText}>Meeting Stats</div>
            </div>
            <div className={styles.chartContainer}>
              <div style={{ width: "40%", textAlign: "center" }}>
                <DonutChart
                  meetingProgress={getMeetingProgress(
                    overallScore.success_score
                  )}
                  value={`${overallScore.success_score}`}
                  x={-8.5}
                  y={-12.5}
                  barColor={barBg(overallScore.success_score)}
                />
                <img alt="icon" src={dividerIcon} />
                <div className={styles.meetingInfoHeading}>
                  Client was satisfied but not interested
                </div>
                <div className={styles.meetingInfoSuggestion}>
                  For next time lets try to be more affirmative and try to clear
                  more doubts for the client
                </div>
              </div>
              <div
                style={{
                  width: "45%",
                  gap: "1vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={curiousIcon} alt="icon" />
                  <div style={{ margin: "auto 5px", width: "40%" }}>
                    <div className={styles.graphTitle}>Curious</div>
                    <div className={styles.graphHeading}>ATTENTION</div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <LinearGraph graphScore={graphData} type={"att"} />
                    <div className={styles.graphXaxis}>
                      {overallScore.meeting_duration} Min
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={moodIcon} alt="icon" />
                  <div style={{ margin: "auto 5px", width: "40%" }}>
                    <div className={styles.graphTitle}>Distracted</div>
                    <div className={styles.graphHeading}>Mood</div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <LinearGraph graphScore={graphData} type={"mood"} />
                    <div className={styles.graphXaxis}>
                      {overallScore.meeting_duration} Min
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <img src={valueIcon} alt="icon" />
                  <div style={{ margin: "auto 5px", width: "40%" }}>
                    <div className={styles.graphTitle}>Interested</div>
                    <div className={styles.graphHeading}>Value</div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <LinearGraph graphScore={graphData} type={"value"} />
                    <div className={styles.graphXaxis}>
                      {overallScore.meeting_duration} Min
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.overallScoreParentContainer}>
              <div className={styles.overallScoreContainer}>
                <img src={ratioGraph} alt="icon" />
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className={styles.overallScoreHeading}>
                    {overallScore.ttlr_dr
                      ? `${(
                          overallScore.ttlr_dr /
                          (overallScore.ttlr_dr + overallScore.ttlr_user)
                        ).toFixed(2)}:${(
                          overallScore.ttlr_user /
                          (overallScore.ttlr_dr + overallScore.ttlr_user)
                        ).toFixed(2)}`
                      : "N/A"}
                  </div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.overallScoreTitle}>Talk:Listen</div>
              </div>
              <div className={styles.overallScoreContainer}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className={styles.overallScoreHeading}>Normal</div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.overallScoreTitle}>Body Alignment</div>
              </div>
              <div className={styles.overallScoreContainer}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className={styles.overallScoreHeading}>Rushed</div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.overallScoreTitle}>Voice Indicator</div>
              </div>
              <div className={styles.overallScoreContainer}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className={styles.overallScoreHeading}>Slight Left</div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.overallScoreTitle}>Head Alignment</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className={styles.sectionHeader}>
                <img src={chatIcon} alt="icon" />
                <div className={styles.sectionHeaderText}>Feedback</div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                {/* <div className={styles.buttonContainer}>
                  <WestIcon sx={{ color: "white" }} />
                </div>
                <div className={styles.buttonContainer}>
                  <EastIcon sx={{ color: "white" }} />
                </div> */}
              </div>
            </div>
            <div className={styles.suggestionBarContainerVertical}>
              {feedBackData.length > 0 ? (
                feedBackData.map((data) => (
                  <SuggestionBarVertical title={data.text} />
                ))
              ) : (
                <div
                  style={{
                    height: "10vh",
                    color: "rgba(255,255,255,0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  No Feedbacks
                </div>
              )}
            </div>
          </>
        )}

        {value === 2 && (
          <>
            <div className={styles.sectionHeader}>
              <img src={capsuleIcon} alt="icon" />
              <div className={styles.sectionHeaderText}>No Product Data available</div>
            </div>
            {/* <ProductTemplate />
            {/* <div className={styles.sectionHeader}>
              <img src={capsuleIcon} alt="icon" />
              <div className={styles.sectionHeaderText}>Attached Documents</div>
            </div>
            <ProductTemplate /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default NewMeetingDetails;
