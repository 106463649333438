import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import DocumentCard from "../../Common/DocumentCard/DocumentCard";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import styles from "./DocumentTab.module.css";
import { Typography } from "@mui/material";
import NewDocumentCard from "Components/NewDashboard/DocumentCard/DocumentCard";

const DocumentTab = ({
  setSelectedIds,
  selectedIds,
  setSourceImage,
  setTitle,
  setDeleteButtonActive,
  isViewerOpen,
  setIsViewerOpen,
  selectedIndex,
  type,
  variant,
  data,
  ishome,
  getAllDocs,
}) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const getDocumentTitle = (url) => {
    const pathname = new URL(url).pathname;
    const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
    const decodedFilename = decodeURIComponent(filename); // Decode the URL-encoded filename
    const title = decodedFilename; //.split('.')[0];        //adding the extension
    const titleWithSpaces = title.replace(/%20/g, " "); // Replace %20 with spaces
    return titleWithSpaces;
  };

  const getDocumentExT = (url) => {
    const pathname = new URL(url).pathname;
    const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
    const decodedFilename = decodeURIComponent(filename); // Decode the URL-encoded filename
    const title = decodedFilename.split(".")[0];
    const extension = decodedFilename.split(".").pop().toLowerCase(); // Extract the file extension
    // Replace %20 with spaces
    return extension;
  };

  return (
    <>
      <Grid
        container
        spacing={type == "page" ? 5 : 2}
        sx={{ padding: "10px", gap: "5px !important", margin: "0" }}
      >
        {type != "page" ? (
          <>
            <Grid xs={4}>
              <Box className={styles.newDocumentCard}>
                <AddOutlinedIcon fontSize="large" />
                <Typography fontSize={18} sx={{ mt: 2 }}>
                  Attach Document
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4}>
              <DocumentCard tabbed={true} />
            </Grid>
            <Grid xs={4}>
              <DocumentCard tabbed={true} />
            </Grid>
            <Grid xs={4}>
              <DocumentCard tabbed={true} />
            </Grid>
          </>
        ) : (
          <>
            {data ? (
              <>
                {variant == "product" ? (
                  <>
                    {data.map((product) => (
                      <>
                        <Grid className={styles.gridItemPage}>
                          <DocumentCard
                            tabbed={false}
                            type="page"
                            variant="product"
                            title={product.title}
                            desc={product.description}
                          />
                        </Grid>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {data.length > 0 &&
                      data.map((document, index) => (
                        <>
                          <Grid
                            sx={{ paddingLeft: "10px !important" }}
                            className={styles.gridItemPage}
                          >
                            {/* <DocumentCard
                              key={index}
                              currentIndex={index}
                              documentLength={data.length}
                              ishome={ishome}
                              selectedIndex={selectedIndex}
                              getAllDocs={getAllDocs}
                              setDeleteButtonActive={setDeleteButtonActive}
                              tabbed={true}
                              isFavourite={document.favourite}
                              type="page"
                              variant="document"
                              src_image={document.document_link}
                              docid={document.document_id}
                              setSelectedIds={setSelectedIds}
                              selectedIds={selectedIds}
                              title={document.document_name}
                              ext={getDocumentExT(document.document_link)}
                            /> */}
                            <NewDocumentCard
                              setSourceImage={setSourceImage}
                              setTitle={setTitle}
                              isViewerOpen={isViewerOpen}
                              setIsViewerOpen={setIsViewerOpen}
                              key={index}
                              currentIndex={index}
                              documentLength={data.length}
                              ishome={ishome}
                              selectedIndex={selectedIndex}
                              getAllDocs={getAllDocs}
                              setDeleteButtonActive={setDeleteButtonActive}
                              tabbed={true}
                              isFavourite={document.favourite}
                              type="page"
                              variant="document"
                              src_image={document.document_link}
                              docid={document.document_id}
                              setSelectedIds={setSelectedIds}
                              selectedIds={selectedIds}
                              title={document.document_name}
                              ext={getDocumentExT(document.document_link)}
                            />
                          </Grid>
                        </>
                      ))}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default DocumentTab;
