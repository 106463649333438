import React from "react";
import styles from "./Integrations.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import MyTaskIcon from "../../../Assets/myTaskIcon.svg";
import IntegrationsIllustration from "../../../Assets/integrationsIllustration.svg";
import CardGrid from "./CardGrid";
import VeevaLogo from "../../../Assets/veevaLogo.svg";
import ZoomLogo from "../../../Assets/zoomLogo.svg";
import MeetsLogo from "../../../Assets/meetslogo.svg";

const Integrations = () => {
  const cardData = [
    {
      height: "247px",
      logo: ZoomLogo,
      title: "Zoom Meeting",
      subtitle: "View and manage all your meetings.",
      func: "Conferencing",
      connected: true,
    },
    {
      height: "247px",
      logo: MeetsLogo,
      title: "Google Calender",
      subtitle: "Tracking your daily schedule.",
      func: "Calendar",
      connected: true,
    },
    {
      height: "247px",
      logo: VeevaLogo,
      title: "Veeva",
      subtitle: "Fetching all your contacts.",
      func: "CRM",
      connected: "false",
    },
  ];
  return (
    <React.Fragment>
      <CssBaseline />
      <Box className={styles.backgroundContainer}>
        <img
          src={IntegrationsIllustration}
          className={styles.bgImage}
          alt="icon"
        />
      </Box>
      <Container
        sx={{
          padding: "2vh 0vw 5vh 0vw !important",
          margin: "0px 0px 0px 5vw !important",
        }}
      >
        <Container maxWidth={false} className={styles.container}>
          <Container
            maxWidth={false}
            sx={{
              padding: "0px !important",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Box>
              <Box className={styles.titleContainer}>
                <Typography
                  className={styles.title}
                  fontSize={24}
                  fontWeight={600}
                >
                  Integrations
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  className={styles.myTaskButton}
                  startIcon={<img src={MyTaskIcon} alt="icon" />}
                >
                  My Tasks
                </Button>
              </Box>
              <Container className={styles.secondaryContainer}>
                <Typography
                  fontSize={20}
                  fontWeight={700}
                  fontFamily={"Red Hat Display !important"}
                  sx={{ color: "#ffffff" }}
                >
                  Allowed Integrations
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  fontFamily={"Satoshi-Variable"}
                  sx={{ color: "#E9E9E9", width: "400px" }}
                >
                  Applications that has been recommended by your organisation
                  for you to have a seamless experience.
                </Typography>
              </Container>
              <Container className={styles.cardContainer}>
                <CardGrid data={cardData} />
              </Container>
            </Box>
          </Container>
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default Integrations;
