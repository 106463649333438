import React from "react";
import styles from "./ClientProfile.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import TrashIcon from "../../../../Assets/trashIcon.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import AnalyticsBox from "Components/SalesRep/AnalyticsBox/AnalyticsBox";
import ClientAnalytics from "./ClientAnalytics";
import ClientInfo from "./ClientInfo";
import ClientDataTable from "./ClientDataTable";
import columnsDef from "./data/ColumnDef";
import MOCK_DATA from "./data/MOCK_DATA.json";

const ClientProfile = () => {
  let navigate = useNavigate();

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container
          sx={{
            padding: "2vh 0vw 5vh 0vw !important",
            margin: "0px 0px 0px 5vw !important",
          }}
        >
          <Container maxWidth={false} className={styles.container}>
            <Container maxWidth={false} sx={{ padding: "0px !important" }}>
              <Box className={styles.titleContainer}>
                <Box className={styles.backButton}>
                  <ArrowBackIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                  <Typography
                    className={styles.title}
                    fontSize={24}
                    fontWeight={600}
                  >
                    John Doe
                  </Typography>
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  className={styles.deleteProspect}
                  startIcon={<img src={TrashIcon} alt="icon" />}
                >
                  Delete Prospect
                </Button>
              </Box>
            </Container>
            <Container className={styles.secondaryContainer}>
              <Box className={styles.userInfoBox}>
                <ClientInfo />
              </Box>
              <Box className={styles.analyticsBox}>
                <ClientAnalytics />
              </Box>
            </Container>
            <Container className={styles.tableContainer}>
              <ClientDataTable
                title="All Meetings"
                defination={columnsDef}
                cellData={MOCK_DATA}
              />
            </Container>
          </Container>
        </Container>
      </React.Fragment>
    </>
  );
};

export default ClientProfile;
