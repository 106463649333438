import React from "react";
import cardImage from "../../Assets/cardImage.png";
import videoInfoIcon from "../../Assets/videoinfoIcon.svg";
import clockIcon from "../../Assets/clockIcon.svg";
import playIcon from "../../Assets/playIcon.svg";

import { Typography } from "@mui/material";
import styles from "./resumecardvideo.module.css";

const ResumeVideoCard = ({ bImage, title, remainingModules, runtime }) => {
  return (
    <div className={styles.mainContainer}>
      <div
        style={{
          borderRadius: "8px",
          backgroundImage: `url(${bImage})`,
          width: "100%",
          height: "57px",
        }}
      ></div>
      <div style={{ width: "100%", marginLeft: "15px" }}>
        <div className={styles.videoInfoContainer}>
          <div className={styles.flexRow}>
            <img style={{ margin: "0" }} src={videoInfoIcon} />
            <Typography className={styles.videoInfo}>
              {remainingModules} Module left
            </Typography>
          </div>
          <div className={styles.flexRow}>
            <img style={{ margin: "0" }} src={clockIcon} />
            <Typography className={styles.videoInfo}>{runtime}</Typography>
          </div>
        </div>
        <div className={styles.flexBetween}>
          <div style={{ display: "flex", flexWrap: "wrap", width: "50%" }}>
            <Typography className={styles.videoTitle}>{title}</Typography>
          </div>
          <img src={playIcon} />
        </div>
      </div>
    </div>
  );
};

ResumeVideoCard.defaultProps = {
  bImage: cardImage,
  title: " Nature’s Reset Module 02",
  remainingModules: 3,
  runtime: "45 min",
};

export default ResumeVideoCard;
