import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box, TextField } from "@mui/material";
import styles from "./ForgotPassword.module.css";
import Logo from "../../Assets/logoTextual.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import ForgotPasswordImage from "../../Assets/forgotPasswordImage.svg";
import resetPasswordImage from "../../Assets/resetPasswordImage.svg";
import EmailIcon from "../../Assets/emailIcon.svg";
import EmailIconGray from "../../Assets/emailIconGray.svg";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import InputField from "Components/Common/InputField/InputField";
import CheckIconBlue from "../../Assets/checkIconBlue.svg";
import LockIcon from "../../Assets/lockIcon.svg";
import LockIconGray from "../../Assets/lockIconGray.svg";
import VisibleIcon from "../../Assets/visibleIcon.svg";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "Api/Firebase/config";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [correctEmail, setCorrectEmail] = useState(false);
  const [correctPassword, setCorrectPassword] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [oobCode, setOobCode] = useState(null);

  firebase.initializeApp(firebaseConfig);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for
    // this URL must be whitelisted in the Firebase Console.
    url: "https://affect-cx-divedeepai.vercel.app/forgot-password?email=%EMAIL%",
    // This must be true for email link sign-in.
  };

  useEffect(() => {
    const searchParamEmail = searchParams.get("email");
    const searchParamOob = searchParams.get("oobCode");
    if (searchParamEmail) {
      setEmailVerified(true);
      setEmail(searchParamEmail);
      setOobCode(searchParamOob);
    }
  }, []);
  console.log(email, oobCode);

  const handleForm = async () => {
    if (correctEmail) {
      const resetEmail = await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((response) => {
          toast.success("Email Sent");
        })
        .catch((e) => {
          toast.error("Email Not Found");
        });
    } else {
      toast.error("Enter correct email");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setCorrectEmail(true);
    } else {
      setCorrectEmail(false);
    }
  };

  const handlePasswordReset = async () => {
    const reset = await firebase
      .auth()
      .confirmPasswordReset(oobCode, password)
      .then(() => {
        navigate("/login");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePasswordChange = (password) => {
    if (
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password)
    ) {
      setCorrectPassword(true);
    }
  };
  return (
    <>
      <Container maxWidth={false} className={styles.verificationContainer}>
        <Box className={styles.header}>
          <img alt="icon" src={Logo} />
          <Box className={styles.headerButtonContainer}>
            <Typography className={styles.headerText}>
              Already have an account?
            </Typography>
            <Button
              className={styles.headerButton}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </Box>
        </Box>
        <Box className={styles.information}>
          <img
            alt="icon"
            src={emailVerified ? resetPasswordImage : ForgotPasswordImage}
          />
          {emailVerified ? (
            <>
              <Typography className={styles.headingText}>
                Now reset your password
              </Typography>
            </>
          ) : (
            <>
              <Typography className={styles.headingText}>
                Forgot your password ?
              </Typography>
            </>
          )}

          {emailVerified ? (
            <>
              <Typography className={styles.subText}>
                Please define a new password that is different from the previous
                one.
              </Typography>
            </>
          ) : (
            <>
              <Typography className={styles.subText}>
                Ohh, don’t worry Vishal! We got you. Please continue to receive
                reset password link.
              </Typography>
            </>
          )}

          {emailVerified ? (
            <>
              <Box className={styles.inputFieldContainer}>
                <InputField
                  value={password}
                  setter={setPassword}
                  placeholder="Password"
                  editable={true}
                  startIcon={password ? LockIcon : LockIconGray}
                  endIcon={VisibleIcon}
                  type="password"
                  verifier={handlePasswordChange}
                />
              </Box>
              <Button
                className={styles.continueButton}
                endIcon={<EastOutlinedIcon />}
                onClick={handlePasswordReset}
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              <Box className={styles.inputFieldContainer}>
                <TextField
                  fullWidth
                  value={email}
                  placeholder="Email"
                  editable={true}
                  onChange={handleEmailChange}
                  startIcon={email ? EmailIcon : EmailIconGray}
                />
              </Box>
              <Button
                className={styles.continueButton}
                endIcon={<EastOutlinedIcon />}
                onClick={handleForm}
              >
                Continue
              </Button>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;
