import React from "react";
import { Container } from "@mui/material";
import { Outlet } from "react-router";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "Store/features/setUserSlice";
import styles from "./managers.module.css";
import ManagerSideNav from "Components/Common/Sidenav/ManagersSideNav";

const ManagerFlow = ({ containerPadding }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setUserFunction = (user) => {
    dispatch(setUser(user));
  };
  useEffect(() => {
    const user = JSON.parse(
      sessionStorage.getItem("user") || localStorage.getItem("user")
    );
    if (sessionStorage.getItem("user") === null) {
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("firebase", localStorage.getItem("firebase"));
    }
    if (user) {
      setUserFunction(user);
    }
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          padding: "0px !important",
          paddingLeft: `${containerPadding}vw !important`,
        }}
      >
        <ManagerSideNav />
        <div className={styles.container}>
          <Outlet />
        </div>
      </Container>
    </>
  );
};

export default ManagerFlow;
