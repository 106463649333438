import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useEffect } from "react";
import {
  allMeetings,
  completedMeetings,
  scheduleCount,
} from "./scheduleMeetingSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "Api/Backend/backend";

const initialState = {
  meetings: [],
};

export const fetchMeetings = createAsyncThunk(
  "getMeetings/fetchMeetings",
  async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}get_meeting_by_user_id/?user_id=${id}`
      );
      const data = response.data.filter(
        (meeting) => meeting.actual_end_time === null
      );
      const completed = response.data.filter(
        (meeting) => meeting.actual_end_time !== null
      );
      return { data, completed };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const getMeetingsSlice = createSlice({
  name: "getMeetings",
  initialState,
  reducers: {
    scheduledMeeting: (state, action) => {
      state.meetings = [...state.meetings, action.payload];
    },
  },
  extraReducers: (builder) => {
    // const dispatch=useDispatch();
    builder
      .addCase(fetchMeetings.fulfilled, (state, action) => {
        state.meetings = action.payload.data;
      })
      .addCase(fetchMeetings.rejected, (state, action) => {
        console.log(action.error);
      });
  },
});

export const { scheduledMeeting } = getMeetingsSlice.actions;

export default getMeetingsSlice.reducer;
