import React, { useEffect, useState } from "react";
import styles from "./ScheduleMeetingForm.module.css";
import { Autocomplete, Box, Chip, Paper, Typography } from "@mui/material";
import InputField from "Components/Common/InputField/InputField";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { TextField } from "@mui/material";
import { GetDocs, GetDoctors } from "Api/Backend/backend";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import capsuleIcon from "../Assets/capsuleIcon.svg";
import briefCase from "../Assets/briefCase.svg";
import pdfIcon from "./Assets/pdfIcon.svg";
import videoIcon from "./Assets/videoIcon.svg";
import imageIcon from "./Assets/imageIcon.svg";
import fileIcon from "./Assets/fileIcon.svg";
import blueSyringe from "../Assets/blueSyringe.svg";
import flaskIcon from "../Assets/flaskIcon.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Add } from "@mui/icons-material";

const AddProductForm = ({ setTitle, setDoc, doc, userid, setDescription }) => {
  const [doclist, setDocList] = useState([]);
  const [productType, setProductType] = useState(0);
  const getDoc = async (id) => {
    const response = await GetDocs(id)
      .then((response) => {
        setDocList(response);
      })
      .catch((e) => console.log(e));
  };
  const handleClick = (index) => {
    setProductType(index);
  };
  useEffect(() => {
    if (userid) {
      getDoc(userid);
    }
  }, []);

  const handleSelectDocument = (event, value) => {
    setDoc(value);
  };
  const getDocumentExT = (url) => {
    const pathname = new URL(url).pathname;
    const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
    const decodedFilename = decodeURIComponent(filename); // Decode the URL-encoded filename
    const title = decodedFilename.split(".")[0];
    const extension = decodedFilename.split(".").pop().toLowerCase(); // Extract the file extension
    // Replace %20 with spaces
    return extension;
  };
  return (
    <>
      <Box className={styles.scheduleMeetingFormContainer}>
        <Box className={styles.formRow}>
          <InputField
            placeholder="Product Name"
            bgColor="null"
            editable={true}
            inputHeight="50"
            inputWidth="550"
            setter={setTitle}
          />
        </Box>
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>Description</div>
        </Box>
        <Box className={styles.formRow}>
          <InputField
            placeholder="Description"
            bgColor="null"
            editable={true}
            inputHeight="50"
            inputWidth="550"
            setter={setDescription}
          />
        </Box>

        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>Product Type</div>
        </Box>
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          {[1, 2, 2, 3].map((data, index) => (
            <div
              onClick={() => handleClick(index)}
              className={
                productType === index
                  ? styles.selectedProductContainer
                  : styles.productContainer
              }
            >
              <img
                src={
                  index === 0
                    ? capsuleIcon
                    : index === 1
                    ? blueSyringe
                    : index === 2
                    ? flaskIcon
                    : briefCase
                }
              />
            </div>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            borderTop:
              "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
          }}
          className={styles.formRow}
        ></Box>

        <Box className={styles.formRow}>
          <>
            <FolderOutlinedIcon fontSize="small" sx={{ color: "#77767C" }} />

            {Array.isArray(doclist) && doclist.length > 0 ? (
              <Autocomplete
                popupIcon={<Add sx={{ color: "rgba(255, 255, 255, 0.50)" }} />}
                multiple
                sx={{
                  width: "40%",
                }}
                options={doclist}
                getOptionLabel={(option) => option.document_name}
                filterSelectedOptions
                PaperComponent={({ children }) => (
                  <Paper
                    elevation={4}
                    style={{
                      backgroundColor: "#333", // Dark background color
                    }}
                  >
                    {children}
                  </Paper>
                )}
                defaultValue={"Add Documents"}
                onChange={handleSelectDocument}
                value={doc}
                renderOption={(props, option) => (
                  <li {...props}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={
                          getDocumentExT(option.document_link) == "pdf"
                            ? pdfIcon
                            : getDocumentExT(option.document_link) === "png" ||
                              getDocumentExT(option.document_link) === "jpeg" ||
                              getDocumentExT(option.document_link) === "jpg"
                            ? imageIcon
                            : getDocumentExT(option.document_link) === "mp4"
                            ? videoIcon
                            : fileIcon
                        } // Assuming there is an 'icon' property in your doclist items
                        alt={option.document_name}
                        style={{
                          width: "24px",
                          height: "24px",
                        }}
                      />
                      <Typography
                        variant="body1"
                        style={{ color: "white", flexGrow: 1 }}
                      >
                        {option.document_name}
                      </Typography>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Add Documents"
                    sx={{
                      "& input": {
                        color: "rgba(255, 255, 255, 0.50) !important",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottom: "none !important", // Customize the hover border color here
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "none !important", // Customize the border color here
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none !important", // Customize the focus border color here
                      },
                    }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{
                        "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                          {
                            color: "rgba(255, 255, 255, 0.50) !important",
                          },
                        color: "rgba(255, 255, 255, 0.50) !important",
                      }}
                      key={index}
                      label={
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            style={{
                              height: "25px",
                              width: "25px",
                            }}
                            src={
                              getDocumentExT(option.document_link) == "pdf"
                                ? pdfIcon
                                : getDocumentExT(option.document_link) ===
                                    "png" ||
                                  getDocumentExT(option.document_link) ===
                                    "jpeg" ||
                                  getDocumentExT(option.document_link) === "jpg"
                                ? imageIcon
                                : getDocumentExT(option.document_link) === "mp4"
                                ? videoIcon
                                : fileIcon
                            }
                            alt="icon"
                          />
                          {/* Add document icon */}
                          {option.document_name}
                        </span>
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderNoOptions={() => (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      mt: 2,
                      fontStyle: "italic",
                      color: "rgba(255, 255, 255, 0.50)",
                    }}
                  >
                    No options
                  </Typography>
                )}
              />
            ) : (
              <Typography
                variant="body1"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "rgba(255, 255, 255, 0.50) !important",
                }}
              >
                {Array.isArray(doclist)
                  ? "Loading..."
                  : "No data available! Add Documents"}
              </Typography>
            )}
          </>
          <>
            <AccountCircleIcon fontSize="small" sx={{ color: "#77767C" }} />

            {Array.isArray(doclist) && doclist.length > 0 ? (
              <Autocomplete
                popupIcon={<Add sx={{ color: "rgba(255, 255, 255, 0.50)" }} />}
                clearIcon={
                  <KeyboardBackspaceIcon
                    sx={{ color: "rgba(255, 255, 255, 0.50)" }}
                  />
                }
                multiple
                sx={{ width: "40%" }}
                options={doclist}
                getOptionLabel={(option) => option.document_name}
                filterSelectedOptions
                defaultValue={"Add Documents"}
                onChange={handleSelectDocument}
                value={doc}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Assign To Users"
                    sx={{
                      "& input": {
                        color: "rgba(255, 255, 255, 0.50) !important",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottom: "none !important", // Customize the hover border color here
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "none !important", // Customize the border color here
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none !important", // Customize the focus border color here
                      },
                    }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{
                        "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                          {
                            color: "rgba(255, 255, 255, 0.50) !important",
                          },
                        color: "rgba(255, 255, 255, 0.50) !important",
                      }}
                      key={index}
                      label={option.document_name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderNoOptions={() => (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      mt: 2,
                      fontStyle: "italic",
                      color: "rgba(255, 255, 255, 0.50)",
                    }}
                  >
                    No options
                  </Typography>
                )}
              />
            ) : (
              <Typography
                variant="body1"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "rgba(255, 255, 255, 0.50) !important",
                }}
              >
                {Array.isArray(doclist)
                  ? "Loading..."
                  : "No data available! Add Documents"}
              </Typography>
            )}
          </>
        </Box>
      </Box>
    </>
  );
};
AddProductForm.defaultProps = {
  setDescription: () => {},
  setTitle: () => {},
};
export default AddProductForm;
