import React from "react";
import styles from "./divider.module.css";

const HorizontalDivider = () => {
  return (
    <div className={styles.container}>
      <div className={styles.dividerColor} />
      <div className={styles.text}>or continue with</div>
      <div className={styles.dividerColor} />
    </div>
  );
};

export default HorizontalDivider;
