import React from "react";
import Styles from "./comp.module.css";
import { Typography, Grid } from "@mui/material";

const UserHead = () => {
  return (
    <Grid container sx={{marginTop:"30px",padding:"10px", borderBottom: "1px solid grey"}}>
      <Grid item md={1} sx={{display:"flex", justifyContent:"start"}}>
      <Typography className={Styles.title}>#</Typography>
      </Grid>
      <Grid item md={3} sx={{display:"flex", justifyContent:"start"}}>
      <Typography className={Styles.title}>Name</Typography>
      </Grid>
      <Grid item md={2} sx={{display:"flex", justifyContent:"start"}}>
      <Typography className={Styles.title}>Role</Typography>
      
      </Grid>
      <Grid item md={2} sx={{display:"flex", justifyContent:"start"}}>
      <Typography className={Styles.title}>Date Active</Typography>
      </Grid>
      <Grid item md={1} sx={{display:"flex", justifyContent:"start"}}>
      <Typography className={Styles.title}>Status</Typography>
      </Grid>
      <Grid item md={2} sx={{ display: "flex", justifyContent: "start" }}>
        <Typography className={Styles.title}>Invite</Typography>
      </Grid>
    </Grid>
  );
};

export default UserHead;
