import React from "react";
import { Navigate, Outlet } from "react-router";

const CheckLogin = () => {
  const userRole =
    sessionStorage.getItem("user") || localStorage.getItem("user");

  return <>{userRole === null ? <Navigate to={"login"} /> : <Outlet />}</>;
};

export default CheckLogin;
