import { createColumnHelper } from "@tanstack/react-table";
import GenericListItem from "Components/Common/GenericListItem/GenericListItem";
import { Box } from "@mui/system";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";
import { Container, Typography } from "@mui/material";

const mySplit = (text, index) => {
  const arr = text.split("!");
  return arr[index];
};

const columnHelper = createColumnHelper();

const columnsDef = [
  columnHelper.accessor("doctor_name", {
    header: () => "Name",
    cell: (info) => (
      <GenericListItem
        avatar={info.row.original.doctor_image}
        text={info.renderValue()}
        isClient={true}
        variant="table"
        name={info.renderValue()}
      />
    ),
  }),
  columnHelper.accessor("meeting_count", {
    header: () => "Total Meetings",
    cell: (info) => (
      <Container
        sx={{
          width: "100%",
          textAlign: "center",
          padding: "0px !important",
          color: "#636269 !important",
          gap: "8px !important",
        }}
      >
        {info.renderValue()}
      </Container>
    ),
  }),
  columnHelper.accessor("last_meeting_time", {
    header: () => "Latest Meeting",
    cell: (info) => (
      <Container
        sx={{
          width: "120%",
          padding: "0px !important",
          transform: "translate(-5px,0px)",
        }}
      >
        <GenericListItem
          text={mySplit(info.renderValue(), 0)}
          secondaryText={mySplit(info.renderValue(), 1)}
          variant="table"
        />
      </Container>
    ),
  }),
  columnHelper.accessor("average_success_score", {
    header: () => "Avg. Affect Score",
    cell: (info) => (
      <Box
        sx={{
          height: "25px",
          width: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HalfDonutChartSmall value={info.renderValue()} />
        <Typography sx={{ color: "#636269 !important" }}>
          {info.renderValue() + `%`}
        </Typography>
      </Box>
    ),
  }),
  columnHelper.accessor("total_feedbacks", {
    header: () => "Total Feedbacks",
    cell: (info) => (
      <Typography sx={{ paddingRight: "25px", textAlign: "center" }}>
        {info.renderValue()}
      </Typography>
    ),
  }),
];

export default columnsDef;
