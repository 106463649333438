import React from "react";
import { Card, Typography, Box } from "@mui/material";
import Styles from "../../Pages/Teams/team.module.css";


const TeamCard = ({data}) => {
  
  
  return (
    <Card className={Styles.card} >
      {console.log.data}
      <Box className={Styles.cardWrapper}>
        <Typography className={Styles.insideTitle}>{data.team_name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase())
            .join('')}</Typography>
      </Box>
      <Typography className={Styles.cardTitle}>{data.team_name}</Typography>
      <Typography className={Styles.cardText}>{ data.manager_first_name + " " +data.manager_last_name}</Typography>
      <Typography className={Styles.cardLiteText}>{data.total_users_in_team_except_manager_director} users</Typography>
    </Card>
  );
};

export default TeamCard;
