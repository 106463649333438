import React, { useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import styles from "./EmailVerification.module.css";
import Logo from "../../Assets/logoTextual.svg";
import { useNavigate } from "react-router-dom";
import EmailVerifyImage from "../../Assets/emailVerificationImage.svg";
import VerificationComplateImage from "../../Assets/verificationCompleteImage.svg";
import UserNotFoundImage from "../../Assets/userNotFoundImage.svg";

const UserNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth={false} className={styles.verificationContainer}>
        <Box className={styles.header}>
          <img alt="icon" src={Logo} />
          <Box className={styles.headerButtonContainer}>
            <Typography className={styles.headerText}>
              Already have an account?
            </Typography>
            <Button className={styles.headerButton}>Login</Button>
          </Box>
        </Box>
        <Box className={styles.information}>
          <img alt="icon" src={UserNotFoundImage} />

          <Typography className={styles.headingText}>
            We’re sorry....
          </Typography>

          <Typography className={styles.subText}>
            We couldn’t set up your account since we haven’t found
            mariyamdavis@denote.com under Denote Solutions, So please contact
            your manager.
          </Typography>

          <Typography
            className={styles.subText}
            onClick={() => navigate("/signup")}
          >
            <a>Go back to Signup</a>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default UserNotFound;
