import React, { useState } from "react";
import styles from "./EndMeeting.module.css";
import {
  Box,
  Container,
  Typography,
  Rating,
  styled,
  TextField,
  Button,
} from "@mui/material";
import MailIllustration from "../Assets/mailIllustration.svg";
import StarEmpty from "../Assets/starEmpty.svg";
import StarFilled from "../Assets/starFilled.svg";

const EndMeeting = () => {
  const [btnGroup, setBtnGroup] = useState("products");
  const [feedback, setFeedback] = useState(true);
  const [value, setValue] = React.useState(2);
  const [placeholder, setPlaceholder] = React.useState(
    "Add comment (optional)"
  );

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#FF974C",
    },
    "& .MuiRating-iconHover": {
      color: "#FF974C",
    },
  });

  const [comment, setComment] = React.useState();

  return (
    <>
      <Container className={styles.inMeetingContainer}>
        <Box className={styles.topBar}>
          <Typography className={styles.meetingTitle}>
            Meeting for paracetamol
          </Typography>
        </Box>
        <Box className={styles.tabsContainer}>
          <img alt="icon" src={MailIllustration} />
          <Typography className={styles.promptTitle}>
            How was your experience with our AI feedbacks?
          </Typography>
          <Typography className={styles.promptSubtext}>
            Tell us more about what you liked and were the feedbacks accurate.
          </Typography>
          <StyledRating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (newValue < 3 && newValue >= 1) {
                setPlaceholder(
                  "So sorry about that. What happened? Talk to us please...."
                );
              } else if (newValue >= 3) {
                setPlaceholder(
                  "Tell us more about your experience, we’re eager to know..."
                );
              } else {
                setPlaceholder("Add comment (optional)");
              }
            }}
            size="large"
          />
          <textarea
            placeholder={placeholder}
            className={styles.textField}
            cols="30"
            rows="4"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          ></textarea>
          <Button className={styles.submitButton}>Submit Rating</Button>
          <Typography className={styles.skip}>Skip for now</Typography>
        </Box>
      </Container>
    </>
  );
};

export default EndMeeting;
