import React, { useEffect, useState } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import LineChartWithArea from "Components/NewDashboard/SuccessGraph/SuccessGraph";
import ColoredCircle from "Components/Common/ColoredCircle/ColorCircle";
import DataTable from "Components/Common/DataTable/DataTable";
import columnsDef from "./ColumnDef";
import upcomingColumnDef from "./upcomingColumnDef";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  baseUrl,
  getTopClients,
  getTopClientsByUser,
  getTopReps,
  get_analytics_feedback,
  recordingStats,
  recordingTable,
} from "Api/Backend/backend";
import { setCurrentRoute } from "Store/features/currentRoute";
import firstaidIcon from "./Assets/firstaidIcon.svg";
import capsuleIcon from "./Assets/capsuleIcon.svg";
import styles from "./stats.module.css";
import SalesRepLeaderBoard from "Components/NewDashboard/SalesrepLeaderBoard/SalesRepLeaderBoard";
import SuggestionBar from "Pages/Meeting/Components/SuggestionBar/SuggestionBar";
import BarChartGraph from "Components/NewDashboard/BarChart/BarChart";
import NewAnalytics from "../NewAnalytics";
import MonthSelect from "Components/NewDashboard/SelectMonth/SelectMonth";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";
import SuggestionBarVertical from "Components/NewDashboard/SuggestionBarVertical/SuggestionBarVertical";
import YearSelect from "Components/NewDashboard/SelectMonth/SelectYear";

const Statistics = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [feedbacks, setFeedBack] = useState([]);
  const [statData, setStatData] = useState({
    meeting_count: 0,
    average_success_score: 0,
    doctor_name: undefined,
    feedback_count: 0,
  });
  const [clients, setClients] = useState([]);
  const [reps, setReps] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState("2024");
  const currentUser = useSelector((state) => state.setUser.user);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const getTableData = async (id) => {
    const response = await recordingTable(id)
      .then((response) => {
        setTableData(response);
      })
      .catch((e) => console.log(e));
  };

  const onRowClick = (data) => {
    sessionStorage.setItem("meetingTitle", data.title);
    navigate(`/Engagements/${data.mid}`, { state: data });
  };

  // const getTopClient = () => {
  //   const clients = getTopClients()
  //     .then((res) => {
  //       setClients(res.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  const getTopClientByUser = (id) => {
    const clients = getTopClientsByUser(id)
      .then((res) => {
        setClients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  const getReps = () => {
    const clients = getTopReps()
      .then((res) => {
        setReps(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getStatData = async (id) => {
    const response = await recordingStats(id)
      .then((response) => setStatData(response))
      .catch((e) => console.log(e));
  };
  function getCurrentTime() {
    const date = new Date();
    const currentTime = date.toLocaleString();

    return currentTime;
  }
  function getTimeOnly(dateString) {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();

    return time;
  }
  function getDateOn() {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    return formattedDate;
  }
  const getAllMeetings = async (id) => {
    const time = getCurrentTime();
    const todayTime = getTimeOnly(time);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const resp = await axios
      .post(`${baseUrl}get_meeting_by_user_id/`, {
        user_id: id,
        current_time: current_time,
        timezone: timeZone,
      })
      .then((response) => {
        const data = response.data.filter(
          (meeting) => meeting.actual_end_time == null
        );
        const completed = response.data.filter(
          (meeting) => meeting.actual_end_time != null
        );
        setUpcomingMeetings(data);
      })
      .catch((e) => console.log(e));
  };
  const getFeedback = (id) => {
    const response = get_analytics_feedback(id).then((res) =>
      setFeedBack(res.data.top_ten_feedbacks)
    );
  };
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      dispatch(setCurrentRoute(1));
      getTableData(user.user_id);
      getAllMeetings(user.user_id);
      getFeedback(user.user_id);
      getStatData(user.user_id);
      // getTopClient();
      getTopClientByUser(user.user_id);
      getReps();
    }
  }, [count]);
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.statisticsContainer}>
          <div className={styles.sectionHeaderContainer}>
            <div className={styles.sectionHeader}>Recent Statistics</div>
            {/* <div className={styles.dateContainer}>
              <div className={styles.dateText}>Oct 01 - Oct 24</div>
              <div className={styles.dateSubtitle}>Monthly</div>
            </div> */}
          </div>
          <div className={styles.statisticsInnerContainer}>
            <div className={styles.stats}>
              <h3>Average Affect Score</h3>
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "50%",
                  }}
                >
                  <HalfDonutChartSmall
                    value={Math.round(statData.average_success_score)}
                  />
                  {console.log(statData)}
                  <div className={styles.valueText}>
                    {Math.round(statData.average_success_score)}%
                  </div>
                </div>
              </div>
              <div
                style={{
                  color: "#9A93A3",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {`${statData.meeting_count} Interactions`}
              </div>
            </div>
            <div className={styles.stats}>
              <h3>Engagements</h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                {clients.length > 0 && (
                  <div className={styles.valueText}>
                    {statData.meeting_count}
                  </div>
                )}
                {/* <ArrowUpwardRounded
                  fontSize="small"
                  style={{
                    color: "#28CA90",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#28CA903D",
                  }}
                /> */}
              </div>
              {clients.length > 0 ? (
                // <InfoCard
                //   title={clients[0].doctor_name}
                //   icon={clients[0].doctor_profile_picture}
                //   subTitle={clients[0].speciality}
                // />
                <div
                  style={{
                    color: "#9A93A3",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`Average ${Math.round(
                    statData.average_success_score
                  )}% affect score`}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.stats}>
              <h3>Highest Interaction</h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                {clients.length > 0 && (
                  <div className={styles.valueText}>
                    {clients[0].doctor_name}
                  </div>
                )}
                {/* <ArrowUpwardRounded
                  fontSize="small"
                  style={{
                    color: "#28CA90",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#28CA903D",
                  }}
                /> */}
              </div>
              {clients.length > 0 ? (
                // <InfoCard
                //   title={clients[0].doctor_name}
                //   icon={clients[0].doctor_profile_picture}
                //   subTitle={clients[0].speciality}
                // />
                <div
                  style={{
                    color: "#9A93A3",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`${clients[0].meeting_count} Interactions`}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.stats}>
              <h3>Best Rep</h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <div className={styles.valueText}>
                  {reps.length > 0 && (
                    <>{`${reps[0].first_name} ${reps[0].last_name}`}</>
                  )}
                </div>
                {/* <ArrowUpwardRounded
                  fontSize="small"
                  style={{
                    color: "#28CA90",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#28CA903D",
                  }}
                /> */}
              </div>
              {reps.length > 0 && (
                <div
                  style={{
                    color: "#9A93A3",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`Average ${Math.round(
                    reps[0].avg_success_score
                  )}% affect score`}
                </div>
              )}
              {/* {reps.length > 0 && (
                <InfoCard
                  title={`${reps[0].first_name} ${reps[0].last_name}`}
                  subTitle={reps[0].role}
                  icon={reps[0].user_image}
                />
              )} */}
            </div>
            <div className={styles.stats}>
              <h3>Best Product </h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <div className={styles.valueText}>Paracetamol OD</div>
              </div>
              <div
                style={{
                  color: "#9A93A3",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {`Average 60% affect score`}
              </div>
            </div>
            <div className={styles.stats}>
              <h3>Clients</h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                {clients.length > 0 && (
                  <div className={styles.valueText}>{clients.length}</div>
                )}
                <ArrowDownwardRounded
                  fontSize="small"
                  style={{
                    color: "#E51A3F",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#E51A3F3D",
                  }}
                />
              </div>
              {clients.length > 0 ? (
                // <InfoCard
                //   title={clients[0].doctor_name}
                //   icon={clients[0].doctor_profile_picture}
                //   subTitle={clients[0].speciality}
                // />
                <div
                  style={{
                    color: "#9A93A3",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`Average ${Math.round(
                    statData.average_success_score
                  )}% affect score`}
                </div>
              ) : (
                <></>
              )}
            </div>{" "}
            <div className={styles.stats}>
              <h3>Reps Ranking</h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                {reps.length > 0 && (
                  <div className={styles.valueText}>{reps.length}</div>
                )}
                <ArrowDownwardRounded
                  fontSize="small"
                  style={{
                    color: "#E51A3F",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#E51A3F3D",
                  }}
                />
              </div>
              {reps.length > 0 ? (
                // <InfoCard
                //   title={clients[0].doctor_name}
                //   icon={clients[0].doctor_profile_picture}
                //   subTitle={clients[0].speciality}
                // />
                <div
                  style={{
                    color: "#9A93A3",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {`Average 60% affect score`}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.stats}>
              <h3>Worst Product </h3>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <div className={styles.valueText}>Zincotic</div>
                <ArrowDownwardRounded
                  fontSize="small"
                  style={{
                    color: "#E51A3F",
                    borderRadius: "20px",
                    marginLeft: "5px",
                    padding: "1px",
                    background: "#E51A3F3D",
                  }}
                />
              </div>
              <div
                style={{
                  color: "#9A93A3",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                {`Average 60% affect score`}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "1vh" }}
          className={styles.sectionHeaderContainer}
        >
          <div className={styles.sectionHeader}>AffectCX Usage</div>
          <div  style={{display:"flex",flexDirection:"row"}}>
          <div className={styles.dateContainer}>
            <MonthSelect
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              setDates={setDates}
            />
          </div>
          <div className={styles.dateContainer}>
            <YearSelect
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
             
            />
          </div>
          </div>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "1vh" }}
        >
          {/* <LineChartWithArea /> */}
          <NewAnalytics
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            dates={dates}
          />
          {/* <div className={styles.statGraphContainer}>
            <div className={styles.statGraphHeading}>
              Success score vs ACX Usage
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={styles.statGraphValue}>23% More</div>
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div className={styles.statGraphSubtitle}>
              Gain in success score over regular calls
            </div>
          </div> */}
        </div>
        <div className={styles.graphFooter}>
          <div style={{ display: "flex", gap: "8px" }}>
            <div className={styles.footerText}>
              <ColoredCircle color={"green"} />
              Score Above 50
            </div>
            <div className={styles.footerText}>
              <ColoredCircle color={"red"} />
              Score Less than 30
            </div>
            <div className={styles.footerText}>
              <ColoredCircle color={"yellow"} />
              Score Between 30 and 50
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div
          style={{ marginTop: "5vh" }}
          className={styles.sectionHeaderContainer}
        >
          <div className={styles.sectionHeader}>Feedbacks</div>
          {/* <div className={styles.dateContainer}>
            <div className={styles.dateText}>Oct 01 - Oct 24</div>
            <div className={styles.dateSubtitle}>Monthly</div>
          </div> */}
        </div>
        <div className={styles.suggestionBarContainerVertical}>
          {feedbacks.length > 0 ? (
            feedbacks.map((data, index) => (
              <SuggestionBarVertical
                title={data.feedback}
                index={index}
                suggestion={`${data.count} times`}
              />
            ))
          ) : (
            <div
              style={{
                height: "10vh",
                color: "rgba(255,255,255,0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Feedbacks
            </div>
          )}
        </div>
        <div className={styles.topRightContainer}>
          {/* <div className={styles.leaderBoard}>
            <div className={styles.sectionHeader}>Reps Leaderboard</div>
            <div className={styles.leaderBoardHeader}>
              <div className={styles.headerText}>Name</div>
              <div className={styles.headerText}>Success Score</div>
            </div>
            {reps.length > 0 &&
              reps.map((data, index) => (
                <SalesRepLeaderBoard
                  index={index}
                  subTitle={`${data.meeting_count} Meetings`}
                  name={`${data.first_name} ${data.last_name}`}
                  icon={data.user_image}
                  value={Math.round(data.avg_success_score)}
                />
              ))}
          </div> */}
          <div className={styles.topClients}>
            <div className={styles.sectionHeader}>Top Clients</div>
            <div className={styles.leaderBoardHeader}>
              <div className={styles.headerText}>Name</div>
            </div>
            {clients.length > 0 ? (
              clients
                .slice(0, 4)
                .map((data, index) => (
                  <SalesRepLeaderBoard
                    isTopClient={true}
                    subTitle={`${data.meeting_count} Meetings`}
                    index={index}
                    name={data.doctor_name}
                    icon={data.doctor_profile_picture}
                  />
                ))
            ) : (
              <div className={styles.emptyTopClients}>No clients to show</div>
            )}
          </div>
        </div>
        {/* <div className={styles.tabContainer}> */}
        {/* <div
            onClick={() => {
              setValue(0);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 0 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 0
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Completed Calls
            </div>
          </div> */}
        {/* <div
            onClick={() => {
              setValue(1);
            }}
            className={styles.tab}
            style={{
              borderBottom:
                value === 1 && "2px solid var(--dark-inverted-bg-100, #fff)",
            }}
          >
            <div
              className={styles.tabText}
              style={{
                color:
                  value === 1
                    ? "white"
                    : "var(--dark-inverted-bg-24, rgba(255, 255, 255, 0.24))",
              }}
            >
              Upcoming Calls
            </div>
          </div> */}
        {/* </div> */}
        {/* {value === 0 && (
          <div className={styles.dataTableContainer}>
            <DataTable
              isAllInteraction={true}
              isOnStatistics={true}
              variant={"filter"}
              defination={columnsDef}
              cellData={tableData}
              onRowClick={onRowClick}
              id={currentUser.user_id}
              getTableData={getTableData}
              setCount={setCount}
            />
          </div>
        )} */}
        {/* {value === 1 && (
          <div className={styles.dataTableContainer}>
            <DataTable
              isAllInteraction={true}
              isOnStatistics={true}
              variant={"filter"}
              defination={upcomingColumnDef}
              cellData={upcomingMeetings}
              onRowClick={() => {}}
              id={currentUser.user_id}
              getTableData={getTableData}
              setCount={setCount}
            />
          </div>
        )} */}

        {/* <div
          style={{ marginTop: "3vh" }}
          className={styles.sectionHeaderContainer}
        >
          <div className={styles.sectionHeader}>Product Metrics</div> */}
        {/* <div className={styles.dateContainer}>
            <div className={styles.dateText}>Oct 01 - Oct 24</div>
            <div className={styles.dateSubtitle}>Monthly</div>
          </div> */}
        {/* </div> */}
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1vh",
            width: "100%",
          }}
        >
          <BarChartGraph />
          <div className={styles.statGraphContainer}>
            <div className={styles.statGraphHeading}>
              Product vs Success score
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={styles.barGraphHeading}>Paracetamol OD</div>
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div className={styles.statGraphSubtitle}>
              Is the most well received among clients
            </div>
          </div>
        </div>
        <div className={styles.graphFooter}>
          <div style={{ display: "flex", gap: "8px" }}>
            <div className={styles.footerText}>
              <ColoredCircle color={"#9747FF"} /> Paracetamol oD
            </div>
            <div className={styles.footerText}>
              <ColoredCircle color={"#597EF7"} />
              Torglip Md
            </div>
            <div className={styles.footerText}>
              <ColoredCircle color={"#597EF7"} />
              Oxygen ai
            </div>{" "}
            <div className={styles.footerText}>
              <ColoredCircle color={"#597EF7"} />
              Zincoflex
            </div>{" "}
            <div className={styles.footerText}>
              <ColoredCircle color={"#597EF7"} />
              Garfuek
            </div>
            <div className={styles.footerText}>
              <ColoredCircle color={"#597EF7"} />
              Masterso
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Statistics;
