import React, { useEffect, useRef, useState } from "react";
import styles from "./ScheduleMeetingModal.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleMeetingForm from "./ScheduleMeetingForm";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl, createMeeting, getZoomToken } from "Api/Backend/backend";
import { toast } from "react-toastify";
import moment from "moment";
import { ClickAwayListener } from "@mui/base";
import { Modal, ThemeProvider, createTheme } from "@mui/material";
import closeIcon from "../../NewDashboard/Assets/closeIcon.svg";
import emailjs from "@emailjs/browser";
import McqQuestion from "Components/NewDashboard/ChoiceComponent/ChoiceComponent";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "var(--theme-dark-0, #222224)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "var(--theme-dark-0, #222224)",
  },
}));

const ScheduleMeetingModal = ({
  meetingModalOpen,
  setMeetingModalOpen,
  scheduledMeetings,
  setAllScheduledMeetings,
  meetings,
  setScheduled,
  getAllMeetings,
  id,
}) => {
  const [meetingTitle, setMeetingTitle] = useState("");
  const [prospect, setProspect] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [sTime, setSTime] = useState("");
  const [eTime, setETime] = useState("");
  const [selectedOption, setSelectedOption] = useState(false);
  const [alertTime, setAlertTime] = useState("");
  const [token, setToken] = useState("");
  const [guest, setGuest] = useState("guest@domain.com");
  const [doctor, setDoctor] = useState("");
  const [productTemp, setProductTemp] = useState(null);
  const [doc, setDoc] = useState([]);
  const buttonRef = useRef();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.setUser.user);
  // const meetings = useSelector((state) => state.scheduleMeeting.meeting);
  const componentRef = useRef(null);
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");

  function getCurrentTime() {
    const date = new Date();
    const currentTime = date.toLocaleString();
    return currentTime;
  }
  function getTimeOnlyCurrent(dateString) {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();
    return time;
  }
  function getDateOn() {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    return formattedDate;
  }
  const mcqQuestion = "What is the capital of France?";
  const mcqOptions = ["Invite Now"];
  const getScheduledMeetings = async () => {
    const time = getCurrentTime();
    const todayTime = getTimeOnlyCurrent(time);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const resp = await axios
      .post(`${baseUrl}get_meeting_by_user_id/`, {
        user_id: currentUser.user_id,
        current_time: current_time,
        timezone: timeZone,
      })
      .then((response) => {
        setAllScheduledMeetings(response.data);
      });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        buttonRef.current.click();
      }
    };
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    if (!scheduledMeetings.length > 0 && meetingModalOpen) {
      getScheduledMeetings();
    }
  }, [meetingModalOpen]);
  const checkScheduling = (scheduleDate) => {
    let bool = false;
    if (scheduledMeetings.length > 0) {
      scheduledMeetings.map((scheduleData) => {
        if (getDateOnly(scheduleData.start_time) === scheduleDate) {
          if (
            sTime >= getTimeOnly(scheduleData.start_time) &&
            sTime < getTimeOnly(scheduleData.end_time)
          ) {
            bool = true;
          } else if (
            eTime > getTimeOnly(scheduleData.start_time) &&
            eTime <= getTimeOnly(scheduleData.end_time)
          ) {
            bool = true;
          }
        }
      });
    } else {
      return false;
    }
    return bool;
  };
  const handleClose = () => {
    setMeetingModalOpen(setMeetingModalOpen);
  };
  const validateEmail = (email) => {
    // email validation function
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(vc|[a-zA-Z]{2,})))$/;

    return re.test(String(email).toLowerCase());
  };
  function getTimeOnly(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  function TimeDifference() {
    // Split the time strings into hours, minutes, and seconds
    const [hours1, minutes1] = eTime.split(":").map(Number);
    const [hours2, minutes2] = sTime.split(":").map(Number);

    // Convert the time values to minutes
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    // Calculate the difference in minutes
    const differenceInMinutes = totalMinutes1 - totalMinutes2;

    // Check if the difference is greater than 30 minutes
    const isTime1Greater = differenceInMinutes >= 30;
    return isTime1Greater;
  }
  function maxTimeDifference() {
    // Split the time strings into hours, minutes, and seconds
    const [hours1, minutes1] = eTime.split(":").map(Number);
    const [hours2, minutes2] = sTime.split(":").map(Number);

    // Convert the time values to minutes
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    // Calculate the difference in minutes
    const differenceInMinutes = totalMinutes1 - totalMinutes2;

    // Check if the difference is greater than 180 minutes
    const isTime1Greater = differenceInMinutes > 180;
    return isTime1Greater;
  }
  const getIso = () => {
    const currentDate = moment();
    const cTime = currentDate.toString();
    return cTime;
  };
  function getDateOnly(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const checkDoctor = (doctorName, scheduleDate) => {
    let bool = false;
    if (scheduledMeetings.length > 0) {
      scheduledMeetings.map((scheduleData) => {
        if (
          scheduleData.doctor_id === doctorName &&
          getDateOnly(scheduleData.start_time) === scheduleDate
        ) {
          bool = true;
        }
      });
    }
    return bool;
  };
  const isStartPmEndAm = (startTime, endTime) => {
    const startHour = parseInt(startTime.split(":")[0]);
    const endHour = parseInt(endTime.split(":")[0]);

    // Check if the start time is in PM (12:00 PM - 11:59 PM) and end time is in AM (12:00 AM - 11:59 AM)
    if (startHour >= 12 && startHour <= 23 && endHour >= 0 && endHour <= 11) {
      return true;
    }

    return false;
  };
  function sendEmailToClient(
    clientEmail,
    message,
    userName,
    clientName,
    title
  ) {
    const templateParams = {
      from_name: userName,
      to_name: clientName,
      to_email: clientEmail,
      message: message,
      meeting_title: title,
    };

    emailjs
      .send(
        "service_i3d9tym",
        "template_0xfg8dw",
        templateParams,
        "YVWLSVJUruJde6JzU",
        { headers: { contentType: "application/json" } }
      )
      .then((result) => {
        toast.success("Email sent successfully");
      })
      .catch((error) => {
        toast.error("Email sending failed");
      });
  }
  const scheduleHandler = async () => {
    if (!meetingTitle && !guest) {
      toast.error("Enter meeting details");
    } else if (!doctor) {
      toast.error("Enter doctor email");
    } else if (!meetingTitle) {
      toast.error("Enter meeting title");
    } else if (meetingTitle.length > 60) {
      toast.error("Please enter a shorter title");
    } else if (!meetingDate || !sTime || !eTime) {
      toast.error("Enter meeting time");
    } else if (meetingDate < getDateOnly(new Date())) {
      toast.error("Invalid date");
    } else if (isStartPmEndAm(sTime, eTime)) {
      toast.error("Please Select the end time before 12 AM");
    } else if (
      meetingDate == getDateOnly(getIso()) &&
      sTime < getTimeOnly(getIso())
    ) {
      toast.error("Start time should be greater than current time");
    } else if (!TimeDifference()) {
      toast.error("Meeting should be of atleast 30 minutes");
    } else if (maxTimeDifference()) {
      toast.error("Meeting cannot be of more than 3 hours");
    } else if (eTime < sTime) {
      toast.error("End time cannot be before start time");
    } else if (checkScheduling(meetingDate)) {
      toast.error("Another meeting already exists at this day & time");
    } else if (checkDoctor(doctor, meetingDate)) {
      toast.error("Meeting with Doctor is already scheduled");
    } else {
      if (validateEmail(guest)) {
        const arr = [];
        doc.forEach((d) => {
          arr.push(d.document_id);
        });

        const data = {
          token: token,
          topic: meetingTitle,
          start_time: `${meetingDate + "T" + sTime}`,
        };
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          token: data.token,
          topic: data.topic,
          start_time: data.start_time,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };
        fetch(`${baseUrl}create_zoom_meeting/`, requestOptions)
          .then((response) => response.text())
          .then((response) => {
            const data = JSON.parse(response);
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const schedule_meeting_data = {
              title: meetingTitle,
              user_id: currentUser.user_id,
              team_id: 1,
              doctor_id: doctor,
              doctor_email: guest,
              meeting_id: data.meeting_id,
              meeting_passcode: "",
              start_time: `${meetingDate + " " + sTime}`,
              end_time: `${meetingDate + " " + eTime}`,
              notification_minutes: 0,
              meeting_link: `https://us06web.zoom.us/j/${data.meeting_id}`,
              email: "string",
              description: "Unknown",
              document_list: arr,
              med_id: 1,
              zak: data.zak,
              timezone: timeZone,
            };
            createMeeting(schedule_meeting_data)
              .then((res) => {
                const meetingData = res.data;
                getAllMeetings(id);
                getScheduledMeetings();
                toast.success("Creating Meeting...");
                if (selectedOption) {
                  const text = `
                  Dear ${meetingData.doctor_name} ,
                 
                  Thank you for agreeing to meet with me. I hope you found our earlier discussion helpful and informative. I have scheduled our meeting today.
                 
                  Talk to you soon 🤗.
                 
                  If you have any queries, please do feel free to contact me.
                 
                  Sincerely,
                 
                  ${currentUser.user_name}
                 
                  AMGEN Pharma
                 
                  
                  Date & Time:${getDateOnly(
                    meetingData.start_time
                  )}  at ${getTimeOnly(
                    meetingData.start_time
                  )}  - ${getTimeOnly(meetingData.end_time)} 
                  
                  Meeting ID: ${data.meeting_id}
                  
                  Passcode: fAk9RX
                  
                  Zoom Link: https://us06web.zoom.us/j/${data.meeting_id}
                  
                  `;
                  sendEmailToClient(
                    meetingData.doctor_email,
                    text,
                    currentUser.user_name,
                    meetingData.doctor_name,
                    meetingData.title
                  );
                }
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .then((res) => {})
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setScheduled((prev) => !prev);
            setMeetingModalOpen(!setMeetingModalOpen);
          });
      } else {
        toast.error("Invalid Doctor Detail");
      }
    }
  };

  React.useEffect(() => {
    setMeetingTitle("");
    setProspect("");
    setSTime("");
    setDoctor("");
    setDoc([]);
    setETime("");
    if (!token) {
      getZoomToken()
        .then((res) => {
          setToken(res);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [meetingModalOpen]);
  const handleClickAway = (e) => {
    e.stopPropagation();
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    borderRadius: "12px",
    background: "var(--theme-dark-0, #222224)",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "95vh",
    p: 4,
    overflowY: "scroll",
  };
  
const theme = createTheme({
  palette: {
    mode: "dark"
  }
});
  return (
    <ThemeProvider theme={theme}>
    <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
      <Modal
        open={meetingModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div className={styles.headerContainer}>
            <div className={styles.dialogueTitle}>Schedule a new meeting</div>
            <img
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              src={closeIcon}
              alt="close icon"
            />
          </div>
          <div style={{ padding: "40px" }}>
            <div className={styles.sectionHeader}>Meeting Info</div>
            <ScheduleMeetingForm
              setTitle={setMeetingTitle}
              setProspect={setProspect}
              setMeetingDate={setMeetingDate}
              setSTime={setSTime}
              setETime={setETime}
              setAlertTime={setAlertTime}
              setDoctor={setDoctor}
              setProductTemp={setProductTemp}
              setDoc={setDoc}
              guest={guest}
              productTemp={productTemp}
              doc={doc}
              userid={id}
            />
          </div>
          <div className={styles.buttonContainer}>
            <McqQuestion
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              question={mcqQuestion}
              options={mcqOptions}
            />
            <div style={{ display: "flex", gap: "15px" }}>
              <button onClick={handleClose} className={styles.cancelButton}>
                Cancel
              </button>
              <button onClick={scheduleHandler} className={styles.createButton}>
                Create Meeting
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </ClickAwayListener>
    </ThemeProvider>
  );
};

export default ScheduleMeetingModal;

ScheduleMeetingModal.defaultProps = {
  getAllMeetings: () => {},
  meetings: "",
  scheduledMeetings: [],
  setAllScheduledMeetings: () => {},
};
