import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import styles from "./searchfield.module.css";

const SearchField = ({ width, placeholder, value, setValue }) => {
  return (
    <Paper
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: width,
        backgroundColor: "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
        border: "none",
        boxShadow: "none",
        borderRadius: "12px",
        height: "45px",
        color: "rgba(195, 194, 197, 1) !important",
      }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon sx={{ color: "rgba(195, 194, 197, 1)" }} />
      </IconButton>
      <InputBase
        sx={{
          ml: 0,
          flex: 1,
          fontSize: "14px",
          fontFamily: "Satoshi-Variable",
          "& input::placeholder": {
            color: "rgba(195, 194, 197, 1) !important", // Replace with the color you want
          },
          "& input": {
            color: "rgba(195, 194, 197, 1) !important", // Replace with the color you want
          },
        }}
        placeholder={placeholder}
        inputProps={{ "aria-label": "search google maps" }}
        value={value ? value : ""}
        onChange={(e) => setValue(e.target.value)}
      />

      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        {value ? (
          <>
            <ClearOutlinedIcon onClick={() => setValue("")} />
          </>
        ) : (
          <></>
        )}
      </IconButton>
    </Paper>
  );
};

export default SearchField;
