import React, { useEffect } from "react";
import LeaderBoard from "Components/NewDashboard/LeaderBoard/LeaderBoard";
import CompanyCard from "Pages/ManagersFlow/Components/CompanyCard/CompanyCard";
import UserCard from "Pages/ManagersFlow/Components/UserCard/UserCard";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useDispatch } from "react-redux";
import capsuleIcon from "../../Assets/capsuleIcon.svg";
import styles from "./home.module.css";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";
import ColoredCircle from "Components/Common/ColoredCircle/ColorCircle";
import BarChartGraph from "Components/NewDashboard/BarChart/BarChart";
import SalesRepLeaderBoard from "Components/NewDashboard/SalesrepLeaderBoard/SalesRepLeaderBoard";

const DirectorHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentRoute(0));
  }, []);
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.menuSection}>
        <UserCard subTitle={"Director"} />
        <CompanyCard />
        <LeaderBoard isManager={true} />
      </div>
      <div className={styles.settingSection}>
        <div className={styles.sectionHeaderContainer}>
          <div className={styles.sectionHeader}>Overview</div>
          <div className={styles.dateContainer}>
            <div className={styles.dateText}>Oct 01 - Oct 24</div>
            <div className={styles.dateSubtitle}>Monthly</div>
          </div>
        </div>
        <div className={styles.statisticsInnerContainer}>
          <div className={styles.stats}>
            <h3>Average Affect Score</h3>
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <DonutChartSmall
                width={"70%"}
                value={43}
                x={40}
                cutout={"90%"}
                fontSize={"22px"}
                y={-3.3}
                barColor={barBg(43)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(238, 104, 128, 1)",
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              8 Reps under 40% average success score
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Engagements</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <div className={styles.statsValueNumber}>450</div>
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(255, 255, 255, 0.50)",
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              More than 2000 calls with over 50% success score
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Top Performing REP</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <div className={styles.statsValue}>Kevin Booker</div>
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(255, 255, 255, 0.80)",
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              32 Engagments
              <br />
              <span style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}>
                {" "}
                {">"} 50% Success Score
              </span>
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Least Performing REP</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <div className={styles.statsValue}>Ross Barad Jr.</div>
              <ArrowDownwardRounded
                fontSize="small"
                style={{
                  color: "#E51A3F",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#E51A3F3D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "#EE6880",
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              12 Engagments
              <br />
              <span style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}>
                {" "}
                {">"} 30% Success Score
              </span>
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Top Product</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <div className={styles.statsValue}>Paracetamol MD</div>
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <InfoCard
              icon={capsuleIcon}
              title={"68%"}
              subTitle={"Avg Success Score"}
            />
          </div>
          <div className={styles.stats}>
            <h3>Low Performing Product</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <div className={styles.statsValue}>Zinco WIT C302</div>
              <ArrowDownwardRounded
                fontSize="small"
                style={{
                  color: "#E51A3F",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#E51A3F3D",
                }}
              />
            </div>
            <InfoCard
              icon={capsuleIcon}
              title={"68%"}
              subTitle={"Avg Success Score"}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "2vw" }}>
          <div style={{ width: "50%" }}>
            <div className={styles.sectionHeaderContainer}>
              <div className={styles.sectionHeader}>Teams Performance</div>
              <div className={styles.dateContainer}>
                <div className={styles.dateText}>Oct 01 - Oct 24</div>
                <div className={styles.dateSubtitle}>Monthly</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1vh",
              }}
            >
              {/* <LineChartWithArea /> */}
              <BarChartGraph isSingle={false} />
              {/* <NewAnalytics
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            dates={dates}
          /> */}
              <div className={styles.statGraphContainer}>
                <div className={styles.statGraphHeading}>
                  Teams vs Success Score vs Engagments
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={styles.statGraphValue}>Starlight Team</div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.statGraphSubtitle}>
                  Gain in success score over regular calls
                </div>
              </div>
            </div>
            <div className={styles.graphFooter}>
              <div style={{ display: "flex", gap: "8px" }}>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#9747FF"} /> Score
                </div>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Engagement
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div className={styles.sectionHeaderContainer}>
              <div className={styles.sectionHeader}>Sales Projection</div>
              <div className={styles.dateContainer}>
                <div className={styles.dateText}>Oct 01 - Oct 24</div>
                <div className={styles.dateSubtitle}>Monthly</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1vh",
              }}
            >
              {/* <LineChartWithArea /> */}
              <BarChartGraph isSingle={false} />
              {/* <NewAnalytics
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            dates={dates}
          /> */}
              <div className={styles.statGraphContainer}>
                <div className={styles.statGraphHeading}>
                  Teams vs Sales projection
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={styles.statGraphValue}>$ 130,000 </div>
                  <ArrowUpwardRounded
                    fontSize="small"
                    style={{
                      color: "#28CA90",
                      borderRadius: "20px",
                      marginLeft: "5px",
                      padding: "1px",
                      background: "#28CA903D",
                    }}
                  />
                </div>
                <div className={styles.statGraphSubtitle}>
                  Total sales projected this month
                </div>
              </div>
            </div>
            <div className={styles.graphFooter}>
              <div style={{ display: "flex", gap: "8px" }}>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#9747FF"} /> Score
                </div>
                <div className={styles.footerText}>
                  <ColoredCircle color={"#597EF7"} />
                  Engagement
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", gap: "2vw", marginTop: "4vh" }}>
          <div style={{ width: "50%" }}>
            <div className={styles.sectionHeaderContainer}>
              <div
                style={{ marginBottom: "3vh" }}
                className={styles.sectionHeader}
              >
                Product Leaderboard
              </div>
            </div>
            <SalesRepLeaderBoard />
          </div>
          <div style={{ width: "50%" }}>
            <div className={styles.sectionHeaderContainer}>
              <div
                style={{ marginBottom: "3vh" }}
                className={styles.sectionHeader}
              >
                Teams Leaderboard
              </div>
            </div>
            <SalesRepLeaderBoard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorHome;
