import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ContactCard from "Components/Common/ContactCard/ContactCard";
import SearchField from "Components/Common/SearchField/SearchField";
import DetailsCardInteraction from "Components/Common/DetailsCard/DetailsCardInteraction";
import CalledCard from "Components/Common/DetailsCard/CalledCard";
import AddNewInteraction from "./AddInteraction/AddNewInteraction";
import { useSelector } from "react-redux";
import {
  getCallInteractions,
  getInteraction,
  getScheduledInteraction,
} from "Api/Backend/backend";
import ScheduleMeetingModal from "Components/SalesRep/ScheduleMeetingForm/ScheduleMeetingModal";
import moment from "moment";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useDispatch } from "react-redux";
import EmptyProspects from "./EmptyProspects";
import filterIcon from "../../../Assets/filter_icon.svg";
import splitIcon from "../../../Assets/split_screen_icon.svg";
import styles from "./Prospects.module.css";

const Prospects = () => {
  let navigate = useNavigate();
  const user = useSelector((state) => state.setUser.user);
  const userImage = user.user_image;
  const userId = user.user_id;
  const [meetingScheduleOpen, setMeetingScheduleOpen] = useState(false);
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [interactions, setInteractions] = useState([]);
  const [scheduledInteractions, setScheduledInteractions] = useState([]);
  const [secondInteractions, setSecondInteractions] = useState([]);
  const [thirdInteractions, setThirdInteractions] = useState([]);
  const [fourthInteractions, setFourthInteractions] = useState([]);
  const [fifthInteractions, setFifthInteractions] = useState([]);
  const [sixthInteractions, setSixthInteractions] = useState([]);
  const [seventhInteractions, setSeventhInteractions] = useState([]);
  const [eighthInteractions, setEighthInteractions] = useState([]);
  const [ninthInteractions, setNinthInteractions] = useState([]);
  const [scheduled, setScheduled] = useState(false);

  const onRowClick = (id) => {
    navigate(`/prospects/${id}`);
  };
  function getCurrentTime() {
    const date = new Date();
    const currentTime = date.toLocaleString();
    return currentTime;
  }
  function getTimeOnly(dateString) {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();
    return time;
  }
  function getDateOn() {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    return formattedDate;
  }
  const getInteractions = (userId) => {
    const time = getCurrentTime();
    const todayTime = getTimeOnly(time);
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const response = getInteraction(userId)
      .then((res) => {
        setInteractions(res);
      })
      .catch((e) => {
        console.log(e);
      });
    getScheduledInteraction(userId, current_time)
      .then((res) => {
        setScheduledInteractions(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getNCall = (userId) => {
    let i = 2;
    while (i < 10) {
      if (i == 2) {
        getCallInteractions(userId, i)
          .then((res) => {
            setSecondInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (i == 3) {
        getCallInteractions(userId, i)
          .then((res) => {
            setThirdInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (i == 4) {
        getCallInteractions(userId, i)
          .then((res) => {
            setFourthInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (i == 5) {
        getCallInteractions(userId, i)
          .then((res) => {
            setFifthInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (i == 6) {
        getCallInteractions(userId, i)
          .then((res) => {
            setSixthInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (i == 7) {
        getCallInteractions(userId, i)
          .then((res) => {
            setSeventhInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (i == 8) {
        getCallInteractions(userId, i)
          .then((res) => {
            setEighthInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (i == 9) {
        getCallInteractions(userId, i)
          .then((res) => {
            setNinthInteractions(res);
          })
          .catch((e) => {
            console.log(e);
          });
      }
      i++;
    }
  };
  const tabOptions = [
    { title: "My Contacts", value: "6", selected: "contact" },
    // { title: "Library", value: "10", selected: "library" },
  ];

  const [selected, setSelected] = React.useState(tabOptions[0].selected);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentRoute(3));
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      getInteractions(user.user_id);
      getNCall(user.user_id);
    }
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInteractions, setFilteredInteractions] = useState([]);
  const [filteredScheduledInteractions, setFilteredScheduledInteractions] =
    useState([]);
  const [filteredSecondInteractions, setFilteredSecondInteractions] = useState(
    []
  );
  const [filteredThirdInteractions, setFilteredThirdInteractions] = useState(
    []
  );
  const [filteredFourthInteractions, setFilteredFourthInteractions] = useState(
    []
  );
  const [filteredFifthInteractions, setFilteredFifthInteractions] = useState(
    []
  );
  const [filteredSixthInteractions, setFilteredSixthInteractions] = useState(
    []
  );
  const [filteredSeventhInteractions, setFilteredSeventhInteractions] =
    useState([]);
  const [filteredEigthInteractions, setFilteredEigthInteractions] = useState(
    []
  );
  const [filteredNinthInteractions, setFilteredNinthInteractions] = useState(
    []
  );

  useEffect(() => {
    // Function to filter interactions based on search term
    const filterInteractions = () => {
      const filteredData = interactions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.speciality.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredInteractions(filteredData);
    };

    filterInteractions();
  }, [interactions, searchTerm]);
  useEffect(() => {
    // Function to filter interactions based on search term
    // if(scheduledInteractions>0){
    const filterInteractions = () => {
      const filteredData = scheduledInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.doctor_speciality.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });
      setFilteredScheduledInteractions(filteredData);
    };

    filterInteractions();
    // };
  }, [scheduledInteractions, searchTerm]);
  useEffect(() => {
    // if (secondInteractions>0){

    // Function to filter interactions based on search term
    const filterInteractions = () => {
      const filteredData = secondInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredSecondInteractions(filteredData);
    };

    filterInteractions();
  }, [secondInteractions, searchTerm]);
  useEffect(() => {
    // Function to filter interactions based on search term
    const filterInteractions = () => {
      const filteredData = thirdInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredThirdInteractions(filteredData);
    };

    filterInteractions();
  }, [thirdInteractions, searchTerm]);
  useEffect(() => {
    // Function to filter interactions based on search term
    // if (fourthInteractions>0){

    const filterInteractions = () => {
      const filteredData = fourthInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredFourthInteractions(filteredData);
    };

    filterInteractions();
  }, [fourthInteractions, searchTerm]);
  useEffect(() => {
    // Function to filter interactions based on search term
    // if (fifthInteractions>0){

    const filterInteractions = () => {
      const filteredData = fifthInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredFifthInteractions(filteredData);
    };

    filterInteractions();
    // }
  }, [fifthInteractions, searchTerm]);
  useEffect(() => {
    // Function to filter interactions based on search term
    // if (sixthInteractions>0){

    const filterInteractions = () => {
      const filteredData = sixthInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredSixthInteractions(filteredData);
    };

    filterInteractions();
    // }
  }, [sixthInteractions, searchTerm]);
  useEffect(() => {
    // Function to filter interactions based on search term
    // if (seventhInteractions>0){

    const filterInteractions = () => {
      const filteredData = seventhInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredSeventhInteractions(filteredData);
    };

    filterInteractions();
    // }
  }, [seventhInteractions, searchTerm]);

  useEffect(() => {
    // Function to filter interactions based on search term
    // if (eighthInteractions > 0) {
    const filterInteractions = () => {
      const filteredData = eighthInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredEigthInteractions(filteredData);
    };

    filterInteractions();
    // }
  }, [eighthInteractions, searchTerm]);
  useEffect(() => {
    // Function to filter interactions based on search term
    // if (eighthInteractions > 0) {
    const filterInteractions = () => {
      const filteredData = ninthInteractions.filter((interaction) => {
        const name = interaction.doctor_name.toLowerCase();
        const title = interaction.title.toLowerCase();
        const searchValue = searchTerm.toLowerCase();

        return name.includes(searchValue) || title.includes(searchValue);
      });

      setFilteredNinthInteractions(filteredData);
    };

    filterInteractions();
    // }
  }, [ninthInteractions, searchTerm]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fluid maxWidth={true}>
        <Container maxWidth={true} className={styles.secondaryContainer}>
          <Container maxWidth={true} className={styles.innerContainer}>
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 0 20px",
                gap: "10px",
              }}
            >
              <SearchField
                width={"30%"}
                placeholder="Search recordings via doctor, medicine and more"
                value={searchTerm}
                setValue={handleSearch}
              />
              {/*<div style={{ display: "flex" }}>
                <img src={filterIcon} />
                <div className={styles.filterText}>All Time</div>
              </div>
              <div style={{ display: "flex" }}>
                <img src={splitIcon} />
                <div className={styles.filterText}>Split View</div>
            </div>*/}
              <button
                onClick={() => navigate("/all-interactions")}
                className={styles.deleteButton}
              >
                All Interactions
              </button>{" "}
              <button
                onClick={() => setMeetingModalOpen(true)}
                className={styles.myTaskButton}
              >
                <AddIcon />
                New Interaction
              </button>
            </div>
            <Container maxWidth={true} className={styles.secondInnerContainer}>
              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={interactions.length}
                  contactTitle={"Qualified Contacts"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {filteredInteractions.length > 0 ? (
                    filteredInteractions.map((qualifiedInteraction) => (
                      <>
                        <DetailsCardInteraction
                          meetingModalOpen={meetingScheduleOpen}
                          setMeetingModalOpen={setMeetingScheduleOpen}
                          width={"95%"}
                          status={"contacts"}
                          name={qualifiedInteraction.doctor_name}
                          clinic={qualifiedInteraction.clinic_name}
                          title={qualifiedInteraction.speciality}
                          imageSrc={qualifiedInteraction.doctor_picture}
                        ></DetailsCardInteraction>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={scheduledInteractions.length}
                  contactTitle={"Calls Scheduled"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {scheduledInteractions.length > 0 ? (
                    filteredScheduledInteractions.map(
                      (qualifiedInteraction) => (
                        <>
                          <DetailsCardInteraction
                            width={"95%"}
                            status={"scheduled"}
                            doctorId={qualifiedInteraction.doctor_id}
                            date={qualifiedInteraction.date}
                            times={qualifiedInteraction.times}
                            name={qualifiedInteraction.doctor_name}
                            clinic={qualifiedInteraction.doctor_clinic}
                            title={qualifiedInteraction.doctor_speciality}
                            imageSrc={qualifiedInteraction.doctor_image}
                          />
                        </>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={secondInteractions.length}
                  contactTitle={"2nd Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {secondInteractions.length > 0 ? (
                    filteredSecondInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={thirdInteractions.length}
                  contactTitle={"3rd Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {thirdInteractions.length > 0 ? (
                    filteredThirdInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={fourthInteractions.length}
                  contactTitle={"4th Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {fourthInteractions.length > 0 ? (
                    filteredFourthInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          doctorName={contact.doctor_name}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={fifthInteractions.length}
                  contactTitle={"5th Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {fifthInteractions.length > 0 ? (
                    filteredFifthInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={sixthInteractions.length}
                  contactTitle={"6th Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {sixthInteractions.length > 0 ? (
                    filteredSixthInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={seventhInteractions.length}
                  contactTitle={"7th Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {seventhInteractions.length > 0 ? (
                    filteredSeventhInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={eighthInteractions.length}
                  contactTitle={"8th Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {eighthInteractions.length > 0 ? (
                    filteredEigthInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "12px",
                  background:
                    "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                  padding: "20px 10px",
                }}
              >
                <ContactCard
                  numberOfContacts={ninthInteractions.length}
                  contactTitle={"9th Call"}
                />
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "340px",
                    minHeight: "800px",
                    maxHeight: "950px",
                    overflowY: "scroll",
                  }}
                >
                  {ninthInteractions.length > 0 ? (
                    filteredNinthInteractions.map((contact) => (
                      <>
                        <CalledCard
                          successScore={contact.success_score}
                          meetingLength={contact.duration}
                          title={contact.title}
                          avgScore={Math.round(contact.average_success_score)}
                          imageSrc={contact.doctor_image}
                          meetingTime={`${contact.date}. ${contact.times}`}
                          doctorName={contact.doctor_name}
                          doctorId={contact.doctor_id}
                          drSpeciality={contact.doctor_speciality}
                        ></CalledCard>
                      </>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "800px",
                      }}
                    >
                      <EmptyProspects title={"Empty Board"} />
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </Container>
        </Container>
      </Container>
      <AddNewInteraction
        getInteraction={getInteractions}
        userId={userId}
        setMeetingModalOpen={setMeetingModalOpen}
        meetingModalOpen={meetingModalOpen}
      />
      <ScheduleMeetingModal
        setScheduled={setScheduled}
        meetingModalOpen={meetingScheduleOpen}
        setMeetingModalOpen={setMeetingScheduleOpen}
        id={userId}
      />
    </React.Fragment>
  );
};

export default Prospects;
