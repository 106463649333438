import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const CustomMenu = ({ icon }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="custom-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <img src={icon} />
      </IconButton>
      <Menu
        id="custom-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            borderRadius: "8px",
            // width: "vw",
            background: "var(--dark-surface-1, #141415)",
            boxShadow: "0px 16px 40px 0px rgba(0, 0, 0, 0.08)",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "8px",
          }}
        >
          <MenuItem
            style={{
              color: "white",
              paddingLeft: "20px",
              marginTop: "10px",
            }}
            onClick={handleCloseMenu}
          >
            <EditIcon
              fontSize="small"
              sx={{ marginRight: 1, color: "white" }}
            />
            Edit
          </MenuItem>
          <MenuItem
            style={{ color: "red", padding: "0px 20px 0px", marginTop: "10px" }}
            onClick={handleCloseMenu}
          >
            <DeleteIcon
              fontSize="small"
              sx={{ color: "red", marginRight: 1 }}
            />
            Delete
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
};

export default CustomMenu;
