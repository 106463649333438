import React from "react";
import styles from "./card.module.css";
import { Typography, Box } from "@mui/material";
import ImageComponent from "../ImageComponent/ImageComponent";
import drugIcon from "../../Assets/drugIcon.svg";
import sideEffectIcon from "../../Assets/sideEffectIcon.svg";
import checkedDrug from "../../Assets/checkedDrug.svg";
import dosageIcon from "../../Assets/dosageIcon.svg";
const ProductCard = ({ type }) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.titleContainer}>
        {type === 1 ? (
          <>
            <ImageComponent src={dosageIcon} />
            <Typography className={styles.timeStamp}>Dosage and use</Typography>
          </>
        ) : type === 2 ? (
          <>
            <ImageComponent src={sideEffectIcon} />
            <Typography className={styles.timeStamp}>Side Effect</Typography>
          </>
        ) : (
          <>
            <ImageComponent src={drugIcon} />
            <Typography className={styles.timeStamp}>
              Drug Interaction
            </Typography>
          </>
        )}
      </Box>
      <Typography className={styles.description}>
        960mg orally once daily. Client is showing low attention for past 20
        seconds. Try to engage a little more or maybe mention{" "}
      </Typography>
    </Box>
  );
};
ProductCard.defaultProps = {
  type: 1,
};

export default ProductCard;
