import { createColumnHelper } from "@tanstack/react-table";
import GenericListItem from "Components/Common/GenericListItem/GenericListItem";
import { Box } from "@mui/system";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";
import { Container, Typography } from "@mui/material";
import styles from '../ClientProfile.module.css'

const columnHelper = createColumnHelper();

const columnsDef = [
  columnHelper.accessor("name", {
    header: () => "Title",
    cell: (info) => (
      <Typography
        fontSize={16}
        fontWeight={500}
        className={styles.firstTd}
      >
        Meeting for Paracetamol
      </Typography>
    ),
  }),
  columnHelper.accessor("total_meetings", {
    header: () => "Client",
    cell: (info) => (
      <Container sx={{ width: "100%", padding: "0px !important" }}>
        <GenericListItem avatar={"EG"} text={"John Doe"} variant="table" />
      </Container>
    ),
  }),
  columnHelper.accessor("total_meetings", {
    header: () => "date & time",
    cell: (info) => (
      <Container sx={{ width: "120%", padding: "0px !important", transform:'translate(-10px,0px)' }}>
        <GenericListItem text={"Oct 2, 2022"} secondaryText={"9:00 - 9:15 AM"} variant="table" />
      </Container>
    ),
  }),
  columnHelper.accessor("avg_success_score", {
    header: () => "Avg. Affect Score",
    cell: (info) => (
      <Box
        sx={{
          height: "25px",
          width: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HalfDonutChartSmall value={info.renderValue()} />
        <Typography>{info.renderValue() + `%`}</Typography>
      </Box>
    ),
  }),
  columnHelper.accessor("top_mood_induced", {
    header: () => "feedback received",
    cell: (info) => <Typography sx={{paddingRight:'35px'}}>{info.renderValue()}</Typography>,
  }),
];

export default columnsDef;
