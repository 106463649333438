import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
} from "recharts";
import styles from "./donutchart.module.css";
;

const DynamicLineGraph = ({ value,inMeeting }) => {
  const [data, setData] = useState([{ time: 0, value: value }]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [incrementing, setIncrementing] = useState(true);
  const [chartWidth, setChartWidth] = useState(350);

  useEffect(() => {
    const interval = setInterval(() => {
      const newElapsedTime = elapsedTime + 5; // Increment elapsed time by 5000 milliseconds (5 seconds)
      const newTime = data[data.length - 1].time + newElapsedTime; // Calculate new time based on elapsed time
      let newValue;
      if (incrementing) {
        newValue = value;
      } else {
        newValue = value;
      }
      if (newValue > 100) {
        setIncrementing(!incrementing);
        return;
      }
      setData((prevData) => [...prevData, { time: newTime, value: newValue }]);
      setElapsedTime(newElapsedTime); // Update elapsed time
    }, 5000);

    return () => clearInterval(interval);
  }, [data, elapsedTime]); // Update interval when data or elapsedTime changes

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      const newWidth = windowWidth * 0.25; // Adjust this ratio as needed
      setChartWidth(newWidth);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div style={{ width: "100%", maxWidth: "400px" }}>
        <Typography className={styles.title}>MEETING TIMELINE</Typography>
        <LineChart
          width={chartWidth}
          height={100}
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <XAxis
            dataKey="time"
            type="number"
            tick={{ fontSize: 12 }}
            domain={["dataMin", "dataMax"]}
            tickFormatter={(time) => `${time}.0 sec`} // Convert time to minutes
            stroke="grey"
          />
          <CartesianGrid
            vertical={false}
            stroke="grey"
            strokeDasharray="3 3"
          />
          <Line type="monotone" dataKey="value" stroke="grey" />
        </LineChart>
      </div>
    </>
  );
};

export default DynamicLineGraph;
