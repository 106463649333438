import React from "react";
import TrainingPageStructure from "../TrainingPageStructure/TrainingPageStructure";
import ModuleDetails from "Pages/BehaviouralTraining/Components/ModuleDetails/ModuleDetails";
import RetroMaster from "Pages/BehaviouralTraining/Components/RetroMaster/RetroMaster";
import { Typography } from "@mui/material";
import quizIcon from "../../Assets/quizIcon.svg";
import SalesBadge from "Pages/BehaviouralTraining/Components/SalesBadge/SalesBadge";
import clockIcon from "../../Assets/clockIcon.svg";
import styles from "./quiz.module.css";
const QuizModule = () => {
  return (
    <TrainingPageStructure>
      <div className={styles.mainContainer}>
        <div className={styles.leftSideBar}>
          <div className={styles.flexColumn}>
            <ModuleDetails />
            <RetroMaster />
            <RetroMaster
              totalModules={[1, 243, 4]}
              title={"Deep Breaths and Articulation"}
            />
          </div>
        </div>
        <div className={styles.mainPage}>
          <div className={styles.quizContainer}>
            <Typography className={styles.moduleTitle}>
              Nature’s Reset Vol 1
            </Typography>
            <div className={styles.flexRow}>
              <div
                style={{
                  width: "8%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <img style={{ textAlign: "left" }} src={quizIcon} />
              </div>
              <div className={styles.quizHeading}>
                Quiz: How to reset during a call
              </div>
            </div>
            <div className={styles.flexBetween}>
              <div style={{ display: "flex", gap: "8px" }}>
                <SalesBadge isScore={false} title={"12 Questions"} />
                <SalesBadge
                  badge={clockIcon}
                  isScore={false}
                  title={"30 min"}
                />
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <div className={styles.criteriaText}>Required to pass</div>
                <div className={styles.criteriaTextOp}> 70</div>
              </div>
            </div>
            <div className={styles.subtitle}>Quiz Brief</div>
            <div className={styles.description}>
              This is the Client's guide to the Webflow Editor. Share these
              lessons to get your clients up and running.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Quisque sollicitudin leo in
              massa hendrerit venenatis.{" "}
            </div>
          </div>
        </div>
      </div>
    </TrainingPageStructure>
  );
};

export default QuizModule;
