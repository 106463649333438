import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware } from "redux";
import ThunkMiddleware from "redux-thunk/es";
import setUserReducer from "./features/setUserSlice";
import scheduleMeetingReducer from "./features/scheduleMeetingSlice";
import meetingApiReducer from "./features/meetingApiSlice";
import endMeetingStatsReducer from "./features/endMeetingStats";
import currentRouteReducer from "./features/currentRoute";
import getMeetingsReducer from "./features/getMeetings";

const composedEnhancer = composeWithDevTools(applyMiddleware(ThunkMiddleware));

const store = configureStore({
  reducer: {
    setUser: setUserReducer,
    scheduleMeeting: scheduleMeetingReducer,
    meetingApi: meetingApiReducer,
    endMeetingStats: endMeetingStatsReducer,
    setMeetings: getMeetingsReducer,
    currentRoute: currentRouteReducer,
  },
  composedEnhancer,
});

export default store;
