import React, { useEffect, useState } from "react";
import styles from "./ScheduleMeetingForm.module.css";
import { Autocomplete, Box, Chip, Typography } from "@mui/material";
import InputField from "Components/Common/InputField/InputField";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DropDownButton from "Components/Common/DropDownButton/DropDownButton";
import { KeyboardArrowDown } from "@mui/icons-material";
import DoubleUserIcon from "../../../Assets/doubleUserIconGray.svg";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import dayjs from "dayjs";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import { GetDocs, GetDoctors } from "Api/Backend/backend";

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const minuteOptions = ["00", "15", "30", "45"];

const alertOption = [
  { id: "0", value: "10 minutes before" },
  { id: "1", value: "15 minutes before" },
  { id: "2", value: "30 minutes before" },
];
const callPlatform = [{ id: "0", value: "Zoom" }];

const ScheduleMeetingForm = ({
  setTitle,
  setProspect,
  setMeetingDate,
  setSTime,
  setETime,
  setAlertTime,
  guest,
  setGuest,
  setDoctor,
  setProductTemp,
  setDoc,
  doc,
  userid,
  productTemp,
}) => {
  const [openSettings, setOpenSettings] = useState(false);
  const [meetingPlatform, setMeetingPlatform] = useState("Zoom");
  const getNextHour = () => {
    const now = new Date();
    const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
    nextHour.setMinutes(0);
    nextHour.setSeconds(0);
    nextHour.setMilliseconds(0);
    return nextHour;
  };
  const [emailList, setEmailList] = useState([]);
  const [doclist, setDocList] = useState([]);

  const [selectedEmails, setSelectedEmails] = useState([]);
  const handleTimeChange = (newTime) => {
    const updatedTime = new Date(newTime);
    updatedTime.setHours(updatedTime.getHours() + 1);

    return updatedTime;
  };
  const [selectedOption, setSelectedOption] = useState("zoom");
  const [selected, setSelected] = React.useState(
    `Today, ${new Date().toLocaleDateString("en-us", options)}`
  );
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState({
    startTime: getNextHour(new Date()),
    endTime: handleTimeChange(getNextHour(new Date())),
  });

  const [alertOptionSelected, setAlertOptionSelected] = useState(
    alertOption[0].id
  );

  const [guestText, setGuestText] = useState(null);
  const [productTempText, setProductTempText] = useState(null);
  const [docText, setDocText] = useState("");
  const [docSelected, setDocSelected] = useState(false);
  const [tempSelected, setTempSelected] = useState(false);
  const [allUserIds, setAllUserIds] = useState([]);
  const [allDocIds, setAllDocIds] = useState([]);
  const [selectedDocument, setSelectedDocuments] = useState([]);

  const [optionSelect, setOptionSelect] = useState("0");
  const [meetingDate, setMeetDate] = useState("");
  const [meetingTime, setMeetingTime] = useState({
    start: "",
    end: "",
  });

  const getDoctor = async (id) => {
    const response = await GetDoctors(id)
      .then((response) => {
        setEmailList(response);
      })
      .catch((e) => console.log(e));
  };
  const getDoc = async (id) => {
    const response = await GetDocs(id)
      .then((response) => {
        setDocList(response);
      })
      .catch((e) => console.log(e));
  };
  const handleClick = () => {
    setOpenSettings(!openSettings);
  };
  useEffect(() => {
    if (userid) {
      getDoctor(userid);
      getDoc(userid);
    }
  }, []);

  const handleRadioChange = (e) => {
    setOptionSelect(e.target.value);
  };
  function getTimeOnly(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  function getDateOnly(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    if (time.endTime && time.startTime) {
      setTime((prevState) => ({
        ...prevState,
        endTime: handleTimeChange(time.startTime),
      }));
    }
  }, [time.startTime]);
  useEffect(() => {
    if (date) {
      const data = getDateOnly(date);
      setMeetDate(data);
      setMeetingDate(data);
    }
  }, [date]);
  // useEffect(() => {
  //   setDoc([]);
  // }, []);
  useEffect(() => {
    if (time.startTime) {
      const data = getTimeOnly(time.startTime);
      setSTime(data);
    }
  }, [time.startTime]);
  useEffect(() => {
    if (time.endTime) {
      const data = getTimeOnly(time.endTime);
      setETime(data);
    }
  }, [time.endTime]);

  const handleSelectEmail = (event, value) => {
    if (value) {
      const selectedId = value.doctor_id;
      setDoctor(selectedId);
    }
  };

  const handleSelectDocument = (event, value) => {
    setDoc(value);
  };

  const controlProps = (item) => ({
    checked: optionSelect === item,
    onChange: handleRadioChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  return (
    <>
      <Box className={styles.scheduleMeetingFormContainer}>
        <Box className={styles.formRow}>
          <InputField
            placeholder="Meeting title"
            bgColor="null"
            editable={true}
            inputHeight="50"
            inputWidth="550"
            setter={setTitle}
          />
        </Box>
        <Box className={styles.formRow}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                borderRadius: "12px",
                background:
                  "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                width: "550px",
                "& .css-1x5jdmq": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-176v99k": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-ykxp8p": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-slyssw": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent", // Set the border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.50) ",
                    borderRadius: "12px", // Set the border color on focus
                  },
                },
              }}
              placeholder="Meeting Date"
              onChange={(newValue) => setDate(newValue)}
              value={dayjs(date)}
            />
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            borderBottom:
              " 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",

            paddingBottom: "20px !important",
          }}
          className={styles.formRow}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={styles.fieldIcon}>FROM</div>
            <TimePicker
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  "& .css-slyssw": {
                    color: "rgba(255, 255, 255, 0.50) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent", // Set the border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.50) ",
                    borderRadius: "12px", // Set the border color on focus
                  },
                },
                borderRadius: "12px",
                "& .css-1x5jdmq": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-ykxp8p": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                background:
                  "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",

                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                  color: "rgba(255, 255, 255, 0.50) ",
                },
              }}
              value={dayjs(time.startTime)}
              onChange={(newValue) =>
                setTime((prevState) => ({ ...prevState, startTime: newValue }))
              }
            />
            <div className={styles.fieldIcon}>TO</div>
            {time.startTime && (
              <TimePicker
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",

                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent", // Set the border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 255, 255, 0.50) ",
                      borderRadius: "12px", // Set the border color on focus
                    },
                    "&.MuiOutlinedInput-multiline": {
                      padding: 10,
                    },
                  },
                  borderRadius: "12px",
                  "& .css-slyssw": {
                    color: "rgba(255, 255, 255, 0.50) !important",
                  },
                  background:
                    "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",

                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    color: "rgba(255, 255, 255, 0.50) !important",
                  },
                  "& .css-ykxp8p": {
                    color: "rgba(255, 255, 255, 0.50) !important",
                  },
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    color: "rgba(255, 255, 255, 0.50) !important",
                  },
                  "& .css-1x5jdmq": {
                    color: "rgba(255, 255, 255, 0.50) !important",
                  },
                  "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                    color: "rgba(255, 255, 255, 0.50) ",
                  },
                }}
                value={dayjs(time.endTime)}
                onChange={(newValue) =>
                  setTime((prevState) => ({ ...prevState, endTime: newValue }))
                }
              />
            )}
          </LocalizationProvider>
        </Box>
        <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
          <div className={styles.sectionHeader}>Client Info</div>
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            padding: "13px 12px",
            background: "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",

            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              color: "rgba(255, 255, 255, 0.50) !important",
            },
            "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
              color: "rgba(255, 255, 255, 0.50) !important",
            },
            "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
              color: "rgba(255, 255, 255, 0.50) ",
            },
          }}
          className={styles.formRow}
        >
          <img alt="icon" src={DoubleUserIcon} className={styles.fieldIcon} />
          <Box sx={{ width: "100%" }}>
            {Array.isArray(emailList) && emailList.length > 0 ? (
              <Autocomplete
                multiple={false}
                sx={{ width: "100%" }}
                options={emailList}
                getOptionLabel={(option) => option.doctor_name}
                onChange={handleSelectEmail}
                renderInput={(params) => (
                  <TextField
                    focused
                    {...params}
                    variant="standard"
                    placeholder="Add Guest"
                    sx={{
                      "& input": {
                        color: "rgba(255, 255, 255, 0.50) !important",
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottom: "none !important", // Customize the hover border color here
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "none !important", // Customize the border color here
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none !important", // Customize the focus border color here
                      },

                      "& .MuiAutocomplete-endAdornment": {
                        display: "none", // Hide the dropdown icon
                      },
                    }}
                  />
                )}
                renderNoOptions={() => (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      mt: 2,
                      fontStyle: "italic",
                      color: "rgba(255, 255, 255, 0.50) !important",
                    }}
                  >
                    Add Doctors
                  </Typography>
                )}
              />
            ) : (
              <Typography
                variant="body1"
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "rgba(255, 255, 255, 0.50) !important",
                }}
              >
                No data available ! Add Doctors
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            borderTop:
              " 1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
            justifyContent: "space-between !important",
            alignItems: "center",
            marginTop: "20px",
            paddingTop: "20px",
            cursor: "pointer",
          }}
          onClick={handleClick}
          className={styles.formRow}
        >
          <div className={styles.sectionHeader}>Other Settings</div>
          {openSettings ? (
            <KeyboardArrowUpSharpIcon
              sx={{
                color: "rgba(255, 255, 255, 0.50) !important",
                borderRadius: "30px",

                background:
                  "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))",

                /* Button_shadow */
                boxShadow: "0px 8px 30px 0px rgba(74, 66, 173, 0.12)",
              }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{
                color: "rgba(255, 255, 255, 0.50) !important",
                borderRadius: "30px",

                background:
                  "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))",

                /* Button_shadow */
                boxShadow: "0px 8px 30px 0px rgba(74, 66, 173, 0.12)",
              }}
            />
          )}
        </Box>
        {openSettings && (
          <>
            <Box className={styles.formRow}>
              <DropDownButton
                Icon={VideocamOutlinedIcon}
                background={
                  "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))"
                }
                // variant={"outlined"}
                text={"Filter"}
                size="medium"
                isHome={true}
                options={callPlatform}
                setter={setMeetingPlatform}
                width="500"
              />
            </Box>
            <Box className={styles.formRow}>
              <DropDownButton
                Icon={NotificationsOutlinedIcon}
                background={
                  "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))"
                }
                // variant={"outlined"}
                text={"Filter"}
                size="medium"
                isHome={true}
                options={alertOption}
                setter={setAlertTime}
                width="500"
              />
            </Box>

            <Box
              sx={{
                borderRadius: "12px",
                padding: "8px 7px",
                background:
                  "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",

                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  color: "rgba(255, 255, 255, 0.50) !important",
                },
                "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                  color: "rgba(255, 255, 255, 0.50) ",
                },
              }}
              className={styles.formRow}
            >
              <FolderOutlinedIcon fontSize="small" sx={{ color: "#77767C" }} />

              {Array.isArray(doclist) && doclist.length > 0 ? (
                <Autocomplete
                  multiple
                  sx={{ width: "100%" }}
                  options={doclist}
                  getOptionLabel={(option) => option.document_name}
                  filterSelectedOptions
                  defaultValue={"Add Documents"}
                  onChange={handleSelectDocument}
                  value={doc}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Documents"
                      sx={{
                        "& input": {
                          color: "rgba(255, 255, 255, 0.50) !important",
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottom: "none !important", // Customize the hover border color here
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none !important", // Customize the border color here
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none !important", // Customize the focus border color here
                        },

                        "& .MuiAutocomplete-endAdornment": {
                          display: "none", // Hide the dropdown icon
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        sx={{
                          "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                            {
                              color: "rgba(255, 255, 255, 0.50) !important",
                            },
                          color: "rgba(255, 255, 255, 0.50) !important",
                        }}
                        key={index}
                        label={option.document_name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderNoOptions={() => (
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        mt: 2,
                        fontStyle: "italic",
                        color: "rgba(255, 255, 255, 0.50)",
                      }}
                    >
                      No options
                    </Typography>
                  )}
                />
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    color: "rgba(255, 255, 255, 0.50) !important",
                  }}
                >
                  {Array.isArray(doclist)
                    ? "Loading..."
                    : "No data available! Add Documents"}
                </Typography>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ScheduleMeetingForm;
