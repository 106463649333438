import React from "react";
import styles from "./NotificationSettings.module.css"
import InfoBlock from "Components/Common/InfoBlock/InfoBlock";
import { Grid } from "@mui/material";
import MyTaskIcon from "../../../Assets/myTaskIcon.svg"

const NotificationSettings = () => {
  return (
    <>
      <Grid container className={styles.settingsGrid}>
        <Grid xs={6} className={styles.gridItem}>
          <InfoBlock
            heading="Email notifications"
            description="Manage your notification settings with just one click."
            headingFontSize={20}
            headingFontWeight={700}
          />
        </Grid>
        <Grid xs={6} spacing={8} className={styles.gridItem}>
            <Grid item className={styles.item}>
          <InfoBlock
            heading="Email notifications"
            description="Recieve email notifications about your upcoming meetings, meeting analytics."
            headingFontSize={16}
            headingFontWeight={500}
            icon={MyTaskIcon}
          />
          </Grid>
          <Grid item className={styles.item}>
          <InfoBlock
            heading="Meeting analytics"
            description="Recieve notifications whenever your meetings are ready for review."
            headingFontSize={16}
            headingFontWeight={500}
            icon={MyTaskIcon}
          />
          </Grid>
          <Grid item className={styles.item}>
          <InfoBlock
            heading="Shared documents"
            description="Recieve notifications whenever someone shares any sort of documents and agenda templates with you."
            headingFontSize={16}
            headingFontWeight={500}
            icon={MyTaskIcon}
          />
          </Grid>
          <Grid item className={styles.item}>
          <InfoBlock
            heading="New updates"
            description="Recieve notifications whenever AffectCX has something new to offer to improve your AI journey."
            headingFontSize={16}
            headingFontWeight={500}
            icon={MyTaskIcon}
          />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationSettings;
