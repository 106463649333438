import axios from "axios";

//  export const baseUrl =
//    process.env.NODE_ENV ==  "development"
//      ? "https://api-test.affectcx.io/"
//      : "https://api.affectcx.io/";

export const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const userEmailSignup = async (data) => {
  const response = await axios
    .post(`${baseUrl}signup/`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response.data)
    .catch((e) => e);
};

export const verifyEmail = async (email) => {
  const response = await axios
    .post(
      `${baseUrl}update_verification_status/${email}`,
      {
        email: email,
        verification_status: "true",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => response.data)
    .catch((e) => e);
};

export const getMeetingsById = async (id) => {
  const response = await axios
    .post(`${baseUrl}get_meeting_by_user_id/?user_id=${id}`)
    .then((response) => response)
    .catch((e) => console.log(e));
};
export const getMeetingsByManager = async (userId, currentTime) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}get_all_meetings_by_manager`,
      { user_id: userId, timezone: timeZone, current_time: currentTime },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const createMeeting = async (data) => {
  const response = await axios
    .post(`${baseUrl}create_meeting/`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response)
    .catch((e) => {
      console.log(e);
      throw e;
    });
  return response;
};
export const createZoomMeeting = async (token, topic, start_time) => {
  const response = await axios
    .post(
      `${baseUrl}create_zoom_meeting/`,
      { token: token, topic: topic, start_time: start_time },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => response)
    .catch((e) => {
      console.log(e);
      throw e;
    });
};
export const getInteraction = async (userId) => {
  try {
    const response = await axios.post(
      `${baseUrl}interaction_qualified/`,
      { user_id: userId },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getScheduledInteraction = async (userId, currentTime) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}interaction_scheduled/`,
      { user_id: userId, timezone: timeZone, current_time: currentTime },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getCallInteractions = async (userId, callNumber) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}nth_interaction/`,
      { user_id: userId, timezone: timeZone, n: callNumber },
      {
        headers: {
          "Content-Type": "application/json",
          // Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getAllInteractions = async (userId) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}view_all_interactions/`,
      { user_id: userId, timezone: timeZone },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getTeamStatsByManager = async (id) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}get_team_stats_by_manager/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getUnAssignedUsers = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_unassigned_users/`,
      { med_id: id, manager_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const assignUsers = async (u_id, m_id) => {
  try {
    const response = await axios.post(
      `${baseUrl}map_user_to_medicine/`,
      { med_id: m_id, user_id: u_id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const assignMedtoTeam = async (t_id, m_id) => {
  try {
    const response = await axios.post(
      `${baseUrl}assign_medicine_to_team/`,
      { med_id: m_id, team_id: t_id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getAllTeamStatsByManager = async (id) => {
  console.log(id);
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}get_all_team_stats_by_manager/`,
      { user_id: id},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getProductStats = async (id) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}get_products_stats_by_manager/`,
      { user_id: id},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getRepStatsByManager = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_reps_stats_by_manager/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getInteractionsByManager = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_interactions_by_manager/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const addProducts = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}add_medicine/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const updateMedicine = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}update_medicine/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getAssignedUsers = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_assigned_users/`,
      { med_id: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getAssignedTeams = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}get-med-assigned-teams`,
      { med_id: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getUnAssignedTeams = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}get-med-unassigned-teams`,
      { med_id: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const assignUserToTeam = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}assign_team_to_user`,data,  
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const updateUser = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}update_user`,data,  
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const DeleteUser = async (id) => {
  try {
    const response = await axios.delete(
      `${baseUrl}delete_user/`,
      {
        data: {
          user_id: id
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};




export const getProducts = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_medicine_by_manager/`,
      { user_id: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getDoctorMeetings = async (userId) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}get_doctor_meetings/`,
      { doctor_id: userId, timezone: timeZone },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
// export const salesRepGraph = async () => {
//   try {
//     const response = await axios.post(
//       `${baseUrl}get_reps_stats_by_manager/`,
//       { user_id: 24 },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           "Access-Control-Allow-Origin": "*",
//         },
//       }
//     );
//     return response.data;
//   } catch (e) {
//     console.log(e);
//     throw e;
//   }
// };

export const addInteraction = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}add_new_interaction/`, data);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteMeetingById = async (id) => {
  const response = await axios
    .delete(`${baseUrl}delete_meeting/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response)
    .catch((e) => console.log(e));
};
export const getZoomVideo = async (id) => {
  try {
    console.log(id);
    const response = await axios.get(
      `https://meeting-documents.s3.amazonaws.com/${id}_video.mp4/`
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const postZoomVideo = async (id, access_token) => {
  try {
    // console.log(access_token, id);
    const response = await axios.post(
      `${baseUrl}upload_video_to_s3/`,
      { mid: id, token: access_token },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getTranscript = async (id, access_token) => {
  try {
    const response = await axios.post(
      `${baseUrl}zoom_transcript/`,
      { mid: id, token: access_token },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const postScoreList = async (id, data) => {
  try {
    const response = await axios.post(
      `${baseUrl}update_score_list/`,
      { mid: id, score_list: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getScoreList = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_scorelist/`,
      { mid: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const postFavouriteDocument = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}mark_favourite/?document_id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const UpdateMeetingEndtime = async (mid) => {
  const response = await axios
    .post(
      `${baseUrl}update_meeting_endtime/`,
      JSON.stringify({ mid: mid, actual_end_time: "2023-04-05T09:25:09.718Z" }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => response)
    .catch((e) => console.log(e));
};

export const recordingStats = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}recording_stats/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const recordingCards = async (id, date) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}dot_cards/`,
      { user_id: id, date: date, timezone: timeZone },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteMeeting = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}delete_meeting/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: { mid: id }, // pass data as object with key "mid" and value of id
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const get_analytics_feedback = (id) => {
  try {
    const response = axios.post(
      `${baseUrl}analytics_feedback/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const get_ttlr_by_user = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}avg_ttl_score_user/`,
      {
        user_id: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};
export const add_notes = async (id, data) => {
  try {
    const response = await axios.post(
      `${baseUrl}add_note/`,
      { mid: id, note_text: data, note_label: "False" },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const get_notes = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_all_notes_by_mid/`,
      { mid: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const get_documents_by_mid = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_all_documents_by_mid/`,
      { mid: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const singleFeedBackData = async (id) => {
  try {
    console.log(id);
    const response = await axios.post(
      `${baseUrl}get_all_feedback/`,
      { mid: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const updateUserPicture = async (data) => {
  // console.log(id, files.name);
  try {
    const response = await axios.post(
      `${baseUrl}update_user_image/`,
      data
      // {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //     "Access-Control-Allow-Origin": "*",
      //   },
      // }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const updateUserProfile = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}update_user_profile/`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const feedbackTime = async (id) => {
  try {
    console.log(id);
    const response = await axios.post(
      `${baseUrl}feedbacks_and_time/`,
      { mid: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getTopClients = async () => {
  try {
    const clients = await axios.post(`${baseUrl}top_10_clients/`);
    return clients;
  } catch (e) {
    throw e;
  }
};
export const getTopClientsByUser = async (id) => {
  try {
    const clients = await axios.post(
      `${baseUrl}top_10_clients_by_user/`,
      {
        user_id: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};

export const getTopReps = async () => {
  try {
    const clients = await axios.post(`${baseUrl}top_10_reps/`);
    return clients;
  } catch (e) {
    throw e;
  }
};
export const recordingTable = async (id) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}recording_table/`,
      { user_id: id, timezone: timeZone },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const monthlyRecord = async (id, month,year) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}day_success/`,
      { user_id: id, month: month, year: year, timezone: timeZone },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const monthlyRecordTable = async (id, month) => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post(
      `${baseUrl}dot_cards_month/`,
      { user_id: id, month: month, timezone: timeZone },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const addTask = async ({ myInteger, user_id, text }) => {
  try {
    const response = await axios.post(
      `${baseUrl}add_task/`,
      { user_id: user_id, task_text: text, mid: myInteger },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const overallSuccessCallSummary = async (mid) => {
  try {
    const response = await axios.post(
      `${baseUrl}overall_success_call_summary/`,
      { mid: mid },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const getZoomToken = async () => {
  try {
    const response = await axios.post(`${baseUrl}zoom/token/`);
    return response.data.access_token;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updatePostMeeting = async (data, mid) => {
  try {
    const response = await axios.post(
      `${baseUrl}update_post_meeting/?mid=${mid}`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const overviewByUserId = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}overview_by_userid/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const GetDoctors = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_doctors/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const GetManagers= async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_all_managers_by_admin/`,
      { user_id: id},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const GetDirectors = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_all_directors_by_admin/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const GetDocs = async (id) => {
  try {
    const response = await axios.post(
      `${baseUrl}get_all_documents/`,
      { user_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};



export const getAllUser = async (id) => {
  try {
    const clients = await axios.post(
      `${baseUrl}get_all_users_by_admin/`,
      {
        user_id: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};

export const getAdminStats = async () => {
  try {
    const clients = await axios.get(
      `${baseUrl}get-all-stats`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};


export const getTeamUser = async (id) => {
  try {
    const clients = await axios.post(
      `${baseUrl}get_team_users`,
      {
        team_id: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};
export const deleteUserFromTeam = async (id) => {
  try {
    const clients = await axios.post(
      `${baseUrl}unassign_team_to_user`,
      {
        user_id: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};


export const getAllTeams = async (payload) => {
  try {
    const clients = await axios.post(
      `${baseUrl}get_all_teams_by_admin/`,payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};

export const createTeam = async (payload) => {
  try {
    const clients = await axios.post(
      `${baseUrl}create_team/`,payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};

export const EditTeam = async (payload) => {
  try {
    const clients = await axios.post(
      `${baseUrl}update_team`,payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return clients;
  } catch (e) {
    throw e;
  }
};
export const DeleteTeam = async (id) => {
  console.log(id)
  try {
    const response = await axios.post(
      `${baseUrl}delete-team/`,
      {
        team_id: id
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
