import React from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import googleLogo from "../../../Pages/Login/Assets/googleLogo.svg";
import styles from "./button.module.css";
const Button = ({ title, primaryButton, onClick }) => {
  return (
    <>
      {primaryButton ? (
        <button onClick={onClick} className={styles.container}>
          <div>{title}</div>
          <EastOutlinedIcon fontSize="small" />
        </button>
      ) : (
        <button onClick={onClick} className={styles.secondaryContainer}>
          <img src={googleLogo} />
          <div>{title}</div>
        </button>
      )}
    </>
  );
};

Button.defaultProps = {
  title: "Continue",
  primaryButton: "true",
  onClick: () => {},
};
export default Button;
