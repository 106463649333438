import React, { useRef, useState } from "react";
import loginBackground from "./Assets/loginBackground.png";
import logo from "../../Pages/Login/Assets/Logo.svg";
import SigninTextField from "Components/NewDashboard/SigninTextField/SigninTextField";
import Button from "Components/NewDashboard/Button/Button";
import HorizontalDivider from "Components/NewDashboard/Divider/HorizontalDivider";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "Api/Firebase/config";
import { useNavigate } from "react-router";
import { setUser } from "Store/features/setUserSlice";
import { useDispatch } from "react-redux";
import { baseUrl } from "Api/Backend/backend";
import styles from "./Login.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "Pages/Misc/Loading/Loading";

const Login = () => {
  firebase.initializeApp(firebaseConfig);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const buttonRef = useRef(null);
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("email");
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const signInWithGoogle = async () => {
    try {
      const userCredential = await firebase.auth().signInWithPopup(provider);
      if (userCredential.additionalUserInfo.isNewUser) {
        await firebase
          .auth()
          .currentUser.delete()
          .then((response) => {
            toast.error("User does not exist!");
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        const user = userCredential.user;
        const user_data = user._delegate;

        const newUser = {
          firebase_id: user_data.uid,
          first_name: user_data.displayName.split(" ")[0],
          last_name: user_data.displayName.split(" ")[1],
          email: user_data.email,
          password: user_data.displayName,
          role: "typeAccount",
          organization_name: "orgName",
          organization_type: "Pharmaceutical Sales",
          user_image: "imageString",
          first_time_user: true,
          role: "sales_rep",
        };
        dispatch(setUser(newUser));
        sessionStorage.setItem("user", JSON.stringify(newUser));
        navigate("/");
      }
    } catch (error) {
      return error;
    }
  };

  const handleSignInWithEmailPassword = async () => {
    try {
      const userExists = await firebase
        .auth()
        .fetchProvidersForEmail(email)
        .then((providers) => {
          if (providers.length === 0) {
            return false;
          } else {
            return true;
          }
        });
      if (userExists) {
        const userCredential = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        const user = userCredential.user;
        return user;
      }
    } catch (error) {
      return;
    }
  };
  const handleCheckBoxChange = (e) => {
    setRememberMe(!rememberMe);
  };
  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  const handleKeyPress = (event) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (event.key === "Enter") {
      // Trigger the button click
      handleEmailPasswordForm(event);
    }
  };
  const handleEmailPasswordForm = async (e) => {
    const loginUser = await handleSignInWithEmailPassword(email, password)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      })
      .then(async (data) => {
        const { uid } = data;
        sessionStorage.setItem("firebase", uid);
        {
          rememberMe && localStorage.setItem("firebase", uid);
        }
        const getUser = await axios
          .post(
            `${baseUrl}get_user_by_firebaseid/?firebase_id=${uid}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            if (response.data) {
              dispatch(setUser(response.data));
              if (response.data.verification_status) {
                sessionStorage.setItem("user", JSON.stringify(response.data));
                {
                  rememberMe &&
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                navigate("/");
              } else {
                data.sendVerificationEmail();
                navigate("/verification");
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        toast.error("Wrong Username or password");
      });
  };
  return (
    <div>
      {loading ? (
        <>
          <Loading setLoading={setLoading} />
        </>
      ) : (
        <div
          style={{
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        >
          <div className={styles.registrationContainer}>
            <div className={styles.loginFormContainer}>
              <img src={logo} />
              <div
                style={{
                  width: "100%",
                  marginTop: "3vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "1vh",
                }}
              >
                <div className={styles.welcomeTitle}>Welcome to AffectCX</div>
                <div className={styles.subTitle}>Sign into your account </div>
              </div>
              <SigninTextField
                autoFocus={true}
                name={"email"}
                onChange={handleChange}
                value={email}
                onKeyPress={handleKeyPress}
              />
              <SigninTextField
                name={"password"}
                onChange={handleChange}
                isPassword={true}
                onKeyPress={handleKeyPress}
                label={"Password"}
              />
              <div className={styles.RememberMeContainer}>
                <div className={styles.RememberMeText}>
                  <input
                    className={styles.checkbox}
                    value={rememberMe}
                    type="checkbox"
                    onChange={handleCheckBoxChange}
                  />
                  Remember Me
                </div>
                <div
                  onClick={() => navigate("/forgot-password")}
                  className={styles.forgotText}
                >
                  Forgot Password?
                </div>
              </div>
              <Button onClick={handleEmailPasswordForm} />
              {/* <HorizontalDivider /> */}
              {/* <Button
                primaryButton={false}
                onClick={signInWithGoogle}
                title={"Sign in through google"}
              /> */}
              {/* <div className={styles.termsText}>
                By signing up, you agree to our{" "}
                <a href="#">Terms & Conditions</a> and{" "}
                <a href="#">Privacy Policy</a>.
              </div> */}
            </div>

            {/* <div className={styles.createAccount}>
              Don’t have an account?
              <b
                onClick={() => {
                  navigate("/signup"); 
                }}
                style={{ cursor: "pointer" }} 
              >
                {" "}
                Register Now
              </b>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
