import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  ae_score: 0,
  mi_score: 0,
  vi_score: 0,
  success_score: 0,
  feedbacks: [],
  mi_score_list: [],
  ae_score_list: [],
  vi_score_list: [],
};

export const endMeetingStatsSlice = createSlice({
  name: "endMeetingStats",
  initialState,
  reducers: {
    setAE: (state, action) => {
      state.ae_score = action.payload;
    },
    setMI: (state, action) => {
      state.mi_score = action.payload;
    },
    setVI: (state, action) => {
      state.vi_score = action.payload;
    },
    setSuccess: (state, action) => {
      state.success_score = action.payload;
    },
    setFeedbacks: (state, action) => {
      state.feedbacks = [...state.feedbacks, action.payload];
    },
    setMiScoreList: (state, action) => {
      state.mi_score_list = [...state.mi_score_list, action.payload];
    },
    setViScoreList: (state, action) => {
      state.vi_score_list = [...state.vi_score_list, action.payload];
    },
    setAeScoreList: (state, action) => {
      state.ae_score_list = [...state.ae_score_list, action.payload];
    },
  },
});

export const {
  setAE,
  setMI,
  setVI,
  setSuccess,
  setFeedbacks,
  setMiScoreList,
  setAeScoreList,
  setViScoreList,
} = endMeetingStatsSlice.actions;

export default endMeetingStatsSlice.reducer;
