import React from "react";
import { Typography } from "@mui/material";
import toggleIcon from "../../Assets/toggleIcon.svg";
import retroPlayIcon from "../../Assets/retroPlayIcon.svg";
import styles from "./retromaster.module.css";
const RetroMaster = ({ totalModules, title }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.flexBetween}>
        <Typography className={styles.title}>{title}</Typography>
        <img src={toggleIcon} />
      </div>
      {totalModules.map((item) => (
        <div className={styles.flexRow}>
          <img src={retroPlayIcon} />
          <Typography className={styles.moduleTitle}>
            Understanding Effect
          </Typography>
          <Typography className={styles.moduleRunTimeText}>
            12:03 Min
          </Typography>
        </div>
      ))}
    </div>
  );
};
RetroMaster.defaultProps = {
  totalModules: [1, 2, 23, 4, 5],
  title: "Retro Master",
};
export default RetroMaster;
