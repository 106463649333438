import React from "react";
import styles from "./suggestionBar.module.css";
import valueIcon from "../../Assets/valueIcon.svg";
import { Typography } from "@mui/material";

const SuggestionBar = ({ title, suggestion }) => {
  return (
    <div className={styles.container}>
      <img src={valueIcon} />
      <div>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.suggestion}>{suggestion}</Typography>
      </div>
    </div>
  );
};
SuggestionBar.defaultProps = {
  title: "Let’s change the topic",
  suggestion: "Ask about Michaels’s Trip to Europe and his time in miland",
};
export default SuggestionBar;
