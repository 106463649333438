import React from "react";
import { Outlet } from "react-router";
import academyLogo from "../Assets/academyLogo.svg";
import dummyProfile from "../Assets/dummyProfile.svg";
import { Typography } from "@mui/material";
import styles from "./header.module.css";

const Header = () => {
  return (
    <div style={{ width: "100%" }}>
      <div className={styles.container}>
        <div className={styles.flexRow}>
          <img src={academyLogo} />
          <Typography className={styles.menuItem}>Foundations</Typography>
          <Typography className={styles.menuItem}>Lessons</Typography>
          <Typography className={styles.menuItem}>Support</Typography>
        </div>
        <div className={styles.flexEnd}>
          <Typography className={styles.menuItem}>Search</Typography>
          <Typography className={styles.menuItem}>Kevin M.</Typography>
          <img src={dummyProfile} />
        </div>
      </div>
      <div className={styles.innerContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default Header;
