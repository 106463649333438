import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import styles from "./HalfDonutChart.module.css";

Chart.register(ArcElement);

const HalfDonutChartSmall = ({ value }) => {
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  const data = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [`${barBg(value)}`, "rgba(255, 255, 255, 0.1)"],
        display: true,
        borderColor: "transparent",
      },
    ],
  };

  return (
    <div
      style={{
        backgroundSize: "100% 100%", // Make the background image responsive
        backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" width="120" height="60" viewBox="0 0 120 60" fill="none">
        <path opacity="0.25" d="M120 60C120 44.087 113.679 28.8258 102.426 17.5736C91.1742 6.32141 75.913 1.2014e-06 60 0C44.087 -1.2014e-06 28.8258 6.32141 17.5736 17.5736C6.32141 28.8258 2.4028e-06 44.087 0 60L36 60C36 53.6348 38.5286 47.5303 43.0294 43.0294C47.5303 38.5286 53.6348 36 60 36C66.3652 36 72.4697 38.5286 76.9706 43.0294C81.4714 47.5303 84 53.6348 84 60H120Z" fill="url(#paint0_linear_531_375)"/>
        <defs>
          <linearGradient id="paint0_linear_531_375" x1="60" y1="0" x2="60" y2="57.3034" gradientUnits="userSpaceOnUse">
            <stop stop-color="${barBg(value)}"/>
            <stop offset="1" stop-color="${barBg(value)}" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
    `)}')`,
        backgroundRepeat: "no-repeat",
      }}
      className={styles.canvas}
    >
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          rotation: -90,
          circumference: 180,
          cutout: "70%",
          maintainAspectRatio: false,
          responsive: true,
        }}
      />
    </div>
  );
};

export default HalfDonutChartSmall;
