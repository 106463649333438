import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Typography } from "@mui/material";
import positiveIcon from "Pages/Meeting/Assets/positiveIcon.svg";
import negativeIcon from "Pages/Meeting/Assets/negativeIcon.svg";
import averageIcon from "Pages/Meeting/Assets/averageIconGroup.svg";
import styles from "./donutchart.module.css";

Chart.register(ArcElement);

const DonutChart = ({ value, x, y, barColor, meetingProgress }) => {
  const tranX = x | 4.5;
  const tranY = y;

  const data = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [`${barColor}`, "rgba(255, 255, 255, 0.3)"],
        display: true,
        borderColor: "transparent",
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        backgroundSize: "100% 100%", // Make the background image responsive
        backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="60" viewBox="0 0 120 60" fill="none">
          <path opacity="0.25" d="M120 60C120 44.087 113.679 28.8258 102.426 17.5736C91.1742 6.32141 75.913 1.2014e-06 60 0C44.087 -1.2014e-06 28.8258 6.32141 17.5736 17.5736C6.32141 28.8258 2.4028e-06 44.087 0 60L36 60C36 53.6348 38.5286 47.5303 43.0294 43.0294C47.5303 38.5286 53.6348 36 60 36C66.3652 36 72.4697 38.5286 76.9706 43.0294C81.4714 47.5303 84 53.6348 84 60H120Z" fill="url(#paint0_linear_531_375)"/>
          <defs>
            <linearGradient id="paint0_linear_531_375" x1="60" y1="0" x2="60" y2="57.3034" gradientUnits="userSpaceOnUse">
              <stop stop-color="${barColor}"/>
              <stop offset="1" stop-color="${barColor}" stop-opacity="0"/>
            </linearGradient>
          </defs>
        </svg>
      `)}')`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Doughnut
        style={{
          width: "100%",
        }}
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          rotation: 270,
          circumference: 180,
          cutout: "88%",
          maintainAspectRatio: false,
          responsive: true,
        }}
      />

      <div
        style={{
          position: "absolute",
          width: "100%",
        }}
      >
        <div className={styles.iconContainer}>
          <img
            alt="icon"
            className={styles.iconGroup}
            src={
              meetingProgress === "positive"
                ? positiveIcon
                : meetingProgress === "negative"
                ? negativeIcon
                : averageIcon
            }
          />
        </div>
      </div>
      <div
        className={styles.valueContainer}
        style={{
          position: "absolute",
          width: "100%",
        }}
      >
        <Typography className={styles.graphValue}>{value}%</Typography>
      </div>
    </div>
  );
};

export default DonutChart;
