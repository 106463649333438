import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {Box} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid, IconButton } from "@mui/material";
import Logo from "../../../Assets/logo.svg";
import SchoolIcon from "@mui/icons-material/School";
import HomeIconFilled from "@mui/icons-material/Home";
import ApartmentIcon from '@mui/icons-material/Apartment';
import TimelineIcon from "@mui/icons-material/Timeline";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import productNavIcon from "../../../Assets/productNavIcon.svg";
import useWindowDimensions from "../GetWindowDimension/GetWindowDimension";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "Api/Firebase/config";
import { useSelector } from "react-redux";
import WestIcon from "@mui/icons-material/West";
import styles from "./SideNav.module.css";

const drawerWidth = 500;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const ManagerSideNav = () => {

  firebase.initializeApp(firebaseConfig);
  const [open, setOpen] = useState(false);
  const currentRoute = useSelector((state) => state.currentRoute.currentPage);
  const [selected, setSelected] = useState("");
  const [selectedText, setSelectedText] = useState("");
  let navigate = useNavigate();
  const user = useSelector((state) => state.setUser.user);
  const { height, width } = useWindowDimensions();
  const location = useLocation()
  const path = location.pathname

  const sidebarWidth =
    width <= 876 ? (width <= 834 ? "100vw" : "67px") : "94px";
  const sidebarHeight = width <= 834 ? "60px" : "100%";
  const closedMixin =
    width <= 834
      ? (theme) => ({
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "transparent !important",
        overflowX: "hidden",
        borderRight: "0px !important",
        height: `${sidebarHeight}`,
        width: `${sidebarWidth}`,
        display: "flex",
        flexDirection: "row !important",
        alignItems: "start",
        justifyContent: "start",
        [theme.breakpoints.up("sm")]: {
          width: `${sidebarWidth}`,
        },
      })
      : (theme) => ({
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "transparent !important",
        overflowX: "hidden",
        borderRight: "0px !important",
        height: `${sidebarHeight}`,
        width: `${sidebarWidth}`,

        [theme.breakpoints.up("sm")]: {
          width: `${sidebarWidth}`,
        },
      });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const handleLogout = () => {
    const logout = firebase
      .auth()
      .signOut()
      .then(() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      });
  };

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));


  const iconsGray = {
    Home: (
      <IconButton
        sx={{
          borderRadius: path === "/" ? "8px" : "none",
          background:
            path === "/"
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            path === "/" ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <HomeIconFilled
          sx={{
            color: path === "/" ? "white" : "white",
            opacity: path === "/" ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    Users: (
      <IconButton
        sx={{
          borderRadius: path === "/users" ? "8px" : "none",
          background:
            path === "/users"
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            path === "/users" ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <PersonAddIcon
          sx={{
            color: path === "/users" ? "white" : "white",
            opacity: path === "/users" ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    Teams: (
      <IconButton
        sx={{
          borderRadius: path === "/teams" ? "8px" : "none",
          background:
            path === "/teams"
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            path === "/teams" ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <GroupsIcon
          sx={{
            color: path === "/teams" ? "white" : "white",
            opacity: path === "/teams" ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    Library: (
      <IconButton
        sx={{
          borderRadius: path === "/library" ? "8px" : "none",
          background:
            path === "/library"
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            path === "/library" ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <FolderOutlinedIcon
          sx={{
            color: path === "/library" ? "white" : "white",
            opacity: path === "/library" ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    Products: (
      <IconButton
      sx={{
        borderRadius: path === "/products" ? "8px" : "none",
        background:
          path === "/products"
            ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
            : "none",
        boxShadow:
          path === "/products" ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
      }}
    >
      <img
        style={{ opacity: path === "/products" ? "1" : "0.5" }}
        src={productNavIcon}
        alt="icon"
      />
    </IconButton>
    ),
    Company: (
      <IconButton
        sx={{
          borderRadius: path === "/company" ? "8px" : "none",
          background:
            path === "/company"
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            path === "/company" ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <ApartmentIcon
          sx={{
            color: path === "/company" ? "white" : "white",
            opacity: path === "/company" ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
  }

  const handlegetRouteName = () => {
    if (path === "/") {
      return `Home`
    }
    if (path === "/users") {
      return `Users`
    }
    if (path === "/teams") {
      return `Teams`
    }
    if (path === "/setting") {
      return `Teams`
    }
    if (path === "/library") {
      return `Library`
    }
    if (path === "/products") {
      return `Products`
    }
    if (path === "/company") {
      return `Company`
    }
    if (path === "/profile") {
      return `Profile`
    }
  }

  const handleChangeRoute = (name) => {
    console.log("name", name)
    if (name === "home") {
       navigate("/")
    }
    if (name === "Profile") {
      navigate("/profile")
   }
    if (name === "users") {
       navigate("/users")
    }
    if (name === "teams") {
       navigate("/teams")
    }
    if (name === "library") {
      navigate("/library")
    }
    if (name === "products") {
      navigate("/products")
    }
    if (name === "company") {
      navigate("/company")
    }
  }

  const MenuList = [
    { name: "Home", label: "home", id: 1, icon: HomeIconFilled },
    { name: "Users", label: "users", id: 2 , icon: HomeIconFilled },
    { name: "Teams", label: "teams", id: 3 , icon: HomeIconFilled },
    { name: "Library", label: "library", id: 4 , icon: HomeIconFilled },
    { name: "Products", label: "products", id: 5 , icon: HomeIconFilled },
    { name: "Company", label: "company", id: 6 , icon: HomeIconFilled },
  ]

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "transparent !important",
        flexDirection: "row",
        zIndex: 0,
        width: "50px !important",
      }}
    >
      <div className={styles.headerContainer}>
        <div style={{ display: "flex" }}>
          {path !== "/" && (
            <div
              className={styles.backIconContainer}
              style={{ marginRight: "10px" }}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
          )}
          <div className={styles.headerText}>{handlegetRouteName()}</div>
        </div>
      </div>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          backgroundColor: "transparent !important",
          width: "50px !important",
          flexDirection: "row !important",
        }}
      >
        <DrawerHeader
          sx={
            width <= 834
              ? {
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 20px",
              }
              : {
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }
          }
        >
          <img alt="icon" src={Logo} onClick={() => handleChangeRoute("home")} />
        </DrawerHeader>
          <List
          sx={
            width <= 834 ? { display: "flex", padding: "0px !important" } : {}
          }
        >
          {MenuList && MenuList.map((item) => (
            <ListItem
              key={item.id}
              disablePadding
              sx={{ display: "block", width: "100%" }}
            >
              <ListItemButton
                sx={
                  width <= 876
                    ? {
                        width: 60,
                        minHeight: 57,
                        justifyContent: open ? "initial" : "center",
                        px: 4,
                      }
                    : {
                        width: 94,
                        minHeight: 80,
                        justifyContent: open ? "initial" : "center",
                        px: 5,
                      }
                }
                onClick={()=>handleChangeRoute(item.label)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <>{iconsGray[item.name]}</>

                    <>
                      {width <= 876 ? null : (
                        <Typography
                          className={styles.menuText}
                          style={{
                            color: "#ffffff",
                          }}
                          fontSize={12}
                          sx={{
                            mt: 1,
                            opacity: ((path === `/${item.label}`) || (item.label === "home" && path === "/" )) ? "1":  "0.5"
                          }}
                          fontWeight={700}
                        >
                          {item.name}
                        </Typography>
                      )}
                    </>
                  </Grid>
                </ListItemIcon>
                <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box
          sx={
            width <= 834
              ? {
                  flexGrow: 0.8,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  height: "100%",
                  justifyContent: "center",
                }
              : {
                  flexGrow: 0.7,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
          }
        >
          <Avatar
            alt="icon"
            sx={{
              cursor: "pointer",
              border: currentRoute === 6 ? "3px solid #9747FF" : "none",
            }}
            src={user.user_image}
            onClick={() => handleChangeRoute("Profile")}
            style={{ fontSize: "50px", color: "white" }}
          />
        </Box>
        <Box
          sx={
            width <= 834
              ? {
                  flexGrow: 0.1,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  height: "100%",
                  justifyContent: "center",
                }
              : {
                  flexGrow: 0.2,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
          }
        >
          <LogoutIcon
            sx={{ cursor: "pointer" }}
            onClick={handleLogout}
            style={{ color: "white" }}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default ManagerSideNav;
