import React from "react";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "./meeting.module.css";
const MeetingResult = ({ width, type, title }) => {
  return (
    <div
      style={{
        width: width,
        background:
          type === 0
            ? "rgba(40, 202, 144, 0.24)"
            : type === 1
            ? "rgba(243, 194, 67, 0.24)"
            : "rgba(229, 26, 63, 0.24)",
      }}
      className={styles.container}
    >
      {title ==="!New" && <InfoOutlinedIcon
        fontSize="small"
        sx={{
          color:
            type === 0
              ? "var(--theme-green, #28CA90)"
              : type === 1
              ? "var(--dark-yellow-0, #F3C243)"
              : "var(--dark-red-10, #EE6880)",
        }}
      />}
      <div
        style={{
          color:
            type === 0
              ? "var(--theme-green, #28CA90)"
              : type === 1
              ? "var(--dark-yellow-0, #F3C243)"
              : "var(--dark-red-10, #EE6880)",
        }}
        className={styles.title}
      >
        {title}
      </div>
    </div>
  );
};
MeetingResult.defaultProps = {
  width: "inherit",
  type: 0,
  title: "Success",
};
export default MeetingResult;
