import React, { useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  Typography,
} from "@mui/material";

const McqQuestion = ({
  question,
  options,
  selectedOption,
  setSelectedOption,
}) => {
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(!selectedOption);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup sx={{ display: "flex" }}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={option}
                checked={selectedOption}
                onChange={handleOptionChange}
                sx={{
                  color: "rgba(255, 255, 255, 0.5)", // White with 0.5 opacity
                }}
              />
            }
            label={
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  width: "max-content", // White with 0.5 opacity
                }}
              >
                {option}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

McqQuestion.defaultProps = {
  setSelectedOption: () => {},
  selectedOption: "",
};

export default McqQuestion;
