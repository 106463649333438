import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import styles from "./EmailVerification.module.css";
import Logo from "../../Assets/logoTextual.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import EmailVerifyImage from "../../Assets/emailVerificationImage.svg";
import VerificationComplateImage from "../../Assets/verificationCompleteImage.svg";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "Api/Firebase/config";
import { useLocation } from "react-router-dom";
import { verifyEmail } from "Api/Backend/backend";

const EmailVerification = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { first_name, email } = location.state;
  const [emailVerified, setEmailVerified] = useState(false);
  const [firstLogin, setFirstLogin] = useState(true);
  const [user, setUser] = useState(null);

  const handleForm = () => {
    // if (emailVerified) {
    if (firstLogin) {
      navigate("/account-integrations", { state: { user: user } });
    } else {
      navigate("/", { state: { user: user } });
    }
    // }
  };
  function maskEmail(email) {
    const atIndex = email.indexOf("@");
    if (atIndex === -1) {
      // if there is no "@" symbol in the email, return the original string
      return email;
    } else {
      // otherwise, replace all characters before the "@" with asterisks
      const username = email.slice(0, atIndex);
      const maskedUsername =
        username.slice(0, 3) + "*".repeat(username.length - 3);
      return maskedUsername + email.slice(atIndex);
    }
  }

  useEffect(() => {
    const email = searchParams.get("email");
    const resetpassword = searchParams.get("mode");
    const oobCode = searchParams.get("oobCode");
    if (resetpassword == "resetPassword") {
      navigate(`/forgot-password?email=${email}&oobCode=${oobCode}`);
    }
    if (email) {
      verifyEmail(email)
        .then((resp) => {
          setEmailVerified(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("not exists");
    }
  }, []);

  return (
    <>
      <Container maxWidth={false} className={styles.verificationContainer}>
        <Box className={styles.header}>
          <img src={Logo} alt="icon" />
          <Box className={styles.headerButtonContainer}>
            <Typography className={styles.headerText}>
              Already have an account?
            </Typography>
            <Button
              className={styles.headerButton}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </Box>
        </Box>
        <Box className={styles.information}>
          <img
            alt="icon"
            src={emailVerified ? VerificationComplateImage : EmailVerifyImage}
          />
          {emailVerified ? (
            <>
              <Typography className={styles.headingText}>
                ✨ Verification done ✨
              </Typography>
            </>
          ) : (
            <>
              <Typography className={styles.headingText}>
                {first_name}, check your email
              </Typography>
            </>
          )}

          {emailVerified ? (
            <>
              <Typography className={styles.subText}>
                You’ve successfully verified your email address, now please
                continue your journey.
              </Typography>
            </>
          ) : (
            <>
              <Typography className={styles.subText}>
                We’ve send you a verfication link at {maskEmail(email)}, Please
                open it to verify.<a> Resend it </a>
              </Typography>
            </>
          )}

          {emailVerified ? (
            <>
              <Button
                className={styles.continueButton}
                endIcon={<EastOutlinedIcon />}
                onClick={handleForm}
              >
                Continue
              </Button>
            </>
          ) : (
            <>{null}</>
          )}
        </Box>
      </Container>
    </>
  );
};

export default EmailVerification;
