import React, { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { deleteMeeting } from "Api/Backend/backend";
import { Avatar, Box, Typography } from "@mui/material";
import styles from "./GenericListItem.module.css";
import CheckedIcon from "../../../Assets/checkedIcon.svg";
import UncheckedIcon from "../../../Assets/uncheckedIcon.svg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteMeetingModal from "Components/SalesRep/MeetingCard/DeletingModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const GenericListItem = ({
  key,
  secondaryAction,
  getScheduledMeetings,
  getAllMeetings,
  isClient,
  avatar,
  text,
  doctorName,
  secondaryText,
  startTime,
  endTime,
  upcoming,
  time,
  mid,
  variant,
  designation,
  component,
  setStyle,
  bColor,
}) => {
  const [checked, setChecked] = useState(false);
  const user = useSelector((state) => state.setUser.user);
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setUserName(user.first_name);
    }
  }, []);
  const handleClick = () => {
    setOpen(true);
  };
  const handleCancelDelete = () => {
    setOpen(false);
  };
  const handleDelete = async (deleteId) => {
    const deletedData = deleteMeeting(deleteId)
      .then(() => {
        setOpen(false);
        getScheduledMeetings(user.user_id);
        getAllMeetings(user.user_id);
        toast.success("Meeting Deleted");
      })
      .catch((err) => {
        toast.error("Meeting not found");
        setOpen(false);
      });
  };
  return (
    <>
      <ListItem
        key={key}
        secondaryAction={secondaryAction}
        className={
          component == "card"
            ? styles.listCard
            : variant == "recent"
            ? styles.listItemRecent
            : variant == "table"
            ? styles.listItemTable
            : styles.listItem
        }
        sx={
          variant == "recent"
            ? {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "95%",
                textAlign: "left",
                height: "120px",
                gap: "8px",
                borderLeft: `4px solid ${bColor}`,
                marginLeft: "1vw",
              }
            : {}
        }
      >
        {avatar && variant != "check" ? (
          <>
            <Avatar
              sx={{
                background:
                  "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                border: avatar ? "none" : "0.5px dashed #807BC6",
                color: "#3b358a !important",
                fontWeight: "700 !important",
                fontSize: "14px !important",
              }}
              src={avatar}
              alt="icon"
            />
          </>
        ) : (
          <>
            {!avatar && variant != "check" && isClient ? (
              <>
                <Avatar
                  alt="icon"
                  sx={{
                    backgroundColor: "#EEEDF7",
                    border: avatar ? "none" : "0.5px dashed #807BC6",
                    color: "#3b358a !important",
                    fontWeight: "700 !important",
                    fontSize: "14px !important",
                  }}
                />
              </>
            ) : avatar && variant == "check" ? (
              <>
                {checked ? (
                  <>
                    <Box
                      className={styles.checkBox}
                      sx={{ height: "39px", width: "39px", cursor: "pointer" }}
                    >
                      <img
                        alt="icon"
                        src={CheckedIcon}
                        onClick={() => setChecked(false)}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      className={styles.checkBox}
                      sx={{ height: "39px", width: "39px", cursor: "pointer" }}
                    >
                      <img
                        alt="icon"
                        src={UncheckedIcon}
                        onClick={() => setChecked(true)}
                      />
                    </Box>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}

        <ListItemText
          sx={
            variant == "table"
              ? { ml: 0.5 }
              : { ml: 0.5, gap: "8px", alignItems: "flex-start !important" }
          }
          style={{ width: "98%" }}
          primary={
            <div
              style={{ width: "98%", display: "flex", flexDirection: "column" }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                className={setStyle ? styles.primaryUnset : styles.primaryText}
                sx={
                  variant == "recent"
                    ? {
                        ml: 0.5,
                        gap: "8px",
                        textAlign: "left",
                        width: "100%",
                        alignItems: "flex-start !important",
                        fontFamily: "Red Hat Display !important",
                        fontSize: "14px !important",
                      }
                    : variant == "table"
                    ? {
                        color: "rgba(255, 255, 255, 0.80)",

                        /* Body/bold */
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "18px" /* 128.571% */,
                      }
                    : { gap: "8px" }
                }
              >
                {text}
              </Typography>

              {upcoming && (
                <Box>
                  <DeleteOutlinedIcon
                    onClick={handleClick}
                    sx={{
                      verticalAlign: "middle",
                      marginLeft: "10px",
                      "&:hover": {
                        cursor: "pointer",
                      },
                      color: "#77767C",
                    }}
                  />
                </Box>
              )}
            </div>
          }
          secondary={
            variant == "recent" ? (
              <>
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  className={
                    setStyle ? styles.primaryUnset : styles.recentSecondary
                  }
                  sx={{
                    color: "#77767C",
                    fontFamily: "Satoshi-Variable",
                    mt: 0.5,
                  }}
                >
                  <Box className={styles.recentSecondaryBox}>
                    <font>
                      <PermIdentityOutlinedIcon
                        fontSize="small"
                        className={styles.icon}
                      />
                      &nbsp;
                      {doctorName}
                    </font>
                  </Box>
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  className={
                    setStyle ? styles.primaryUnset : styles.recentSecondary
                  }
                  sx={{
                    color: "#77767C",
                    fontFamily: "Satoshi-Variable",
                    mt: 0.5,
                  }}
                >
                  <Box className={styles.recentSecondaryBox}>
                    <font>
                      <AccessTimeOutlinedIcon
                        fontSize="small"
                        className={styles.icon}
                      />
                      &nbsp;
                      {`${startTime} - ${endTime}`}
                    </font>
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  className={
                    setStyle ? styles.primaryUnset : styles.secondaryText
                  }
                  sx={
                    variant == "table"
                      ? { color: "rgba(255, 255, 255, 0.50)" }
                      : { color: "#77767C", fontFamily: "Satoshi-Variable" }
                  }
                >
                  {(time || designation) && secondaryText ? (
                    <>
                      {secondaryText} &nbsp;&#x2022;&nbsp; {time || designation}
                    </>
                  ) : time ? (
                    <>{time}</>
                  ) : secondaryText ? (
                    <>{secondaryText}</>
                  ) : (
                    <></>
                  )}
                </Typography>
              </>
            )
          }
        />
      </ListItem>
      <DeleteMeetingModal
        isOpen={open}
        onClose={handleCancelDelete}
        onDelete={() => handleDelete(mid)}
        title={"Are you sure you want to delete the meeting?"}
      />
    </>
  );
};

export default GenericListItem;
GenericListItem.defaultProps = {
  upcoming: false,
  getScheduledMeetings: () => {},
};
