import React, { useEffect, useRef, useState } from "react";
import styles from "./Interaction.module.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DialogContent from "@mui/material/DialogContent";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Profile from "../Assets/user.svg";
import Clinic from "../Assets/clinic.svg";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import Speciality from "../Assets/speciality.svg";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { Avatar, Box } from "@mui/material";
import InputField from "Components/Common/InputField/InputField";
import { addInteraction } from "Api/Backend/backend";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "var(--theme-dark-0, #222224)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "var(--theme-dark-0, #222224)",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        background: "var(--theme-dark-0, #222224)",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddNewInteraction = ({
  meetingModalOpen,
  setMeetingModalOpen,
  userId,
  getInteraction,
}) => {
  const [name, setName] = useState("");

  const [contact, setContact] = useState("");
  const [region, setRegion] = useState("");
  const [email, setEmail] = useState("");
  const [clinic, setClinic] = useState("");
  const [speciality, setSpeciality] = useState("");
  const componentRef = useRef(null);
  const buttonRef = useRef();
  const [file, setFile] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const handleClose = () => {
    setSelectedImage("");
    setMeetingModalOpen(false);
  };
  const validateEmail = (email) => {
    // email validation function
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(vc|[a-zA-Z]{2,})))$/;

    return re.test(String(email).toLowerCase());
  };
  const handleClick = () => {
    if (
      !name &&
      !contact &&
      !email &&
      !clinic &&
      !speciality &&
      !contact &&
      !region &&
      !file
    ) {
      toast.error("Enter Details");
    } else if (!name) {
      toast.error("Enter Name");
    } else if (!email) {
      toast.error("Enter Email");
    } else if (!clinic) {
      toast.error("Enter Clinic Name");
    } else if (!speciality) {
      toast.error("Enter Speciality");
    } else if (!contact) {
      toast.error("Enter Contact");
    } else if (!file) {
      toast.error("Upload Image");
    } else if (!region) {
      toast.error("Enter Region");
    } else {
      if (validateEmail(email)) {
        const formData = new FormData();
        formData.append("doctor_email", email);
        formData.append("doctor_name", name);
        formData.append("doctor_contact", contact);
        formData.append("clinic_name", clinic);
        formData.append("added_by", userId);
        formData.append("speciality", speciality);
        formData.append("region", region);
        formData.append("image_file", file);

        const response = addInteraction(formData)
          .then((res) => {
            console.log(res);
            if (res === "Doctor with the same email already exists.") {
              toast.error("Doctor Email already exists");
            } else if (res === "Please select the correct format") {
              toast.error("Incorrect image format, PNG or JPEG only");
            } else {
              handleClose();
              toast.success("Interaction Uploaded");
              getInteraction();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        toast.error("Email Format Invalid");
      }
    }
  };
  const handleIconClick = () => {
    // Trigger the hidden file input
    document.getElementById("fileInput").click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    setFile(file);
  };
  return (
    <>
      <input
        id="fileInput"
        type="file"
        onChange={handleImageChange}
        accept="image/*"
        style={{ display: "none" }}
      />
      <BootstrapDialog
        ref={componentRef}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={meetingModalOpen}
        className={styles.dialogue}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={styles.dialogueTitle}
        >
          New Interaction
        </BootstrapDialogTitle>
        <DialogContent className={styles.dialogueDivider} dividers>
          <Box className={styles.scheduleMeetingFormContainer}>
            <Box className={styles.formRow}>
              <img src={Profile} className={styles.fieldIcon} alt="icon" />

              <Box>
                <InputField
                  variant="outlined"
                  editable={true}
                  inputHeight="35"
                  inputWidth="500"
                  placeholder="Name"
                  setter={setName}
                />
              </Box>
            </Box>
            <Box className={styles.formRow}>
              <EmailIcon className={styles.fieldIcon} />

              <Box>
                <InputField
                  variant="outlined"
                  editable={true}
                  inputHeight="35"
                  inputWidth="500"
                  placeholder="Email"
                  setter={setEmail}
                />
              </Box>
            </Box>
            <Box className={styles.formRow}>
              <CallIcon className={styles.fieldIcon} />

              <Box>
                <InputField
                  variant="outlined"
                  editable={true}
                  inputHeight="35"
                  inputWidth="500"
                  placeholder="Contact"
                  setter={setContact}
                />
              </Box>
            </Box>
            <Box className={styles.formRow}>
              <SouthAmericaIcon className={styles.fieldIcon} />

              <Box>
                <InputField
                  variant="outlined"
                  editable={true}
                  inputHeight="35"
                  inputWidth="500"
                  placeholder="Region"
                  setter={setRegion}
                />
              </Box>
            </Box>
            <Box className={styles.formRow}>
              <img alt="icon" src={Speciality} className={styles.fieldIcon} />
              <Box>
                <InputField
                  placeholder="Speciality"
                  editable={true}
                  inputHeight="35"
                  inputWidth="500"
                  setter={setSpeciality}
                />
              </Box>
            </Box>
            <Box className={styles.formRow}>
              <img alt="icon" src={Clinic} className={styles.fieldIcon} />
              <Box>
                <InputField
                  placeholder="Clinic"
                  editable={true}
                  inputHeight="35"
                  inputWidth="500"
                  setter={setClinic}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "rgba(195, 194, 197, .7)",
                  cursor: "pointer",
                }}
                alt="Edit"
              >
                <EditOutlinedIcon
                  sx={{ color: "#201f28" }}
                  onClick={handleIconClick}
                />
              </Avatar>
              <div>
                {selectedImage ? (
                  <img
                    style={{
                      marginLeft: "20px",
                      width: "100px",
                    }}
                    src={selectedImage ? selectedImage : ""}
                    alt="Profile Picture"
                  />
                ) : (
                  <Typography
                    style={{
                      marginLeft: "20px",
                      color: "rgba(195, 194, 197, 1)",
                    }}
                    alt="Profile Picture"
                  >
                    Add Profile Picture
                  </Typography>
                )}
              </div>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ paddingRight: "20px !important", height: "70px" }}>
          <Button className={styles.cancelButton} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleClick}
            ref={buttonRef}
            className={styles.scheduleButton}
          >
            Add Interaction
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default AddNewInteraction;
