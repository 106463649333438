import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuList from "Components/Common/MenuList/MenuList";
import BadgeAvatar from "Components/Common/BadgeAvatar/BadgeAvatar";
import NotificationSettings from "./NotificationSettings";
import AccountSettings from "./AccountSettings";
import { useSelector } from "react-redux";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useDispatch } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import styles from "./Profile.module.css";
import { setUser } from "Store/features/setUserSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl, updateUserPicture } from "Api/Backend/backend";

const Profile = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);

  const user = useSelector((state) => state.setUser.user);
  const userName = `${user.first_name} ${user.last_name}`;
  const userImage = user.user_image;

  const userId = user.user_id;
  const options = [
    {
      icon: (
        <AccountCircleOutlinedIcon fontSize="small" sx={{ color: "white" }} />
      ),
      text: "Account Settings",
    },
    {
      icon: (
        <NotificationsNoneOutlinedIcon
          fontSize="small"
          sx={{ color: "white" }}
        />
      ),
      text: "Notification Settings",
    },
  ];

  const [selectedListItem, setSelectedListItem] = React.useState(
    options[0].text
  );
  const handleClick = () => {
    if (selectedListItem === options[0].text) {
      setSelectedListItem(options[1].text);
    } else {
      setSelectedListItem(options[0].text);
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userId);

    const response = updateUserPicture(formData)
      .then(async () => {
        toast.success("Picture Updated");
        const uid = sessionStorage.getItem("firebase");
        const getUser = await axios
          .post(
            `${baseUrl}get_user_by_firebaseid/?firebase_id=${uid}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            if (response.data) {
              console.log(response.data)
              dispatch(setUser(response.data));
              console.log("here in profile")
              sessionStorage.setItem("user", JSON.stringify(response.data));
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };
  useEffect(() => {
    dispatch(setCurrentRoute(6));
  }, []);

  return (
    <React.Fragment>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <CssBaseline />

      <div className={styles.container}>
        <Box className={styles.menuSection}>
          <Box className={styles.avatarContainer}>
            <BadgeAvatar
              userId={userId}
              userImage={userImage}
              height={44}
              width={44}
              bHeight={0}
              bWidth={0}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
            <div className={styles.userInfoContainer}>
              <div className={styles.userName}>{userName}</div>
              <div onClick={handleIconClick} className={styles.editText}>
                Edit Image
              </div>
            </div>
          </Box>
          {options.map((data) => (
            <div
              onClick={handleClick}
              className={
                selectedListItem === data.text
                  ? styles.MenuListContainerActive
                  : styles.MenuListContainer
              }
            >
              {data.icon}
              <div className={styles.MenuItem}>{data.text}</div>
            </div>
          ))}
        </Box>
        <Box className={styles.settingSection}>
          {selectedListItem == "Account Settings" ? (
            <>
              <AccountSettings />
            </>
          ) : (
            <>
              <NotificationSettings />
            </>
          )}
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Profile;
