import * as React from "react";
import styles from "./DropDownButton.module.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/system";

const DropDownButton = ({
  isHome,
  isMonthly,
  Icon,
  setCurrentId,
  background,
  variant,
  text,
  size,
  options,
  setter,
  width,
  setMonth,
}) => {
  const [selected, setSelected] = React.useState(
    isHome || isMonthly
      ? options[0]?.id || ""
      : options[new Date().getMonth()]?.id || ""
  );

  React.useEffect(() => {
    setSelected(
      isHome || isMonthly
        ? options[0]?.id || ""
        : options[new Date().getMonth()]?.id || ""
    );
  }, []);

  const handleOptionClick = (option, popupState) => {
    setSelected(option.id);
    setCurrentId(option.id);
    if (setter) {
      setter(option.id);
    }
    if (setMonth) {
      setMonth(option.id);
    }
    popupState.close();
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            variant={variant}
            startIcon={
              <Icon
                fontSize="small"
                sx={{ color: "rgba(255, 255, 255, 0.25)" }}
              />
            }
            size={size}
            sx={{
              backgroundColor: background,
              color: "#77767c",

              /* H5/medium */
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "24px" /* 150% */,
              borderRadius: "12px",
              // border: "1px solid #77767C",
              height: "40px",
              padding: "9px 16px 9px 16px",
              border: "0px",
              ":hover": {
                border: "0px",
              },
              textTransform: "none",
              width: width ? `${width}px` : "fit-content",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "0px",
            }}
            {...bindTrigger(popupState)}
          >
            {selected
              ? options.find((option) => option.id === selected)?.value
              : "Filter By"}
          </Button>
          <Menu
            {...bindMenu(popupState)}
            PaperProps={{ style: { paddingRight: "2.5vw" } }}
          >
            {options ? (
              options.map((option) => (
                <Box
                  key={option.id}
                  className={
                    selected === option.id
                      ? styles.itemSelected
                      : styles.itemNotSelected
                  }
                >
                  {selected === option.id ? (
                    <CheckIcon sx={{ color: "#77767C" }} fontSize="small" />
                  ) : (
                    <CheckIcon
                      sx={{ color: "#2F2F2F", visibility: "none" }}
                      fontSize="small"
                    />
                  )}
                  <MenuItem
                    onClick={() => handleOptionClick(option, popupState)}
                    className={
                      selected === option.id
                        ? styles.optionSelected
                        : styles.optionNotSelected
                    }
                    sx={{ textAlign: "start" }}
                  >
                    {option.value}
                  </MenuItem>
                </Box>
              ))
            ) : (
              <></>
            )}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};
DropDownButton.defaultProps = {
  setCurrentId: () => {},
};
export default DropDownButton;
