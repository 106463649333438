import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import IntegrationCard from "Components/Common/IntegrationCard/IntegrationCard";

const CardGrid = ({ data }) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <>
      <Grid container spacing={2} sx={{padding:'0px'}}>
        {data.map((item) => (
          <>
            <Grid >
              <IntegrationCard
                height="247px"
                logo={item.logo}
                title={item.title}
                subtitle={item.subtitle}
                func={item.func}
                connected={item.connected}
              />
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
};

export default CardGrid;
