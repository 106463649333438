import { createColumnHelper } from "@tanstack/react-table";
import GenericListItem from "Components/Common/GenericListItem/GenericListItem";
import { Box } from "@mui/system";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";
import { Container, Typography } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import medicineIcon from "../../../../Assets/medicineIcon.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styles from "./Recordings.module.css";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";
import MeetingResult from "Components/NewDashboard/MeetingResult/MeetingResult";
import MeetingsDataBox from "Components/NewDashboard/MeetingsDataBox/MeetingsDataBox";

const dummyOptions = [
  { id: "0", value: "All Time" },
  { id: "1", value: "Today" },
  { id: "2", value: "Yesterday" },
  { id: "3", value: "This Week" },
];

const mySplit = (text, index) => {
  const arr = text.split("T");
  return arr[index];
};
const getTimeInAMPMFormat = (inputTime) => {
  const inputDate = new Date(inputTime);

  // Ensure the input time is a valid date
  if (isNaN(inputDate.getTime())) {
    return "Invalid Date";
  }

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return inputDate.toLocaleString("en-US", options);
};

const columnHelper = createColumnHelper();

const columnsDef = [
  columnHelper.accessor("title", {
    header: () => "Title",
    cell: (info) => (
      <Typography fontSize={16} fontWeight={500} className={styles.firstTd}>
        {info.renderValue()}
      </Typography>
    ),
  }),
  columnHelper.accessor("doctor_email", {
    header: () => "Client",
    cell: (info) => (
      <Container
        sx={{
          width: "100%",
          padding: "0px !important",
          color: "#636269 !important",
          gap: "8px !important",
        }}
      >
        <GenericListItem
          avatar={info.row.original.doctor_profile_picture}
          secondaryText={"Oncologist"}
          text={info.renderValue()}
          isClient={true}
          variant="table"
          name={info.renderValue()}
        />
      </Container>
    ),
  }),
  columnHelper.accessor("doctor_name", {
    header: () => "type",
    cell: (info) => (
      <Container
        sx={{
          width: "100%",
          padding: "0px !important",
          color: "#636269 !important",
          gap: "8px !important",
        }}
      >
        <GenericListItem
          avatar={medicineIcon}
          secondaryText={"Medicine"}
          text={"Paracetamol OD 340"}
          isClient={true}
          variant="table"
          name={info.renderValue()}
        />
      </Container>
    ),
  }),

  columnHelper.accessor("start_time", {
    header: () => "date",
    cell: (info) => (
      <Container
        sx={{
          // width: "120%",
          padding: "0px !important",
          // transform: "translate(-5px,0px)",
        }}
      >
        <GenericListItem
          text={mySplit(info.renderValue(), 0)}
          isClient={false}
          secondaryText={getTimeInAMPMFormat(info.renderValue())}
          variant="table"
        />
      </Container>
    ),
  }),
  columnHelper.accessor("success_score", {
    header: () => "Score",
    cell: (info) => (
      <Box
        sx={{
          height: "25px",
          width: "100px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <HalfDonutChartSmall value={0} />
        <Typography sx={{ color: "rgba(255, 255, 255, 0.8) !important" }}>
          0
        </Typography>
      </Box>
    ),
  }),

  // columnHelper.accessor("doctor_name", {
  //   header: () => "",
  //   cell: (info) => <MeetingResult />,
  // }),
  // columnHelper.accessor("doctor_name", {
  //   header: () => "",
  //   cell: (info) => (
  //     <MeetingsDataBox isValue={false} text={"Curious"} value={"23%"} />
  //   ),
  // }),
  // columnHelper.accessor("doctor_name", {
  //   header: () => "",
  //   cell: (info) => (
  //     <MeetingsDataBox isValue={true} text={"Valuable"} value={"53%"} />
  //   ),
  // }),
];

export default columnsDef;
