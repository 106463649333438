import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Pagination, Stack, Typography } from "@mui/material";
import styles from "./DataTable.module.css";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import SearchField from "../SearchField/SearchField";
import DropDownButton from "../DropDownButton/DropDownButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import VeevaRefreshIcon from "../../../Assets/veevaRefreshIcon.svg";
import CachedIcon from "@mui/icons-material/Cached";
import Collapse from "@mui/material/Collapse";
import { deleteMeeting } from "Api/Backend/backend";
import DeleteMeetingModal from "Components/SalesRep/MeetingCard/DeletingModal";
import filterIcon from "../../../Assets/filter_icon.svg";
import splitIcon from "../../../Assets/split_screen_icon.svg";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAlt";
import { toast } from "react-toastify";

const DataTable = ({
  defination,
  isAllInteraction,
  cellData,
  title,
  variant,
  fallback,
  isOnStatistics,
  id,
  getTableData,
  onRowClick,
  onInteraction,
  searchFieldText,
  handleDelete,
  setCount,
}) => {
  const dummyOptions = onInteraction
    ? [
        { id: 1, value: "Affect score" },
        { id: 2, value: "Feedback recieved" },
      ]
    : [
        { id: 0, value: "Date & time" },
        { id: 1, value: "Affect score" },
        { id: 2, value: "Feedback recieved" },
      ];
  const [open, setOpen] = useState(false);
  const [mid, setMid] = useState(null);
  const [currentId, setCurrentId] = useState(0);
  const searchPlaceHolder =
    searchFieldText | "Search meetings, clients, score...";
  const [btnGroup, setBtnGroup] = useState("");
  const [refresh, setRefresh] = useState(false);
  const columns = defination;
  const data = cellData;
  const rerender = React.useReducer(() => ({}), {})[1];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  const handleRefresh = () => {
    setRefresh(true);
    const interval = setInterval(3000);
    setTimeout(() => {
      clearInterval(interval);
      setRefresh(false);
    }, 3000);
  };
  const handleClick = (data) => {
    setMid(data);
    setOpen(true);
  };
  const handleDeleted = () => {
    const deletedData = deleteMeeting(mid)
      .then(() => {
        toast.success("Meeting Deleted");
        setOpen(false);
        setCount((prevCount) => prevCount + 1);
        setMid(null);
      })
      .catch((err) => {
        toast.error("Meeting not found");
        // setOpen(false);
      });
  };
  function getStringAfterUnderscore(str) {
    const index = str.indexOf("_");
    if (index === -1) {
      return str; // If there's no underscore, return the original string
    }
    return str.slice(index + 1);
  }
  const handleCancelDelete = () => {
    setMid(null);
    setOpen(false);
  };

  useEffect(() => {}, [cellData]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const filteredRecords =
    currentId === 0
      ? table.getRowModel().rows.filter((row) => {
          if (onInteraction) {
            const name = row.original.doctor_name || ""; // Provide a default value if 'name' is undefined
            return name.toLowerCase().includes(searchValue.toLowerCase());
          } else {
            const name = row.original.doctor_name || ""; // Provide a default value if 'name' is undefined
            const title = row.original.title || ""; // Provide a default value if 'title' is undefined

            const lowercaseName = name.toLowerCase();
            const lowercaseTitle = title.toLowerCase();
            const lowercaseSearchValue = searchValue.toLowerCase();

            return (
              lowercaseName.includes(lowercaseSearchValue) ||
              lowercaseTitle.includes(lowercaseSearchValue)
            );
          }
        })
      : currentId === 1
      ? table
          .getRowModel()
          .rows.sort(
            (a, b) => b.original.success_score - a.original.success_score
          )
          .filter((row) => {
            if (onInteraction) {
              const name = row.original.doctor_name || ""; // Provide a default value if 'name' is undefined
              return name.toLowerCase().includes(searchValue.toLowerCase());
            } else {
              const name = row.original.doctor_name || ""; // Provide a default value if 'name' is undefined
              const title = row.original.title || ""; // Provide a default value if 'title' is undefined

              const lowercaseName = name.toLowerCase();
              const lowercaseTitle = title.toLowerCase();
              const lowercaseSearchValue = searchValue.toLowerCase();

              return (
                lowercaseName.includes(lowercaseSearchValue) ||
                lowercaseTitle.includes(lowercaseSearchValue)
              );
            }
          })
      : table
          .getRowModel()
          .rows.sort(
            (a, b) => b.original.feedback_count - a.original.feedback_count
          )
          .filter((row) => {
            if (onInteraction) {
              const name = row.original.doctor_name || ""; // Provide a default value if 'name' is undefined
              return name.toLowerCase().includes(searchValue.toLowerCase());
            } else {
              const name = row.original.doctor_name || ""; // Provide a default value if 'name' is undefined
              const title = row.original.title || ""; // Provide a default value if 'title' is undefined

              const lowercaseName = name.toLowerCase();
              const lowercaseTitle = title.toLowerCase();
              const lowercaseSearchValue = searchValue.toLowerCase();

              return (
                lowercaseName.includes(lowercaseSearchValue) ||
                lowercaseTitle.includes(lowercaseSearchValue)
              );
            }
          });

  // Calculate the index range of records to display on the current page
  const recordsPerPage = 10;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // Get the current page's records based on the index range and search filter
  const currentRecords = filteredRecords.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredRecords.length / recordsPerPage);

  // Function to handle page changes
  const handlePageChange = (e, pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);
  useEffect(() => {
    if (currentId === 0) {
      setCurrentPage(1);
      getTableData(id);
    }
  }, [currentId]);

  return (
    <Container maxWidth={true} className={styles.tableContainer}>
      {!isAllInteraction && (
        <div className={styles.headerTitle}>Engagement Library</div>
      )}
      {!isOnStatistics && (
        <Box className={styles.headerContainer}>
          {variant == "filter" || variant == "prospects" ? (
            <>
              <SearchField
                width={"40%"}
                placeholder="Search recordings via doctor, medicine and more"
                value={searchValue}
                setValue={setSearchValue}
              />
              <>
                <DropDownButton
                  Icon={FilterAltOutlinedIcon}
                  background={"transparent"}
                  variant={"outlined"}
                  text={"Filter"} // filter buttons
                  size="medium"
                  options={dummyOptions}
                  setCurrentId={setCurrentId}
                />
              </>
              {/* <div style={{ display: "flex" }}>
                <img src={filterIcon} />
                <div className={styles.filterText}>All Time</div>
              </div> 
              <div style={{ display: "flex" }}>
                <img src={splitIcon} />
                <div className={styles.filterText}>Split View</div>
              </div>
              */}
              {/* <Box className={styles.btnGroupContainer}>
              {variant == "prospects" ? (
                <>
                  {data ? (
                    <>
                      <Stack direction="row" gap={3}>
                        <img
                          src={VeevaRefreshIcon}
                          onClick={() => handleRefresh()}
                          style={{ cursor: "pointer" }}
                          alt="image"
                        />
                        {subVariant == "contact" ? (
                          <>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#4A42AD",
                                height: "40px",
                                width: "149px",
                                padding: "9px 16px 9px 16px",
                              }}
                              startIcon={
                                <AddIcon
                                  sx={{ height: "13.33px", width: "13.33px" }}
                                />
                              }
                            >
                              <Typography
                                fontSize={14}
                                fontWeight={500}
                                sx={{
                                  textTransform: "none",
                                  fontFamily: "Satoshi-Variable",
                                }}
                              >
                                New Prospect
                              </Typography>
                            </Button>
                          </>
                        ) : (
                          <>{null}</>
                        )}
                      </Stack>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <DropDownButton
                    Icon={KeyboardArrowDownIcon}
                    background={"#ffffff"}
                    variant={"outlined"}
                    text={"Filter"} // filter buttons
                    size="medium"
                    options={dummyOptions}
                    setCurrentId={setCurrentId}
                  />
                </>
              )}
            </Box> */}
            </>
          ) : (
            <>
              <Typography
                fontSize={20}
                fontWeight={700}
                sx={{ color: "#201F28" }}
              >
                {title}
              </Typography>
              {variant == "ranked" ? (
                <></>
              ) : (
                <>
                  <Box className={styles.btnGroupContainer}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        "& > *": {
                          m: 1,
                        },
                      }}
                    >
                      <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{ border: "none" }}
                      >
                        <ThemeProvider theme={theme}>
                          <Button
                            className={
                              btnGroup == "day"
                                ? styles.btnSelected
                                : styles.btn
                            }
                            onClick={() => setBtnGroup("day")}
                          >
                            Day
                          </Button>
                          <Button
                            className={
                              btnGroup == "week"
                                ? styles.btnSelected
                                : styles.btn
                            }
                            onClick={() => setBtnGroup("week")}
                          >
                            Week
                          </Button>
                          <Button
                            className={
                              btnGroup == "month"
                                ? styles.btnSelected
                                : styles.btn
                            }
                            onClick={() => setBtnGroup("month")}
                          >
                            Month
                          </Button>
                        </ThemeProvider>
                      </ButtonGroup>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}

      {variant == "prospects" ? (
        <>
          <Collapse in={refresh}>
            <Box className={styles.prospectRefresh}>
              <CachedIcon
                className={styles.refreshIcon}
                sx={{ height: "11.98px", width: "12px" }}
              />
              <Typography fontSize={14} fontWeight={500}>
                Refreshing your contacts.
              </Typography>
            </Box>
          </Collapse>
        </>
      ) : (
        <></>
      )}

      {data ? (
        <>
          <Box className={styles.tableSection}>
            <table className={variant == "ranked" ? styles.rankedTable : null}>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {currentRecords.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <>
                        {" "}
                        {getStringAfterUnderscore(cell.id) === "doctor_id" ? (
                          <td
                            key={cell.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(row.original.mid)}
                          >
                            {/* {console.log(cell.id,"value")} */}
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ) : (
                          <td
                            key={cell.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => onRowClick(row.original)}
                          >
                            {/* {console.log(cell.id,"no value")} */}

                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )}
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          {/* Pagination */}
        </>
      ) : (
        <>
          <Container className={styles.fallbackContainer}>{fallback}</Container>
        </>
      )}
      <DeleteMeetingModal
        title={"Are you sure you want to delete the meeting?"}
        isOpen={open}
        onClose={handleCancelDelete}
        onDelete={handleDeleted}
      ></DeleteMeetingModal>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 2,
          background: "#030204",
        }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          sx={{
            "& .Mui-selected": {
              borderRadius: "8px",
              background:
                " var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16)) !important",
              color: "white",
              opacity: "1 !important",
            },
            color: "#7F7E8C !important",
            "& .css-1ybx0qg-MuiButtonBase-root-MuiPaginationItem-root": {
              color: "white !important",
            },
            "& .css-cruynr-MuiPaginationItem-root": {
              color: "white !important",
            },
            "& .css-thzl35": {
              color: "white !important",
            },
            "& .css-1upx99s": {
              color: "#7F7E8C !important",
            },
          }}
        />
      </Box>
    </Container>
  );
};
DataTable.defaultProps = {
  onInteraction: false,
  getTableData: () => {},
  id: null,
  isAllInteraction: false,
  onRowClick: () => {},
  isOnStatistics: false,
};
export default DataTable;
