import React from "react";
import { Typography } from "@mui/material";
import avatar from "./Assets/avatar.svg";
import styled from "@emotion/styled";
import calendarIcon from "./Assets/calendarIcon.svg";
import clockIcon from "./Assets/clockIcon.svg";
import { useNavigate } from "react-router";

const Card_Success = styled("div")({
  boxSizing: "border-box",
  // position: absolute,
  // height: "100%",
  borderRadius: "8px",
  borderBottom:
    "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
  background: "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
});
const StyledContainer = styled("div")({
  display: "flex",
  width: "90%",
  paddingTop: "20px",
  alignItems: "flex-start",
  justifyContent: "space-evenly",
});

const StyledScore = styled(Typography)({
  textAlign: "center",
  fontFamily: "'Red Hat Display'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#636269",
  marginLeft: "5px",
});
const StyledTimeContainer = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "left",
});
const StyledTime = styled(Typography)({
  // border:"1px solid",
  // borderRadius:"21px",
  lineHeight: "20px",
  letterSpacing: "0.01em",
  fontSize: "10px",
  width: "70%",
  textAlign: "left",
  // background:"#464092",
  fontSize: "12px",
  fontWeight: 500,
  color: "rgba(255, 255, 255, 0.50)",
});
const StyledTitle = styled(Typography)({
  textAlign: "left",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "19px",
  color: "rgba(255, 255, 255, 0.80)",
});
const ImageStyled = styled("img")({
  width: "40px",
  height: "40px",
  // "left": "16px",
  // "top": "23px",
  // "background": `url(${imageSrc})), #D9D9D9`,

  borderRadius: "20px",
  // "boxShadow": "0px 0px 4px rgba(0, 0, 0, 0.25)"
});

const DetailsCardInteraction = ({
  imageSrc,
  index,
  onInteraction,
  onClick,
  title,
  status,
  clinic,
  width,
  doctorId,
  currentIndex,
  name,
  mid,
  meetingModalOpen,
  setMeetingModalOpen,
  times,
  date,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (status === "scheduled") {
      const data = {
        imageSrc,
        title,
        status,
        clinic,
        width,
        name,
        times,
        date,
        doctorId,
      };
      navigate("/single-interaction", { state: data });
    }
  };
  const handleScheduledClick = () => {
    onClick(index);
  };
  return (
    <Card_Success
      onClick={
        status === "scheduled" && onInteraction
          ? handleScheduledClick
          : handleClick
      }
      style={{
        width: width,
        marginBottom: "10px",
        cursor: status === "scheduled" ? "pointer" : "auto",
        borderBottomColor: onInteraction && currentIndex === mid && "purple",
        borderWidth: onInteraction && currentIndex === mid && "2px 4px 2px 2px",
      }}
    >
      <StyledContainer>
        <div
          style={{
            width: "20%",
            textAlign: "left",
            marginLeft: "20px",
          }}
        >
          {imageSrc == null ? (
            <ImageStyled src={avatar} alt="icon" />
          ) : (
            <ImageStyled src={imageSrc} />
          )}
        </div>
        <div style={{ width: "80%", marginTop: "5px" }}>
          <StyledTitle>{name}</StyledTitle>
          <StyledTimeContainer>
            {/* <ImageStyled style={{height:"15px",width:"15px"}} src={callIcon} /> */}
            <StyledTime>{title}</StyledTime>
          </StyledTimeContainer>
        </div>
      </StyledContainer>
      {/* <div
        style={{
          marginTop: "1px",
          marginBottom: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <StyledScore style={{ marginLeft: "20px" }}>{clinic}</StyledScore>
      </div> */}
      <div
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginLeft: "20px",
        }}
      >
        {status === "scheduled" ? (
          <>
            <Typography
              sx={{
                fontFamily: "Satoshi",
                fontStyle: "normal",
                fontWeight: 500,
                marginLeft: "5px",
                cursor: "pointer",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.0075em",
                color: "rgba(255, 255, 255, 0.60)",
              }}
            >
              {date}. {times}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              onClick={() => setMeetingModalOpen(true)}
              sx={{
                ":hover": {
                  textDecorationLine: "underline",
                },
                fontFamily: "Satoshi",
                fontStyle: "normal",
                fontWeight: 700,
                marginLeft: "5px",
                cursor: "pointer",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.0075em",
                color: "rgba(255, 255, 255, 0.60)",
              }}
            >
              Schedule Meeting
            </Typography>
          </>
        )}
      </div>
    </Card_Success>
  );
};

export default DetailsCardInteraction;
DetailsCardInteraction.defaultProps = {
  avgScore: "73%",
  date: "",
  times: "",
  setMeetingModalOpen: () => {},
  onInteraction: false,
};
