import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const CustomFileViewer = ({ fileType, filePath, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const PdfViewer = () => {
    return (
      <div style={{ height: "100%" }}>
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            filePath
          )}&embedded=true`}
          title="PDF Viewer"
          width="100%"
          height="100%"
          seamless
        />
      </div>
    );
  };

  const renderWordDocument = () => {
    return (
      <iframe
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          filePath
        )}&embedded=true`}
        title="Word Document"
        width="100%"
        height="500px"
        seamless
      ></iframe>
    );
  };

  const renderImage = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ height: "300px" }} src={filePath} alt="Image" />
      </div>
    );
  };

  const renderFile = () => {
    switch (fileType) {
      case "pdf":
        return PdfViewer();
      case "doc":
      case "docx":
        return renderWordDocument();
      case "jpg":
      case "jpeg":
      case "png":
        return renderImage();
      default:
        return <div>Unsupported file type</div>;
    }
  };

  return <div style={{ width: "100%", height: "100%" }}>{renderFile()}</div>;
};
CustomFileViewer.defaultProps = {
  onClose: () => {},
};
export default CustomFileViewer;
