import React from "react";
import emptyProspectIcon from "./Assets/startIcon.svg";
import styles from "./EmptyProspects.module.css";

const EmptyProspects = ({ title }) => {
  return (
    <div className={styles.container}>
      <img src={emptyProspectIcon} alt="icon" />
      <div className={styles.heading}>{title}</div>
      <div className={styles.subtitle}>Add more prospects to get started</div>
    </div>
  );
};

export default EmptyProspects;
