import React from "react";
import { Divider } from "@mui/material";
import callIcon from "./Assets/callIcon.svg";
import avatar from "./Assets/avatar.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ColoredCircle from "../ColoredCircle/ColorCircle";
import {
  Card_Success,
  ImageStyled,
  LastMeetingInfo,
  LastMeetingInfoHeading,
  StyledContainer,
  StyledMeetingTime,
  StyledScore,
  StyledTime,
  StyledTimeContainer,
  StyledTitle,
} from "./CalledCardStyled";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MeetingResult from "Components/NewDashboard/MeetingResult/MeetingResult";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";

const CalledCard = ({
  imageSrc,
  title,
  status,
  avgScore,
  meetingTime,
  meetingLength,
  doctorName,
  successScore,
  onClick,
  mid,
  index,
  currentIndex,
  doctorId,
  drSpeciality,
  onInteraction,
}) => {
  const user = useSelector((state) => state.setUser.user);
  const navigate = useNavigate();
  const handleClick = () => {
    const data = {
      imageSrc,
      title,
      status,
      avgScore,
      meetingTime,
      meetingLength,
      doctorName,
      successScore,
      onClick,
      mid,
      index,
      drSpeciality,
    };
    onClick(data);
  };
  const handleProspectsClicked = () => {
    const data = {
      name: doctorName,
      imageSrc,
      doctorId,
    };
    navigate("/single-interaction", { state: data });
  };
  return (
    <Card_Success
      onClick={status === "interaction" ? handleClick : handleProspectsClicked}
      style={{
        width: "95%",
        cursor: "pointer",

        borderWidth:
          status === "interaction" && currentIndex === mid
            ? "2px 5px 2px 2px"
            : "2px",
        borderBottomColor:
          status === "interaction" && currentIndex === mid
            ? parseInt(avgScore) <= 30
              ? "red"
              : parseInt(avgScore) > 30 && parseInt(avgScore) <= 49
              ? "orange"
              : "green"
            : "",
        marginRight: "5px",
      }}
    >
      <div style={{ margin: "10px 20px" }}>
        {avgScore <= 30 ? (
          <MeetingResult width={"fit-content"} type={2} title={"Low"} />
        ) : avgScore > 30 && avgScore < 50 ? (
          <MeetingResult width={"fit-content"} type={1} title={"Medium"} />
        ) : (
          <MeetingResult width={"fit-content"} type={0} title={"High"} />
        )}
        <StyledContainer>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {imageSrc == null ? (
              <ImageStyled src={avatar} alt="icon" />
            ) : (
              <ImageStyled src={imageSrc} />
            )}
          </div>

          <div style={{ width: "80%", marginTop: "5px", marginLeft: "5px" }}>
            <StyledTitle>{doctorName}</StyledTitle>
            <StyledTimeContainer>
              {/* <ImageStyled style={{height:"15px",width:"15px"}} src={callIcon} /> */}
              <StyledTime>{drSpeciality}</StyledTime>
            </StyledTimeContainer>
          </div>
        </StyledContainer>

        <LastMeetingInfo>
          <LastMeetingInfoHeading>Last Meeting</LastMeetingInfoHeading>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <StyledMeetingTime>{meetingTime}</StyledMeetingTime>
          </div>
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{ display: "flex", width: "45%", alignItems: "center" }}
            >
              <HalfDonutChartSmall value={avgScore} />
              <div
                style={{
                  color: "rgba(255, 255, 255, 0.80)",
                  textAlign: "center",

                  /* Body/bold */
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "18px" /* 128.571% */,
                }}
              >
                {avgScore}%
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AccessTimeIcon
                fontSize="small"
                sx={{ color: "#ffffff", opacity: "0.8" }}
              />
              <StyledMeetingTime sx={{ marginLeft: "5px" }}>
                {meetingLength}
              </StyledMeetingTime>
            </div>
          </div>
        </LastMeetingInfo>

        {/* <div
          style={{
            marginTop: "10px",
            marginBottom: "05px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <ColoredCircle />
          {onInteraction ? (
            <StyledScore>Avg Affect Score : {avgScore}</StyledScore>
          ) : (
            <StyledScore>
              Avg Affect Score : {`${avgScore.toFixed(2)}%`}
            </StyledScore>
          )}
        </div> */}
        {/* {status === "interaction" && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {successScore.map((item, index) => (
              <div style={{ flexBasis: "10%", marginTop: "5px" }}>
                {item <= 50 ? (
                  <ColoredCircle color={"red"} size={"20px"} />
                ) : item > 50 && item < 70 ? (
                  <ColoredCircle color={"orange"} size={"20px"} />
                ) : (
                  <ColoredCircle size={"20px"} />
                )}
              </div>
            ))}
          </div>
        )} */}
      </div>
    </Card_Success>
  );
};

export default CalledCard;
CalledCard.defaultProps = {
  avgScore: "73",
  onInteraction: false,
};
