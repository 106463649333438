import React, { useState } from "react";
import styles from "./MeetingControls.module.css";
import { Box } from "@mui/material";
import Logo from "../../Assets/iconLogo.svg";
import grid from "../../Assets/Grid.svg";
import Tabs from "../../Assets/Tabs.svg";
import tabsActive from "../../Assets/tabsActive.svg";
import gridActive from "../../Assets/gridActive.svg";
import switchGrid from "../../Assets/switchGrid.svg";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const MeetingControls = ({ setGridIsOn, isGridOn, setIsOn, isOn }) => {
  const toggleSwitch = () => {
    setIsOn(!isOn);
    setGridIsOn(!isGridOn);
  };

  return (
    <>
      <Box className={styles.meetingControls}>
        <Box className={styles.topControls}>
          {/* <img alt="grid-icon" src={grid} /> */}
          <></>
          <img alt="icon" src={Logo} />
          <></>

          {/* <div className={styles.switch} onClick={toggleSwitch}>
            <img
              className={styles.image}
              src={isOn ? tabsActive : Tabs}
              alt={isOn ? "On" : "Off"}
            />
            <img
              className={styles.image}
              src={isGridOn ? gridActive : switchGrid}
              alt={isGridOn ? "On" : "Off"}
            />
          </div> */}
        </Box>
      </Box>
    </>
  );
};

export default MeetingControls;
