import React from "react";
import { Container, Typography, Button, Box, Divider } from "@mui/material";
import styles from "./AccountIntegrations.module.css";
import Logo from "../../Assets/logoTextual.svg";
import { useNavigate } from "react-router-dom";
import CardGrid from "Pages/SalesRep/Integrations/CardGrid";
import VeevaLogo from "../../Assets/veevaLogo.svg";
import ZoomLogo from "../../Assets/zoomLogo.svg";
import MeetsLogo from "../../Assets/meetslogo.svg";

const AccountIntegrations = () => {
  const navigate = useNavigate();

  const cardData = [
    {
      height: "247px",
      logo: ZoomLogo,
      title: "Zoom Meeting",
      subtitle: "View and manage all your meetings.",
      func: "Conferencing",
      connected: true,
    },
    {
      height: "247px",
      logo: MeetsLogo,
      title: "Google Calender",
      subtitle: "Tracking your daily schedule.",
      func: "Calendar",
      connected: true,
    },
    {
      height: "247px",
      logo: VeevaLogo,
      title: "Veeva",
      subtitle: "Fetching all your contacts.",
      func: "CRM",
      connected: "false",
    },
  ];

  return (
    <>
      <Container maxWidth={false} className={styles.verificationContainer}>
        <Box className={styles.header}>
          <img src={Logo} alt="icon" />
        </Box>
        <Container maxWidth={false} className={styles.integrationContainer}>
          <Box className={styles.textContainer}>
            <Box className={styles.leftText}>
              <Typography className={styles.headingText}>
                Let’s connect your toolkit
              </Typography>
              <Typography className={styles.subText}>
                Please connect these softwares that has been recommended by your
                organisation.
              </Typography>
            </Box>
            <Box>
              <Typography>
                <a onClick={() => navigate("/")}> Skip for now </a>
              </Typography>
            </Box>
          </Box>
          <Divider className={styles.divider} />
          <Container className={styles.cardContainer}>
            <CardGrid data={cardData} />
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default AccountIntegrations;
