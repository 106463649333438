import * as React from "react";
import Box from "@mui/material/Box";
import styles from './Loading.module.css'
import LinearProgress from "@mui/material/LinearProgress";

export default function LinearDeterminate({setLoading}) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {  
            setLoading(false)
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + 2, 100);
      });
    }, 25);

    return () => {
      clearInterval(timer);
      
    };
  }, []);

  return (
    <Box
      sx={{
        width: "480px",
        height: "12px",
        backgroundColor: "#e9e9e9",
        borderRadius: "4px",
      }}
    >
      <Box
      className={styles.bar}
        sx={{
          width: `${progress}%`,
          height: "12px",
          borderRadius: "4px",
        }}
      ></Box>
    </Box>
  );
}
