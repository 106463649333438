import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./WeeklyCalender.module.css";
import DayCard from "./DayCard";
import DropDownButton from "Components/Common/DropDownButton/DropDownButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MonthCard from "./MonthCard";

const dayArray = [
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat",
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat",
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat",
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat",
  "Sun",
  "Mon",
  "Tue",
];
const array = ["Mon", "Tue", "Wed", "Thur", "Fri"];

const WeeklyCalender = ({
  totalDays,
  isHome,
  setFirstDate,
  setMonth,
  month,
  setDates,
  selectedMonth,
  setSelectedMonth,
  set,
  setter,
}) => {
  const filteroptions = [
    { id: "1", value: "Daily" },
    { id: "2", value: "Monthly", selected: true },
  ];
  const dummyOptions = [
    { id: "0", value: "January" },
    { id: "1", value: "February" },
    { id: "2", value: "March" },
    { id: "3", value: "April" },
    { id: "4", value: "May" },
    { id: "5", value: "June", selected: true },
    { id: "6", value: "July" },
    { id: "7", value: "August" },
    { id: "8", value: "September" },
    { id: "9", value: "October" },
    { id: "10", value: "November" },
    { id: "11", value: "December" },
  ];
  const [days, setDays] = useState([]);
  const [monthdates, setMonthdates] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(0);

  function getDatesOfMonth(d) {
    const monthsWith30Days = [4, 6, 9, 11];
    const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
    d = new Date(d);
    const year = d.getFullYear();
    const month = d.getMonth();
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);
    const datesArray = [];

    for (let i = firstDate.getDate(); i <= lastDate.getDate(); i++) {
      datesArray.push(new Date(year, month, i));
    }

    return datesArray;
  }

  useEffect(() => {
    const currentDate = new Date();
    const selectedDate = new Date(currentDate.getFullYear(), month, 1);
    const dates = getDatesOfMonth(selectedDate);

    const dummyDays = dates.map((date, index) => ({
      id: index + 1,
      dayNum: date.getDate(),
      dayName: date.toLocaleDateString("en-US", { weekday: "short" }),
      dayMonth: months[date.getMonth()],
      monthDate: date.getMonth() + 1,
      year: date.getFullYear(),
    }));

    // Filter out Saturdays and Sundays
    const filteredDays = dummyDays.filter((day) => {
      const dayOfWeek = new Date(
        day.year,
        day.monthDate - 1,
        day.dayNum
      ).getDay();
      return dayOfWeek !== 0 && dayOfWeek !== 6; // 0 = Sunday, 6 = Saturday
    });

    setMonthdates(filteredDays);
  }, [month]);

  function getMonday(d) {
    const monthsWith30Days = [4, 6, 9, 11];
    const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
    d = new Date(d);
    let year = d.getFullYear();
    let monthDate = d.getMonth() + 1;
    let month = months[d.getMonth()];
    let day = d.getDay();
    let diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday

    // Adjust the first date and month if it falls into the previous month
    if (diff <= 0) {
      monthDate = monthDate === 1 ? 12 : monthDate - 1; // Move to the previous month
      year = monthDate === 12 ? year - 1 : year; // Decrement year if moving to December
      let lastDateOfPrevMonth = new Date(year, monthDate, 0).getDate();
      diff = lastDateOfPrevMonth + diff;
    }

    let firstDate = new Date(d.setDate(diff)).getDate();
    let dummyDays = [];

    for (let i = 0; i < totalDays; i++) {
      if (
        (monthDate === 2 && firstDate > 28) || // February has 28 days
        (monthsWith30Days.includes(monthDate) && firstDate > 30) || // Months with 30 days
        (monthsWith31Days.includes(monthDate) && firstDate > 31) // Months with 31 days
      ) {
        monthDate = monthDate === 12 ? 1 : monthDate + 1; // Move to the next month
        year = monthDate === 1 ? year + 1 : year; // Increment year if moving to January
        firstDate = 1; // Start from the first date of the next month
      }
      let myObj = {
        id: i + 1,
        dayNum: firstDate,
        dayName: isHome ? array[i] : dayArray[i],
        dayMonth: month,
        monthDate: monthDate,
        year: year,
      };
      dummyDays.push(myObj);
      firstDate++;
    }
    setDays(dummyDays);
  }

  const date = new Date();
  const day = date.getDate();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    getMonday(new Date());
  }, []);

  const handleMonthClick = (index) => {
    setSelectedMonth(index);
  };

  return (
    <Box className={styles.weeklyCalenderContainer}>
      {days && days.length > 1 ? (
        <>
          <Box
            className={styles.dateContainer}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className={styles.dateText}>
              Today, {days[0].dayMonth + " " + day}
            </Typography>

            {!isHome && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <DropDownButton
                    Icon={KeyboardArrowDownIcon}
                    background="#ffffff"
                    variant="outlined"
                    size="medium"
                    options={filteroptions}
                    isMonthly={true}
                    setter={setter}

                    // setMonth={setMonth}
                  />
                </div>
                {set == "1" && (
                  <DropDownButton
                    Icon={KeyboardArrowDownIcon}
                    background="#ffffff"
                    variant="outlined"
                    size="medium"
                    options={dummyOptions}
                    setMonth={setMonth}
                  />
                )}
              </Box>
            )}
          </Box>

          <Box className={styles.cardList}>
            {isHome
              ? days.map((day, index) => (
                  <DayCard
                    dayData={days}
                    selectedComponent={selectedComponent}
                    setSelectedComponent={setSelectedComponent}
                    setFirstDate={setFirstDate}
                    todayData={day}
                    dayNum={day.dayNum}
                    dayName={day.dayName}
                    onClick={() => {}}
                    index={index}
                  />
                ))
              : set == "1"
              ? monthdates.map((date, i) => (
                  <DayCard
                    dayData={monthdates}
                    isAnalytics={true}
                    selectedComponent={selectedComponent}
                    setSelectedComponent={setSelectedComponent}
                    setFirstDate={setFirstDate}
                    todayData={date}
                    dayNum={date.dayNum}
                    dayName={date.dayName}
                    onClick={() => console.log(i)}
                    index={i}
                  />
                ))
              : set === "2" &&
                months.map((date, i) => (
                  <MonthCard
                    key={i}
                    month={date}
                    handleMonthClick={handleMonthClick}
                    selectedMonth={selectedMonth}
                    index={i}
                    setDates={setDates}
                  />
                ))}
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default WeeklyCalender;

WeeklyCalender.defaultProps = {
  setFirstDate: () => {},
};
