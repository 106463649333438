import React, { useState } from "react";
import loginBackground from "../Login/Assets/loginBackground.png";
import logo from "../../Pages/Login/Assets/Logo.svg";
import SigninTextField from "Components/NewDashboard/SigninTextField/SigninTextField";
import Button from "Components/NewDashboard/Button/Button";
import HorizontalDivider from "Components/NewDashboard/Divider/HorizontalDivider";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "Api/Firebase/config";
import "firebase/compat/auth";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import styles from "../Login/Login.module.css";
import { toast } from "react-toastify";
import { setUser } from "Store/features/setUserSlice";
import { userEmailSignup, verifyEmail } from "Api/Backend/backend";
import Loading from "Pages/Misc/Loading/Loading";

const Registration = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  firebase.initializeApp(firebaseConfig);
  const provider = new firebase.auth.GoogleAuthProvider();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rePassword, setRePassword] = useState(null);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else {
      setRePassword(e.target.value);
    }
  };
  const handleSignUpWithEmailPassword = async (email, password) => {
    try {
      const userExists = await firebase
        .auth()
        .fetchProvidersForEmail(email)
        .then((providers) => {
          if (providers.length === 0) {
            return false;
          } else {
            return true;
          }
        });
      if (!userExists) {
        const userCredential = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        const user = userCredential.user;
        user.sendEmailVerification();
        return user;
      }
    } catch (error) {
      toast.error("already exists");
    }
  };

  const signUpWithGoogle = async () => {
    try {
      const userCredential = await firebase.auth().signInWithPopup(provider);
      const user = userCredential.user;
      return user;
    } catch (error) {
      return error;
    }
  };

  const handleEmailPasswordForm = async (e) => {
    if (password === rePassword) {
      const newUser = await handleSignUpWithEmailPassword(email, password)
        .then((response) => {
          return response;
        })
        .then((resp) => {
          const data = resp._delegate;
          const newUser = {
            firebase_id: data.uid,
            first_name: "firstName",
            last_name: "lastName",
            email: email,
            password: password,
            role: "typeAccount",
            organization_name: "orgName",
            organization_type: "Pharmaceutical Sales",
            user_image: "imageString",
            first_time_user: true,
            role: "manager",
          };
          userEmailSignup(newUser);
          navigate("/verification", { state: newUser });
        })
        .catch(() => {
          toast.error("User already exists");
        });
    } else {
      toast.error("Passwords don't match");
    }
  };

  const handleSignUpWithGoogle = async () => {
    const account_type = "sales_rep";
    const organization_name = "AA Solutions";
    const newUser = await signUpWithGoogle()
      .then((response) => {
        return response;
      })
      .then((data) => {
        let user = firebase.auth().currentUser;

        const user_data = user._delegate;

        const newUser = {
          firebase_id: user_data.uid,
          first_name: user_data.displayName.split(" ")[0],
          last_name: user_data.displayName.split(" ")[1],
          email: user_data.email,
          password: user_data.displayName,
          role: "typeAccount",
          organization_name: "orgName",
          organization_type: "Pharmaceutical Sales",
          user_image: "imageString",
          first_time_user: true,
          role: "sales_rep",
        };

        userEmailSignup(newUser)
          .then((response) => {})
          .catch((e) => {
            console.log(e);
          })
          .then((response) => {
            verifyEmail(newUser.email);
            dispatch(setUser(newUser));
            sessionStorage.setItem("user", JSON.stringify(newUser));
            navigate("/");
          })
          .catch((e) => {
            console.log(e);
          });
      });
  };
  return (
    <div>
      {loading ? (
        <>
          <Loading setLoading={setLoading} />
        </>
      ) : (
        <div
          style={{
            backgroundImage: `url(${loginBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        >
          <div className={styles.registrationContainer}>
            <div
              style={{ marginBottom: "50px", marginTop: "100px" }}
              className={styles.loginFormContainer}
            >
              <img src={logo} />
              <div
                style={{
                  width: "100%",
                  marginTop: "3vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "1vh",
                }}
              >
                <div className={styles.welcomeTitle}>
                  Start your AffectCX journey now
                </div>
                <div className={styles.subTitle}>Create your account </div>
              </div>
              <SigninTextField onChange={handleChange} name="email" />
              <SigninTextField
                onChange={handleChange}
                name="password"
                isPassword={true}
                label={"Password"}
              />
              <SigninTextField
                onChange={handleChange}
                name="re-password"
                isPassword={true}
                label={"Re-enter password"}
              />
              <div className={styles.RememberMeContainer}>
                <div className={styles.RememberMeText}>
                  <input type="checkbox" />
                  Remember Me
                </div>
                <div className={styles.forgotText}>Forgot Password?</div>
              </div>
              <Button onClick={handleEmailPasswordForm} />
              <HorizontalDivider />
              <Button
                onClick={handleSignUpWithGoogle}
                primaryButton={false}
                title={"Sign in through google"}
              />
              <div className={styles.termsText}>
                By signing up, you agree to our{" "}
                <a href="#">Terms & Conditions</a> and{" "}
                <a href="#">Privacy Policy</a>.
              </div>
            </div>
            {/* <Container maxWidth={false} className={styles.signUp}>
          <Container maxWidth={false} className={styles.backgroundTemplate}>
          <Box className={styles.blueContainer}>
          <Box className={styles.blueRect}>
          <img alt="icon" src={OnboardingIllustration} />
          <Typography className={styles.blueRectText}>
          Get Live feedbacks using AI for better engagement.
                </Typography>
                </Box>
              <Box className={styles.blueTri}>
              <img
              alt="icon"
              src={CurvedTriangle}
              className={styles.triangleImage}
              />
              </Box>
              </Box>
          </Container>
          <Box className={styles.signUpContainer}>
          <Box className={styles.spaceContainer}>
              <Box className={styles.spacer}></Box>
              <Box className={styles.formContainer}>
                <LoginForm />
                </Box>
                </Box>
          </Box>
        </Container> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Registration;
