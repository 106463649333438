import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./WeeklyCalender.module.css";

const MonthCard = ({
  month,
  index,
  handleMonthClick,
  selectedMonth,
  setDates,
}) => {
  const [selected, setSelected] = useState(false);

  const handleClick = () => {
    setSelected(true);
    const generatedDates = generateMonthDates();
    setDates(generatedDates);
    handleMonthClick(index);
  };

  const generateMonthDates = () => {
    // Logic to generate the dates of the selected month
    // Replace this with your own implementation
    const currentDate = new Date();
    const selectedMonth = index;
    const selectedYear = currentDate.getFullYear();

    const firstDay = new Date(selectedYear, selectedMonth, 1);
    const lastDay = new Date(selectedYear, selectedMonth + 1, 0);

    const dates = [];
    for (let date = firstDay.getDate(); date <= lastDay.getDate(); date++) {
      dates.push(date);
    }
    return dates;
  };

  return (
    <Box
      onClick={handleClick}
      className={
        index === selectedMonth
          ? styles.monthCardContainerSelected
          : styles.monthCardContainer
      }
      sx={{ marginRight: "12px" }}
    >
      <div className={styles.cardContent}>
        <Typography
          className={
            index === selectedMonth ? styles.cardTextSelected : styles.cardText
          }
        >
          {month}
        </Typography>
      </div>
    </Box>
  );
};

export default MonthCard;
