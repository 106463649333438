import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Typography } from "@mui/material";

Chart.register(ArcElement);

const HalfDonutChart = ({ value, x, y }) => {
  const tranX = x | 4.5;
  const tranY = y | -8;

  const data = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: ["#4C0ABF", "#E5E5E5"],
        display: true,
        borderColor: "#ffffff",
      },
    ],
  };

  return (
    <div>
      <Doughnut
      style={{position:"relative"}}
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          rotation: -90,
          circumference: 180,
          cutout: "85%",
          maintainAspectRatio: false,
          responsive: true,
        }}
      />
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          transform: `translate(${tranX}px,${tranY}vh)`,
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={700}
          fontFamily={"Red Hat Display !important"}
          sx={{ color: "#201F28" }}
        >
          {value}%
        </Typography>
        <Typography
          fontSize={14}
          fontWeight={500}
          fontFamily={"Satoshi-Variable"}
          sx={{ color: "#77767C", mt: 1 }}
        >
          Avg. Affect Score
        </Typography>
      </div>
    </div>
  );
};

export default HalfDonutChart;
