import React, { useState } from "react";
import styles from "./card.module.css";
import { Typography, Box } from "@mui/material";
const NotesCard = ({ timeStamp, data, inMeeting }) => {
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const [time, setTime] = useState(getCurrentTime);
  return (
    <Box className={styles.container}>
      {inMeeting ? (
        <>
          <Typography className={styles.description}>{data}</Typography>
          <Typography className={styles.timeStamp}>{time}</Typography>
        </>
      ) : (
        <>
          <Typography
            dangerouslySetInnerHTML={{ __html: data }}
            className={styles.description}
          ></Typography>
          <Typography className={styles.timeStamp}>{timeStamp}</Typography>
        </>
      )}
    </Box>
  );
};
NotesCard.defaultProps = {
  timeStamp: "Yesterday",
  inMeeting: false,
};

export default NotesCard;
