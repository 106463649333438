import React, { useEffect, useState } from "react";
import { Badge, Avatar } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { updateUserPicture } from "Api/Backend/backend";
import { toast } from "react-toastify";
import { setUser } from "Store/features/setUserSlice";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../Api/Backend/backend";
const axios = require("axios");
const BadgeAvatar = ({
  height,
  width,
  bHeight,
  bWidth,
  userImage,
  userId,
  setSelectedImage,
  selectedImage,
}) => {
  const dispatch = useDispatch();
  // const [file, setFile]= useState(null)
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userId);

    const response = updateUserPicture(formData)
      .then(async () => {
        toast.success("Picture Updated");
        const uid = sessionStorage.getItem("firebase");
        const getUser = await axios
          .post(
            `${baseUrl}get_user_by_firebaseid/?firebase_id=${uid}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            if (response.data) {
              dispatch(setUser(response.data));
              sessionStorage.setItem("user", JSON.stringify(response.data));
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (userImage) {
      setSelectedImage(userImage);
    }
  }, [userImage]);
  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };
  return (
    <>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // badgeContent={
        //   <Avatar
        //     sx={{
        //       height: { bHeight },
        //       width: { bWidth },
        //       border: "3px solid #ffffff",
        //       backgroundColor: "#FF974C",
        //       cursor: "pointer",
        //     }}
        //     alt="Edit"
        //   >
        //     <EditOutlinedIcon onClick={handleIconClick} />
        //   </Avatar>
        // }
      >
        {userImage ? (
          <Avatar
            sx={{
              height: { height },
              width: { width },
            }}
            src={selectedImage}
            alt="Profile Picture"
          />
        ) : (
          <Avatar
            sx={{
              height: { height },
              width: { width },
              border: "4px solid #ffffff",
            }}
            alt="Profile Picture"
          ></Avatar>
        )}
      </Badge>
    </>
  );
};
BadgeAvatar.defaultProps = {
  setSelectedImage: () => {},
};
export default BadgeAvatar;
