import React from "react";
import Pill from "../Assets/Pill.svg";
import styles from "./engagement.module.css";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";

const EngagementInfoBox = ({
  title,
  value,
  type,
  talkValue,
  listenValue,
  fontSize,
}) => {
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {type === 0 ? (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div style={{ fontSize: fontSize }} className={styles.valueText}>
            {value}
          </div>
          <img src={Pill} />
        </div>
      ) : type === 1 ? (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div style={{ fontSize: fontSize }} className={styles.valueText}>
            {talkValue + " "}
            <span className={styles.ttlrcolon}>:</span>
            {" " + listenValue}
          </div>
          <img src={Pill} />
        </div>
      ) : type === 2 ? (
        <div style={{ display: "flex", gap: "8px" }}>
          <div style={{ fontSize: fontSize }} className={styles.valueText}>
            {value}
          </div>
          <div className={styles.subtitle}>per call</div>

          <img src={Pill} />
        </div>
      ) : (
        <DonutChartSmall
          width={"50%"}
          value={value}
          x={40}
          cutout={"90%"}
          fontSize={"16px"}
          y={-2.3}
          barColor={barBg(value)}
        />
      )}
    </div>
  );
};
EngagementInfoBox.defaultProps = {
  title: "",
  value: "",
  type: 0,
  talkValue: "",
  listenValue: "",
  fontSize: "30px",
};

export default EngagementInfoBox;
