import React from "react";
import { Divider } from "@mui/material";
import classes from "./divider.module.css";
import checkbox from "../../Assets/Checkbox.svg";
const VerticalDividerWithCheckbox = () => {
  return (
    <Divider
      sx={{
        width: "2px",
        alignItems: "center",
        backgroundColor: "#222224",
      }}
      flexItem
      orientation="vertical"
      //   className={classes.divider}
    >
      <img alt="check-box" style={{ zIndex: 10 }} src={checkbox} />
    </Divider>
  );
};

export default VerticalDividerWithCheckbox;
