import React, { useEffect, useRef } from "react";
import { Modal, Typography, Box, ClickAwayListener } from "@mui/material";
import closeIcon from "./Assets/Icon.svg";

import styles from "./../../../Components/NewDashboard/AddProducts/ScheduleMeetingModal.module.css";

const ModalComp = ({ open, isClose, title, children }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    borderRadius: "12px",
    background: "var(--theme-dark-0, #222224)",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "95vh",
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    overflowY: "scroll",
    boxSizing: "border-box",
    margin: 0,
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // padding: "10px",
  };

  const closeButtonStyle = {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    // background: "black",
    border:"2px solid white",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
  };

  const childContainer = {
    marginTop:"20px"
  };

  const buttonRef = useRef();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        buttonRef.current.click();
      }
    };
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const handleClickAway = (e) => {
    e.stopPropagation();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    borderRadius: "12px",
    background: "var(--theme-dark-0, #222224)",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "95vh",
    padding: "20px",
    overflowY: "scroll",
  };

  
  

  return (
    <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
    <Modal
      open={open}
      onClose={isClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
          <div className={styles.headerContainer}>
            <div className={styles.dialogueTitle}>{title}</div>
            <img
              style={{ cursor: "pointer" }}
              onClick={isClose}
              src={closeIcon}
              alt="close icon"
            />
            
          </div>
          <div style={childContainer}>
          {children}
        </div>
         
   </div>
    </Modal>
    </ClickAwayListener>
  );
};

export default ModalComp;
