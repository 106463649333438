import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    user: {}
}

export const setUserSlice = createSlice({
    name: 'setUser',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        logOut: (state) => {
            state.user = {}
        }
    }
})

export const { setUser, logOut } = setUserSlice.actions

export default setUserSlice.reducer