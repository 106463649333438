import React from "react";
import moduleTitleIcon from "../../Assets/moduleTitleIcon.svg";
import trainingScoreIcon from "../../Assets/trainingScoreIcon.svg";
import clockIcon from "../../Assets/clockIcon.svg";
import { Typography } from "@mui/material";
import styles from "./moduledetails.module.css";
import SearchBar from "../SearchBar/SearchBar";
const ModuleDetails = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.moduleContainer}>
        <img src={moduleTitleIcon} alt="icon" />
        <div>
          <Typography className={styles.subtitle}>
            Breathing and Resetting
          </Typography>
          <Typography className={styles.title}>Nature’s Reset Vol 1</Typography>
        </div>
      </div>
      <div className={styles.flexBetween}>
        <div className={styles.flexRow}>
          <img src={trainingScoreIcon} />
          <Typography className={styles.scoreIconText}> +130</Typography>
        </div>
        <div className={styles.flexRow}>
          <img src={clockIcon} />
          <Typography className={styles.timeText}> 33:10 Min</Typography>
        </div>
      </div>
      <SearchBar width={"100%"} placeholder={"Search inside module"} />
    </div>
  );
};

export default ModuleDetails;
