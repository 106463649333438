import React from "react";
import styles from "./salesrepleader.module.css";
import InfoCard from "../InfoCard/InfoCard";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";

const SalesRepLeaderBoard = ({
  index,
  name,
  icon,
  value,
  isTopClient,
  subTitle,
}) => {
  return (
    <div className={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={styles.index}>{index + 1}</div>
        {isTopClient ? (
          <InfoCard
            subTitle={subTitle}
            title={name}
            width={"max-content"}
            icon={icon}
          />
        ) : (
          <InfoCard subTitle={subTitle} title={name} icon={icon} />
        )}
      </div>
      {!isTopClient && (
        <div style={{ display: "flex", alignItems: "center", width: "20%" }}>
          <HalfDonutChartSmall value={value} />
          <div className={styles.valueText}>{value}%</div>
        </div>
      )}
    </div>
  );
};
SalesRepLeaderBoard.defaultProps = {
  index: 0,
  value: 93,
  isTopClient: false,
  name: "Dr. Jessy Brand",
  subTitle: "",
};

export default SalesRepLeaderBoard;
