import React from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./searchBar.module.css";
import { InputAdornment } from "@mui/material";
function SearchBar({ width, placeholder }) {
  return (
    <div style={{ width: width, marginBottom: "20px" }}>
      <TextField
        fullWidth
        className={styles.mainContainer}
        sx={{
          "& .MuiInputBase-input::placeholder": {
            color: "#C3C2C5", // Set the desired placeholder color
          },
        }}
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton aria-label="Search">
                <SearchIcon
                  sx={{
                    color: "#C3C2C5",
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
SearchBar.defaultProps = {
  width: "25%",
  placeholder: "Search courses, lessons and more",
};
export default SearchBar;
