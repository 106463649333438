import React from "react";
import Welcome from "./Welcome";
import InMeeting from "./InMeeting";
import EndMeeting from "./EndMeeting";

const Screen = ({
  active,
  meetingTitle,
  mid,
  note,
  doc_list,
  isBotCohost,
  doctorName,
}) => {
  return (
    <>
      {active == "welcome" ? <Welcome /> : null}
      {active == "inmeeting" ? (
        <InMeeting
          isBotCohost={isBotCohost}
          meetingTitle={meetingTitle}
          mid={mid}
          doctorName={doctorName}
          note={note}
          doc_list={doc_list}
        />
      ) : null}
      {active == "endmeeting" ? <EndMeeting /> : null}
    </>
  );
};

export default Screen;
