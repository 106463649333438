import React,{useState, useEffect} from "react";
import styles from "./team.module.css";
import AddIcon from "@mui/icons-material/Add";
import UserCard from "Pages/ManagersFlow/Components/UserCard/UserCard";
import {
  Typography,
  Card,
  Button,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import TeamCard from "Pages/AdminFlow/Components/teams/TeamCard";
import AddTeamModal from "Pages/AdminFlow/Components/teams/AddTeamModal";

import { getAllTeams} from "Api/Backend/backend";
import { useSelector } from "react-redux";
import { useNavigate, } from "react-router";

const Teams = () => {
  const navigate =useNavigate();

  const [loader,setLoader] = useState(false)
  const [data, setData] = useState([])
  const [open,setOpen]=useState(false);
  const userReducer = useSelector((state) => state.setUser.user);
  const {user_id} = userReducer

  const handleGetTeam = () => {
    if(!user_id) return
    const payload ={user_id:user_id}

    setLoader(true)
    getAllTeams(payload)
    .then((res)=>{
      setData(res.data)
      setLoader(false)
    })
    .catch((err)=>{
      setLoader(false)
    })

  }
  const handleTeamClick = (item) => {
   
    navigate("/setting", { state: { teamData: item } });
  };

  useEffect(()=>{
    handleGetTeam()
  },[open])

  return (
    <div className={styles.container}>
      <div className={styles.wrapper1}>
        <UserCard
          title={`Admin`}
          subTitle={"Super Admin Account"}
          // icon={currentUser.user_image}
        />
      </div>
      <div className={styles.wrapper2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={styles.title}>Manage Teams</Typography>
          <Button
            sx={{
              background: "#551ba0",
              display: "flex",
              padding: "13px 20px 13px 13px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "12px",
              border: "none",
              color: "var(--neutrals-primary-white, #fff)"
            }}
            onClick={() => setOpen(true)}
          >
            {" "}
            <AddIcon
              className={styles.plusIcon}
              sx={{ height: "20px", width: "20px" }}
            />
            Add Team
          </Button>
          

        </Box>
        <Grid container spacing={6}>
          {loader && (
            <Grid item md={12}>
               <CircularProgress size={36} style={{marginTop:"30px"}}/>
            </Grid>
          )}
          {!loader && data.length === 0 && (
            <Grid item md={12}>
                <Typography style={{fontSize:"20px", marginTop:"30px", color:"white"}}>No Team available !</Typography>
            </Grid>
          )}
          {/* {console.log(data)} */}
          {!loader && data && data.length > 0 && data.map((item)=>(
               <Grid item md={4}>
                <div style={{cursor:"pointer"}} onClick={(e) => handleTeamClick(item)}>
               <TeamCard data={item}/>
               </div>
             </Grid>
          ))}
       
        </Grid>
      </div>
      <AddTeamModal meetingModalOpen={open} setMeetingModalOpen={setOpen} id={user_id} />
    </div>
  );
};

export default Teams;
