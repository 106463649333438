import React from "react";
import styles from "./ClientInfo.module.css";
import { Avatar, Box, Divider, Typography } from "@mui/material";
import StethoscopeIcon from "../../../../Assets/stethoscopeIcon.svg";
import ClinicIcon from "../../../../Assets/clinicIcon.svg";

const ClientInfo = () => {
  return (
    <>
      <Box className={styles.basicInfo}>
        <Avatar alt="profile_picture" className={styles.avatar}>
          JD
        </Avatar>
        <Typography className={styles.name}>Dr.John Doe, MD</Typography>
        <Typography className={styles.email}>johndoe@divine.com</Typography>
      </Box>
      <Divider />
      <Box className={styles.workInfo}>
        <Box className={styles.speciality}>
          <Box className={styles.iconWithLabel}>
            <img src={StethoscopeIcon} className={styles.icon} alt="icon" />
            <Typography className={styles.label}>Speciality</Typography>
          </Box>
          <Box className={styles.value}>
            <Typography className={styles.value}>General Physician</Typography>
          </Box>
        </Box>
        <Box className={styles.speciality}>
          <Box className={styles.iconWithLabel}>
            <img src={ClinicIcon} alt="icon" className={styles.icon} />
            <Typography className={styles.label}>Clinic</Typography>
          </Box>
          <Box className={styles.value}>
            <Box className={styles.clinicInfo}>
              <Typography className={styles.value}>Divine Clinic</Typography>
              <Typography className={styles.location}>Toronto, CA</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ClientInfo;
