import React, { useEffect, useState } from "react";
import styles from "./AccountInfo.module.css";
import InputField from "Components/Common/InputField/InputField";
import InfoBlock from "Components/Common/InfoBlock/InfoBlock";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import UserIcon from "./Assets/AccountIcon.svg";
import EmailIcon from "./Assets/MailIcon.svg";
import styles1 from "./AccountInfo.module.css";
import stylesAutoComplete from "../../../../../Components/NewDashboard/AddProducts/ScheduleMeetingForm.module.css";
// import BuildingIcon from "../../../../Assets/buildingIcon.svg";
// import DoubleUserIcon from "../../../Assets/doubleUserIcon.svg";
import BriefCaseIcon from "./Assets/BriefCaseIcon.svg";
import BriefcaseIcon from "./Assets/BriefCaseIcon.svg";
import { EditTeam, updateUserProfile } from "Api/Backend/backend";
import { toast } from "react-toastify";
import { setUser } from "Store/features/setUserSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import { baseUrl } from "Api/Backend/backend";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import organisationIcon from "./Assets/organisationIcon.svg";
import {
  GetDirectors,
  GetDocs,
  GetDoctors,
  GetManagers,
} from "Api/Backend/backend";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";

const AccountInfo = ({
  isEditMode,
  setEditMode,
  teamData,
}) => {
  const [editData, setEditData] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [director, setDirector] = useState({});
  const [manager, setManager] = useState({});
  const [managerlist, setManagerList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [organizationName, setOrganizationName] = useState(
    editData.organization_name
  );
  const [organizationType, setOrganizationType] = useState(
    editData.organization_type
  );
  const [region, setRegion] = useState(editData?.team_name);
  const userReducer = useSelector((state) => state.setUser.user);
  const { user_id } = userReducer;
  const dispatch = useDispatch();
  useEffect(() => {
    if (editData.length === 0) {
      setEditData(teamData);
    }
  }, []);

  const getManager = (id) => {
    GetManagers(id)
      .then((response) => {
        setManagerList(response);
        const manager = response.find((man) => man.user_id === teamData.manager_id)
        if (manager) {
          setManager(manager);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const getDirector = (id) => {
    GetDirectors(id)
      .then((response) => {
        setDirectorList(response);
        const director = response.find((dir) => dir.user_id === teamData.director_id);
        if (director) {
          setDirector(director);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (user_id) {
      getManager(user_id);
      getDirector(user_id);
    }
  }, []);

  const handleManager = (event, value) => {
    setManager(value);
  };
  const handleDirector = (event, value) => {
    if(isEditMode){
      setDirector(value);
    }
    return;
  };

  const updateTeam =()=>{
    const postData = {
          team_id:teamData.team_id,
          director_id:director.user_id,
          manager_id:manager.user_id,
          organization_name: "AMGEN",
          team_name: name,
        };
    const response = EditTeam(postData)
        .then(async (res) => {
        toast.success("Team Updated!");
        setEditMode(false);
        })
        .catch((e) => {
                console.log(e);
        });


  }

  // function separateNames(fullName) {
  //   const namesArray = fullName.split(" ");
  //   const firstName = namesArray[0];
  //   const lastName = namesArray.slice(1).join(" ");

  //   return {
  //     first_name: firstName,
  //     last_name: lastName,
  //   };
  // }
  // const updateUser = () => {
  //   const sepName = separateNames(name);
  //   const first_name = sepName.first_name;
  //   const last_name = sepName.last_name;

  //   const postData = {
  //     user_id: userId,
  //     first_name: first_name,
  //     last_name: last_name,
  //     role: role,
  //     organization_name: organizationName,
  //     organization_type: organizationType,
  //     team_name: region,
  //   };
  //   const response = updateUserProfile(postData)
  //     .then(async (res) => {
  //       toast.success("Profile Updated");
  //       setEditMode(false);
  //       const uid = sessionStorage.getItem("firebase");
  //       const getUser = await axios
  //         .post(
  //           `${baseUrl}get_user_by_firebaseid/?firebase_id=${uid}`,
  //           {},
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Accept: "application/json",
  //               "Access-Control-Allow-Origin": "*",
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           if (response.data) {
  //             dispatch(setUser(response.data));
  //             sessionStorage.setItem("user", JSON.stringify(response.data));
  //           }
  //         })
  //         .catch((e) => {
  //           console.log(e);
  //         });
  //       setData(postData);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  useEffect(() => {
    

    setName(editData.team_name);
    setDirector(editData.director_id);
    setOrganizationName(editData.organization_name);
    setOrganizationType(editData.organization_type);
    setRegion(editData.team_name);
  }, [editData]);
  return (
    <>
      <Grid item className={styles.itemContainer}>
        <Box className={styles.buttonContainer}>
          <div className={styles.headingText}>Team Settings</div>
          <div >
            <Button
              onClick={() => setEditMode(true)}
              className={
                isEditMode ? styles.submitButtonDisabled : styles.editButton
              }
              disabled={isEditMode ? true : false}
              >
              Edit
            </Button>
            <></>
            <Button
              onClick={updateTeam}
              sx={{marginLeft:"20px"}}
              className={
                !isEditMode ? styles.submitButtonDisabled:styles.submitButton
              }
              disabled={isEditMode ? false : true}
              >
            Save
          </Button>
          </div>
        </Box>
      </Grid>
      <Grid container className={styles.settingsGrid}>
        <Grid xs={6} className={styles.gridItem}>
          <InfoBlock
            heading="Team info"
            description="You can update your team information here "
            headingFontSize={20}
            headingFontWeight={700}
          />
        </Grid>

        <Grid xs={6} spacing={8} className={styles.gridItem}>
          <>
            {" "}
            <Grid item className={styles.item}>
              <InputField
                value={name}
                editable={isEditMode}
                startIcon={UserIcon}
                setter={setName}
              />
            </Grid>
            {/* <Grid item className={styles.item}>
              <InputField
                value={editData.email}
                editable={false}
                startIcon={EmailIcon}
              />
            </Grid> */}
            <Grid item className={styles.item} >

              <InputField
                
                value={"AMGEN"}
                editable={false}
                startIcon={BriefCaseIcon}
                setter={setRole}
              />
            </Grid>
          </>
        </Grid>
      </Grid>
      <>
        <Divider />
        <Box sx={{ mt: 5 }}></Box>
        <Grid container className={styles1.settingsGrid}>
          <Grid xs={6} className={styles1.gridItem}>
            <InfoBlock
              heading="Team Manager"
              description="Assign manager to your team here."
              headingFontSize={20}
              headingFontWeight={700}
            />
          </Grid>
          <Grid xs={6} spacing={8} className={styles1.gridItem}>
            {/* <Grid item className={styles.item}>
              <InputField
                value={manager}
                editable={isEditMode}
                startIcon={UserIcon}
                setter={setManager}
              />
            </Grid> */}
           
            <Grid item className={styles.item}>
            <Box
              sx={{
                backgroundColor: "rgba(64, 64, 64) !important",
                padding: "13px",
                width: "440px",
                paddingLeft:"21px",
                borderRadius: "10px",
              }}
              className={stylesAutoComplete.formRow}
            >
              <>
                <AccountCircleIcon fontSize="small" sx={{ color: "#77767C" }} />
                {console.log(manager)}
                {Array.isArray(managerlist) && managerlist.length > 0 ? (
                  <Autocomplete
                    popupIcon={
                      <Add sx={{ color: "rgba(255, 255, 255, 0.50)" }} />
                    }
                    clearIcon={
                      <KeyboardBackspaceIcon
                        sx={{ color: "rgba(255, 255, 255, 0.50)" }}
                      />
                    }
                    sx={{ width: "440px" }}
                    options={managerlist}
                    getOptionLabel={(option) => option.email}
                    filterSelectedOptions
                    defaultValue={"Add Manager"}
                    onChange={handleManager}
                    value={manager}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Assign Manager"
                        sx={{
                          "& input": {
                            color: "rgba(255, 255, 255, 0.50) !important",
                          },
                          "& .MuiInput-underline:hover:before": {
                            borderBottom: "none !important", // Customize the hover border color here
                          },
                          "& .MuiInput-underline:before": {
                            borderBottom: "none !important", // Customize the border color here
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: "none !important", // Customize the focus border color here
                          },
                        }}
                      />
                    )}
                      renderOption={(props, option) => (
                        <Box sx={{
                          background: "rgba(64, 64, 64, 1)", color: "rgba(255, 255, 255, 0.50)",
                          "&: hover": { backgroundColor: "rgba(64, 64, 64, 0.8) !important", color: "rgba(255, 255, 255, 0.50) " }
                        }} {...props}>
                          <Avatar
                            sx={{
                              background:
                                "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                              border: option.user_image ? "none" : "0.5px dashed #807BC6",
                              color: "#3b358a !important",
                              fontWeight: "700 !important",
                              fontSize: "14px !important",
                              marginRight: "5px"
                            }}
                            src={option.user_image}
                            alt="icon"
                          />

                          {option.first_name + " " + option.last_name + " (" + option.email + ")"}
                        </Box>
                      )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          sx={{
                            "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                              {
                                color: "rgba(255, 255, 255, 0.50) !important",
                              },
                            color: "rgba(255, 255, 255, 0.50) !important",
                          }}
                          key={index}
                          label={option.email}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderNoOptions={() => (
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{
                          mt: 2,
                          fontStyle: "italic",
                          color: "rgba(255, 255, 255, 0.50)",
                        }}
                      >
                        No Managers
                      </Typography>
                    )}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      color: "rgba(255, 255, 255, 0.50) !important",
                    }}
                  >
                    {Array.isArray(managerlist) && managerlist.length > 0
                      ? "Loading..."
                      : "No Manager"}
                  </Typography>
                )}
              </>
            </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
      <>
        <Divider />
        <Box sx={{ mt: 5 }}></Box>
        <Grid container className={styles1.settingsGrid}>
          <Grid xs={6} className={styles1.gridItem}>
            <InfoBlock
              heading="Team Director"
              description="Assign director to your team here."
              headingFontSize={20}
              headingFontWeight={700}
            />
          </Grid>
          <Grid xs={6} spacing={8} className={styles1.gridItem}>
            {/* <Grid item className={styles.item}>
              <InputField
                value={director}
                editable={isEditMode}
                startIcon={UserIcon}
                setter={setDirector}
              />
            </Grid> */}
              <Grid item className={styles.item}>
            <Box
              
              sx={{
                  backgroundColor: "rgba(64, 64, 64) !important",
                padding: "13px",
                width: "440px",
                paddingLeft:"21px",
                  borderRadius: "10px",
              }}
              className={stylesAutoComplete.formRow}
            >
              <>
                <AccountCircleIcon fontSize="small" sx={{ color: "#77767C" }} />

                {Array.isArray(directorList) && directorList.length > 0 ? (
                  <Autocomplete
                    popupIcon={
                      <Add sx={{ color: "rgba(255, 255, 255, 0.50)" }} />
                    }
                    clearIcon={
                      <KeyboardBackspaceIcon
                        sx={{ color: "rgba(255, 255, 255, 0.50)" }}
                      />
                    }
                    sx={{ width: "440px"}}
                    options={directorList}
                    getOptionLabel={(option) => option.email}
                    filterSelectedOptions
                    defaultValue={"Add Director"}
                    onChange={handleDirector}
                    value={director}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Assign Manager"
                        sx={{
                          "& input": {
                            color: "rgba(255, 255, 255, 0.50) !important",
                          },
                          "& .MuiInput-underline:hover:before": {
                            borderBottom: "none !important", // Customize the hover border color here
                          },
                          "& .MuiInput-underline:before": {
                            borderBottom: "none !important", // Customize the border color here
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: "none !important", // Customize the focus border color here
                          },
                        }}
                      />
                    )}
                      renderOption={(props, option) => (
                        <Box sx={{
                          background: "rgba(64, 64, 64, 1)", color: "rgba(255, 255, 255, 0.50)",
                          "&: hover": { backgroundColor: "rgba(64, 64, 64, 0.8) !important", color: "rgba(255, 255, 255, 0.50) " }
                        }} {...props}>
                          <Avatar
                            sx={{
                              background:
                                "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                              border: option.user_image ? "none" : "0.5px dashed #807BC6",
                              color: "#3b358a !important",
                              fontWeight: "700 !important",
                              fontSize: "14px !important",
                              marginRight: "5px"
                            }}
                            src={option.user_image}
                            alt="icon"
                          />

                          {option.first_name + " " + option.last_name + " (" + option.email + ")"}
                        </Box>
                      )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          sx={{
                            "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                              {
                                color: "rgba(255, 255, 255, 0.50) !important",
                              },
                            color: "rgba(255, 255, 255, 0.50) !important",
                          }}
                          key={index}
                          label={option.email}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderNoOptions={() => (
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{
                          mt: 2,
                          fontStyle: "italic",
                          color: "rgba(255, 255, 255, 0.50)",
                        }}
                      >
                        No Directors
                      </Typography>
                    )}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      color: "rgba(255, 255, 255, 0.50) !important",
                    }}
                  >
                    {Array.isArray(directorList) && directorList.length > 0
                      ? "Loading..."
                      : "No Director"}
                  </Typography>
                )}
              </>
            </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default AccountInfo;
