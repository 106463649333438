import { Add } from "@mui/icons-material";
import { Autocomplete, Avatar, Box, Chip, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../../../../../Components/NewDashboard/AddProducts/ScheduleMeetingForm.module.css";
import buttonStyles from "../../../../../Components/NewDashboard/AddProducts/ScheduleMeetingModal.module.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { assignUserToTeam } from "Api/Backend/backend";
import { toast } from "react-toastify";

export default function UserModal({ setOpen, team_id, users,teamData }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [allUsers,setAllUsers]= useState([]);
  const handleUser = (event, value) => {
    setSelectedUser(value);
  };
  useEffect(() => {
    
    const teamDataUserIds = teamData.map(user => user.user_id);
    const filteredSalesReps = users.filter(user => user.role === "sales_rep");
    const filteredAllUsers = filteredSalesReps.filter(user => !teamDataUserIds.includes(user.user_id)); 
    setAllUsers(filteredAllUsers);
    
  }, []);

  const assignUser = () => {
   
    if(team_id){
      const data = {
             user_id:selectedUser.user_id,
             team_id:team_id,
          };
            const response = assignUserToTeam(data)
              .then((res) => {
                toast.success("User Added");
                setOpen(false);
                selectedUser(null);
              })
              .catch((e) => console.log(e));
          };
    
    
  };
  return (
    <>
      <Box sx={{ marginTop: "20px" }} className={styles.formRow}>
        <div className={styles.sectionHeader}>User</div>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          backgroundColor: "rgba(64, 64, 64) !important",
          padding: "10px",
          borderRadius: "10px",
        }}
        className={styles.formRow}
      >
        <>
          <AccountCircleIcon fontSize="small" sx={{ color: "#77767C" }} />

          {Array.isArray(allUsers) && allUsers.length > 0 ? (
            <Autocomplete
              popupIcon={<Add sx={{ color: "rgba(255, 255, 255, 0.50)" }} />}
              clearIcon={
                <KeyboardBackspaceIcon
                  sx={{ color: "rgba(255, 255, 255, 0.50)" }}
                />
              }
              sx={{ width: "100%" }}
              options={allUsers}
              getOptionLabel={(option) => option.email}
              filterSelectedOptions
              onChange={handleUser}
              value={selectedUser}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Assign User"
                  sx={{
                    "& input": {
                      color: "rgba(255, 255, 255, 0.50) !important",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottom: "none !important", // Customize the hover border color here
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "none !important", // Customize the border color here
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none !important", // Customize the focus border color here
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Box sx={{
                  background: "rgba(64, 64, 64, 1)", color: "rgba(255, 255, 255, 0.50)",
                  "&: hover": { backgroundColor: "rgba(64, 64, 64, 0.8) !important", color: "rgba(255, 255, 255, 0.50) " }
                }} {...props}>
                  <Avatar
                    sx={{
                      background:
                        "var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
                      border: option.user_image ? "none" : "0.5px dashed #807BC6",
                      color: "#3b358a !important",
                      fontWeight: "700 !important",
                      fontSize: "14px !important",
                      marginRight: "5px"
                    }}
                    src={option.user_image}
                    alt="icon"
                  />

                  {option.first_name + " " + option.last_name + " (" + option.email + ")"}
                </Box>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    sx={{
                      "& .css-1eifpjh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":
                        {
                          color: "rgba(255, 255, 255, 0.50) !important",
                        },
                      color: "rgba(255, 255, 255, 0.50) !important",
                    }}
                    key={index}
                    label={option.email}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderNoOptions={() => (
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    mt: 2,
                    fontStyle: "italic",
                    color: "rgba(255, 255, 255, 0.50)",
                  }}
                >
                  No Users
                </Typography>
              )}
            />
          ) : (
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "rgba(255, 255, 255, 0.50) !important",
              }}
            >
              {Array.isArray(allUsers) && allUsers.length > 0
                ? "Loading..."
                : "No Users"}
            </Typography>
          )}
        </>
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          borderTop:
            "1px solid var(--dark-inverted-bg-8, rgba(255, 255, 255, 0.08))",
        }}
        className={styles.formRow}
      ></Box>
      <div className={buttonStyles.buttonContainer}>
        <button
          onClick={() => setOpen(false)}
          className={buttonStyles.cancelButton}
        >
          Cancel
        </button>
        <button
          onClick={assignUser}
          disabled={selectedUser ? false : true}
          className={buttonStyles.createButton}
        >
          Add User to Team
        </button>
      </div>
    </>
  );
}
