import React from "react";
import styles from "./TranscriptedText.module.css";
import { Typography, Box } from "@mui/material";
import Attachment from "./Attachment";

const TranscriptedText = ({ type, attachment, transcript }) => {
  return (
    <>
      <>
        {transcript.length ? (
          transcript.map((item) => (
            <Box className={styles.attachedDocument}>
              <Box className={styles.timeContainer}>
                <Typography className={styles.time}>{item.time}</Typography>
              </Box>
              <Box className={styles.infoContainer}>
                {attachment ? (
                  <>
                    <Attachment />
                  </>
                ) : (
                  <>
                    <Typography
                      className={
                        item.label == "sales_rep"
                          ? styles.nameSales
                          : styles.nameDoc
                      }
                    >
                      {item.speaker}
                    </Typography>
                    <Typography className={styles.description}>
                      {item.dialogue}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          ))
        ) : (
          <>Transcripts Processing</>
        )}
      </>
    </>
  );
};

export default TranscriptedText;
