import React from "react";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import dummyDrIcon from "../../../../Assets/dummyProfilePicture.svg";

import styles from "./user.module.css";
const UserCard = ({ title, subTitle, icon, CompanyName }) => {
  return (
    <div className={styles.container}>
      <div className={styles.managerCompany}>{CompanyName}</div>
      <InfoCard
        isUserCard={true}
        icon={icon}
        title={title}
        subTitle={subTitle}
      />
    </div>
  );
};
UserCard.defaultProps = {
  title: "Dr. Jessy Barad ",
  subTitle: "oncologist",
  icon: dummyDrIcon,
  CompanyName: "Biopharmatech inc.",
};
export default UserCard;
