import React from "react";
import { Typography } from "@mui/material";
import valueIcon from "./Assets/valueIcon.svg";
import starIcon from "./Assets/starIcon.svg";
import arrowDownIcon from "./Assets/arrowDownIcon.svg";
import styles from "./suggestion.module.css";

const SuggestionBarVertical = ({ title, suggestion, index }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <img
          src={
            index === 0
              ? valueIcon
              : index === 1
              ? starIcon
              : index === 2
              ? arrowDownIcon
              : starIcon
          }
        />
        <div>
          {/* <div className={styles.timeStamp}>04:41</div> */}
          <Typography className={styles.title}>{title}</Typography>
          <Typography className={styles.suggestion}>{suggestion}</Typography>
        </div>
      </div>
    </div>
  );
};
SuggestionBarVertical.defaultProps = {
  title: "Let’s change the topic",
  suggestion: "Ask about Michaels’s Trip to Europe and his time in miland",
};
export default SuggestionBarVertical;
