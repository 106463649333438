import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid, IconButton } from "@mui/material";
import Logo from "../../../Assets/logo.svg";
import SchoolIcon from "@mui/icons-material/School";
import HomeIconFilled from "@mui/icons-material/Home";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import productNavIcon from "../../../Assets/productNavIcon.svg";
import teamsIcon from "Pages/DirectorFlow/Assets/teamsIcon.svg";
import useWindowDimensions from "../GetWindowDimension/GetWindowDimension";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "Api/Firebase/config";
import { useSelector } from "react-redux";
import WestIcon from "@mui/icons-material/West";
import styles from "./SideNav.module.css";

const drawerWidth = 500;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const DirectorSideNav = () => {
  firebase.initializeApp(firebaseConfig);
  const [open, setOpen] = useState(false);
  const currentRoute = useSelector((state) => state.currentRoute.currentPage);
  const [selected, setSelected] = useState("");
  const [selectedText, setSelectedText] = useState("");
  let navigate = useNavigate();
  const user = useSelector((state) => state.setUser.user);
  const { height, width } = useWindowDimensions();

  const sidebarWidth =
    width <= 876 ? (width <= 834 ? "100vw" : "67px") : "94px";
  const sidebarHeight = width <= 834 ? "60px" : "100%";
  const closedMixin =
    width <= 834
      ? (theme) => ({
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: "transparent !important",
          overflowX: "hidden",
          borderRight: "0px !important",
          height: `${sidebarHeight}`,
          width: `${sidebarWidth}`,
          display: "flex",
          flexDirection: "row !important",
          alignItems: "start",
          justifyContent: "start",
          [theme.breakpoints.up("sm")]: {
            width: `${sidebarWidth}`,
          },
        })
      : (theme) => ({
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: "transparent !important",
          overflowX: "hidden",
          borderRight: "0px !important",
          height: `${sidebarHeight}`,
          width: `${sidebarWidth}`,

          [theme.breakpoints.up("sm")]: {
            width: `${sidebarWidth}`,
          },
        });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const handleLogout = () => {
    const logout = firebase
      .auth()
      .signOut()
      .then(() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/login");
      });
  };
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const navigateTo = (text, index) => {
    {
      text == "Home"
        ? navigate("/")
        : navigate(
            `/${
              index === 1
                ? "teams"
                : index === 2
                ? "my-products"
                : index === 3
                ? text
                : text
            }`
          );
    }
    setSelected(index);
    setSelectedText(text);
  };
  const iconsGray = {
    Home: (
      <IconButton
        sx={{
          borderRadius: selected == 0 ? "8px" : "none",
          background:
            selected == 0
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 0 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <HomeIconFilled
          sx={{
            color: selected == 0 ? "white" : "white",
            opacity: selected == 0 ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    Teams: (
      <IconButton
        sx={{
          borderRadius: selected == 1 ? "8px" : "none",
          background:
            selected == 1
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 1 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <img
          style={{ opacity: selected == 1 ? "1" : "0.5" }}
          src={teamsIcon}
          alt="icon"
        />
      </IconButton>
    ),
    Products: (
      <IconButton
        sx={{
          borderRadius: selected == 2 ? "8px" : "none",
          background:
            selected == 2
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 2 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <img
          style={{ opacity: selected == 2 ? "1" : "0.5" }}
          src={productNavIcon}
          alt="icon"
        />
      </IconButton>
    ),
    Interaction: (
      <IconButton
        sx={{
          borderRadius: selected == 3 ? "8px" : "none",
          background:
            selected == 3
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 3 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <PersonOutlineOutlinedIcon
          sx={{
            color: selected == 3 ? "white" : "white",
            opacity: selected == 3 ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    Library: (
      <IconButton
        sx={{
          borderRadius: selected == 4 ? "8px" : "none",
          background:
            selected == 4
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 4 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <FolderOutlinedIcon
          sx={{
            color: selected == 4 ? "white" : "white",
            opacity: selected == 4 ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
    Training: (
      <IconButton
        sx={{
          opacity: 0.2,
          borderRadius: selected == 5 ? "8px" : "none",
          background:
            selected == 5
              ? "var(--dark-inverted-bg-16, rgba(255, 255, 255, 0.16))"
              : "none",
          boxShadow:
            selected == 5 ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <SchoolIcon
          sx={{
            color: selected == 5 ? "white" : "white",
            opacity: selected == 5 ? "1" : "0.5",
          }}
        />
      </IconButton>
    ),
  };
  useEffect(() => {
    setSelected(currentRoute);
    if (currentRoute === 0) {
      setSelectedText("Home");
    } else if (currentRoute === 1) {
      setSelectedText("Teams");
    } else if (currentRoute === 2) {
      setSelectedText("Products");
    } else if (currentRoute === 3) {
      setSelectedText("Interaction");
    } else if (currentRoute === 4) {
      setSelectedText("Library");
    }
  }, [currentRoute]);
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "transparent !important",
        flexDirection: "row",
        zIndex: 0,
        width: "50px !important",
      }}
    >
      <div className={styles.headerContainer}>
        {currentRoute === 0 ? (
          <div className={styles.headerText}>
            Good Morning, {user.first_name}!
          </div>
        ) : currentRoute === 1 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>Teams</div>
          </div>
        ) : currentRoute === 2 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>My Engagements</div>
          </div>
        ) : currentRoute === 3 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>Interactions</div>
          </div>
        ) : currentRoute === 4 ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>My Library</div>
          </div>
        ) : currentRoute === 5 ? (
          <div className={styles.headerText}>Integrations</div>
        ) : (
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={styles.backIconContainer}
              onClick={() => navigate(-1)}
            >
              <WestIcon fontSize="small" sx={{ color: "white" }} />
            </div>
            <div className={styles.headerText}>My Profile</div>
          </div>
        )}
      </div>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          backgroundColor: "transparent !important",
          width: "50px !important",
          flexDirection: "row !important",
        }}
      >
        <DrawerHeader
          sx={
            width <= 834
              ? {
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 20px",
                }
              : {
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }
          }
        >
          <img alt="icon" src={Logo} onClick={() => navigateTo("Home")} />
        </DrawerHeader>
        <List
          sx={
            width <= 834 ? { display: "flex", padding: "0px !important" } : {}
          }
        >
          {[
            "Home",
            "Teams",
            "Products",
            // "Interaction",
            // "Library",
            // "Training",
          ].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block", width: "100%" }}
            >
              <ListItemButton
                sx={
                  width <= 876
                    ? {
                        width: 60,
                        minHeight: 57,
                        justifyContent: open ? "initial" : "center",
                        px: 4,
                      }
                    : {
                        width: 94,
                        minHeight: 80,
                        justifyContent: open ? "initial" : "center",
                        px: 5,
                      }
                }
                onClick={() => {
                  navigateTo(text, index);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <>{iconsGray[text]}</>

                    <>
                      {width <= 876 ? null : (
                        <Typography
                          className={styles.menuText}
                          style={{
                            color: "#ffffff",
                          }}
                          fontSize={12}
                          sx={{
                            mt: 1,
                            opacity:
                              selectedText == text
                                ? "1"
                                : index === 5
                                ? "0.2"
                                : "0.5",
                          }}
                          fontWeight={700}
                        >
                          {text}
                        </Typography>
                      )}
                    </>
                  </Grid>
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box
          sx={
            width <= 834
              ? {
                  flexGrow: 0.8,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  height: "100%",
                  justifyContent: "center",
                }
              : {
                  flexGrow: 0.7,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
          }
        >
          <Avatar
            alt="icon"
            sx={{
              cursor: "pointer",
              border: currentRoute === 6 ? "3px solid #9747FF" : "none",
            }}
            src={user.user_image}
            onClick={() => navigateTo("Profile")}
            style={{ fontSize: "50px", color: "white" }}
          />
        </Box>
        <Box
          sx={
            width <= 834
              ? {
                  flexGrow: 0.1,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  height: "100%",
                  justifyContent: "center",
                }
              : {
                  flexGrow: 0.15,
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
          }
        >
          <LogoutIcon
            sx={{ cursor: "pointer" }}
            onClick={handleLogout}
            style={{ color: "white" }}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default DirectorSideNav;
