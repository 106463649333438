import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import styles from "./Analytics.module.css";
import WeeklyCalender from "Components/SalesRep/WeeklyCalender/WeeklyCalender";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import icon from "../../../Components/Common/DetailsCard/Assets/icon.png";
import DetailsCard from "Components/Common/DetailsCard/DetailsCard";
import {
  monthlyRecord,
  monthlyRecordTable,
  recordingCards,
} from "Api/Backend/backend";
import axios from "axios";
import { baseUrl } from "Api/Backend/backend";
import { useDispatch } from "react-redux";
import { setCurrentRoute } from "Store/features/currentRoute";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const NewAnalytics = ({ dates, setDates, selectedMonth, setSelectedMonth,selectedYear }) => {
  const currentDate = new Date();
  const [firstDate, setFirstDate] = useState(new Date());
  const [set, setter] = useState("2");

  const formattedDate = firstDate.toString();
  const dateValue = new Date(formattedDate).getDate();
  const [tableData, setTableData] = useState([]);
  const [monthlyTableData, setMonthlyTableData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const dispatch = useDispatch();

  const [month, setMonth] = useState(new Date().getMonth());
  const [graphData, setGraphData] = useState([]);

  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const Monthdates = [...Array(daysInMonth).keys()].map((i) => i + 1);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          stepSize: 50,
          min: 0,
          max: 100,
        },
        scaleLabel: {
          display: true,
          labelString: "Y-Axis Label",
        },
      },
      x: {
        scaleLabel: {
          display: true,
          labelString: "X-Axis Label",
        },
      },
    },

    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          width: "100%",

          color: "rgba(255, 255, 255, 0.1)", // Change the color of x-axis grid lines
        },
        ticks: {
          stepSize: 20,
          min: 0,
          max: 100,
        },
        scaleLabel: {
          display: true,
          labelString: "Y-Axis Label",
        },
      },
      x: {
        grid: {
          width: "100%",
          color: "rgba(255, 255, 255, 0.1)", // Change the color of x-axis grid lines
        },
        ticks: {
          precision: 0, // Set precision to 0 to display integers
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = [
    "7 am",
    "8 am",
    "9 am",
    "10 am",
    "11 am",
    "12 pm",
    "1 pm",
    "2 pm",
    "3 pm",
    "4 pm",
    "5 pm",
    "6 pm",
    "7 pm",
    "8 pm",
    "9 pm",
    "10 pm",
    "11 pm",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Affect Score using AI",
        data: graphData,
        backgroundColor: graphData.map((value) => {
          if (value <30) {
            return "red"; // red color for values less than 30
          } else if (value < 50) {
            return "orange"; // Orange color for values between 30 and 50
          } else {
            return "green"; // Green color for values between 50 and 100
          }
        }),
      },
    ],
  };

  const backgroundColors = monthlyData.map((value) => {
    if (value < 30) {
      return "red"; // Red color for values less than 30
    } else if (value < 50) {
      return "orange"; // Orange color for values between 30 and 50
    } else {
      return "green"; // Green color for values greater than or equal to 70
    }
  });

  const data2 = {
    labels: dates.length > 0 ? dates : Monthdates,
    datasets: [
      {
        label: "none",
        data: monthlyData,
        backgroundColor: backgroundColors,
      },
    ],
  };
  useEffect(() => {
    dispatch(setCurrentRoute(1));
  }, []);

  const getTableData = async (id, date) => {
    const response = await recordingCards(id, date)
      .then((response) => {
        setTableData(response);
      })
      .catch((e) => console.log(e));
  };

  const getMonthlyData = async (id, month,year) => {
    const res = await monthlyRecord(id, month,year)
      .then((response) => {
        const scores = [];
        for (let i = 1; i <= 30; i++) {
          const score = response.find((record) => record.date === i);
          scores.push(score ? score.score : 0);
        }
        setMonthlyData(scores);
      })
      .catch((e) => console.log(e));
  };

  const getTableDataMonthly = async (id, month) => {
    const res = await monthlyRecordTable(id, month)
      .then((response) => {
        setMonthlyTableData(response);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      const fetchData = async () => {
        const mon = parseInt(month) + 1;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {
          const response = await axios.post(
            `${baseUrl}hour_success/`,
            { user_id: user.user_id, month: mon, timezone: timeZone },
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
          const data = response.data;

          // Find the selected date
          const selectedDate = data.find((item) => item.date === dateValue); // Replace 'selectedDay' with your selected date value
          if (selectedDate) {
            // Insert the score list into a list
            const scoreList = selectedDate.score_list;
            setGraphData(scoreList);

            // Use the scoreList as needed
            // ...
          } else {
            setGraphData([]);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };

      fetchData();
    }
  }, [month, formattedDate, set]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    if (user) {
      getTableData(user.user_id, formattedDate.toString());
    }
  }, [formattedDate, firstDate, set]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    const month = parseInt(selectedMonth) + 1;

    if (user) {
      getTableDataMonthly(user.user_id, month);
    }
  }, [selectedMonth, set]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    const month = parseInt(selectedMonth) + 1;
    if (user) {
      getMonthlyData(user.user_id, month,selectedYear);
    }
  }, [selectedMonth, set, dates,selectedYear]);

  return (
    <React.Fragment>
      <CssBaseline />

      <div className={styles.graphContainer}>
        {/* <Box sx={{ paddingBottom: "20px", paddingTop: "5px" }}>
              <WeeklyCalender
                isHome={false}
                totalDays={30}
                month={month}
                setMonth={setMonth}
                setFirstDate={setFirstDate}
                setDates={setDates}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                set={set}
                setter={setter}
              />
            </Box> */}
        {/* <Divider sx={{ color: "#E9E9E9", marginBottom: "40px" }} /> */}
        {set === "1" && (
          <Bar
            style={{ width: "100%", height: "500px" }}
            options={options}
            data={data}
          />
        )}
        {set === "2" && (
          <Bar
            style={{ width: "100%", height: "250px" }}
            options={options2}
            data={data2}
          />
        )}
      </div>

      {/* <div
            style={{
              height: "700px",
              overflow: "auto",
              background: "white",
              borderRadius: "4px",
              width: "100%",
              padding: "30px",
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{
                "@media (max-width: 500px)": {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
              }}
            >
              {set === "1" &&
                tableData.length > 0 &&
                tableData.map((t) => (
                  <Grid item key={t.id} xs={12} sm={6} md={4} lg={3}>
                    <DetailsCard
                      status="success"
                      title={t.doctor_name}
                      meetingId={t.mid}
                      meeting_title={t.start_time}
                      dots={t.dots}
                      call_time={t.call_duration}
                      avgScore={t.success_score}
                      imageSrc={t.doctor_image ? t.doctor_image : icon}
                    />
                  </Grid>
                ))}
              {set === "2" &&
                monthlyTableData.length > 0 &&
                monthlyTableData.map((t) => (
                  <Grid item key={t.id} xs={12} sm={6} md={4} lg={3}>
                    <DetailsCard
                      status="success"
                      title={t.doctor_name}
                      meetingId={t.mid}
                      meeting_title={t.start_time}
                      dots={t.dots}
                      call_time={t.call_duration}
                      avgScore={t.success_score}
                      imageSrc={t.doctor_image ? t.doctor_image : icon}
                    />
                  </Grid>
                ))}
            </Grid>
          </div> */}
    </React.Fragment>
  );
};

export default NewAnalytics;
NewAnalytics.defaultProps = {
  dates: [],
  setDates: () => {},
  setSelectedMonth: () => {},
};
