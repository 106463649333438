import React, { useState } from "react";
import styles from "./Modal.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeleteMeetingModal = ({ isOpen, onClose, onDelete, title }) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      className={styles.dialogue}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        className={styles.dialogueTitle}
      >
        {title}
      </BootstrapDialogTitle>

      <DialogActions sx={{ paddingRight: "20px !important", height: "70px" }}>
        <Button className={styles.cancelButton} onClick={onClose}>
          Cancel
        </Button>
        <Button className={styles.scheduleButton} onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DeleteMeetingModal;
DeleteMeetingModal.defaultProps = {
  isOpen: () => {},
  onClose: () => {},
  onDelete: () => {},
  title: "Pass title in props",
};
