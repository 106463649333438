import React from "react";
import styles from "./FeedbackCards.module.css";
import { Typography, Box } from "@mui/material";
import AttentionIcon from "../../Assets/attention.svg";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setFeedbacks } from "Store/features/endMeetingStats";

const GenericFeedbackCard = ({ category, prompt }) => {
  const [show, setShow] = useState(false);
  const cardColor =
    category == "attention"
      ? "#EDFFD8"
      : category == "mood"
      ? "#AFFFF2"
      : "#D5EAFF";

  const dispatch = useDispatch();
  const generateTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const time = `${hours}:${minutes}`;
    return now;
  };
  const dispatchFeedback = () => {
    dispatch(
      setFeedbacks({
        feedback_text: prompt,
        originating_time: generateTime(),
      })
    );
  };

  function popupBox() {
    setTimeout(() => setShow(false), 5000);
  }

  useEffect(() => {
    if (prompt) {
      setShow(true);
      dispatchFeedback();
      popupBox();
    }
  }, [prompt]);

  return (
    <>
      {show && prompt ? (
        <>
          <Box
            className={styles.attachedDocument}
            sx={{ backgroundColor: cardColor }}
          >
            <Box className={styles.imageContainer}>
              <img alt="icon" src={AttentionIcon} />
            </Box>
            <Box className={styles.infoContainer}>
              <Typography className={styles.name}>{prompt}</Typography>
              <Typography className={styles.description}>
                Prompted for speeding up your talking points.
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <Box className={styles.attachedDocumentEmpty}></Box>
      )}
    </>
  );
};

export default GenericFeedbackCard;
