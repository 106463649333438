import React from "react";
import glamourIcon from "../Assets/glamourIcon.svg";
import engagementIcon from "../Assets/engagementIcon.svg";
import dummyDrIcon from "../Assets/dummyDrIcon.svg";
import styles from "./leaderboard.module.css";
import { Avatar } from "@mui/material";
const LeaderBoard = ({
  totalEngagements,
  successScore,
  mostContactedDoctor,
  position,
  totalReps,
  icon,
  isManager,
}) => {
  return (
    <div className={isManager ? styles.managerContainer : styles.container}>
      <div className={styles.flexRow}>
        <img src={glamourIcon} alt="icon" />
        <div className={styles.flexColumn}>
          <div className={styles.title}>
            {isManager ? "Scheduled Engagements" : "LeaderBoard"}
          </div>
          <div className={styles.titleInfo}>
            {position === "" || totalReps === "" ? (
              <> N/A</>
            ) : (
              <>
                {isManager ? (
                  <>{position}</>
                ) : (
                  <>
                    #{position} of {totalReps}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.flexRow}>
        <img src={engagementIcon} alt="icon" />
        <div className={styles.flexColumn}>
          <div className={styles.title}>
            {isManager ? "AVG success score" : "My engagements"}
          </div>
          <div className={styles.titleInfo}>{successScore}%</div>
        </div>
      </div>
      {isManager ? (
        <div className={styles.flexRow}>
          <img src={engagementIcon} alt="icon" />
          <div className={styles.flexColumn}>
            <div className={styles.title}>My engagements</div>
            <div className={styles.titleInfo}>{totalEngagements}</div>
          </div>
        </div>
      ) : (
        <div className={styles.flexRow}>
          <Avatar src={icon} alt="Ucon" />
          <div className={styles.flexColumn}>
            <div className={styles.title}>top interaction</div>
            <div className={styles.titleInfo}>
              {mostContactedDoctor === "" ? "N/A" : mostContactedDoctor}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
LeaderBoard.defaultProps = {
  totalEngagements: 0,
  mostContactedDoctor: "N/A",
  isManager: false,
  successScore: 0,
};
export default LeaderBoard;
