import { createColumnHelper } from "@tanstack/react-table";
import GenericListItem from "Components/Common/GenericListItem/GenericListItem";
import { Box } from "@mui/system";
import HalfDonutChartSmall from "Components/SalesRep/HalfDonutChartSmall.js/HalfDonutChartSmall";
import { Container, Typography } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import medicineIcon from "../../../../Assets/medicineIcon.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styles from "./Recordings.module.css";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";
import MeetingResult from "Components/NewDashboard/MeetingResult/MeetingResult";
import MeetingsDataBox from "Components/NewDashboard/MeetingsDataBox/MeetingsDataBox";

const dummyOptions = [
  { id: "0", value: "All Time" },
  { id: "1", value: "Today" },
  { id: "2", value: "Yesterday" },
  { id: "3", value: "This Week" },
];
function convertToCustomDateFormat(dateTimeString) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Create a new Date object by passing the dateTimeString
  var date = new Date(dateTimeString);

  // Extract day, month, and year components
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear() % 100; // Extracting the last two digits of the year

  // Format the date as required
  var formattedDate = day + " " + months[monthIndex] + " '" + year;

  return formattedDate;
}
function convertToCustomTimeFormat(dateTimeString) {
  // Create a new Date object by passing the dateTimeString
  var date = new Date(dateTimeString);

  // Extract hour and minute components
  var hour = date.getHours();
  var minute = date.getMinutes();

  // Convert hour to 12-hour format
  var formattedHour = hour % 12 || 12; // Handle midnight (0) as 12
  var period = hour < 12 ? "AM" : "PM";

  // Format the time as required
  var formattedTime =
    formattedHour + ":" + (minute < 10 ? "0" : "") + minute + " " + period;

  return formattedTime;
}

const mySplit = (text, index) => {
  const arr = text.split("!");
  return arr[index];
};

const columnHelper = createColumnHelper();

const columnsDef = [
  columnHelper.accessor("title", {
    header: () => "Title",
    cell: (info) => (
      <Typography fontSize={16} fontWeight={500} className={styles.firstTd}>
        {info.renderValue()}
      </Typography>
    ),
  }),
  columnHelper.accessor("doctor_name", {
    header: () => "Client",
    cell: (info) => (
      <Container
        sx={{
          width: "100%",
          padding: "0px !important",
          color: "#636269 !important",
          gap: "8px !important",
        }}
      >
        <GenericListItem
          avatar={info.row.original.doctor_image}
          secondaryText={"Oncologist"}
          text={info.renderValue()}
          isClient={true}
          variant="table"
          name={info.renderValue()}
        />
      </Container>
    ),
  }),
  columnHelper.accessor("doctor_name", {
    header: () => "type",
    cell: (info) => (
      <Container
        sx={{
          width: "100%",
          padding: "0px !important",
          color: "#636269 !important",
          gap: "8px !important",
        }}
      >
        <GenericListItem
          avatar={medicineIcon}
          secondaryText={"Medicine"}
          text={"Paracetamol OD 340"}
          isClient={true}
          variant="table"
          name={info.renderValue()}
        />
      </Container>
    ),
  }),

  columnHelper.accessor("start_time", {
    header: () => "date",
    cell: (info) => (
      <Container
        sx={{
          // width: "120%",
          padding: "0px !important",
          // transform: "translate(-5px,0px)",
        }}
      >
        <GenericListItem
          text={convertToCustomDateFormat(info.renderValue())}
          isClient={false}
          secondaryText={`${convertToCustomTimeFormat(
            info.renderValue()
          )} - ${convertToCustomTimeFormat(info.row.original.end_time)}`}
          variant="table"
        />
      </Container>
    ),
  }),
];

export default columnsDef;
